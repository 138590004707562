// @flow

import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape, defineMessages } from 'react-intl';
import moment from 'moment-timezone';
import { SELECT_NEXT_PERIOD, SELECT_PREV_PERIOD, RESET_CHART_PAGE } from 'Client/actions/types';
import {
  isOneHourPassed,
  subTimeUnit,
  timeUnitStyle,
  timeFormat,
  nextPeriod,
  prevPeriod,
} from 'Client/lib/chartUtils';
import Prefixer from 'Client/lib/Prefixer';
import type { State } from 'Client/types/state';

const prefixer = new Prefixer();

const arrowLeft = require('Client/images/arrow_left.png');
const arrowRight = require('Client/images/arrow_right.png');

const style = prefixer.prefix({
  periodArea: {
    color: '#847965',
    fontSize: '1.2em',
    fontWeight: 'bold',
    padding: '12px 0px 8px',
    display: 'table',
    width: '80%',
    margin: '0px auto',
  },
  moveButton: {
    display: 'table-cell',
    textAlign: 'center',
    color: '#ffbd00',
  },
  arrow: {
    width: '12px',
  },
  arrowDisable: {
    opacity: '0.3',
    visibility: 'hidden',
  },
});

const canProceed = (unit, period) => {
  const now = moment();
  const target = moment(period, timeFormat[unit]);
  return now.diff(target, timeUnitStyle[unit].toMulti) > 0;
};

const canGoBack = (unit, period, startDate) => {
  if (!startDate) {
    return false;
  }
  const start = moment(moment(startDate).format(timeFormat[unit]), timeFormat[unit]);
  const target = moment(period, timeFormat[unit]);
  const diffUnit = unit === 'week' ? 'days' : timeUnitStyle[unit].toMulti;
  return start.diff(target, diffUnit) < 0;
};

const canProcess = (actionType, unit, period, startDate) =>
  actionType === SELECT_NEXT_PERIOD ? canProceed(unit, period) : canGoBack(unit, period, startDate);

const clickButton = (
  requesting,
  unitEnergyList,
  subUnitEnergyList,
  unit,
  period,
  currentTime,
  startDate,
  dispatch,
  actionType,
  router
) => {
  if (requesting) {
    return;
  }
  if (!canProcess(actionType, unit, period, startDate)) {
    return;
  }
  if (isOneHourPassed(currentTime)) {
    dispatch({ type: RESET_CHART_PAGE });
  }
  dispatch({ type: actionType });
  const movedPeriod =
    actionType === SELECT_NEXT_PERIOD ? nextPeriod(unit, period) : prevPeriod(unit, period);

  router.history.push(`/chart/${unit}/${movedPeriod}`);
};

const messages = defineMessages({
  year: {
    id: 'chartPeriod.year',
    description: '2017年 or 2017',
  },
  month: {
    id: 'chartPeriod.month',
    description: '2017年6月 or 6 2017',
  },
  weekOf: {
    id: 'chartPeriod.weekOf',
    description: '6月12日の週 or Week of {month1} {day}',
  },
  day: {
    id: 'chartPeriod.day',
    description: '6月12日 or {month1} {day}',
  },
});

const chartTitleYear = (year, intl) => intl.formatMessage(messages.year, { year });
const chartTitleMonth = (year, month, monthName, intl) =>
  intl.formatMessage(messages.month, { year, month, monthName });
const chartTitleWeek = (month, monthName, day, intl) =>
  intl.formatMessage(messages.weekOf, { month, monthName, day });
const chartTitleday = (month, monthName, day, intl) =>
  intl.formatMessage(messages.day, { month, monthName, day });

const chartTitle = (unit, period, intl) => {
  const [year, month, day] = moment(period, timeFormat[unit])
    .format(intl.formatMessage({ id: `formatDate.${unit}` }))
    .split('-');

  const monthName =
    month &&
    moment(month, 'MM')
      .locale(intl.locale)
      .format('MMMM');
  switch (unit) {
    case 'day':
      return chartTitleday(month, monthName, day, intl);
    case 'week':
      return chartTitleWeek(month, monthName, day, intl);
    case 'month':
      return chartTitleMonth(year, month, monthName, intl);
    case 'year':
      return chartTitleYear(year, intl);
    default:
      return '';
  }
};

type StateProps = {|
  requesting: boolean,
  unitEnergyList: {
    week?: string,
    month?: string,
    year?: string,
    sellEnergy: number,
    buyEnergy: number[],
    consumeEnergy?: number,
    pgEnergy?: number,
  }[],
  subUnitEnergyList: {
    day?: string,
    week?: string,
    month?: string,
    sellEnergy: number,
    buyEnergy: number[],
    consumeEnergy?: number,
    pgEnergy?: number,
  }[],
  unit: string,
  period: string,
  currentTime: string,
  startDate: string,
|};
type OwnProps = {|
  intl: intlShape,
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

export const PureChartPeriod = (
  {
    requesting,
    unitEnergyList,
    subUnitEnergyList,
    unit,
    period,
    currentTime,
    startDate,
    dispatch,
    intl,
  }: Props,
  { router }: Object
): Node => (
  <div style={style.periodArea}>
    <div
      style={style.moveButton}
      onClick={() => {
        clickButton(
          requesting,
          unitEnergyList,
          subUnitEnergyList,
          unit,
          period,
          currentTime,
          startDate,
          dispatch,
          SELECT_PREV_PERIOD,
          router
        );
      }}
    >
      <img
        style={
          canGoBack(unit, period, startDate) && !requesting
            ? style.arrow
            : { ...style.arrow, ...style.arrowDisable }
        }
        src={arrowLeft}
        alt="left"
      />
    </div>
    <div>{chartTitle(unit, period, intl)}</div>
    <div
      style={style.moveButton}
      onClick={() =>
        clickButton(
          requesting,
          unitEnergyList,
          subUnitEnergyList,
          unit,
          period,
          currentTime,
          startDate,
          dispatch,
          SELECT_NEXT_PERIOD,
          router
        )
      }
    >
      <img
        style={
          canProceed(unit, period) && !requesting
            ? style.arrow
            : { ...style.arrow, ...style.arrowDisable }
        }
        src={arrowRight}
        alt="right"
      />
    </div>
  </div>
);

const requesting = ({ energy, applianceEnergy, chartTime }) =>
  energy[timeUnitStyle[chartTime.unit].toRequesting] ||
  energy[timeUnitStyle[subTimeUnit[chartTime.unit]].toRequesting] ||
  applianceEnergy.requesting;

PureChartPeriod.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state: State): StateProps => ({
  requesting: requesting(state),
  unitEnergyList: state.energy[timeUnitStyle[state.chartTime.unit].toUnit],
  subUnitEnergyList: state.energy[timeUnitStyle[subTimeUnit[state.chartTime.unit]].toUnit],
  unit: state.chartTime.unit,
  period: state.chartTime.period,
  currentTime: state.chartTime.currentTime,
  startDate: state.userInfo.learningStartDate,
});

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  injectIntl(PureChartPeriod)
);
