// @flow

import React from 'react';
import type { Node } from 'react';
import { intlShape } from 'react-intl';
import Currency from 'Client/components/utils/Currency';
import Prefixer from 'Client/lib/Prefixer';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  total: {
    textAlign: 'right',
    display: 'inline-block',
    float: 'right',
    width: 'auto',
    fontWeight: 'bold',
  },
  kwh: {
    color: '#b1a99a',
    textAlign: 'right',
    fontWeight: 'normal',
    fontSize: '0.7em',
    clear: 'both',
  },
});

type Props = {
  unitCharge: number,
  unitEnergy: number,
  intl: intlShape,
};

const TopChargeItem = ({ unitCharge, unitEnergy, intl }: Props): Node => (
  <div>
    <div style={style.total}>
      <Currency value={unitCharge} />
    </div>
    <div style={style.kwh}>
      {intl.formatNumber(unitEnergy, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}{' '}
      kWh
    </div>
  </div>
);

export default TopChargeItem;
