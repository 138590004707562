// @flow

import React from 'react';
import type { Node } from 'react';
import { injectIntl, intlShape } from 'react-intl';

type Props = {
  intl: intlShape,
  value: number,
  style?: Object,
};

const Currency = (props: Props): Node => {
  const fractionDigits = props.intl.formatMessage({ id: 'currency.fractionDigits' });
  const currency = props.intl.formatNumber(props.value, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
  return (
    <span style={props.style}>
      {props.intl.formatMessage({ id: 'application.currency' }, { currency })}
    </span>
  );
};

Currency.defaultProps = {
  style: {},
};

export default injectIntl(Currency);

export const CurrencyForTest = Currency;
