// @flow

import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { Link } from 'react-router-dom';
import ReactScroll from 'react-scroll';
import { Animate } from 'react-move';
import { GO_TO_NEXT_PAGE, BACK_TO_PREV_PAGE, TUTORIAL_FINISHED } from 'Client/actions/types';
import Prefixer from 'Client/lib/Prefixer';
import type { State } from 'Client/types/state';
import dotOrange from 'Client/images/tutorial/mark_orange.png';
import dotWhite from 'Client/images/tutorial/mark_white.png';
import usage1 from 'Client/images/tutorial/usage1_ja.png';
import usage2 from 'Client/images/tutorial/usage2_ja.png';
import usage3 from 'Client/images/tutorial/usage3_ja.png';
import usage4 from 'Client/images/tutorial/usage4_ja.png';
import usage5 from 'Client/images/tutorial/usage5_ja.png';

const prefixer = new Prefixer();

const tutorialpagesJa = [usage1, usage2, usage3, usage4, usage5];

const style = prefixer.prefix({
  progress: {
    margin: '30px auto',
    width: '50%',
    height: '10px',
    display: 'table',
  },
  progressCell: {
    width: '20%',
    display: 'table-cell',
  },
  dotImage: {
    height: '10px',
    width: '10px',
    display: 'block',
    margin: 'auto',
  },
  tutorialImageWrapper: {
    width: '100%',
    margin: '0 auto',
    textAlign: 'center',
  },
  tutorialImage: {
    width: '100%',
    maxWidth: '480px',
  },
  moveButtonWrapper: {
    margin: '20px auto 0px',
    textAlign: 'center',
  },
  button: {
    display: 'inline-block',
    color: '#fff',
    fontWeight: 'bold',
    borderRadius: '5px',
    width: '80px',
    height: '42px',
    lineHeight: '42px',
    margin: '0px auto 20px',
    textAlign: 'center',
  },
  buttonNext: {
    background: '#ffb835',
  },
  buttonPrev: {
    background: '#888',
  },
  centerSpace: {
    display: 'inline-block',
    width: '10px',
    height: '10px',
    padding: '10px',
  },
  skip: {
    display: 'block',
    clear: 'both',
    textAlign: 'center',
    margin: '0px auto 50px',
  },
  skipLink: {
    color: '#555',
    textDecoration: 'underline',
    fontWeight: 'bold',
    fontSize: '0.8em',
  },
  skipDummy: {
    marginBottom: '69px',
  },
});

const dots = page => {
  const a = [dotWhite, dotWhite, dotWhite, dotWhite, dotWhite];
  a[page] = dotOrange;
  return a;
};

const renderProgress = page => (
  <div style={style.progress}>
    {dots(page).map((dot, _i) => {
      const i = _i;
      return (
        <div key={`page-progress-${page}-${i}`} style={style.progressCell}>
          <img src={dot} alt={'dot'} style={style.dotImage} />
        </div>
      );
    })}
  </div>
);

const renderContent = page => {
  const origin = { opacity: 0 };
  const target = { opacity: 1 };
  return (
    <Animate default={origin} data={target} duration={1000} easing="easeCubicOut">
      {data => (
        <div style={{ ...style.tutorialImageWrapper, opacity: data.opacity }}>
          <img src={tutorialpagesJa[page]} alt="content" style={style.tutorialImage} />
        </div>
      )}
    </Animate>
  );
};

const renderNextButton = (dispatch, intl) => (
  <div
    style={{ ...style.button, ...style.buttonNext }}
    onClick={() => dispatch({ type: GO_TO_NEXT_PAGE })}
  >
    {intl.formatMessage({ id: 'tutorialPage.next' })}
  </div>
);

const renderPrevButton = (dispatch, intl) => (
  <div
    style={{ ...style.button, ...style.buttonPrev }}
    onClick={() => dispatch({ type: BACK_TO_PREV_PAGE })}
  >
    {intl.formatMessage({ id: 'tutorialPage.prev' })}
  </div>
);

const renderStartButton = (dispatch, intl) => (
  <Link to="/top" className="TopPage" onClick={() => dispatch({ type: TUTORIAL_FINISHED })}>
    <div style={{ ...style.button, ...style.buttonNext }}>
      {intl.formatMessage({ id: 'tutorialPage.start' })}
    </div>
  </Link>
);

const renderMove = (page, dispatch, intl) => (
  <div style={style.moveButtonWrapper}>
    {page !== 0 && renderPrevButton(dispatch, intl)}
    {page !== 0 && <div style={style.centerSpace} />}
    {page === 4 ? renderStartButton(dispatch, intl) : renderNextButton(dispatch, intl)}
  </div>
);

const renderSkip = (page, dispatch, intl) =>
  page === 4 ? (
    <div style={style.skipDummy} />
  ) : (
    <div style={style.skip}>
      <Link
        to="/top"
        className="TopPage"
        style={style.skipLink}
        onClick={() => dispatch({ type: TUTORIAL_FINISHED })}
      >
        {intl.formatMessage({ id: 'tutorialPage.skip' })}
      </Link>
    </div>
  );

type StateProps = {|
  page: number,
|};
type OwnProps = {|
  intl: intlShape,
|};
type Props = {
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
};

export const PureTutorialPage = ({ page, dispatch, intl }: Props): Node => (
  <div>
    {renderProgress(page)}
    {renderContent(page)}
    {renderMove(page, dispatch, intl)}
    {renderSkip(page, dispatch, intl)}
    {ReactScroll.animateScroll.scrollToTop()}
  </div>
);

const mapStateToProps = (state: State): StateProps => ({
  page: state.tutorial.page,
});

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  injectIntl(PureTutorialPage)
);
