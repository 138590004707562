export const ENTER_APPLICATION = 'ENTER_APPLICATION';
export const FORCIBLY_TRANSITION_TO_LOGIN = 'FORCIBLY_TRANSITION_TO_LOGIN';

export const LOAD_TOP_PAGE = 'LOAD_TOP_PAGE';
export const LOAD_TOP_PAGE_LATEST_TWO_HOURS = 'LOAD_TOP_PAGE_LATEST_TWO_HOURS';
export const SELECT_NEXT_HOUR = 'SELECT_NEXT_HOUR';
export const SELECT_PREV_HOUR = 'SELECT_PREV_HOUR';
export const SELECT_CLICKED_HOUR = 'SELECT_CLICKED_HOUR';

export const LOAD_SETTING_PAGE = 'LOAD_SETTING_PAGE';
export const CHANGE_SELL_PRICE = 'CHANGE_SELL_PRICE';
export const CHANGE_BUY_PRICE = 'CHANGE_BUY_PRICE';
export const SWITCH_PG_FLAG = 'SWITCH_PG_FLAG';
export const SWITCH_APL_DISPLAY_FLAG = 'SWITCH_APL_DISPLAY_FLAG';
export const CHANGE_NORMAL_PLAN_PRICE = 'CHANGE_NORMAL_PLAN_PRICE';
export const CHANGE_PLAN_PRICE = 'CHANGE_PLAN_PRICE';
export const CHANGE_PLAN_TIME_START = 'CHANGE_PLAN_TIME_START';
export const ADD_PRICE_PLAN = 'ADD_PRICE_PLAN';
export const SUB_PRICE_PLAN = 'SUB_PRICE_PLAN';

export const RESET_TUTORIAL = 'RESET_TUTORIAL';
export const TUTORIAL_FINISHED = 'TUTORIAL_FINISHED';
export const GO_TO_NEXT_PAGE = 'GO_TO_NEXT_PAGE';
export const BACK_TO_PREV_PAGE = 'BACK_TO_PREV_PAGE';

export const OPEN_PRICE_PLAN_POP_UP = 'OPEN_PRICE_PLAN_POP_UP';
export const GO_TO_NORMAL_POP_UP = 'GO_TO_NORMAL_POP_UP';
export const GO_TO_MULTISTAGE_POP_UP = 'GO_TO_MULTISTAGE_POP_UP';

export const OPEN_POP_UP = 'OPEN_POP_UP';
export const CLOSING_POP_UP = 'CLOSING_POP_UP';
export const CLOSED_POP_UP = 'CLOSED_POP_UP';
export const SETTING_NORMAL_PRICE_PLAN_POP_UP = 'SETTING_NORMAL_PRICE_PLAN_POP_UP';
export const SETTING_MULTISTAGE_PRICE_PLAN_POP_UP = 'SETTING_MULTISTAGE_PRICE_PLAN_POP_UP';

export const REQUEST_CHANGE_MAIL = 'REQUEST_CHANGE_MAIL';
export const SUCCESS_CHANGE_MAIL = 'SUCCESS_CHANGE_MAIL';
export const FAILURE_CHANGE_MAIL = 'FAILURE_CHANGE_MAIL';
export const RESET_CHANGE_MAIL = 'RESET_CHANGE_MAIL';
export const REQUEST_CHANGE_PASSWORD = 'REQUEST_CHANGE_PASSWORD';
export const SUCCESS_CHANGE_PASSWORD = 'SUCCESS_CHANGE_PASSWORD';
export const FAILURE_CHANGE_PASSWORD = 'FAILURE_CHANGE_PASSWORD';
export const RESET_CHANGE_PASSWORD = 'RESET_CHANGE_PASSWORD';
export const REQUEST_CERTIFICATION = 'REQUEST_CERTIFICATION';
export const SUCCESS_CERTIFICATION = 'SUCCESS_CERTIFICATION';
export const FAILURE_CERTIFICATION = 'FAILURE_CERTIFICATION';
export const INIQUITY_CERTIFICATION = 'INIQUITY_CERTIFICATION';
export const SUCCESS_PASSWORD_RESET_CERTIFICATION = 'SUCCESS_PASSWORD_RESET_CERTIFICATION';
export const RESET_CERTIFICATION = 'RESET_CERTIFICATION';

export const REQUEST_RESET_PASSWORD = 'REQUEST_RESET_PASSWORD';
export const SUCCESS_RESET_PASSWORD = 'SUCCESS_RESET_PASSWORD';
export const FAILURE_RESET_PASSWORD = 'FAILURE_RESET_PASSWORD';
export const RESET_RESET_PASSWORD = 'RESET_RESET_PASSWORD';

export const LOAD_CHART_PAGE = 'LOAD_CHART_PAGE';
export const RESET_CHART_PAGE = 'RESET_CHART_PAGE';
export const CHANGE_CHART_UNIT = 'CHANGE_CHART_UNIT';
export const CHANGE_CHART_TYPE = 'CHANGE_CHART_TYPE';
export const CHANGE_SELECTED_APPLIANCE = 'CHANGE_SELECTED_APPLIANCE';
export const SELECT_NEXT_PERIOD = 'SELECT_NEXT_PERIOD';
export const SELECT_PREV_PERIOD = 'SELECT_PREV_PERIOD';

export const REQUEST_API_LOGIN_INFO = 'REQUEST_API_LOGIN_INFO';
export const SUCCESS_API_LOGIN_INFO = 'SUCCESS_API_LOGIN_INFO';
export const FAILURE_API_LOGIN_INFO = 'FAILURE_API_LOGIN_INFO';

export const REQUEST_API_LOGIN = 'REQUEST_API_LOGIN';
export const SUCCESS_API_LOGIN = 'SUCCESS_API_LOGIN';
export const FAILURE_API_LOGIN = 'FAILURE_API_LOGIN';

export const REQUEST_API_LOGOUT = 'REQUEST_API_LOGOUT';
export const SUCCESS_API_LOGOUT = 'SUCCESS_API_LOGOUT';
export const FAILURE_API_LOGOUT = 'FAILURE_API_LOGOUT';

export const REQUEST_API_USER_INFO = 'REQUEST_API_USER_INFO';
export const SUCCESS_API_USER_INFO = 'SUCCESS_API_USER_INFO';
export const FAILURE_API_USER_INFO = 'FAILURE_API_USER_INFO';

export const REQUEST_API_USER_STATUS = 'REQUEST_API_USER_STATUS';
export const SUCCESS_API_USER_STATUS = 'SUCCESS_API_USER_STATUS';
export const FAILURE_API_USER_STATUS = 'FAILURE_API_USER_STATUS';

export const REQUEST_API_TIME_SERIES = 'REQUEST_API_TIME_SERIES';
export const SUCCESS_API_TIME_SERIES = 'SUCCESS_API_TIME_SERIES';
export const FAILURE_API_TIME_SERIES = 'FAILURE_API_TIME_SERIES';

export const REQUEST_API_USE_STATS = 'REQUEST_API_USE_STATS';
export const SUCCESS_API_USE_STATS = 'SUCCESS_API_USE_STATS';
export const FAILURE_API_USE_STATS = 'FAILURE_API_USE_STATS';

export const REQUEST_API_MULTIFETCH = 'REQUEST_API_MULTIFETCH';
export const SUCCESS_API_MULTIFETCH = 'SUCCESS_API_MULTIFETCH';
export const FAILURE_API_MULTIFETCH = 'FAILURE_API_MULTIFETCH';

export const REQUEST_API_DAY_STATS = 'REQUEST_API_DAY_STATS';
export const SUCCESS_API_DAY_STATS = 'SUCCESS_API_DAY_STATS';
export const FAILURE_API_DAY_STATS = 'FAILURE_API_DAY_STATS';

export const REQUEST_API_ENERGY_HOURLY = 'REQUEST_API_ENERGY_HOURLY';
export const SUCCESS_API_ENERGY_HOURLY = 'SUCCESS_API_ENERGY_HOURLY';
export const FAILURE_API_ENERGY_HOURLY = 'FAILURE_API_ENERGY_HOURLY';

export const REQUEST_API_SETTINGS_UPDATE = 'REQUEST_API_SETTINGS_UPDATE';
export const SUCCESS_API_SETTINGS_UPDATE = 'SUCCESS_API_SETTINGS_UPDATE';
export const FAILURE_API_SETTINGS_UPDATE = 'FAILURE_API_SETTINGS_UPDATE';

export const CHECK_LOGIN = 'CHECK_LOGIN';
export const TRY_LOGIN = 'TRY_LOGIN';

export const UPDATE_ID_TOKEN = 'UPDATE_ID_TOKEN';
