// @flow

import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import {
  ADD_PRICE_PLAN,
  SUB_PRICE_PLAN,
  SETTING_MULTISTAGE_PRICE_PLAN_POP_UP,
  CHANGE_PLAN_TIME_START,
  OPEN_PRICE_PLAN_POP_UP,
} from 'Client/actions/types';
import Prefixer from 'Client/lib/Prefixer';
import checkPrice from 'Client/lib/checkPrice';
import { toSetUpPriceValue } from 'Client/lib/globalUtils';
import { minNumberOfPlan, maxNumberOfPlan } from 'Client/lib/pricePlanUtils';
import type { State } from 'Client/types/state';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  content: {
    position: 'relative',
    overflow: 'auto',
  },
  description: {
    fontSize: '0.8em',
    lineHeight: '150%',
    overflow: 'hidden',
  },
  button: {
    margin: '20px 5% 15px',
    padding: '20px 10px',
    backgroundColor: '#ffbd00',
    borderRadius: 5,
    fontSize: '1.3em',
    fontWeight: 'bold',
    cursor: 'pointer',
    color: '#fff',
  },
  descItem: {
    margin: '0 0 0 0',
    color: '#1f1f21',
    minHeight: '44px',
    lineHeight: '44px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  listItem: {
    margin: '5px 0 0 0',
    borderTop: 'none',
    borderBottom: 'none',
    color: '#1f1f21',
    minHeight: '34px',
    lineHeight: '34px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    position: 'relative',
  },
  priceItem: {
    textAlign: 'center',
    marginRight: '25px',
  },
  timeSelectItem: {
    textAlign: 'center',
  },
  input: {
    width: '40px',
    border: '1px solid #ccc',
    textAlign: 'right',
    borderRadius: '4px',
    height: '21px',
    padding: '2px 8px 2px 0px',
    fontSize: '18px',
  },
  selectInput: {
    width: '35px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    height: '27px',
    backgroundColor: '#fff',
    padding: '2px 8px 2px 0px',
    fontSize: '18px',
    appearance: 'none',
  },
  priceError: {
    color: 'red',
    fontSize: '0.8em',
  },
  addButton: {
    margin: '20px 20% 5px',
    borderRadius: 4,
    cursor: 'pointer',
    backgroundColor: '#A6A6A6',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    pading: '5px 5px',
  },
  addText: {
    margin: '5px',
    marginLeft: '3px',
    fontSize: '1em',
    fontWeight: 'bold',
    color: '#FFFFFF',
  },
  addIcon: {
    textAlign: 'center',
    marginRight: '3px',
  },
  subIcon: {
    alignSelf: 'center',
    position: 'absolute',
    right: '8px',
    top: '4px',
  },
  defaultChoices: {
    display: 'none',
  },
  centerLine: {
    width: '35%',
    borderTop: '2px solid #ffbd00',
    marginTop: '9.5px',
  },
  caption: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 10px',
  },
  captionText: {
    fontSize: '13px',
    fontWeight: 'bold',
    color: '#ffbd00',
  },
  centerLineNight: {
    width: '35%',
    borderTop: '2px solid #51AEED',
    marginTop: '9.5px',
  },
  captionTextNight: {
    fontSize: '13px',
    fontWeight: 'bold',
    color: '#51AEED',
  },
  backButton: {
    width: '20%',
    borderRadius: '5px',
    backgroundColor: '#A6A6A6',
    margin: '5% 5%',
    padding: '4px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  backIcon: {
    textAlign: 'center',
    marginRight: '3px',
    width: '20%',
  },
  backText: {
    marginLeft: '3px',
    fontSize: '1em',
    fontWeight: '500',
    color: '#FFFFFF',
  },
  backButtonArea: {
    padding: '5px',
    borderTop: 'thin solid #DDDDDD',
  },
  scrollArea: {
    maxHeight: '40vh',
    backgroundColor: '#fafafa',
  },
});

const checkTime = (targetTime, targetIndex, setting) => {
  if (targetIndex < 1) {
    return false;
  }
  if (!targetTime) {
    return true;
  }
  const target = Number(targetTime);
  const prevTime = Number(setting[targetIndex - 1].startTime);
  const firstTime = Number(setting[0].startTime);
  return (
    (target < prevTime && !(firstTime > target && target >= 0)) ||
    (prevTime < firstTime && !(target < firstTime && target > prevTime)) ||
    target === prevTime
  );
};

const allCheckTime = settings => {
  const duplicate = settings.every((ele, index) => !checkTime(ele.startTime, index, settings));
  return !duplicate;
};

const filterSelectedOption = (targetTime, targetIndex, setting) => {
  if (targetIndex === 0) {
    return false;
  }
  const target = Number(targetTime);
  const prevTime = Number(setting[targetIndex - 1].startTime);
  const firstTime = Number(setting[0].startTime);
  return (
    (target < prevTime && !(firstTime > target && target >= 0)) ||
    (prevTime < firstTime && !(target < firstTime && target > prevTime)) ||
    target === prevTime
  );
};

const renderButton = (dispatch, intl) => {
  const button = require('Client/images/chebron-l-white.svg');
  return (
    <div
      style={style.backButton}
      className="styleBack"
      onClick={() =>
        dispatch({
          type: OPEN_PRICE_PLAN_POP_UP,
        })
      }
    >
      <img src={button} alt="chebron-left" style={style.backIcon} />
      <div style={style.backText}>{intl.formatMessage({ id: 'settingPricePlanPopup.back' })}</div>
    </div>
  );
};

const renderPriceItem = (price, index, dispatch, intl) => {
  const type = intl.formatMessage({ id: 'settingPage.priceAlertType' });
  const pattern = intl.formatMessage({ id: 'settingPage.priceAlertPattern' });
  const minLength = intl.formatMessage({ id: 'settingPage.priceAlertMinLength' });
  const maxLength = intl.formatMessage({ id: 'settingPage.priceAlertMaxLength' });
  const priceName = `price${index}`;
  return (
    <div>
      <div style={style.priceItem}>
        <input
          type="text"
          name={priceName}
          style={style.input}
          pattern={pattern}
          maxLength={maxLength}
          minLength={minLength}
          value={price}
          onChange={e => {
            const inputElement = e.target;
            toSetUpPriceValue(inputElement, type, 'CHANGE_PLAN_PRICE', dispatch, index);
          }}
        />
        {intl.formatMessage({ id: 'settingPage.yen' })}
      </div>
    </div>
  );
};
const timeValue = (setting, index) => {
  const times = Array.from(new Array(24)).map((v, i) => i);
  const filterTimes = times.filter(ele => !filterSelectedOption(ele, index, setting));
  return filterTimes.map(item => <option key={item}>{item}</option>);
};

const timeValuew = (setting, index) => {
  const times = Array.from(new Array(24)).map((v, i) => i);
  const filterTimes = times.filter(ele => !filterSelectedOption(ele, index, setting));
  return filterTimes.map(item => <option key={item}>{item}</option>);
};

// タイム変更
const renderTimeSelectItem = (
  setting,
  startTime,
  endTime,
  index,
  dispatch,
  intl,
  isStartTimeErr
) => {
  const endTimeIndex = setting.length - 1 === index ? 0 : index + 1;
  const isEndTimeError = checkTime(endTime, endTimeIndex, setting);
  return (
    <div style={style.timeSelectItem}>
      <select
        style={style.selectInput}
        name="time"
        value={startTime}
        dir="rtl"
        onChange={e => {
          dispatch({ type: CHANGE_PLAN_TIME_START, startTime: e.target.value, index });
        }}
      >
        <option key="none" disabled style={style.defaultChoices} />
        {isStartTimeErr && (
          <option key={startTime} disabled style={style.defaultChoices}>
            {startTime}
          </option>
        )}
        {timeValue(setting, index)}
      </select>
      {intl.formatMessage({ id: 'settingPricePlanPopup.timeStart' })}
      <select
        style={style.selectInput}
        name="time"
        value={endTime}
        dir="rtl"
        onChange={e => {
          dispatch({
            type: CHANGE_PLAN_TIME_START,
            startTime: e.target.value,
            index: endTimeIndex,
          });
        }}
      >
        <option key="none" disabled style={style.defaultChoices} />
        {isEndTimeError && (
          <option key={endTime} disabled style={style.defaultChoices}>
            {endTime}
          </option>
        )}
        {timeValuew(setting, endTimeIndex)}
      </select>
      {intl.formatMessage({ id: 'settingPricePlanPopup.timeEnd' })}
    </div>
  );
};

const renderPriceError = intl => (
  <div style={style.priceError}>{intl.formatMessage({ id: 'settingPage.priceAlert' })}</div>
);

const renderPricePlanMultistage = (dispatch, intl, multistagePlanSetting, numberOfPricePlan) => {
  const type = intl.formatMessage({ id: 'settingPage.priceAlertType' });
  const addIcon = require('Client/images/icon_circle_plus.png');
  const subIcon = require('Client/images/icon_circle_cross.png');
  const messages = defineMessages({
    caption: {
      id: 'settingPricePlanPopup.caption',
      description: '時間帯{index + 1}',
    },
  });
  return (
    <div style={style.content}>
      <div style={style.description}>
        <div style={style.descItem}>
          {intl.formatMessage({ id: 'settingPricePlanPopup.guideMultistage' })}
        </div>
        <div className="scrollArea" style={style.scrollArea}>
          {multistagePlanSetting.map((ele, index) => {
            const key = `pricePlan${index}`;
            const endTime =
              multistagePlanSetting.length === index + 1
                ? multistagePlanSetting[0].startTime
                : multistagePlanSetting[index + 1].startTime;
            const isTimeErr = ele.startTime
              ? checkTime(ele.startTime, index, multistagePlanSetting)
              : true;
            return (
              <div key={key}>
                <div style={style.caption}>
                  <span style={index === 0 ? style.centerLine : style.centerLineNight} />
                  <div style={index === 0 ? style.captionText : style.captionTextNight}>
                    {intl.formatMessage(messages.caption, { index: index + 1 })}
                  </div>
                  <span style={index === 0 ? style.centerLine : style.centerLineNight} />
                </div>
                <div style={style.listItem}>
                  {renderTimeSelectItem(
                    multistagePlanSetting,
                    ele.startTime,
                    endTime,
                    index,
                    dispatch,
                    intl,
                    isTimeErr
                  )}
                  {renderPriceItem(ele.price, index, dispatch, intl)}
                  {index >= minNumberOfPlan && (
                    <img
                      style={style.subIcon}
                      alt="subIcon"
                      src={subIcon}
                      onClick={() =>
                        dispatch({
                          type: SUB_PRICE_PLAN,
                          index,
                        })
                      }
                    />
                  )}
                </div>
                {checkPrice(ele.price, type) ? renderPriceError(intl) : ''}
                {isTimeErr ? (
                  <div style={style.priceError}>
                    {intl.formatMessage({ id: 'settingPricePlanPopup.timeAlert' })}
                  </div>
                ) : (
                  ''
                )}
              </div>
            );
          })}
          {numberOfPricePlan !== maxNumberOfPlan && (
            <div
              style={style.addButton}
              onClick={() =>
                dispatch({
                  type: ADD_PRICE_PLAN,
                })
              }
            >
              <img style={style.addIcon} src={addIcon} alt="addIcon" />
              <div style={style.addText}>
                {intl.formatMessage({ id: 'settingPricePlanPopup.addPlan' })}
              </div>
            </div>
          )}
        </div>
        <div
          style={
            checkPrice(multistagePlanSetting, type) || allCheckTime(multistagePlanSetting)
              ? { ...style.button, opacity: 0.3 }
              : style.button
          }
          onClick={() =>
            dispatch({
              type: SETTING_MULTISTAGE_PRICE_PLAN_POP_UP,
              pricePlan: 'MULTISTAGE',
              multistagePlanSetting,
              isErr: checkPrice(multistagePlanSetting, type) || allCheckTime(multistagePlanSetting),
            })
          }
        >
          {intl.formatMessage({ id: 'settingPricePlanPopup.saveSetting' })}
        </div>
        <div style={style.backButtonArea}>{renderButton(dispatch, intl)}</div>
      </div>
    </div>
  );
};

type StateProps = {|
  dispMultistagePlanSetting: Object[],
  numberOfPricePlan: number,
|};
type OwnProps = {|
  intl: intlShape,
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

export const PureSettingPricePlanMultistage = ({
  dispatch,
  intl,
  dispMultistagePlanSetting,
  numberOfPricePlan,
}: Props): Node => (
  <div>
    {renderPricePlanMultistage(dispatch, intl, dispMultistagePlanSetting, numberOfPricePlan)}
  </div>
);

const mapStateToProps = (state: State): StateProps => ({
  dispMultistagePlanSetting: state.pricePlan.dispMultistagePlanSetting,
  numberOfPricePlan: state.pricePlan.numberOfPricePlan,
});

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  injectIntl(PureSettingPricePlanMultistage)
);
