// @flow

import type { Action } from 'redux';
/* eslint-env browser */
import {
  SUCCESS_CERTIFICATION,
  FAILURE_CERTIFICATION,
  INIQUITY_CERTIFICATION,
  SUCCESS_PASSWORD_RESET_CERTIFICATION,
  RESET_CERTIFICATION,
} from 'Client/actions/types';
import type { CertificationState as State } from 'Client/types/state';

const initialState = {
  certificationState: '',
};

export default (state: State = initialState, action: Action = {}): State => {
  switch (action.type) {
    case SUCCESS_CERTIFICATION: {
      return {
        ...state,
        certificationState: 'SUCCESS',
      };
    }
    case FAILURE_CERTIFICATION: {
      return {
        ...state,
        certificationState: 'ERROR',
      };
    }
    case INIQUITY_CERTIFICATION: {
      return {
        ...state,
        certificationState: 'INIQUITY',
      };
    }
    case SUCCESS_PASSWORD_RESET_CERTIFICATION: {
      return {
        ...state,
        certificationState: 'PASSWORD_CHANGE',
      };
    }
    case RESET_CERTIFICATION: {
      return {
        ...state,
        certificationState: '',
      };
    }
    default: {
      return state;
    }
  }
};
