// @flow

import React, { useState } from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import Loading from 'Client/components/utils/Loading';
import Card from 'Client/components/utils/Card';
import QuestionIcon from 'Client/components/utils/QuestionIcon';
import ChartNodata, { CHART_NO_DATA } from 'Client/components/ChartNoData';
import StackedBarChart from 'Client/components/StackedBarChart';
import { HELP_STACKED_CHART } from 'Client/actions/helpPopupTypes';
import { OPEN_POP_UP } from 'Client/actions/types';
import Prefixer from 'Client/lib/Prefixer';
import { applianceId, pgIds } from 'Client/lib/id';
import { timeUnitStyle, getAppliancePgEnergy, formatEnergy } from 'Client/lib/chartUtils';
import type { State, ApplianceEnergyDetailState, CastedSplitEnergies } from 'Client/types/state';
import type { SplitEnergies, ChartData, StackedKeyData } from 'Client/types/types';
import { convertEnergy, getTotalEnergy } from 'Client/lib/globalUtils';
import 'Client/css/chartTree.css';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  areaTitle: {
    verticalAlign: 'middle',
  },
  criterion: {
    fontSize: '0.7em',
    paddingLeft: '5px',
  },
  info: {
    top: '-11px',
    right: '-5px',
    zIndex: '2',
    position: 'absolute',
  },
  legendContainer: {
    margin: '15px 10px 20px',
    lineHeight: 1,
  },
  legendItem: {
    height: 16,
    display: 'flex',
    alignItems: 'center',
  },
  legendSubItem: {
    position: 'relative',
    marginLeft: '7px',
  },
  adjacentLegendItem: {
    marginTop: 10,
  },
  adjacentLegendSubItem: {
    height: 20,
    marginTop: 5,
  },
  legendIcon: {
    width: 16,
    height: 16,
    marginRight: 5,
    backgroundSize: 'cover',
  },
  legendSoldIcon: {
    backgroundImage:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAAIVBMVEUAAABKvq5Kvq5Lva1Kva1Kv69Kvq5Kv69Iv69Kva1Lvq7u1tPtAAAACnRSTlMA8MBwgDCwfyCvXTsbnAAAAF9JREFUKM9jYGAIV1wFBUKlDEDAvAoBFhoABbJWIYGVUAUIYMAQhSogyuCFKrCEQQtVYBGDFKrAQoZVaGCkC6AHEEYQYgZyIKqABEZEoUXlMlBkS6FFNkMIIjl4MDAAAMhEO2WKCaPxAAAAAElFTkSuQmCC)',
  },
  legendBoughtIcon: {
    backgroundImage:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAAIVBMVEUAAAD/mZL/mpL/mZL/mZH/mo//mpH/m5P/l4//mZL/mpLKzfsxAAAACnRSTlMA8MBwgDCwfyCvXTsbnAAAAF9JREFUKM9jYGAIV1wFBUKlDEDAvAoBFhoABbJWIYGVUAUIYMAQhSogyuCFKrCEQQtVYBGDFKrAQoZVaGCkC6AHEEYQYgZyIKqABEZEoUXlMlBkS6FFNkMIIjl4MDAAAMhEO2WKCaPxAAAAAElFTkSuQmCC)',
  },
  legendConsumedIcon: {
    backgroundImage:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAAIVBMVEUAAADd3d3c3Nzd3d3d3d3f39/c3Nzd3d3f39/c3Nzd3d1wHqkQAAAACnRSTlMA8MBwgDCwfyCvXTsbnAAAAF9JREFUKM9jYGAIV1wFBUKlDEDAvAoBFhoABbJWIYGVUAUIYMAQhSogyuCFKrCEQQtVYBGDFKrAQoZVaGCkC6AHEEYQYgZyIKqABEZEoUXlMlBkS6FFNkMIIjl4MDAAAMhEO2WKCaPxAAAAAElFTkSuQmCC)',
  },
  legendGeneratedIcon: {
    backgroundImage:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAAIVBMVEXc3NxKvq4AAADd3d1Lvq7c3NxJv6/f399Mv6/d3d1Lvq5598D+AAAACXRSTlPAwADw71BQQEAvB7lOAAAAPElEQVQoz2NQE1mFBBY2MWStQgErGKRQBRYyrEIFS9AFVg1lgZloYBgJTGbgRBWYwBCJKjCdQZUZRUERAJo4Pskj+q9wAAAAAElFTkSuQmCC)',
  },
  legendConsumedPowerIcon: {
    backgroundImage:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAAIVBMVEXc3Nz/kIYAAADd3d3/kIXc3Nz/j4bf39//j4fd3d3/kIZ+i7L3AAAACXRSTlPAwADw71BQQEAvB7lOAAAAPElEQVQoz2NQE1mFBBY2MWStQgErGKRQBRYyrEIFS9AFVg1lgZloYBgJTGbgRBWYwBCJKjCdQZUZRUERAJo4Pskj+q9wAAAAAElFTkSuQmCC)',
  },
  legendLabel: {
    width: '5em',
    margin: 0,
    marginRight: '1em',
    fontSize: 14,
    fontWeight: 'normal',
    textAlign: 'left',
  },
  legendSubLabel: {
    width: '6em',
    margin: 0,
    marginRight: '1em',
    fontSize: 14,
    fontWeight: 'normal',
    textAlign: 'left',
  },
  legendValueNormal: {
    fontWeight: 'normal',
  },
  legendSubLabelBold: {
    fontWeight: 'bold',
  },
  legendValue: {
    margin: 0,
    fontSize: 14,
    textAlign: 'right',
  },
  legendValueCenter: {
    margin: 0,
    marginRight: '1em',
    fontSize: 13,
    fontWeight: 'normal',
    textAlign: 'right',
  },
});

const stackedDataColor = {
  consumedDefault: '#d8d8d8',
  consumedDisabled: '#eaeaea',
  soldDefault: '#4bbeae',
  soldDisabled: '#a8ddd3',
  boughtDefault: '#ff9a92',
  boughtDisabled: '#f9cdc7',
};

type ValidSplitEnergyData = {
  startHour: number,
  endHour: number,
  splitEnergies: CastedSplitEnergies,
};

type BarData = null | {
  [key: 'consumed' | 'sold' | 'bought' | 'eneFarm']: ChartData,
};
type UpdateActiveBarData = (barData: BarData) => void;

type StateProps = {|
  idsToShow: (string | null)[],
  applianceEnergy: ApplianceEnergyDetailState[],
  isZeh: boolean,
|};
type OwnProps = {|
  ...SplitEnergies,
  requesting: boolean,
  period: string,
  intl: intlShape,
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

/**
 * 24時間のうち、最初と最後の電力発生を見つけて、その間のデータを返す
 * baseEnergies で指定されたプロパティから検出し、他のデータは baseEnergies から検出された範囲に習う
 */
const getValidEnergy = (
  splitEnergies: CastedSplitEnergies,
  baseEnergies: string[]
): null | ValidSplitEnergyData => {
  const initialHour: { startHour: number | null, endHour: number | null } = {
    startHour: null,
    endHour: null,
  };
  const { startHour, endHour } = baseEnergies
    .map(baseEnergy => {
      const idData = splitEnergies[baseEnergy].find(energy => energy !== null);
      if (!idData && idData !== 0) return initialHour;
      return splitEnergies[baseEnergy].reduce(
        (foundHour, value, index) => ({
          startHour: value && foundHour.startHour === null ? index : foundHour.startHour,
          endHour: value ? index : foundHour.endHour,
        }),
        {
          startHour: null,
          endHour: null,
        }
      );
    })
    .reduce((p, n) => ({
      startHour:
        n.startHour && p.startHour === null
          ? n.startHour
          : Math.min(p.startHour ? p.startHour : 0, n.startHour ? n.startHour : 0),
      endHour:
        n.endHour && p.endHour === null
          ? n.endHour
          : Math.max(p.endHour ? p.endHour : 0, n.endHour ? n.endHour : 0),
    }));

  const validSplitEnergies = { ...splitEnergies };

  if (startHour === null || endHour === null) {
    const isNull = !baseEnergies.find(baseEnergy =>
      splitEnergies[baseEnergy].find(energy => energy !== null)
    );
    return isNull
      ? null
      : {
          startHour: 9,
          endHour: 15,
          splitEnergies: validSplitEnergies,
        };
  }

  Object.keys(validSplitEnergies).forEach(splitEnergyKey => {
    validSplitEnergies[splitEnergyKey] = validSplitEnergies[splitEnergyKey].slice(
      startHour,
      endHour + 1
    );
  });
  return {
    startHour,
    endHour,
    splitEnergies: validSplitEnergies,
  };
};

/**
 * VictoryBar 用データ作成
 * x = 時, y = energy
 */
const createChartData = ({
  validEnergyData,
  target,
}: {
  validEnergyData: ValidSplitEnergyData,
  target: string,
}): ChartData[] => {
  const runningTime = validEnergyData.endHour + 1 - validEnergyData.startHour;
  return new Array(runningTime).fill().map((_, i) => ({
    x: validEnergyData.startHour + i,
    y:
      (validEnergyData.splitEnergies &&
        validEnergyData.splitEnergies[target] &&
        validEnergyData.splitEnergies[target][i]) ||
      0,
  }));
};

/**
 * 発電量グラフ用データ生成
 * sold: 売電
 * consumed: 自家消費量(蓄電池の放電の消費量を含む)
 */
const createChartPropsForGeneration = (
  validEnergyData: ValidSplitEnergyData,
  isHybrid: boolean
): { stackedData: StackedKeyData, maximumValue: number } => {
  const soldData = createChartData({ validEnergyData, target: 'splitSellEnergy' });
  const generatedData = createChartData({ validEnergyData, target: 'splitPgEnergy' });
  const consumedData = generatedData.map((generatedItem, i) => ({
    ...generatedItem,
    y: generatedData[i].y - soldData[i].y,
  }));
  // ハイブリッド蓄電池の場合、売電のみ表示
  const consumedPowerData = !isHybrid
    ? consumedData
    : soldData.map(item => ({
        ...item,
        y: 0,
      }));
  const totalData = !isHybrid ? generatedData : soldData;
  // totalData 内の最大値
  const maximumValue = Math.ceil(Math.max(...totalData.map(({ y }) => y)) * 10) / 10 || 0.2;
  // 0.2 で割り切れる値に変換
  const divisibleMaximumValue =
    (maximumValue * 10) % 2 === 0 ? maximumValue : (maximumValue * 10 + 1) / 10;

  return {
    stackedData: {
      consumed: {
        data: consumedPowerData,
        colors: {
          default: stackedDataColor.consumedDefault,
          disabled: stackedDataColor.consumedDisabled,
        },
      },
      sold: {
        data: soldData,
        colors: {
          default: stackedDataColor.soldDefault,
          disabled: stackedDataColor.soldDisabled,
        },
      },
    },
    maximumValue: divisibleMaximumValue,
  };
};

/**
 * 電力使用グラフ用データ生成
 * consumed: 発電・放電から消費した電力量
 * bought: 買電
 */
const createChartPropsForConsumed = (
  validEnergyData: ValidSplitEnergyData
): { stackedData: StackedKeyData, maximumValue: number } => {
  const whpData = createChartData({ validEnergyData, target: 'splitWhp' });
  const boughtData = createChartData({ validEnergyData, target: 'splitBuyEnergy' });
  const chargedData = createChartData({ validEnergyData, target: 'splitChargedEnergy' });
  // 使用量 + 充電量
  const whpPowerData = whpData.map((whpItem, i) => ({
    ...whpItem,
    y: whpData[i].y + chargedData[i].y,
  }));
  // 発電+放電からの消費量
  const consumedPowerData = whpPowerData.map((whpItem, i) => ({
    ...whpItem,
    y: whpPowerData[i].y - boughtData[i].y > 0 ? whpPowerData[i].y - boughtData[i].y : 0,
  }));
  // totalData 内の最大値
  const maximumValue =
    Math.ceil(Math.max(...whpPowerData.map(({ y }) => y), ...boughtData.map(({ y }) => y)) * 10) /
      10 || 0.2;
  // 0.2 で割り切れる値に変換
  const divisibleMaximumValue =
    (maximumValue * 10) % 2 === 0 ? maximumValue : (maximumValue * 10 + 1) / 10;

  return {
    stackedData: {
      consumed: {
        data: consumedPowerData,
        colors: {
          default: stackedDataColor.consumedDefault,
          disabled: stackedDataColor.consumedDisabled,
        },
      },
      bought: {
        data: boughtData,
        colors: {
          default: stackedDataColor.boughtDefault,
          disabled: stackedDataColor.boughtDisabled,
        },
      },
    },
    maximumValue: divisibleMaximumValue,
  };
};

/**
 * 電力使用グラフ用配列から該当データを取得
 */
const getChartData = (data: ?(ChartData[]), index: string | number): ChartData =>
  (data && data.find(energy => energy.x === index)) || { x: index, y: 0 };

/**
 * 電力使用グラフ用配列に変換
 */
const convertChartData = (applianceData: ?{ id: string, energy: number[] }): ChartData[] =>
  (applianceData &&
    applianceData.energy
      .reduce((pre, now) => [...pre, now || 0], [])
      .map((energy, index) => ({ x: index, y: energy }))) ||
  [];

const InfoButton = (props: { contentType: string, dispatch: Dispatch }): Node => (
  <div style={style.info}>
    <QuestionIcon
      onClick={e => {
        props.dispatch({
          type: OPEN_POP_UP,
          contentType: props.contentType,
          target: e.target,
        });
      }}
    />
  </div>
);

const CardWithTitle = (props: {
  children: Node,
  messageKey: string,
  isGenerated: boolean,
  isHybrid: boolean,
  intl: intlShape,
}): Node => (
  <Card>
    <div>
      <span style={style.areaTitle}>
        {props.isGenerated && props.isHybrid
          ? props.intl.formatMessage({ id: `${props.messageKey}.hybridTitle` })
          : props.intl.formatMessage({ id: `${props.messageKey}.title` })}
      </span>
      <span style={style.criterion}>{props.intl.formatMessage({ id: 'others.criterion' })}</span>
    </div>
    {props.children}
  </Card>
);

/**
 * 発電量グラフエリア、電領使用グラフエリア表示用カセットコンポーネント
 */
const Cards = (props: {|
  ...Props,
  component?: Node,
  generationComponent?: Node,
  consumedComponent?: Node,
  isHybrid?: boolean,
  isZeh?: boolean,
|}): Node => (
  <div>
    {(!props.isZeh || props.generationComponent) && (
      <CardWithTitle
        messageKey="chartGeneratedAtDayOnGeneration"
        isGenerated
        isHybrid={props.isHybrid}
        intl={props.intl}
      >
        <InfoButton contentType={HELP_STACKED_CHART} dispatch={props.dispatch} />
        {props.component || props.generationComponent || null}
      </CardWithTitle>
    )}
    <CardWithTitle
      messageKey="chartGeneratedAtDayOnConsumed"
      isGenerated={false}
      isHybrid={props.isHybrid}
      intl={props.intl}
    >
      <InfoButton contentType={HELP_STACKED_CHART} dispatch={props.dispatch} />
      {props.component || props.consumedComponent || null}
    </CardWithTitle>
  </div>
);

Cards.defaultProps = {
  component: null,
  generationComponent: null,
  consumedComponent: null,
  isHybrid: false,
  isZeh: false,
};

const Legend = ({
  type,
  activeBarData,
  pv,
  eneFarmData,
  idsToShow,
  intl,
  validEnergyData,
  isBatteryIds,
  isHybrid,
}: {
  type: 'generation' | 'consumed',
  activeBarData: BarData,
  pv: ?(ChartData[]),
  eneFarmData: ?(ChartData[]),
  idsToShow: (string | null)[],
  intl: intlShape,
  validEnergyData: ValidSplitEnergyData,
  isBatteryIds: boolean,
  isHybrid: boolean,
}): Node => {
  const isGeneration = type === 'generation';

  // 発電機器が複数存在するか
  const isPv =
    (idsToShow || []).filter(
      id => pgIds.includes(id) && applianceId.dischargedHybridStorageBattery !== id
    ).length > 1;

  const isEneFarm = eneFarmData && eneFarmData.length > 0;
  const eneFarmItem =
    isEneFarm && activeBarData && isGeneration && getChartData(eneFarmData, activeBarData.sold.x);
  const eneFarmEnergy = (eneFarmItem && eneFarmItem.y) || 0;
  const totalEneFarm =
    (eneFarmData || []).reduce((total, current) => total + (current.y || 0), 0) || 0;

  const pvItem = activeBarData && isGeneration && getChartData(pv, activeBarData.sold.x);
  const pvEnergy = (pvItem && pvItem.y) || 0;
  const totalPv = (pv || []).reduce((total, current) => total + (current.y || 0), 0) || 0;

  const activeHour = activeBarData ? activeBarData[isGeneration ? 'sold' : 'bought'].x : null;
  const {
    splitWhp,
    splitSellEnergy,
    splitBuyEnergy,
    splitPgEnergy,
    splitChargedEnergy,
  } = validEnergyData.splitEnergies;

  // 売電
  const sellEnergy = convertEnergy(splitSellEnergy);
  const totalSellEnergy = getTotalEnergy(sellEnergy);
  const pgEnergy = convertEnergy(splitPgEnergy);
  const totalPgEnergy = getTotalEnergy(pgEnergy);
  const consumedSellEnergy =
    (!isHybrid && pgEnergy.map((pg, i) => (pg - sellEnergy[i] > 0 ? pg - sellEnergy[i] : 0))) || [];
  const totalConsumedSellEnergy = getTotalEnergy(consumedSellEnergy);

  // 買電
  const buyEnergy = convertEnergy(splitBuyEnergy);
  const chargedEnergy = convertEnergy(splitChargedEnergy);
  const wholeHousePower = convertEnergy(splitWhp).map((whp, i) => whp + chargedEnergy[i]);
  const consumedBuyEnergy = wholeHousePower.map((whp, i) =>
    whp - buyEnergy[i] > 0 ? whp - buyEnergy[i] : 0
  );
  const totalWholeHousePower = getTotalEnergy(wholeHousePower);
  const totalBuyEnergy = getTotalEnergy(buyEnergy);
  const totalConsumedBuyEnergy = getTotalEnergy(consumedBuyEnergy);

  const unit = intl.formatMessage({ id: 'chartGeneratedAtDay.axisUnit' });

  return (
    <div style={style.legendContainer}>
      <div style={style.legendItem}>
        <div style={{ ...style.legendIcon }} />
        <p style={style.legendLabel} />
        <p style={style.legendSubLabel}>
          {intl.formatMessage({ id: 'chartGeneratedAtDayOnConsumed.dailyTitle' })}
        </p>
        {activeHour !== null && (
          <p style={{ ...style.legendValue, ...style.legendValueNormal }}>
            {activeHour}
            {intl.formatMessage({ id: 'chartGeneratedAtDay.horizonAxisUnit' })}
          </p>
        )}
      </div>

      {!isGeneration && (
        <div>
          <div style={{ ...style.legendItem, ...style.adjacentLegendItem }}>
            <div
              key="legendIcon"
              style={{
                ...style.legendIcon,
                ...style.legendConsumedPowerIcon,
              }}
            />
            <p key="legendLabel" style={style.legendLabel}>
              {isBatteryIds
                ? intl.formatMessage({ id: 'chartGeneratedAtDay.wholeHousePower' })
                : intl.formatMessage({ id: 'chartGeneratedAtDay.consumedPower' })}
            </p>
            {validEnergyData && (
              <p style={{ ...style.legendSubLabel, ...style.legendSubLabelBold }}>
                {formatEnergy({ intl, energy: totalWholeHousePower, unit })}
              </p>
            )}
            {activeBarData && (
              <p key="legendValue" style={style.legendValue}>
                {formatEnergy({
                  intl,
                  energy: activeBarData.consumed.y + activeBarData.bought.y,
                  unit,
                })}
              </p>
            )}
          </div>
        </div>
      )}

      {!isHybrid && activeBarData && isGeneration && (
        <div>
          <div style={{ ...style.legendItem, ...style.adjacentLegendItem }}>
            <div
              key="legendIcon"
              style={{
                ...style.legendIcon,
                ...style.legendGeneratedIcon,
              }}
            />
            <p key="legendLabel" style={style.legendLabel}>
              {intl.formatMessage({ id: 'chartGeneratedAtDay.pg' })}
            </p>
            {validEnergyData && (
              <p key="legendValue" style={{ ...style.legendSubLabel, ...style.legendSubLabelBold }}>
                {formatEnergy({
                  intl,
                  energy: totalPgEnergy,
                  unit,
                })}
              </p>
            )}
            {activeBarData && (
              <p style={style.legendValue}>
                {formatEnergy({
                  intl,
                  energy: activeBarData.sold.y + activeBarData.consumed.y,
                  unit,
                })}
              </p>
            )}
          </div>
          {isPv && (
            <div className="pg-tree" style={style.legendSubItem}>
              <div
                className={isEneFarm ? 'pg-list-small' : 'pg-last-list-small'}
                style={{ ...style.legendItem, ...style.adjacentLegendSubItem }}
              >
                <p style={style.legendSubLabel}>
                  {intl.formatMessage({ id: 'chartGeneratedAtDay.solarPower' })}
                </p>
                {validEnergyData && (
                  <p
                    key="legendValue"
                    style={{ ...style.legendSubLabel, ...style.legendSubLabelBold }}
                  >
                    {formatEnergy({
                      intl,
                      energy: totalPv,
                      unit,
                    })}
                  </p>
                )}
                {activeBarData && (
                  <p style={style.legendValue}>
                    {formatEnergy({
                      intl,
                      energy: pvEnergy,
                      unit,
                    })}
                  </p>
                )}
              </div>
              {isEneFarm && (
                <div
                  className="pg-last-list-small"
                  style={{ ...style.legendItem, ...style.adjacentLegendSubItem }}
                >
                  <p style={style.legendSubLabel}>
                    {intl.formatMessage({ id: 'chartGeneratedAtDay.eneFarm' })}
                  </p>
                  {validEnergyData && (
                    <p
                      key="legendValue"
                      style={{ ...style.legendSubLabel, ...style.legendSubLabelBold }}
                    >
                      {formatEnergy({
                        intl,
                        energy: totalEneFarm,
                        unit,
                      })}
                    </p>
                  )}
                  {activeBarData && (
                    <p style={style.legendValue}>
                      {formatEnergy({
                        intl,
                        energy: eneFarmEnergy,
                        unit,
                      })}
                    </p>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}

      <div style={{ ...style.legendItem, ...style.adjacentLegendItem }}>
        <div
          key="legendIcon"
          style={{
            ...style.legendIcon,
            ...(isGeneration ? style.legendSoldIcon : style.legendBoughtIcon),
          }}
        />
        <p key="legendLabel" style={style.legendLabel}>
          {intl.formatMessage({
            id: isGeneration ? 'chartGeneratedAtDay.sellCharge' : 'chartGeneratedAtDay.buyCharge',
          })}
        </p>
        {validEnergyData && (
          <p key="legendValue" style={{ ...style.legendSubLabel, ...style.legendSubLabelBold }}>
            {formatEnergy({ intl, energy: isGeneration ? totalSellEnergy : totalBuyEnergy, unit })}
          </p>
        )}
        {activeBarData && (
          <p style={style.legendValue}>
            {formatEnergy({
              intl,
              energy: activeBarData[isGeneration ? 'sold' : 'bought'].y,
              unit,
            })}
          </p>
        )}
      </div>

      {!(isHybrid && isGeneration) && (
        <div style={{ ...style.legendItem, ...style.adjacentLegendItem }}>
          <div key="legendIcon" style={{ ...style.legendIcon, ...style.legendConsumedIcon }} />
          <p key="legendLabel" style={style.legendLabel}>
            {(isGeneration || (!isGeneration && !isBatteryIds)) &&
              intl.formatMessage({ id: 'chartGeneratedAtDay.consumed' })}
            {!isGeneration &&
              isBatteryIds &&
              intl.formatMessage({ id: 'chartGeneratedAtDay.consumedBattery' })}
          </p>
          {validEnergyData && (
            <p style={{ ...style.legendSubLabel, ...style.legendSubLabelBold }}>
              {formatEnergy({
                intl,
                energy: isGeneration ? totalConsumedSellEnergy : totalConsumedBuyEnergy,
                unit,
              })}
            </p>
          )}
          {activeBarData && (
            <p style={style.legendValue}>
              {formatEnergy({ intl, energy: activeBarData.consumed.y, unit })}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

const methodForCreatingChartProps = (type, validEnergyData, isHybrid) =>
  type === 'generation'
    ? createChartPropsForGeneration(validEnergyData, isHybrid)
    : createChartPropsForConsumed(validEnergyData);

/**
 * 積算グラフ
 * 必要データを生成してチャートコンポーネントに渡す
 * 発電量 or 電力使用で生成データ、ラベルスタイルを変える
 */
const Chart = ({
  type,
  updateActiveBarData,
  activeBarData,
  pv,
  eneFarmData,
  idsToShow,
  intl,
  validEnergyData,
  isBatteryIds,
  isHybrid,
}: {
  type: 'generation' | 'consumed',
  updateActiveBarData: UpdateActiveBarData,
  activeBarData: BarData,
  pv: ?(ChartData[]),
  eneFarmData: ?(ChartData[]),
  idsToShow: (string | null)[],
  intl: intlShape,
  validEnergyData: ValidSplitEnergyData,
  isBatteryIds: boolean,
  isHybrid: boolean,
}): Node => {
  const chartProps = methodForCreatingChartProps(type, validEnergyData, isHybrid);
  return (
    <div>
      <StackedBarChart
        startHour={validEnergyData.startHour}
        endHour={validEnergyData.endHour}
        initialHour={12}
        axisUnit={intl.formatMessage({ id: 'chartGeneratedAtDay.axisUnit' })}
        horizonAxisUnit={intl.formatMessage({
          id: 'chartGeneratedAtDay.horizonAxisUnit',
        })}
        {...chartProps}
        handleSelect={barData => {
          updateActiveBarData(barData);
        }}
      />
      <Legend
        type={type}
        activeBarData={activeBarData}
        pv={pv}
        eneFarmData={eneFarmData}
        idsToShow={idsToShow}
        intl={intl}
        validEnergyData={validEnergyData}
        isBatteryIds={isBatteryIds}
        isHybrid={isHybrid}
      />
    </div>
  );
};

const PureChartGeneratedAtDay = (props: Props): Node => {
  const [activeBarDataForGeneration, updateActiveBarDataForGeneration] = useState<
    BarData,
    UpdateActiveBarData
  >(null);
  const [activeBarDataForConsumed, updateActiveBarDataForConsumed] = useState<
    BarData,
    UpdateActiveBarData
  >(null);

  const {
    requesting,
    period,
    splitWhp,
    splitSellEnergy,
    splitBuyEnergy,
    splitConsumeEnergy,
    splitPgEnergy,
    splitChargedEnergy,
    splitDischargedEnergy,
    idsToShow,
    applianceEnergy,
    intl,
    isZeh,
  } = props;
  const hasAllSplitEnergy =
    splitWhp &&
    splitSellEnergy &&
    splitBuyEnergy &&
    splitConsumeEnergy &&
    splitPgEnergy &&
    splitChargedEnergy &&
    splitDischargedEnergy;

  const isBatteryIds = idsToShow.find(id => /^994/.test(id) || /^9972/.test(id));
  const isHybrid = !!idsToShow.find(id => /^9972/.test(id));

  if (requesting || !hasAllSplitEnergy) {
    return (
      <Cards
        {...props}
        component={
          <Loading space={80}>
            <div>{props.intl.formatMessage({ id: 'application.dataLoading' })}</div>
          </Loading>
        }
        isHybrid={isHybrid}
      />
    );
  }

  const pv = convertChartData(
    getAppliancePgEnergy(idsToShow, applianceEnergy, period, applianceId.pv)
  );
  const eneFarmData = convertChartData(
    getAppliancePgEnergy(idsToShow, applianceEnergy, period, applianceId.eneFarm)
  );

  // hasAllSplitEnergy で値が保証されたためキャスト
  const castedSplitEnergies = {
    splitWhp: ((splitWhp: any): (number | null)[]),
    splitSellEnergy: ((splitSellEnergy: any): (number | null)[]),
    splitBuyEnergy: ((splitBuyEnergy: any): (number | null)[]),
    splitConsumeEnergy: ((splitConsumeEnergy: any): (number | null)[]),
    splitPgEnergy: ((splitPgEnergy: any): (number | null)[]),
    splitChargedEnergy: ((splitChargedEnergy: any): (number | null)[]),
    splitDischargedEnergy: ((splitDischargedEnergy: any): (number | null)[]),
  };

  const validEnergyDataForGeneration = getValidEnergy(castedSplitEnergies, [
    isHybrid ? 'splitSellEnergy' : 'splitPgEnergy',
  ]);
  const validEnergyDataForConsumed = getValidEnergy(castedSplitEnergies, [
    'splitWhp',
    'splitBuyEnergy',
    'splitChargedEnergy',
  ]);

  return (
    <Cards
      {...props}
      generationComponent={
        validEnergyDataForGeneration ? (
          <Chart
            type="generation"
            updateActiveBarData={updateActiveBarDataForGeneration}
            activeBarData={activeBarDataForGeneration}
            pv={pv}
            eneFarmData={eneFarmData}
            idsToShow={idsToShow}
            intl={intl}
            validEnergyData={validEnergyDataForGeneration}
            isBatteryIds={isBatteryIds}
            isHybrid={isHybrid}
          />
        ) : (
          !isZeh && <ChartNodata type={CHART_NO_DATA} />
        )
      }
      consumedComponent={
        validEnergyDataForConsumed || isZeh ? (
          <Chart
            type="consumed"
            updateActiveBarData={updateActiveBarDataForConsumed}
            activeBarData={activeBarDataForConsumed}
            pv={pv}
            eneFarmData={eneFarmData}
            idsToShow={idsToShow}
            intl={intl}
            validEnergyData={validEnergyDataForConsumed || {}}
            isBatteryIds={isBatteryIds}
            isHybrid={isHybrid}
          />
        ) : (
          <ChartNodata type={CHART_NO_DATA} />
        )
      }
      isHybrid={isHybrid}
    />
  );
};

const mapStateToProps = (state: State): StateProps => {
  const { unit } = state.chartTime;
  const applianceEnergy = unit ? state.applianceEnergy[timeUnitStyle[unit].toUnit] : [];
  return {
    idsToShow: state.applianceState.idsToShow,
    applianceEnergy,
    isZeh: state.userInfo.isZeh,
  };
};

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  injectIntl(PureChartGeneratedAtDay)
);

export const PureChartGeneratedAtDayForTest = PureChartGeneratedAtDay;
