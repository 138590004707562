// @flow
/* eslint-env browser */

import { injectIntl, intlShape } from 'react-intl';
import React from 'react';
import type { Node } from 'react';
import { Link } from 'react-router-dom';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Prefixer from 'Client/lib/Prefixer';
import { login } from 'Client/lib/mbaasAuthentication';
import type { State } from 'Client/types/state';

const prefixer = new Prefixer();
const companyLogo = require('Client/images/informetis_logo_white.png');
const caution = require('Client/images/caution.png');

const style = prefixer.prefix({
  loginPage: {
    background: '#ffbd00',
    height: '100vh',
  },
  login: {
    background: '#ffbd00',
    margin: '0px',
    padding: '0px 0px 20px',
  },
  loginLogo: {
    textAlign: 'center',
    paddingTop: '30px',
    width: '100%',
    maxWidth: '500px',
    margin: '0 auto',
  },
  loginImage: {
    width: '55%',
  },
  loginFormArea: {
    width: '80%',
    maxWidth: '300px',
    margin: '0px auto',
    clear: 'both',
    padding: '0px',
    color: '#fff',
  },
  forRegistant: {
    fontSize: '0.8em',
    fontWeight: 'bold',
    textAlign: 'center',
    margin: '10px',
  },
  input: {
    width: '100%',
    boxSizing: 'border-box',
    height: '30px',
    margin: '10px 0px 10px 0px',
  },
  inputDefault: {
    border: '1px solid #fff',
  },
  inputError: {
    border: '1px solid #ff0000',
  },
  forgetPassArea: {
    textAlign: 'center',
    fontSize: '0.8em',
    marginBottom: '10px',
  },
  forgetPass: {
    color: '#fff',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  loginButton: {
    border: '1px solid #847965',
    margin: '5px auto',
    padding: '5px',
    textAlign: 'center',
    width: '100%',
    height: '40px',
    background: '#292020',
    color: '#fff',
    fontSize: '0.8em',
  },
  requesting: {
    background: '#4e4e4e',
  },
  companyLogo: {
    textAlign: 'center',
    margin: '0px auto',
    display: 'block',
    padding: '10px 0px',
    bottom: '0',
    left: '0',
    width: '100%',
  },
  companyImage: {
    width: '120px',
  },
  errorMessage: {
    color: '#ff0000',
    textAlign: 'left',
    fontSize: '0.75em',
    marginBottom: '10px',
    padding: '0px 4px 0px 8px',
    marginLeft: '1em',
    textIndent: '-1.4em',
  },
  cautionImage: {
    width: '18px',
    verticalAlign: 'middle',
    margin: '0px 2px 4px 0px',
  },
});

const renderEmailField = (loginState, intl) => (
  <div>
    <input
      type="email"
      name="username"
      style={
        loginState === 'FAILED'
          ? { ...style.input, ...style.inputError }
          : { ...style.input, ...style.inputDefault }
      }
      placeholder={intl.formatMessage({ id: 'loginPage.mailaddress' })}
    />
  </div>
);

const renderPasswordField = (loginState, intl) => (
  <div>
    <input
      type="password"
      name="password"
      style={
        loginState === 'FAILED'
          ? { ...style.input, ...style.inputError }
          : { ...style.input, ...style.inputDefault }
      }
      placeholder={intl.formatMessage({ id: 'loginPage.pass' })}
    />
  </div>
);

const renderLoginErrorMessage = intl => (
  <div style={style.errorMessage}>
    <img src={caution} alt="caution" style={style.cautionImage} />
    {intl.formatMessage({ id: 'loginPage.error1' })}
    {intl.formatMessage({ id: 'loginPage.error2' })}
  </div>
);

const renderServerErrorMessage = intl => (
  <div style={style.errorMessage}>
    <img src={caution} alt="caution" style={style.cautionImage} />
    {intl.formatMessage({ id: 'loginPage.serverError1' })}
    {intl.formatMessage({ id: 'loginPage.serverError2' })}
  </div>
);

const renderErrorMessage = (loginState, intl) => {
  if (loginState === 'API_ERROR') {
    return renderServerErrorMessage(intl);
  } else if (loginState === 'FAILED') {
    return renderLoginErrorMessage(intl);
  }
  return null;
};

declare var config: { URLS: Object[] };

const renderForgetPass = intl => (
  <p style={style.forgetPassArea}>
    <Link to="/reset/password/sendmail" style={style.forgetPass}>
      {intl.formatMessage({ id: 'loginPage.forgetGuide' })}
    </Link>
  </p>
);

const renderLoginButton = (loginState, statusRequesting, intl) => (
  <div>
    <input
      type="submit"
      style={
        loginState === 'REQUESTING' || statusRequesting
          ? { ...style.loginButton, ...style.requesting }
          : style.loginButton
      }
      value={
        loginState === 'REQUESTING' || statusRequesting
          ? intl.formatMessage({ id: 'loginPage.loginRequesting' })
          : intl.formatMessage({ id: 'loginPage.login' })
      }
      disabled={loginState === 'REQUESTING' || statusRequesting}
    />
  </div>
);

const renderForgetLinks = intl => {
  const showForgetPass = intl.formatMessage({ id: 'loginPage.showForgetPass' }) === 'true';

  return <div>{showForgetPass && renderForgetPass(intl)}</div>;
};

const renderForm = (loginState, statusRequesting, userStatus, intl, locale, dispatch) => (
  <form
    method="POST"
    onSubmit={event => {
      event.preventDefault();
      login(event.target.username.value, event.target.password.value, dispatch);
    }}
  >
    <div style={style.forRegistant}>{intl.formatMessage({ id: 'loginPage.forRegistant' })}</div>
    {renderEmailField(loginState, intl)}
    {renderPasswordField(loginState, intl)}
    {renderErrorMessage(loginState, intl)}
    {renderLoginButton(loginState, statusRequesting, intl)}
    {renderForgetLinks(intl)}
  </form>
);

const renderCompanyLogo = intl => {
  const { COMPANY_PAGE } = config.URLS[intl.locale.toUpperCase()] || {};
  return (
    <div style={style.companyLogo}>
      <a href={COMPANY_PAGE} target="_blank" rel="noopener noreferrer">
        <img src={companyLogo} alt="companyLogo" style={style.companyImage} />
      </a>
    </div>
  );
};

type StateProps = {|
  loginState: string,
  statusRequesting: boolean,
  userStatus: string,
|};
type OwnProps = {|
  intl: intlShape,
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

export const PureLoginPage = ({
  loginState,
  statusRequesting,
  userStatus,
  intl,
  dispatch,
}: Props): Node => {
  const loginLogo = require(`Client/images/login_logo_ja.png`);
  return (
    <div style={style.loginPage}>
      <div style={style.login}>
        <div style={style.loginLogo}>
          <img src={loginLogo} alt="login" style={style.loginImage} />
        </div>
        <div style={style.loginFormArea}>
          {renderForm(
            loginState,
            statusRequesting,
            userStatus,
            intl,
            intl.locale.toUpperCase(),
            dispatch
          )}
        </div>
        {renderCompanyLogo(intl)}
      </div>
    </div>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  loginState: state.loginInfo.loginState,
  statusRequesting: state.userInfo.statusRequesting,
  userStatus: state.userInfo.status,
});

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  injectIntl(PureLoginPage)
);
