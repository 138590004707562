// @flow

import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { VictoryContainer, VictoryBar, VictoryChart, VictoryAxis, VictoryTheme } from 'victory';
import sizeMe from 'react-sizeme';
import Prefixer from 'Client/lib/Prefixer';
import { calcPrice } from 'Client/lib/pricePlanUtils';
import type { State, PricePlanState } from 'Client/types/state';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  legend: {
    textAlign: 'left',
    fontSize: '0.8em',
    marginTop: '0px',
  },
  legendSell: {
    color: '#4bbeae',
  },
  legendBuy: {
    color: '#ff9086',
  },
});

const barChartPadding = {
  week: 20,
  month: 35,
  year: 20,
};

const renderLegend = intl => (
  <div style={style.legend}>
    <span style={style.legendSell}>
      {intl.formatMessage({ id: 'chartSubUnitChart.sellCharge' })}
    </span>
    &nbsp;
    <span style={style.legendBuy}>{intl.formatMessage({ id: 'chartSubUnitChart.buyCharge' })}</span>
  </div>
);

const xAxisStyle = {
  grid: { stroke: 'transparent' },
  axis: { stroke: 'black', strokeWidth: 1 },
  ticks: { stroke: 'transparent' },
  tickLabels: { fontSize: 10, fill: '#847965', padding: 5 },
};

const renderDayAxis = intl => (
  <VictoryAxis
    tickValues={[1, 2, 3, 4, 5, 6, 7]}
    tickFormat={[
      intl.formatMessage({ id: 'chartSubUnitChart.sun' }),
      intl.formatMessage({ id: 'chartSubUnitChart.mon' }),
      intl.formatMessage({ id: 'chartSubUnitChart.tue' }),
      intl.formatMessage({ id: 'chartSubUnitChart.wed' }),
      intl.formatMessage({ id: 'chartSubUnitChart.thu' }),
      intl.formatMessage({ id: 'chartSubUnitChart.fri' }),
      intl.formatMessage({ id: 'chartSubUnitChart.sat' }),
    ]}
    style={xAxisStyle}
  />
);

const renderWeekAxis = (itemSize, intl) => (
  <VictoryAxis
    tickValues={Array.from(Array(itemSize)).map((value, index) => index + 1)}
    tickFormat={x =>
      intl.formatMessage({ id: 'chartSubUnitChart.place' }).split(',')[x - 1] +
      intl.formatMessage({ id: 'chartSubUnitChart.week' })
    }
    style={xAxisStyle}
  />
);

const renderMonthAxis = intl => (
  <VictoryAxis
    tickValues={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
    tickFormat={x => intl.formatMessage({ id: 'chartSubUnitChart.month' }).split(',')[x - 1]}
    style={xAxisStyle}
  />
);

const renderXAxis = (unit, itemSize, intl) => {
  switch (unit) {
    case 'week':
      return renderDayAxis(intl);
    case 'month':
      return renderWeekAxis(itemSize, intl);
    case 'year':
      return renderMonthAxis(intl);
    default:
      return <VictoryAxis />;
  }
};

const barWidth = (chartWidth, itemSize, isPg) => {
  const fullWidth = (chartWidth / itemSize) * 0.8;
  return isPg ? fullWidth / 2 : fullWidth;
};

const renderBuyBarPg = (unitEnergyList, width, pricePlan) => (
  <VictoryBar
    style={{ data: { fill: '#ff8f86', width } }}
    data={unitEnergyList.map((unitEnergy, index) => ({
      x: index + 1.2,
      y: calcPrice(0, 24, pricePlan, unitEnergy.buyEnergy),
    }))}
  />
);

const renderBuyBar = (unitEnergyList, width, pricePlan) => (
  <VictoryBar
    style={{ data: { fill: '#ff8f86', width } }}
    data={unitEnergyList.map((unitEnergy, index) => ({
      x: index + 1,
      y: calcPrice(0, 24, pricePlan, unitEnergy.buyEnergy),
    }))}
  />
);

const renderChart = (unitEnergyList, unit, sellPrice, isPg, intl, width, pricePlan) => (
  <VictoryChart
    theme={VictoryTheme.material}
    containerComponent={<VictoryContainer responsive={false} style={{ touchAction: 'auto' }} />}
    width={width}
    height={286}
    padding={{ right: 3, left: 3, bottom: 30 }}
    domainPadding={barChartPadding[unit]}
  >
    {renderXAxis(unit, unitEnergyList.length, intl)}
    <VictoryAxis
      dependentAxis
      tickCount={7}
      style={{
        grid: { strokeWidth: 1, stroke: '#ccc' },
        axis: { stroke: 'transparent' },
        ticks: { stroke: 'transparent' },
        tickLabels: { fill: 'transparent' },
      }}
    />
    {isPg && (
      <VictoryBar
        style={{ data: { fill: '#4bbeae', width: barWidth(width, unitEnergyList.length, isPg) } }}
        data={unitEnergyList.map((unitEnergy, index) => ({
          x: index + 0.8,
          y: unitEnergy.sellEnergy * sellPrice,
        }))}
      />
    )}
    {isPg
      ? renderBuyBarPg(unitEnergyList, barWidth(width, unitEnergyList.length, isPg), pricePlan)
      : renderBuyBar(unitEnergyList, barWidth(width, unitEnergyList.length, isPg), pricePlan)}
  </VictoryChart>
);

type StateProps = {|
  isPg: boolean,
  pricePlan: PricePlanState,
|};
type OwnProps = {|
  unitEnergyList: {
    period?: string,
    sellEnergy: number,
    buyEnergy: number[],
  }[],
  unit: string,
  sellPrice: number,
  intl: intlShape,
  size: {
    width: number,
    height: number,
  },
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

export const PureChartSubUnitChart = ({
  unitEnergyList,
  unit,
  sellPrice,
  isPg,
  intl,
  size,
  pricePlan,
}: Props): Node => (
  <div>
    {isPg && renderLegend(intl)}
    {renderChart(unitEnergyList, unit, sellPrice, isPg, intl, size.width, pricePlan)}
  </div>
);

const mapStateToProps = (state: State): StateProps => ({
  isPg: state.userSetting.isPg,
  pricePlan: state.pricePlan,
});

const config = { monitorHeight: true };
const sizeMeHOC = sizeMe(config);

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  sizeMeHOC(injectIntl(PureChartSubUnitChart))
);
