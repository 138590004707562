// @flow

import moment from 'moment-timezone';
import type { Dispatch } from 'redux';
import { applianceId, timeUnitId } from 'Client/lib/id';
import {
  apiGetTimeSeriesOfToday,
  apiMultiFetch,
  apiGetUseStatsForLastYear,
  apiGetEnergyHourlyByUnit,
} from 'Client/actions/apis';

import { LOAD_TOP_PAGE, LOAD_TOP_PAGE_LATEST_TWO_HOURS } from 'Client/actions/types';

import { getHourlyRequestMeta } from 'Client/lib/globalUtils';

const houseAnime0 = require('Client/images/animation0.png');
const houseAnime1 = require('Client/images/animation1.gif');
const houseAnime2 = require('Client/images/animation2.gif');
const houseAnime3 = require('Client/images/animation3.gif');
const houseAnime4 = require('Client/images/animation4.gif');
const houseAnime5 = require('Client/images/animation5.gif');

const houseAnimeJA = [
  houseAnime0,
  houseAnime1,
  houseAnime1,
  houseAnime1,
  houseAnime1,
  houseAnime2,
  houseAnime2,
  houseAnime2,
  houseAnime3,
  houseAnime3,
  houseAnime4,
  houseAnime4,
  houseAnime5,
];

const TIMEBAR_WITHOUT_YESTERDAY = 12;
const TWELVE_HOURS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
const create24HoursArray = () => {
  const hours = Array.from(new Array(24)).map((v, i) => i);
  return hours.map(hour => hour);
};

const generateHourId = (hourNumber, addedHours) =>
  addedHours.includes(hourNumber) ? hourNumber + 100 : hourNumber;

export const getUseStatsByHourRequests = (requestHours: number[] = TWELVE_HOURS): Object[] => {
  const addedHours = [];
  const req = requestHours.map(i => {
    const hour = moment().subtract(i, 'hours');
    const hourId = generateHourId(Number(hour.format('HH')), addedHours);
    addedHours.push(hourId);
    return {
      type: 'useStats',
      name: `useStats${hourId}`,
      meta: {
        hour: hourId,
      },
      body: {
        sts: hour.startOf('hour').unix(),
        ets: hour.endOf('hour').unix(),
      },
    };
  });
  return req;
};

export const getTimeSeriesByHourRequestsIsPg = (
  requestHours: number[] = TWELVE_HOURS
): Object[] => {
  const addedHours = [];
  if (parseInt(moment().format('HH'), 10) >= TIMEBAR_WITHOUT_YESTERDAY) {
    const request24Hours = create24HoursArray();
    const req = [];
    request24Hours.some(i => {
      const hour = moment().subtract(i, 'hours');
      const hourId = generateHourId(Number(hour.format('HH')), addedHours);
      addedHours.push(hourId);
      const tmp = {
        type: 'timeSeries',
        name: `timeSeries${hourId}`,
        meta: {
          dateTime: hourId,
          unit: timeUnitId.hour,
        },
        body: {
          unit: timeUnitId.hour,
          sts: hour.startOf('hour').unix(),
          ets: hour.endOf('hour').unix(),
        },
      };
      req.push(tmp);
      return hourId === 0;
    });
    return req;
  }
  const req = requestHours.map(i => {
    const hour = moment().subtract(i, 'hours');
    const hourId = generateHourId(Number(hour.format('HH')), addedHours);
    addedHours.push(hourId);
    return {
      type: 'timeSeries',
      name: `timeSeries${hourId}`,
      meta: {
        dateTime: hourId,
        unit: timeUnitId.hour,
      },
      body: {
        unit: timeUnitId.hour,
        sts: hour.startOf('hour').unix(),
        ets: hour.endOf('hour').unix(),
      },
    };
  });
  return req;
};

export const getTimeSeriesByHourRequests = (requestHours: number[] = TWELVE_HOURS): Object[] => {
  const addedHours = [];
  const req = requestHours.map(i => {
    const hour = moment().subtract(i, 'hours');
    const hourId = generateHourId(Number(hour.format('HH')), addedHours);
    addedHours.push(hourId);
    return {
      type: 'timeSeries',
      name: `timeSeries${hourId}`,
      meta: {
        dateTime: hourId,
        unit: timeUnitId.hour,
      },
      body: {
        unit: timeUnitId.hour,
        sts: hour.startOf('hour').unix(),
        ets: hour.endOf('hour').unix(),
      },
    };
  });
  return req;
};

const loadTotalAmountArea = dispatch => {
  const thisMonth = moment().format('YYYY-MM');
  dispatch(apiGetEnergyHourlyByUnit(getHourlyRequestMeta('month'), 'month', 'day', thisMonth));
};

export const loadTopPage = (dispatch: Dispatch, isPg: boolean): void => {
  dispatch({ type: LOAD_TOP_PAGE });
  const multifetchReqest = [
    ...getUseStatsByHourRequests(),
    ...(isPg ? getTimeSeriesByHourRequestsIsPg() : getTimeSeriesByHourRequests()),
  ];
  dispatch(apiMultiFetch(multifetchReqest));
  if (!isPg) {
    loadTotalAmountArea(dispatch);
  }
  dispatch(apiGetUseStatsForLastYear());
};

export const loadTopPageLatestTwoHours = (dispatch: Dispatch, isPg: boolean): void => {
  const latestTwoHours = [0, 1];
  dispatch({ type: LOAD_TOP_PAGE_LATEST_TWO_HOURS });
  dispatch(apiMultiFetch(getUseStatsByHourRequests(latestTwoHours)));
  dispatch(apiMultiFetch(getTimeSeriesByHourRequests(latestTwoHours)));
  if (isPg) {
    dispatch(apiGetTimeSeriesOfToday());
  } else {
    loadTotalAmountArea(dispatch);
  }
};

export const needsUpdate = (currentDate: string, currentTime: string): boolean => {
  const now = moment();

  const matched = currentTime.match(/^[0-9]*/);
  return (
    now.format('YYYY-MM-DD') !== currentDate && now.format('HH') !== (matched ? matched[0] : '')
  );
};

export const currentHour = (currentTime: string): number => Number(currentTime.match(/^[0-9]*/));

export const withinTwoHours = (
  currentDate: string,
  currentTime: string,
  selectedHour: number
): boolean => {
  const currentH = currentHour(currentTime);
  const anHourAgo = currentH === 0 ? 23 : currentH - 1;
  return (
    moment().format('YYYY-MM-DD') === currentDate &&
    (selectedHour === currentH || selectedHour === anHourAgo)
  );
};

export const withinOneHours = (
  currentDate: string,
  currentTime: string,
  selectedHour: number
): boolean =>
  moment().format('YYYY-MM-DD') === currentDate && selectedHour === currentHour(currentTime);

const isAplUsed = (hourlyDuration, apl) => {
  const hourlyDurationApl = hourlyDuration.appliances.find(x => x.id === apl.id);
  return hourlyDurationApl && hourlyDurationApl.duration > 0;
};

export const isUsedAtHour = (
  hour: number,
  aplFilter: Object[],
  hourlyDurations: Object[]
): Object[] | boolean => {
  const hourlyDuration = hourlyDurations.find(x => x.hour === hour);
  if (!hourlyDuration) {
    return false;
  }

  // エコキュート・エネファーム・蓄電池を除外する
  const exclusionApplianceIds = [
    applianceId.ecoCute,
    applianceId.eneFarm,
    applianceId.chargedStorageBattery,
    applianceId.dischargedStorageBattery,
    applianceId.chargedHybridStorageBattery,
    applianceId.dischargedHybridStorageBattery,
  ];
  const excludedAplFilter = aplFilter.filter(filter => !exclusionApplianceIds.includes(filter.id));
  const result = !!excludedAplFilter.find(apl => apl.display && isAplUsed(hourlyDuration, apl));
  return result;
};

const aplUsedHourCount = (range, aplFilter, hourlyDurations) =>
  range.filter(hour => isUsedAtHour(hour, aplFilter, hourlyDurations)).length;

export const houseAnimationType = (
  range: number[],
  aplFilter: Object[],
  hourlyDurations: Object[]
) => houseAnimeJA[aplUsedHourCount(range, aplFilter, hourlyDurations)];
