export const timeUnitId = {
  second: '10',
  minute: '20',
  halfHour: '25',
  hour: '30',
  day: '40',
  week: '45',
  month: '50',
  year: '60',
};

export const applianceId = {
  airConditioner: '2',
  airConditionerObserved: '2_observed',
  clothesWasher: '5',
  microwave: '20',
  refrigerator: '24',
  riceCooker: '25',
  tv: '30',
  vacuumCleaner: '31',
  dishwasher: '34',
  oven: '35',
  ih: '37',
  ecoCute: '39',
  ecoCuteObserved: '39_observed',
  highFever: '300',
  heater: '301',
  light: '304',
  pv: '309',
  ev: '991',
  chargedStorageBattery: '994_charged',
  dischargedStorageBattery: '994_discharged',
  eneFarm: '996',
  chargedHybridStorageBattery: '9972_charged',
  dischargedHybridStorageBattery: '9972_discharged',
  background: '998',
};

// グラフに表示する発電家電
export const pgIds = [
  applianceId.pv,
  applianceId.eneFarm,
  applianceId.dischargedHybridStorageBattery, // 発電・放電
];

// グラフに表示する個別計測機器
export const individualMeasurementIds = [
  applianceId.airConditionerObserved,
  applianceId.ecoCuteObserved,
  applianceId.ev,
];
