// @flow

import moment from 'moment-timezone';
import type { Dispatch } from 'redux';
import { intlShape } from 'react-intl';
import { apiGetTimeSeriesByUnit } from 'Client/actions/apis';
import { timeUnitId, pgIds } from 'Client/lib/id';
import type { ApplianceEnergyDetailState } from 'Client/types/state';

export const timeUnitList = ['day', 'week', 'month', 'year'];

export const subTimeUnit = {
  day: 'day',
  week: 'day',
  month: 'week',
  year: 'month',
};
export const hourlyRequestUnit = {
  day: 'day',
  week: 'day',
  month: 'day',
  year: 'month',
};

export const timeUnitStyle = {
  day: { toUnit: 'daily', toMulti: 'days', toRequesting: 'dailyRequesting' },
  week: { toUnit: 'weekly', toMulti: 'weeks', toRequesting: 'weeklyRequesting' },
  month: { toUnit: 'monthly', toMulti: 'months', toRequesting: 'monthlyRequesting' },
  year: { toUnit: 'yearly', toMulti: 'years', toRequesting: 'yearlyRequesting' },
};

export const timeFormat = {
  day: 'YYYY-MM-DD',
  week: 'YYYY-MM-DD',
  month: 'YYYY-MM',
  year: 'YYYY',
};

const dailyChartAccessibleProviderIds = ['0054', '0097'];

export const isOneHourPassed = (currentTime: string): boolean =>
  moment(currentTime).diff(moment(), 'hours') < 0;

export const getEnergy = (array: Object[], unit: string, period: string): ?Object =>
  array.find(elem => elem[unit] === period);

export const latestPeriod = (unit: string): string => {
  const format = timeFormat[unit];
  const now = moment();
  return unit === 'week' ? now.startOf(unit).format(format) : now.format(format);
};

export const nextPeriod = (unit: string, period: string): string => {
  const unitMulti = timeUnitStyle[unit].toMulti;
  const format = timeFormat[unit];
  return moment(period, format)
    .add(1, unitMulti)
    .format(format);
};

export const prevPeriod = (unit: string, period: string): string => {
  const unitMulti = timeUnitStyle[unit].toMulti;
  const format = timeFormat[unit];
  return moment(period, format)
    .subtract(1, unitMulti)
    .format(format);
};

export const startDates = (unit: string, argPeriod: ?string): string[] => {
  const period = argPeriod || moment().format('YYYY-MM');
  const time = moment(period, timeFormat[unit]);
  const startOfPeriod = time.startOf(unit).valueOf();
  const endOfPeriod = time.endOf(unit).valueOf();
  const subUnit = subTimeUnit[unit];
  const subFormat = timeFormat[subUnit];
  const startDateList = [];
  const iter = moment(startOfPeriod).startOf(subUnit);
  while (iter.valueOf() < endOfPeriod) {
    startDateList.push(iter.format(subFormat));
    iter.add(1, timeUnitStyle[subUnit].toMulti);
  }
  return startDateList;
};

const requestDates = (startDateList, energyList, unit) => {
  if (!energyList.length) {
    return startDateList;
  }
  return startDateList.filter(startDate => {
    if (energyList.find(elem => elem[unit] === startDate) || moment().isBefore(startDate)) {
      return false;
    }
    return true;
  });
};

export const getSubUnitEnergyRequests = (
  unit: string,
  period: string,
  subUnitEnergyList: Object[] = []
): Object[] => {
  const subUnit = subTimeUnit[unit];
  const subFormat = timeFormat[subUnit];
  const subUnitId = timeUnitId[subUnit];
  const startDateList = startDates(unit, period);
  const requestDateList = requestDates(startDateList, subUnitEnergyList, subUnit);
  const bodySubUnitId = subUnitId === timeUnitId.month ? timeUnitId.day : subUnitId;
  const req = requestDateList.map(date => {
    const time = moment(date, subFormat);
    return {
      type: 'timeSeries',
      name: `timeSeries${date}`,
      meta: {
        dateTime: date,
        unit: subUnitId,
      },
      body: {
        unit: bodySubUnitId,
        sts: time.startOf(subUnit).unix(),
        ets: time.endOf(subUnit).unix(),
      },
    };
  });
  return req;
};

export const getSubUnitDateTime = (
  unit: string,
  period: string,
  learningStartDate: string,
  subUnitEnergyList: Object[] = []
): string[] => {
  const subUnit = subTimeUnit[unit];
  const startDateList = startDates(unit, period);
  const requestDateLimit =
    subUnit === 'week'
      ? moment(learningStartDate, timeFormat[subUnit]).subtract(7, 'days')
      : moment(learningStartDate, timeFormat[subUnit]);
  const requestDateList = requestDates(startDateList, subUnitEnergyList, subUnit);
  const result = requestDateList.filter(requestDate =>
    moment(requestDate).isSameOrAfter(requestDateLimit)
  );
  return result;
};

export const apiGetUnloadedSubUnitEnergy = (
  subUnitEnergyList: { unit: string }[],
  unit: string,
  period: string,
  dispatch: Dispatch
) => {
  const subUnit = subTimeUnit[unit];
  const startDateList = startDates(unit, period);
  startDateList.forEach(startDate => {
    if (!getEnergy(subUnitEnergyList, subUnit, startDate)) {
      dispatch(apiGetTimeSeriesByUnit(subUnit, startDate));
    }
  });
};

export const isValidDateFormat = (unit: string, period: string) => {
  switch (unit) {
    case 'week':
    case 'day':
      return period.match(/^\d{4}-\d{2}-\d{2}$/);
    case 'month':
      return period.match(/^\d{4}-\d{2}$/);
    case 'year':
      return period.match(/^\d{4}$/);
    default:
      return false;
  }
};

export const isDailyChartAccessibleProvider = (providerId: string): ?string =>
  dailyChartAccessibleProviderIds.find(ele => ele === providerId);

// 発電する家電のデータを正の値に変換し返却する
export const getAppliancePgEnergy = (
  idsToShow: (string | null)[],
  applianceEnergy: ApplianceEnergyDetailState[],
  period: string,
  id: string
): ?{ id: string, energy: number[] } => {
  // 発電する家電に含まれないまたは表示可能な家電に含まれなければnull
  if (!pgIds.includes(id) || !idsToShow.includes(id)) {
    return null;
  }
  const targetData = applianceEnergy.find(d => d.dateTime === period);
  const targetApplianceData =
    targetData &&
    targetData.applianceEnergy &&
    targetData.applianceEnergy.find(energy => energy.id === id);
  const applianceData = targetApplianceData && {
    id: targetApplianceData.id,
    energy: targetApplianceData.energy.reduce((pre, now) => [...pre, (now < 0 && -now) || 0], []),
  };

  return applianceData;
};

/**
 * エネルギー値（x.xxxxxxxxxxx）を整形する
 */
export const formatEnergy = ({
  intl,
  energy,
  unit,
}: {
  intl: intlShape,
  energy: number,
  unit: string,
}): string => {
  const formattedEnergy = intl.formatNumber(energy, {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });
  return `${formattedEnergy} ${unit}`;
};
