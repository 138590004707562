// @flow
/* eslint-env browser */

import React, { Component } from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import ReactHighcharts from 'react-highcharts';
import moment from 'moment-timezone';
import sizeMe from 'react-sizeme';
import Loading from 'Client/components/utils/Loading';
import ChartNodata, { CHART_NO_DATA } from 'Client/components/ChartNoData';
import QuestionIcon from 'Client/components/utils/QuestionIcon';
import Prefixer from 'Client/lib/Prefixer';
import { OPEN_POP_UP } from 'Client/actions/types';
import { HELP_CHART_DAY } from 'Client/actions/helpPopupTypes';
import type { State, AplPowersState } from 'Client/types/state';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  legend: {
    textAlign: 'left',
    fontSize: '0.8em',
    marginTop: '0px',
  },
  legendSell: {
    color: '#62ba58',
  },
  legendBuy: {
    color: '#ffbd00',
  },
  chart: {
    margin: '0px',
    padding: '0px',
  },
  info: {
    padding: '3px 10px 0 0',
    textAlign: 'right',
  },
});

type StateProps = {|
  aplPowers: AplPowersState,
|};
type OwnProps = {|
  intl: intlShape,
  period: string,
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

export class PureChartDayStats extends Component<Props> {
  componentDidUpdate() {
    const unixtimeMillisec = 1000 * 60 * 60 * 6; // ６時間
    if (this.chart) {
      const chartOption = this.chart.chart;
      const pointStart = chartOption.options.plotOptions.series.pointStart;
      chartOption.xAxis[0].setExtremes(pointStart, pointStart + unixtimeMillisec);
      chartOption.showResetZoom();
    }
  }

  chart: ?ReactHighcharts;

  render() {
    const renderDayStatsCharts = (aplPowers, period, intl) => {
      ReactHighcharts.Highcharts.setOptions({
        global: {
          timezoneOffset: -9 * 60,
        },
        lang: {
          resetZoom: intl.formatMessage({ id: 'chartDayStats.resetZoom' }),
          numericSymbols: null,
        },
      });
      const aplType = {
        '2': { name: intl.formatMessage({ id: 'chartDayStats.airConditioner' }), color: '#9cd5e4' },
        '5': { name: intl.formatMessage({ id: 'chartDayStats.washingMachine' }), color: '#ffe868' },
        '20': { name: intl.formatMessage({ id: 'chartDayStats.microwave' }), color: '#ff6c2f' },
        '24': { name: intl.formatMessage({ id: 'chartDayStats.refrigerator' }), color: '#8ee2d9' },
        '25': { name: intl.formatMessage({ id: 'chartDayStats.riceCooker' }), color: '#e2f3ac' },
        '30': { name: intl.formatMessage({ id: 'chartDayStats.television' }), color: '#adbbef' },
        '31': { name: intl.formatMessage({ id: 'chartDayStats.vacuumCleaner' }), color: '#98e09b' },
        '37': { name: intl.formatMessage({ id: 'chartDayStats.IH' }), color: '#ffb835' },
        '300': { name: intl.formatMessage({ id: 'chartDayStats.highHeatapl' }), color: '#ff9366' },
        '998': { name: intl.formatMessage({ id: 'chartDayStats.standbyPower' }), color: '#ffefca' },
      };
      const series = [
        {
          name: intl.formatMessage({ id: 'chartDayStats.overallPpower' }),
          type: 'line',
          data: aplPowers.rootPower,
          color: '#ff9a91',
          marker: {
            enabled: false,
          },
        },
      ];
      Object.keys(aplPowers.powers).forEach(ele => {
        series.push({
          name: aplType[ele].name,
          type: 'area',
          data: aplPowers.powers[ele],
          color: aplType[ele].color,
          marker: {
            enabled: false,
          },
        });
      });
      const chartConfig = {
        chart: {
          zoomType: 'x', // ズームタイプ。 x: x軸のみ / y: y軸のみ / xy: xy軸,
          panning: true,
          panKey: 'shift',
          resetZoomButton: {
            theme: {
              fill: 'white',
              style: {
                color: '#847965',
              },
            },
          },
        },
        title: {
          text: intl.formatMessage({ id: 'chartDayStats.chartTitle' }),
          style: {
            color: '#847965',
          },
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
          followTouchMove: false,
        },
        legend: {
          itemMarginTop: 20,
          itemStyle: {
            color: '#847965',
            lineHeight: '14px',
          },
        },
        yAxis: {
          title: {
            enabled: false,
          },
          labels: {
            style: {
              color: '#c9c9c9',
            },
          },
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            day: '%m/%d',
          },
          labels: {
            style: {
              color: '#c9c9c9',
            },
          },
        },
        plotOptions: {
          series: {
            pointStart: Number(
              moment(period)
                .startOf('day')
                .utc()
            ),
            pointInterval: 60 * 1000,
          },
          area: {
            stacking: 'normal',
            lineColor: '#666666',
            lineWidth: 1,
            marker: {
              lineWidth: 1,
              lineColor: '#666666',
            },
          },
        },
        series,
      };
      return (
        <ReactHighcharts
          config={chartConfig}
          ref={c => {
            this.chart = c;
          }}
        />
      );
    };
    const { aplPowers, period, intl, dispatch } = this.props;
    // 学習期間中も特例で家電分離グラフを見えるようにしています
    // 表示を隠すように修正する予定
    if (aplPowers.requesting) {
      return (
        <Loading space={80}>
          <div>{intl.formatMessage({ id: 'application.dataLoading' })}</div>
        </Loading>
      );
    } else if (aplPowers.rootPower.length !== 0) {
      return (
        <div>
          <div style={style.info}>
            <QuestionIcon
              onClick={e => {
                dispatch({
                  type: OPEN_POP_UP,
                  contentType: HELP_CHART_DAY,
                  target: e.target,
                });
              }}
            />
          </div>
          <div style={style.chart}>{renderDayStatsCharts(aplPowers, period, intl)}</div>
        </div>
      );
    }
    return <ChartNodata type={CHART_NO_DATA} />;
  }
}

const mapStateToProps = (state: State): StateProps => ({
  aplPowers: state.aplPowers,
});

const config = { monitorHeight: true };
const sizeMeHOC = sizeMe(config);

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  sizeMeHOC(injectIntl(PureChartDayStats))
);
