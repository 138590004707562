// @flow

import type { Action } from 'redux';
import moment from 'moment-timezone';
import {
  SUCCESS_API_LOGIN_INFO,
  CHANGE_CHART_UNIT,
  CHANGE_CHART_TYPE,
  CHANGE_SELECTED_APPLIANCE,
  SELECT_NEXT_PERIOD,
  SELECT_PREV_PERIOD,
  LOAD_CHART_PAGE,
  RESET_CHART_PAGE,
  LOAD_TOP_PAGE,
} from 'Client/actions/types';
import { latestPeriod, nextPeriod, prevPeriod } from 'Client/lib/chartUtils';
import { overWriteStateWithFirebase } from 'Client/lib/settingUtils';
import type { ChartTimeState as State } from 'Client/types/state';

export const initialState = {
  unit: 'month', // note: if this is empty, CSRF error occurs. Cause unknown.
  period: '',
  currentTime: '',
  chartType: 'balance', // balance or pg
  selectedApplianceTypeId: '', // selected individual measurement appliances id
};

export default (state: State = initialState, action: Action = {}): State => {
  const { type } = action;
  switch (type) {
    case SUCCESS_API_LOGIN_INFO: {
      const { payload } = action;
      return {
        ...state,
        selectedApplianceTypeId:
          (payload.settings &&
            overWriteStateWithFirebase(state, payload.settings).selectedApplianceTypeId) ||
          payload.selectedApplianceTypeId ||
          state.selectedApplianceTypeId,
      };
    }
    case CHANGE_CHART_UNIT: {
      const { unit } = action;
      return {
        ...state,
        unit,
        period: latestPeriod(unit),
      };
    }
    case CHANGE_CHART_TYPE: {
      const { contentType } = action;
      return {
        ...state,
        chartType: contentType,
      };
    }
    case CHANGE_SELECTED_APPLIANCE: {
      const { selectedApplianceTypeId } = action;
      return {
        ...state,
        selectedApplianceTypeId,
      };
    }
    case SELECT_NEXT_PERIOD: {
      const { unit, period } = state;
      return {
        ...state,
        period: nextPeriod(unit, period),
      };
    }
    case SELECT_PREV_PERIOD: {
      const { unit, period } = state;
      return {
        ...state,
        period: prevPeriod(unit, period),
      };
    }
    case LOAD_CHART_PAGE: {
      const { unit, period } = action.payload;
      return {
        ...state,
        unit,
        period,
        currentTime: moment().format('YYYY-MM-DD kk:mm'),
      };
    }
    case RESET_CHART_PAGE: {
      return {
        ...state,
        currentTime: moment().format('YYYY-MM-DD kk:mm'),
      };
    }
    case LOAD_TOP_PAGE: {
      return {
        ...initialState,
        currentTime: moment().format('YYYY-MM-DD kk:mm'),
        selectedApplianceTypeId: state.selectedApplianceTypeId,
      };
    }

    default: {
      return state;
    }
  }
};
