// @flow

import React, { useState } from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import QuestionIcon from 'Client/components/utils/QuestionIcon';
import StackedMirrorBarChart from 'Client/components/StackedMirrorBarChart';
import { HELP_STACKED_CHART, HELP_CHART_HYBRID } from 'Client/actions/helpPopupTypes';
import { OPEN_POP_UP } from 'Client/actions/types';
import Prefixer from 'Client/lib/Prefixer';
import { startDates, formatEnergy } from 'Client/lib/chartUtils';
import type { SplitEnergiesData, ChartData } from 'Client/types/types';
import { getTotalEnergy } from 'Client/lib/globalUtils';
import type { State } from 'Client/types/state';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  container: {
    position: 'relative',
  },
  headline: {
    marginBottom: 20,
  },
  title: {
    verticalAlign: 'middle',
  },
  criterion: {
    fontSize: '0.7em',
    paddingLeft: '5px',
  },
  legendContainer: {
    margin: '15px 10px 20px',
    lineHeight: 1,
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
  },
  adjacentLegendItem: {
    marginTop: 10,
  },
  legendIcon: {
    width: 16,
    height: 16,
    marginRight: 5,
    backgroundSize: 'cover',
  },
  legendChargeIcon: {
    backgroundImage:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAAIVBMVEUAAACgzlGhzlGizlKhzVKfz1ChzlGhz1Kfz1CgzVKhzlIBGUN/AAAACnRSTlMA8MBwgDCwfyCvXTsbnAAAAF9JREFUKM9jYGAIV1wFBUKlDEDAvAoBFhoABbJWIYGVUAUIYMAQhSogyuCFKrCEQQtVYBGDFKrAQoZVaGCkC6AHEEYQYgZyIKqABEZEoUXlMlBkS6FFNkMIIjl4MDAAAMhEO2WKCaPxAAAAAElFTkSuQmCC)',
  },
  legendDischargeIcon: {
    backgroundImage:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAAElBMVEUAAADz1SLy1CH31yDz1CLy1CKvFbUsAAAABXRSTlMAkNAgjxohaDYAAABQSURBVCjPY2BgMFENhYIgZwYgYAxFAg5AAbA8XA1UAQIIMJiiCgQziKIKBMKMQBgSigZGBQgIYAQhRiBjRANGRGFGJaoSAVD0GyKSgzADAwBDzJ/mLY2auwAAAABJRU5ErkJggg==)',
  },
  legendPgIcon: {
    backgroundSize: 'auto',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage:
      'url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNSAzIj48cGF0aCBkPSJNMTMuNSwwYTEuNTEsMS41MSwwLDAsMC0xLjQxLDFIMi45MWExLjUsMS41LDAsMSwwLDAsMWg5LjE4QTEuNSwxLjUsMCwxLDAsMTMuNSwwWiIgc3R5bGU9ImZpbGw6Izc0N2FlOCIvPjwvc3ZnPgo=)',
  },
  legendLabel: {
    width: '5em',
    margin: 0,
    marginRight: '1em',
    fontSize: 14,
    fontWeight: 'normal',
    textAlign: 'left',
  },
  legendValue: {
    margin: 0,
    fontSize: 14,
    textAlign: 'right',
  },
  info: {
    top: '-11px',
    right: '-5px',
    zIndex: '2',
    position: 'absolute',
  },
});

type SplitEnergies = {
  dataTime?: string,
  ...SplitEnergiesData,
}[];

type StorageBattery = {
  dataTime: string,
  chargedEnergies: number[],
  dischargedEnergies: number[],
}[];

type BarData = null | {
  regular: ChartData,
  mirror: ChartData,
  line?: ChartData,
};
type UpdateActiveBarData = (barData: BarData) => void;

type StateProps = {|
  idsToShow: (string | null)[],
|};

type OwnProps = {|
  splitEnergies: SplitEnergies,
  storageBattery: StorageBattery,
  unit: string,
  period: string,
  intl: intlShape,
|};
type Props = {
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
};

/**
 * 24時間のうち、最初と最後の稼働を見つけて、その index を返す
 * 充電・放電の両データがあるため、最初にそれぞれの配列を結合し、稼働の有無を boolean で表す
 * 週の場合、曜日の数で返す
 */
const getHours = (
  chargedEnergies: number[],
  dischargedEnergies: number[],
  unit: string
): { startHour: number, endHour: number } => {
  switch (unit) {
    case 'day': {
      const runningEnergies = Array(chargedEnergies.length)
        .fill(0)
        .map((_, index) => Boolean(chargedEnergies[index] || dischargedEnergies[index]));
      const foundHour = runningEnergies.reduce(
        (hours, isRunning, index) => ({
          startHour: isRunning && hours.startHour === null ? index : hours.startHour,
          endHour: isRunning ? index : hours.endHour,
        }),
        { startHour: null, endHour: null }
      );

      return {
        startHour: foundHour.startHour || 0,
        endHour: foundHour.endHour || 23,
      };
    }
    case 'week':
      return { startHour: 0, endHour: 6 };
    default:
      return { startHour: 0, endHour: 24 };
  }
};

const Legend = ({
  activeBarData,
  pgEnergy,
  isHybrid,
  intl,
}: {
  activeBarData: BarData,
  pgEnergy: ChartData[],
  isHybrid: boolean,
  intl: intlShape,
}): Node => (
  <div style={style.legendContainer}>
    <div style={style.legendItem}>
      <div style={{ ...style.legendIcon, ...style.legendChargeIcon }} />
      <p style={style.legendLabel}>{intl.formatMessage({ id: 'chartStorageBattery.charge' })}</p>
      {activeBarData && (
        <p style={style.legendValue}>
          {formatEnergy({
            intl,
            energy: activeBarData.regular.y,
            unit: intl.formatMessage({ id: 'chartStorageBattery.axisUnit' }),
          })}
        </p>
      )}
    </div>
    <div style={{ ...style.legendItem, ...style.adjacentLegendItem }}>
      <div style={{ ...style.legendIcon, ...style.legendDischargeIcon }} />
      <p style={style.legendLabel}>
        {isHybrid
          ? intl.formatMessage({ id: 'chartStorageBattery.hybridDischarge' })
          : intl.formatMessage({ id: 'chartStorageBattery.discharge' })}
      </p>
      {activeBarData && (
        <p style={style.legendValue}>
          {formatEnergy({
            intl,
            energy: activeBarData.mirror.y,
            unit: intl.formatMessage({ id: 'chartStorageBattery.axisUnit' }),
          })}
        </p>
      )}
    </div>
    {pgEnergy.length > 0 && (
      <div style={{ ...style.legendItem, ...style.adjacentLegendItem }}>
        <div style={{ ...style.legendIcon, ...style.legendPgIcon }} />
        <p style={style.legendLabel}>{intl.formatMessage({ id: 'chartStorageBattery.pg' })}</p>
        {activeBarData && activeBarData.line && (
          <p style={style.legendValue}>
            {formatEnergy({
              intl,
              energy: activeBarData.line.y,
              unit: intl.formatMessage({ id: 'chartStorageBattery.axisUnit' }),
            })}
          </p>
        )}
      </div>
    )}
  </div>
);

/**
 * 24時間のうち、最初と最後の電力発生を見つけて、その間のデータを返す
 * baseEnergy で指定されたプロパティから検出し、他のデータは baseEnergy から検出された範囲に習う
 */
const getValidEnergyDay = (splitEnergies: ?((number | null)[])): ChartData[] => {
  if (!splitEnergies) {
    return [];
  }

  const initialHour: { startHour: number | null, endHour: number | null } = {
    startHour: null,
    endHour: null,
  };
  const { startHour, endHour } = splitEnergies.reduce(
    (foundHour, value, index) => ({
      startHour: value && foundHour.startHour === null ? index : foundHour.startHour,
      endHour: value ? index : foundHour.endHour,
    }),
    initialHour
  );

  if (startHour === null || endHour === null) {
    return [];
  }

  const validSplitEnergies = splitEnergies
    .slice(startHour, endHour + 1)
    .reduce((pre, now) => [...pre, now || 0], []);
  return validSplitEnergies.map((energy, index) => ({ x: index + startHour, y: energy }));
};

/**
 * 週ごとのデータに整形し返す
 */
const getValidEnergy = (
  splitEnergies: SplitEnergies,
  unit: string,
  period: string,
  target: string
): ChartData[] => {
  const startDateList = startDates(unit, period);
  if (!splitEnergies || splitEnergies.length === 0 || startDateList.length === 0) {
    return [];
  }

  const initialHour: { startHour: number | null, endHour: number | null } = {
    startHour: null,
    endHour: null,
  };

  const splitEnergyData = startDateList.reduce((prev, now) => {
    const splitEnergy = splitEnergies.find(data => data.dataTime === now);
    const splitEnergyTotal = splitEnergy ? getTotalEnergy(splitEnergy[target]) : 0;
    return [...prev, splitEnergyTotal];
  }, []);

  const { startHour, endHour } = splitEnergyData.reduce(
    (foundHour, value, index) => ({
      startHour: value && foundHour.startHour === null ? index : foundHour.startHour,
      endHour: value ? index : foundHour.endHour,
    }),
    initialHour
  );

  if (startHour === null || endHour === null) {
    return [];
  }

  const splitEnergyList = splitEnergyData
    .slice(startHour, endHour + 1)
    .reduce((pre, now) => [...pre, now || 0], []);
  return splitEnergyList.map((energy, index) => ({ x: index + startHour, y: energy }));
};

/**
 * 蓄電池の充放電を週ごとのデータに整形し返す
 */
const getStorageBattery = (
  storageBattery: StorageBattery,
  unit: string,
  period: string,
  target: string
): number[] => {
  const startDateList = startDates(unit, period);
  if (!storageBattery || storageBattery.length === 0 || startDateList.length === 0) {
    return Array(24).fill(0);
  }

  const storageBatteryData = startDateList.reduce((prev, now) => {
    const batteryEnergy = storageBattery.find(data => data.dataTime === now);
    const BatteryTotal = batteryEnergy ? getTotalEnergy(batteryEnergy[target]) : 0;
    return [...prev, BatteryTotal];
  }, []);

  return storageBatteryData;
};

const InfoButton = (props: { contentType: string, dispatch: Dispatch }): Node => (
  <div style={style.info}>
    <QuestionIcon
      onClick={e => {
        props.dispatch({
          type: OPEN_POP_UP,
          contentType: props.contentType,
          target: e.target,
        });
      }}
    />
  </div>
);

/**
 * X軸ラベル
 */
const horizonAxisUnit = (unit, intl) => {
  switch (unit) {
    case 'day':
      return intl.formatMessage({ id: 'chartStorageBattery.horizonAxisUnit' });
    case 'week':
      return intl.formatMessage({ id: 'chartStorageBattery.dayOfTheWeek' });
    default:
      return '';
  }
};

const title = (unit, isHybrid, intl) => {
  if (isHybrid) return intl.formatMessage({ id: 'chartStorageBattery.hybridTitle' });
  switch (unit) {
    case 'day':
      return intl.formatMessage({ id: 'chartStorageBattery.dailyTitle' });
    case 'week':
      return intl.formatMessage({ id: 'chartStorageBattery.weeklyTitle' });
    default:
      return '';
  }
};

const PureChartStorageBattery = (props: Props): Node => {
  const { splitEnergies, storageBattery, unit, period, idsToShow, intl, dispatch } = props;

  const [activeBarData, updateActiveBarData] = useState(null);
  (activeBarData: BarData);
  (updateActiveBarData: UpdateActiveBarData);

  // chargedEnergies, dischargedEnergiesを取得
  const storageBatteryDay = unit === 'day' && storageBattery.find(data => data.dataTime === period);
  const chargedEnergies =
    unit === 'day' && storageBatteryDay
      ? storageBatteryDay.chargedEnergies
      : getStorageBattery(storageBattery, unit, period, 'chargedEnergies');
  const dischargedEnergies =
    unit === 'day' && storageBatteryDay
      ? storageBatteryDay.dischargedEnergies
      : getStorageBattery(storageBattery, unit, period, 'dischargedEnergies');
  const { startHour, endHour } = getHours(chargedEnergies, dischargedEnergies, unit);

  // ハイブリッド蓄電池か判別
  const isHybrid = !!idsToShow.find(id => id && /^9972/.test(id));

  // 発電のデータを取得
  const pgEnergyListDay = unit === 'day' && splitEnergies.find(data => data.dataTime === period);
  const validEnergyDataForGeneration =
    (isHybrid && []) ||
    (unit === 'day' && pgEnergyListDay && getValidEnergyDay(pgEnergyListDay.splitPgEnergy)) ||
    (unit !== 'day' && getValidEnergy(splitEnergies, unit, period, 'splitPgEnergy')) ||
    [];
  const pgEnergyData = validEnergyDataForGeneration.reduce(
    (prev, current) => [...prev, current.y],
    []
  );

  // chargedEnergies, dischargedEnergies, pgEnergyData 内の最大値
  const maximumValue =
    Math.ceil(Math.max(...[...chargedEnergies, ...dischargedEnergies, ...pgEnergyData]) * 10) /
      10 || 0.2;
  // 0.2 で割り切れる値に変換
  const divisibleMaximumValue =
    (maximumValue * 10) % 2 === 0 ? maximumValue : (maximumValue * 10 + 1) / 10;

  return (
    <div style={style.container}>
      <div style={style.headline}>
        <span style={style.title}>{title(unit, isHybrid, intl)}</span>
        <span style={style.criterion}>{intl.formatMessage({ id: 'others.criterion' })}</span>
      </div>
      {unit === 'day' && !isHybrid && (
        <InfoButton contentType={HELP_STACKED_CHART} dispatch={dispatch} />
      )}
      {unit === 'day' && isHybrid && (
        <InfoButton contentType={HELP_CHART_HYBRID} dispatch={dispatch} />
      )}
      <StackedMirrorBarChart
        startHour={startHour}
        endHour={endHour}
        initialHour={12}
        axisUnit={intl.formatMessage({ id: 'chartStorageBattery.axisUnit' })}
        horizonAxisUnit={horizonAxisUnit(unit, intl)}
        unit={unit}
        stackedData={{
          data: chargedEnergies.map((energy, index) => ({ x: index, y: energy })),
          colors: {
            default: '#b8dd1f',
            disabled: '#d4e8b8',
          },
        }}
        stackedMirrorData={{
          data: dischargedEnergies.map((energy, index) => ({ x: index, y: energy })),
          colors: {
            default: '#f2d422',
            disabled: '#efe7ab',
          },
        }}
        pgEnergy={{
          data: validEnergyDataForGeneration,
          colors: {
            default: '#7179dd',
            disabled: '#7179dd',
          },
        }}
        maximumValue={divisibleMaximumValue}
        handleSelect={(barData: BarData) => {
          updateActiveBarData(barData);
        }}
      />
      <Legend
        activeBarData={activeBarData}
        pgEnergy={validEnergyDataForGeneration}
        isHybrid={isHybrid}
        intl={intl}
      />
    </div>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  idsToShow: state.applianceState.idsToShow,
});

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  injectIntl(PureChartStorageBattery)
);

export const PureChartStorageBatteryForTest = PureChartStorageBattery;
export const LegendForTest = Legend;
