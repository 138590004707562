// @flow

import type { Action } from 'redux';
import {
  REQUEST_API_LOGIN_INFO,
  SUCCESS_API_LOGIN_INFO,
  FAILURE_API_LOGIN_INFO,
  REQUEST_API_LOGIN,
  SUCCESS_API_LOGIN,
  FAILURE_API_LOGIN,
  REQUEST_API_LOGOUT,
  SUCCESS_API_LOGOUT,
  FAILURE_API_LOGOUT,
  FORCIBLY_TRANSITION_TO_LOGIN,
  TRY_LOGIN,
  CHECK_LOGIN,
  REQUEST_RESET_PASSWORD,
  SUCCESS_RESET_PASSWORD,
  FAILURE_RESET_PASSWORD,
  RESET_RESET_PASSWORD,
  FAILURE_API_MULTIFETCH,
  FAILURE_API_USER_INFO,
  FAILURE_API_USER_STATUS,
  FAILURE_API_TIME_SERIES,
  FAILURE_API_USE_STATS,
  FAILURE_API_ENERGY_HOURLY,
  FAILURE_API_SETTINGS_UPDATE,
} from 'Client/actions/types';
import type { LoginInfoState as State } from 'Client/types/state';

const initialState = {
  initialized: false,
  loginState: 'NONE',
  logoutState: 'NONE',
  resetPasswordState: '',
};

export default (state: State = initialState, action: Action = {}): State => {
  switch (action.type) {
    case CHECK_LOGIN:
    case REQUEST_API_LOGIN_INFO: {
      return {
        ...state,
        initialized: true,
        loginState: 'INFO_REQUESTING',
        logoutState: 'NONE',
      };
    }
    case TRY_LOGIN:
    case REQUEST_API_LOGIN: {
      return {
        ...state,
        initialized: true,
        loginState: 'REQUESTING',
        logoutState: 'NONE',
      };
    }
    case SUCCESS_API_LOGIN_INFO: {
      if (action.payload.result) {
        return {
          ...state,
          loginState: 'SUCCESS',
        };
      }
      if (!action.payload.result) {
        return {
          ...state,
          loginState: 'NONE',
        };
      }
      return {
        ...state,
      };
    }
    case SUCCESS_API_LOGIN: {
      return {
        ...state,
        initialized: true,
        loginState: 'SUCCESS',
      };
    }
    case FAILURE_API_LOGIN_INFO:
    case FAILURE_API_LOGIN: {
      if (action.payload.status === 502 || action.payload.status === 500) {
        return {
          ...state,
          initialized: true,
          loginState: 'API_ERROR',
        };
      }
      if (
        action.payload.status === 403 &&
        action.payload.response &&
        action.payload.response.error &&
        action.payload.response.error === 'plan 1010 is expired'
      ) {
        return {
          ...state,
          initialized: true,
          loginState: 'CONTRACT_EXPIRED',
        };
      }
      return {
        ...state,
        initialized: true,
        loginState: 'FAILED',
      };
    }
    case FAILURE_API_MULTIFETCH:
    case FAILURE_API_USER_INFO:
    case FAILURE_API_USER_STATUS:
    case FAILURE_API_TIME_SERIES:
    case FAILURE_API_USE_STATS:
    case FAILURE_API_ENERGY_HOURLY:
    case FAILURE_API_SETTINGS_UPDATE: {
      if (action.payload.status === 401) {
        return {
          ...state,
          initialized: true,
          loginState: 'FAILED',
        };
      }
      return state;
    }
    case REQUEST_API_LOGOUT: {
      return {
        ...state,
        initialized: true,
        logoutState: 'REQUESTING',
      };
    }
    case SUCCESS_API_LOGOUT: {
      const loginState = state.loginState === 'API_ERROR' ? state.loginState : 'NONE';
      return {
        ...state,
        initialized: true,
        loginState,
        logoutState: 'SUCCESS',
      };
    }
    case FAILURE_API_LOGOUT: {
      return {
        ...state,
        initialized: true,
        logoutState: 'FAILED',
      };
    }
    case FORCIBLY_TRANSITION_TO_LOGIN: {
      return initialState;
    }
    case REQUEST_RESET_PASSWORD: {
      return {
        ...state,
        resetPasswordState: action.error ? 'ERROR' : 'REQUESTING',
      };
    }
    case SUCCESS_RESET_PASSWORD: {
      return {
        ...state,
        resetPasswordState: 'SUCCESS',
      };
    }
    case FAILURE_RESET_PASSWORD: {
      return {
        ...state,
        resetPasswordState: 'ERROR',
      };
    }
    case RESET_RESET_PASSWORD: {
      return {
        ...state,
        resetPasswordState: '',
      };
    }
    default: {
      return state;
    }
  }
};
