export default {
  // application
  'application.title': 'うちワケ',
  'application.currency': '{currency} 円',
  'application.dataLoading': 'データの取得中・・・',

  // loginPage
  'loginPage.mailaddress': 'メールアドレス',
  'loginPage.pass': 'パスワード',
  'loginPage.forRegistant': 'アカウントをお持ちの方',
  'loginPage.forgetGuide': 'パスワードをリセットする',
  'loginPage.login': 'ログイン',
  'loginPage.loginRequesting': 'ログイン中',
  'loginPage.error1': 'メールアドレス、または、パスワードが正しくありません。',
  'loginPage.error2': 'ご確認の上、再度入力ください。',
  'loginPage.serverError1': 'サービスに一時的にアクセスできません。',
  'loginPage.serverError2': 'しばらく時間をおいてから、アクセスしてください。',
  'loginPage.remenber': 'この情報を端末に保存する',

  // topPage
  'topBody.waitingT1': 'アプリの利用開始まで',
  'topBody.waitingT2': 'もうしばらくお待ち下さい',
  'topBody.waitingDF1': 'センサー取付作業報告が',
  'topBody.waitingDF2': '取付業者から提出されるのを',
  'topBody.waitingDF3': '待っています。',
  'topBody.waitingDS1': '通常、作業後、数日(営業日)にて',
  'topBody.waitingDS2': 'アプリの利用が開始されます。',
  'topBody.waitingDT1': '利用開始まで',
  'topBody.waitingDT2': 'もうしばらくお待ち下さい。',
  'topBody.waitingA1': '※この画面が1週間以上継続して表示される場合は',
  'topBody.waitingA2': 'こちら',
  'topBody.waitingA3': 'を参照ください',
  'topBody.disconnectedT1': 'センサーをネットに',
  'topBody.disconnectedT2': '接続して下さい',
  'topBody.disconnectedD1': '電力センサーを無線LANに接続すると',
  'topBody.disconnectedD2': 'アプリが利用できるようになります',
  'topBody.disconnectedButton': '接続方法はこちら',
  'topDailyBalance.sell': '売電しました',
  'topDailyBalance.buy': '買電しました',
  'topDailyBalance.same': '同額でした',
  'topDailyBalance.loading': '読み込み中',
  'topDailyBalance.title': '今日の収支',
  'topDailyCharge.sell': '売電',
  'topDailyCharge.buy': '買電',
  'topMonthlyTotalAmount.title': '{month}月の電気代',
  'topApplianceRankingItem.place': '位,位,位',
  'topApplianceRankingItem.airConditioner': 'エアコン',
  'topApplianceRankingItem.clothesWasher': '洗濯機',
  'topApplianceRankingItem.microwave': '電子レンジ',
  'topApplianceRankingItem.dishwasher': '食洗機',
  'topApplianceRankingItem.oven': '電気オーブン',
  'topApplianceRankingItem.refrigerator': '冷蔵庫',
  'topApplianceRankingItem.riceCooker': '炊飯器',
  'topApplianceRankingItem.tv': 'テレビ等',
  'topApplianceRankingItem.vacuumCleaner': '掃除機',
  'topApplianceRankingItem.ih': 'IH調理器',
  'topApplianceRankingItem.ecoCute': 'エコキュート',
  'topApplianceRankingItem.highFever': '高熱家電',
  'topApplianceRankingItem.heater': '暖房器具',
  'topApplianceRankingItem.light': '照明',
  'topApplianceRankingItem.background': '待機電力',
  'topApplianceRankingItem.nd1': 'データが',
  'topApplianceRankingItem.nd2': '記録されていません',
  'topApplianceRankingItem.ndSub1': 'センサーがネットに',
  'topApplianceRankingItem.ndSub2': '接続できていなかったようです',
  'topTimeItem.hour': 'H時',
  'topHourlyAnalysisArea.today': '今日',
  'topHourlyAnalysisArea.yesterday': '昨日',
  'topHourlyAnalysisArea.hour': ' {oclock}時台',
  'topHourlyChargePg.sell': '売電',
  'topHourlyChargePg.buy': '買電',
  'topHourlyChargePg.charge': '電気代',
  'topHourlyCharge.charge': '電気代',
  'TopHourlyApplianceStateItem.hourLabel': '時間',
  'TopHourlyApplianceStateItem.minuteLabel': '分',
  'topHourlyApplianceState.lm1': '家電使用を発見しました',
  'topHourlyApplianceState.lm2': 'ご自宅の電気使用を学習しています',
  'topHourlyApplianceState.lsm1': '発見した家電の種類を推定するため',
  'topHourlyApplianceState.lsm2': 'その特徴を分析しています',
  'topHourlyApplianceState.title': '家電の使用状況',
  'topHourlyApplianceState.current': '{currentTime} 現在',
  'topHourlyApplianceState.alt': '(初期学習中)',
  'topHourlyApplianceStateList.da1': '家電の動きの学習に',
  'topHourlyApplianceStateList.da2': '時間がかかっています',
  'topHourlyApplianceStateList.daSub1': '同時に複数の家電が使われていると',
  'topHourlyApplianceStateList.daSub2': '学習に時間がかかる場合があります。',
  'topHourlyApplianceStateList.daSub3': '学習完了までもうしばらくお待ち下さい。',
  'topHourlyApplianceStateList.daBtn': '対象家電とは？',
  'topHourlyApplianceStateList.nd': 'データが記録されていません',
  'topHourlyApplianceStateList.ndSub': 'センサーがネットに接続できていなかったようです',
  'topHourlyApplianceStateList.ms': 'データを取得中です',
  'topHourlyApplianceStateList.msSub': 'センサーから測定データの送信・処理完了を待っています',
  'topHourlyApplianceStateList.na1': '全ての家電について',
  'topHourlyApplianceStateList.na2': '表示設定がオフになっています',

  // chartPage
  'chartBody.toTop': 'ページのトップへ',
  'chartTimeUnit.day': '日',
  'chartTimeUnit.week': '週',
  'chartTimeUnit.month': '月',
  'chartTimeUnit.year': '年',
  'chartPeriod.year': '{year}年',
  'chartPeriod.month': '{year}年{month}月',
  'chartPeriod.weekOf': '{month}月{day}日の週',
  'chartPeriod.day': '{month}月{day}日',
  'chartPowerGeneration.balance': '収支',
  'chartPowerGeneration.pg': '発電',
  'chartPowerGeneration.buy': '買電',
  'chartPowerGeneration.sellCharge': '売電',
  'chartPowerGeneration.self': '自家消費',
  'chartPowerGeneration.solarPower': '太陽光発電',
  'chartPowerGeneration.eneFarm': 'エネファーム',
  'chartPowerGeneration.balanceIs': '収支は',
  'chartPowerGeneration.currency': '{plusOrMinus}{currency} 円',
  'chartPowerGeneration.axisUnit': 'kWh',
  'chartEnergyDetails.balance': '収支',
  'chartEnergyDetails.pg': '発電',
  'chartEnergyDetails.buy': '買電',
  'chartEnergyDetails.details': '電力のうちワケ',
  'chartEnergyDetails.hybridDetails': '発電・蓄電',
  'chartEnergyDetails.in': 'IN',
  'chartEnergyDetails.out': 'OUT',
  'chartEnergyDetails.inDetail': 'IN 供給された元',
  'chartEnergyDetails.outDetail': 'OUT 供給された先',
  'chartEnergyDetails.whp': '使用',
  'chartEnergyDetails.consumed': '消費',
  'chartEnergyDetails.sellCharge': '売電',
  'chartEnergyDetails.self': '消費',
  'chartEnergyDetails.solarPower': '太陽光発電',
  'chartEnergyDetails.hybridPg': '発電/放電',
  'chartEnergyDetails.charge': '充電',
  'chartEnergyDetails.discharge': '放電',
  'chartEnergyDetails.currency': '{plusOrMinus}{currency} 円',
  'chartEnergyDetails.axisUnit': 'kWh',
  'chartChargeAtDayOnCircle.title': '1日の電気代',
  'chartChargeAtDayOnBar.title': '時間ごとの電気代',
  'chartChargeAtDayOnBar.axisUnit': '円',
  'chartChargeAtDayOnBar.horizonAxisUnit': '時',
  'chartChargeAtDayOnBar.charge': '使用電力',
  'chartChargeAtDayOnBar.background': '常に消費',
  'chartChargeAtDayOnBar.total': '合計',
  'chartGeneratedAtDay.pg': '発電',
  'chartGeneratedAtDay.sellCharge': '売電',
  'chartGeneratedAtDay.consumed': '自家消費',
  'chartGeneratedAtDay.consumedBattery': '発電/放電',
  'chartGeneratedAtDay.solarPower': '太陽光発電',
  'chartGeneratedAtDay.eneFarm': 'エネファーム',
  'chartGeneratedAtDay.consumedPower': '電力使用',
  'chartGeneratedAtDay.wholeHousePower': '使用+充電',
  'chartGeneratedAtDay.buyCharge': '買電',
  'chartGeneratedAtDay.axisUnit': 'kWh',
  'chartGeneratedAtDay.horizonAxisUnit': '時',
  'chartGeneratedAtDayOnGeneration.title': '発電と供給先のうちワケ',
  'chartGeneratedAtDayOnGeneration.hybridTitle': '時間ごとの売電量',
  'chartGeneratedAtDayOnConsumed.title': '電力使用と供給元のうちワケ',
  'chartGeneratedAtDayOnConsumed.dailyTitle': '1日の合計',
  'chartCircle.dailyTitle': '1日の電気代',
  'chartCircle.midnight': '深夜',
  'chartCircle.morning': '朝',
  'chartCircle.noon': '昼',
  'chartCircle.night': '夜',
  'chartCircle.background': '常に消費',
  'chartCharge.dailyTitle': '1日の電気代',
  'chartCharge.weeklyTitle': '週の電気代',
  'chartCharge.monthlyTitle': '月の電気代',
  'chartCharge.yearlyTitle': '年の電気代',
  'chartStorageBattery.dailyTitle': '蓄電池 時間ごとの充放電',
  'chartStorageBattery.weeklyTitle': '蓄電池 日ごとの充放電',
  'chartStorageBattery.hybridTitle': 'ハイブリッド蓄電池の動き',
  'chartStorageBattery.charge': '充電',
  'chartStorageBattery.discharge': '放電',
  'chartStorageBattery.hybridDischarge': '発電/放電',
  'chartStorageBattery.pg': '発電量',
  'chartStorageBattery.axisUnit': 'kWh',
  'chartStorageBattery.horizonAxisUnit': '時',
  'chartStorageBattery.dayOfTheWeek': '日,月,火,水,木,金,土',
  'ChartIndividualMeasurement.title': '機器ごとの消費量',
  'ChartIndividualMeasurement.whp': '家庭全体の機器電力',
  'ChartIndividualMeasurement.airConditioner': 'エアコン{applianceIndex}',
  'ChartIndividualMeasurement.ecoCute': 'エコキュート{applianceIndex}',
  'ChartIndividualMeasurement.ev': 'EV充電器{applianceIndex}',
  'ChartIndividualMeasurement.axisUnit': 'kWh',
  'ChartIndividualMeasurement.horizonAxisUnit': '時',
  'ChartIndividualMeasurement.dayOfTheWeek': '日,月,火,水,木,金,土',
  'chartApplianceRanking.title': '家電の電気代ランキング',
  'chartLearning.title': 'ご自宅の電気使用を学習しています',
  'chartLearning.desc1': '発見した家電の種類を特定するため',
  'chartLearning.desc2': 'その特徴を分析しています',
  'chartImperfectLearning.title1': '家電使用が',
  'chartImperfectLearning.title2': '見つかっていません',
  'chartImperfectLearning.desc1': '対象家電の使用を発見すると、',
  'chartImperfectLearning.desc2': 'ここに表示されます。',
  'chartImperfectLearning.link': '対象家電とは？',
  'chartNoData.title1': 'データが',
  'chartNoData.title2': '記録されていません',
  'chartNoData.desc1': 'センサーがネットに',
  'chartNoData.desc2': '接続できていなかったようです',
  'chartNoData.link': 'ご確認方法',
  'chartApplianceRankingItem.place': '位,位,位,位,位,位,位,位,位,位,位,位,位,位,位',
  'chartApplianceRankingItem.lessThan10': '10 円 未満',
  'chartApplianceRankingItem.airConditioner': 'エアコン',
  'chartApplianceRankingItem.highFever': '高熱家電',
  'chartApplianceRankingItem.heater': '暖房器具',
  'chartApplianceRankingItem.microwave': '電子レンジ',
  'chartApplianceRankingItem.dishwasher': '食洗機',
  'chartApplianceRankingItem.oven': '電気オーブン',
  'chartApplianceRankingItem.riceCooker': '炊飯器',
  'chartApplianceRankingItem.refrigerator': '冷蔵庫',
  'chartApplianceRankingItem.clothesWasher': '洗濯機',
  'chartApplianceRankingItem.ih': 'IH',
  'chartApplianceRankingItem.ecoCute': 'エコキュート',
  'chartApplianceRankingItem.vacuumCleaner': '掃除機',
  'chartApplianceRankingItem.tv': 'テレビ等',
  'chartApplianceRankingItem.background': '待機電力',
  'chartApplianceRankingItem.light': '照明',
  'chartSubUnit.hourlyGeneratedTitle': '時間ごとの発電量',
  'chartSubUnit.dailyTitle': '日ごとの電気代',
  'chartSubUnit.weeklyTitle': '週ごとの電気代',
  'chartSubUnit.monthlyTitle': '月ごとの電気代',
  'chartSubUnit.noData': 'データ未記録',
  'chartSubUnitChart.sellCharge': '売電',
  'chartSubUnitChart.buyCharge': '買電',
  'chartSubUnitChart.sun': '日',
  'chartSubUnitChart.mon': '月',
  'chartSubUnitChart.tue': '火',
  'chartSubUnitChart.wed': '水',
  'chartSubUnitChart.thu': '木',
  'chartSubUnitChart.fri': '金',
  'chartSubUnitChart.sat': '土',
  'chartSubUnitChart.place': '1,2,3,4,5,6',
  'chartSubUnitChart.week': '週目',
  'chartSubUnitChart.month': '1月,2月,3月,4月,5月,6月,7月,8月,9月,10月,11月,12月',
  'chartSubUnitChargeItem.sun': '日',
  'chartSubUnitChargeItem.mon': '月',
  'chartSubUnitChargeItem.tue': '火',
  'chartSubUnitChargeItem.wed': '水',
  'chartSubUnitChargeItem.thu': '木',
  'chartSubUnitChargeItem.fri': '金',
  'chartSubUnitChargeItem.sat': '土',
  'chartSubUnitChargeItem.place': '1,2,3,4,5,6',
  'chartSubUnitChargeItem.week': '週目',
  'chartSubUnitChargeItem.month': '1月,2月,3月,4月,5月,6月,7月,8月,9月,10月,11月,12月',
  'chartSubUnitChargeItem.period': '({periodDate}〜)',
  'chartDayStats.chartTitle': '家電ごとの使用電力 (Wh)',
  'chartDayStats.resetZoom': '24時間分表示',
  'chartDayStats.airConditioner': 'エアコン',
  'chartDayStats.washingMachine': '洗濯機',
  'chartDayStats.microwave': '電子レンジ',
  'chartDayStats.refrigerator': '冷蔵庫',
  'chartDayStats.riceCooker': '炊飯器',
  'chartDayStats.television': 'テレビ等',
  'chartDayStats.vacuumCleaner': '掃除機',
  'chartDayStats.IH': 'IHヒーター',
  'chartDayStats.highHeatapl': '高熱家電',
  'chartDayStats.standbyPower': '待機電力',
  'chartDayStats.overallPpower': '家全体の電力',

  // headerBar
  'headerBar.back': '戻る',
  'headerBar.resetPassword': 'パスワードリセット',
  'headerBar.setting': '設定',
  'headerBar.changeMail': 'メールアドレス変更',
  'headerBar.changePassword': 'パスワード変更',

  // tutorialPage
  'tutorialPage.next': '次へ',
  'tutorialPage.prev': '戻る',
  'tutorialPage.start': 'スタート',
  'tutorialPage.skip': 'スキップ',

  // settingPage
  'settingPage.serviceProviderId': '事業者ID',
  'settingPage.house': 'ハウスID',
  'settingPage.macAddress': 'MACアドレス',
  'settingPage.accountStartDate': 'サービス開始日',
  'settingPage.emailAddress': 'メールアドレス',
  'settingPage.password': 'パスワード',
  'settingPage.aplsDisplay': '表示対象家電',
  'settingPage.pricePlan': '料金プラン種類',
  'settingPage.sellPrice': '電力買取単価',
  'settingPage.version': 'バージョン',
  'settingPage.change': '変更',
  'settingPage.yen': '円/kWh',
  'settingPage.pgSwitch': '発電表示に切替',
  'settingPage.tutorial': 'チュートリアル',
  'settingPage.display': '表示',
  'settingPage.faq': 'よくあるご質問',
  'settingPage.logout': 'ログアウト',
  'settingPage.priceAlert': '1〜100円の範囲で半角入力してください',
  'settingPage.nomalPlan': '24時間同一料金プラン',
  'settingPage.normalPlanPrice': '{planPrice} 円/kWh',
  'settingPage.multistagePlan': '夜間がお得なプラン',
  'settingUserPopup.emailChange': 'メールアドレスの変更',
  'settingUserPopup.passChange': 'パスワードの変更',
  'settingUserPopup.button': '変更はこちら',
  'settingUserPopup.back': '戻る',
  'settingAppliancePopup.title': '表示対象家電の変更',
  'settingAppliancePopup.description1': '「家電の使用状況」に',
  'settingAppliancePopup.description2': '表示する家電を変更できます',
  'settingAppliancePopup.close': '閉じる',

  // change email and password
  'changeMail.toAskAddress': '新しいメールアドレス',
  'changeMail.guideL1': '届いたメールに記載されているURLを開くと変更が完了します。',
  'changeMail.guideL2': 'URLの有効期限は30分程度です。',
  'changeMail.toSendMail': 'メールを送信',
  'changeMail.sentMessageL1': 'メールを送信しました',
  'changeMail.changedMessageL1': 'メールアドレスを',
  'changeMail.changedMessageL2': '変更しました',
  'changeMail.requiredMailAddress': 'メールアドレスを入力してください',
  'changeMail.requiredFormat': 'メールアドレスの形式が正しくありません',
  'changePassword.toAskOldPassword': '現在のパスワード',
  'changePassword.toAskNewPassword': '新しいパスワード',
  'changePassword.toConfirmNewPassword': '新しいパスワード（確認）',
  'changePassword.guideL1': '※8文字以上、英数字・記号が利用できます。',
  'changePassword.guideL2': '※少なくとも1文字の英字と数字を利用してください。',
  'changePassword.guideL3': '※前と同じパスワードは使えません。',
  'changePassword.toChangePassword': '変更する',
  'changePassword.changedMessageL1': 'パスワードを',
  'changePassword.changedMessageL2': '変更しました',
  'changePassword.requiredOldPassword': 'パスワードを入力してください',
  'changePassword.requiredNewPassword': '新しいパスワードを入力してください',
  'changePassword.requiredConfirmationPassword': '新しいパスワード（確認）を入力してください',
  'changePassword.requiredComplex':
    'パスワードは8文字以上、最低1文字の英字と数字を含む英数字・記号で入力してください',
  'changePassword.requiredDifferent': '現在のパスワードと同じものは設定できません',
  'changePassword.requiredMatched': '同じパスワードを入力してください',
  'changeOnCompleted.toBack': '再ログイン',
  'changeOnError.expiredL1': 'URLの有効期限が',
  'changeOnError.expiredL2': '切れています',
  'changeOnError.setting': '設定に失敗しました',
  'changeOnError.anything': 'エラーが発生しました',
  'changeOnError.guideMessage': '恐れ入りますが、もう一度はじめからお試しください。',
  'changeOnError.toBack': 'やり直す',

  // reset password
  'resetPassword.toAskAddress': '登録したメールアドレス',
  'resetPassword.sendMailGuideL1': '届いたメールに記載されているURLから再設定をしてください。',
  'resetPassword.sendMailGuideL2': 'URLの有効期限は30分程度です。',
  'resetPassword.toSendMail': 'メールを送信',
  'resetPassword.toAskNewPassword': '登録するパスワード',
  'resetPassword.toConfirmNewPassword': '登録パスワード（確認）',
  'resetPassword.requiredMailAddress': 'メールアドレスを入力してください',
  'resetPassword.requiredFormat': 'メールアドレスの形式が正しくありません',
  'resetPassword.requiredNewPassword': '登録するパスワードを入力してください',
  'resetPassword.requiredConfirmationPassword': '登録するパスワード（確認）を入力してください',
  'resetPassword.requiredComplex':
    'パスワードは8文字以上、最低1文字の英字と数字を含む英数字・記号で入力してください',
  'resetPassword.requiredMatched': '同じパスワードを入力してください',
  'resetPassword.registerGuideL1': '※8文字以上、英数字・記号が利用できます。',
  'resetPassword.registerGuideL2': '※少なくとも1文字の英字と数字を利用してください。',
  'resetPassword.toRegisterMail': '登録する',

  // helpPopup
  'helpPopup.forDetails': '詳しくはこちら',
  'helpPopup.targetApls': '対象家電は？',
  'helpPopup.hourlyStateT': '時間帯毎の家電使用状況',
  'helpPopup.hourlyStateD':
    'ご自宅の家電のうち、使用を検知した家電を光ってお知らせします。外出中にもご自宅の様子がうかがえます。',
  'helpPopup.topRankingT': '電気代ランキング',
  'helpPopup.topRankingD':
    '今月の電気代と、その中でも電気を多く使っている家電ランキングを表示します。日々の変化を確認してみましょう。',
  'helpPopup.balanceT': '発電収支',
  'helpPopup.balanceD':
    '今日を振り返って、自宅で発電した電気のうち、余って売った電気料金と足りずに買った電気料金の収支を表示します。',
  'helpPopup.chartRankingT': '電気代 家電ランキング',
  'helpPopup.chartRankingD':
    '「機器分離技術」が日々の電気波形を分析・学習し、家電ごとの電気代を推定します。なお、待機電力は「24時間常に電力消費する家電をまとめて」、高熱家電は「高熱を発生する家電をまとめて」表示します。',
  'helpPopup.transitionT': '電気代の変化',
  'helpPopup.transitionD':
    '毎日、毎週、毎月のご家庭の電気使用状況を振り返りながら、実際の電気代変化にどう影響しているか確認してみましょう',
  'helpPopup.buySellT': '売電・買電状況を確認',
  'helpPopup.buySellD':
    '発電した電気の一部は自家消費し、余った電気は売り、発電で足りなかった電気は買うことになります。これを集計し、結局いくら電気を売れたか、買ったかを表示します。',
  'helpPopup.hybridSellT': 'ハイブリッド蓄電池の表示',
  'helpPopup.hybridSellD':
    'ハイブリッド蓄電池では、太陽光により発電した電気と、蓄電池に蓄えておいた電気を、賢く組み合わせて電気を供給します。アプリでは、発電と放電を組み合わせた後の電力量を表示しています。',
  'helpPopup.dayChartT': '家電ごとの使用電力とは',
  'helpPopup.dayChartD1': '1日の使用電力の推移が、',
  'helpPopup.dayChartD2': '家電ごとに表示されます。',
  'helpPopup.dayChartSTP': 'パソコンで操作する場合',
  'helpPopup.dayChartSMP': '＜特定の時間帯を拡大＞',
  'helpPopup.dayChartSMPP': '表示したい時間帯をドラッグ',
  'helpPopup.dayChartSMS': '＜他の時間帯を表示＞',
  'helpPopup.dayChartSMSP1': '「shift」キーを押しながら',
  'helpPopup.dayChartSMSP2': '横にドラッグ',
  'helpPopup.dayCircleT': 'グラフの見方',
  'helpPopup.dayCircleD':
    '「常に消費」とは、1日中電気を消費し続ける「待機電力」と「冷蔵庫」を合わせたものです。それ以外の電気代を4つの時間帯に分けて表示します。深夜は0〜6時、朝は6〜12時、昼は12〜18時、夜は18〜24時を示します。',
  'helpPopup.stackedChartT': 'グラフの操作',
  'helpPopup.stackedChartD': 'グラフをスライドして24時間分のデータを確認することができます',

  // alertPopup
  'alertPopup.noAction': 'このまま利用する',
  'alertPopup.noDataT1': 'センサーから測定データが',
  'alertPopup.noDataT2': '送信されていません',
  'alertPopup.noDataD':
    'ここ24時間、センサーがネットに接続できていないため、電力利用の分析が行えていません。',
  'alertPopup.noDataLink': '改善方法を確認する',
  'alertPopup.disableAquireT': 'データが取得できません',
  'alertPopup.disableAquireD1': 'サービスに一時的にアクセスできません',
  'alertPopup.disableAquireD2': 'しばらく時間をおいてから、',
  'alertPopup.disableAquireD3': 'アクセスしてください',
  'alertPopup.disableAquireLink': '障害メンテナンス情報',
  'alertPopup.StartT1': '家電の特徴を',
  'alertPopup.StartT2': '発見しました！',
  'alertPopup.StartD1': 'ご自宅で使った電気の中から',
  'alertPopup.StartD2': '一部の家電の特徴を発見しました',
  'alertPopup.StartD3': 'これから、各家電を使った時間帯と',
  'alertPopup.StartD4': 'その電力量を推定し表示します',
  'alertPopup.StartButton': '使用状況を見てみる',
  'alertPopup.loginFalied': 'ログインできませんでした',
  'alertPopup.inquiry': 'お問い合わせ先',
  'alertPopup.checkContract': 'お申し込み状況のご確認をお願いいたします',
  'alertPopup.userStatusErrorTitle': 'データが取得できません',
  'alertPopup.userStatusErrorMessage1': 'サービスに一時的にアクセスできません',
  'alertPopup.userStatusErrorMessage2': 'しばらく時間をおいてから、',
  'alertPopup.userStatusErrorMessage3': 'アクセスしてください',
  'alertPopup.sessionExpiredMessage1': 'セッションが切れました。',
  'alertPopup.sessionExpiredMessage2':
    '引き続きご利用の場合は、この画面を閉じてからもう一度アクセスしてください。',

  // ohters
  'others.timeOut': 'タイムアウトになりましたので、再ログインをお願いします',
  'others.expired': '契約が終了しています',
  'others.unavailable': '調整中につき、しばらくお待ちください',
  'others.networkError': 'ネットワーク接続に失敗しました',
  'others.notice': 'お知らせ',
  'others.close': '閉じる',
  'others.ok': 'OK',
  'others.criterion': '[目安]',

  // no data notices
  'topDailyNoData.Title': 'データが記録されていません',
  'topDailyNoData.Desc': 'センサーがネットに接続できていなかった様です',
  'topDailyNoData.Link': 'ご確認方法',
  'topDailyLoading.Title': 'データを取得中です',
  'topDailyLoading.Desc': 'センサーから測定データ送信・処理完了を待っています',
  'topDailyLoading.Link': 'ご確認方法',
  'topHourlyNoData.Title': 'データが記録されていません',
  'topHourlyNoData.Desc': 'センサーがネットに接続できていなかった様です',
  'topHourlyLoading.Title': 'データを取得中です',
  'topHourlyLoading.Desc': 'センサーから測定データ送信・処理完了を待っています',

  // settingPricePlanPopup
  'settingPricePlanPopup.title': '電気料金プランの設定',
  'settingPricePlanPopup.popup1T1': 'さっきの1時間で',
  'settingPricePlanPopup.popup1D1': 'うちワケでは、1時間単位で',
  'settingPricePlanPopup.popup1D2': '概算の電気代が分かるため、',
  'settingPricePlanPopup.popup1D3': 'いつ電気を使いすぎたか気付けます。',
  'settingPricePlanPopup.selectD1':
    '電気料金の目安をより正確に表示するために、ご契約中の料金プランを設定して下さい。',
  'settingPricePlanPopup.selectD2': '',
  'settingPricePlanPopup.selectD3': '',
  'settingPricePlanPopup.selectD4': '※目安なので実際の請求金額とは誤差があります。',
  'settingPricePlanPopup.popup1B': 'プランの種類を選ぶ',
  'settingPricePlanPopup.nomalPricePlanName': '24時間同一料金プラン',
  'settingPricePlanPopup.nomalPricePlanDescribe': 'どの時間帯でも同じ電気代がかかります',
  'settingPricePlanPopup.guideNormal': '契約に合わせて単価を変更できます',
  'settingPricePlanPopup.guideMultistage': '時間帯ごとの料金単価を入力ください',
  'settingPricePlanPopup.saveSetting': '設定する',
  'settingPricePlanPopup.multistagePricePlanName': '夜間がお得なプラン',
  'settingPricePlanPopup.multistagePricePlanDescribe': '昼よりも夜間の電気代が安いプランです',
  'settingPricePlanPopup.yen': '円/kWh',
  'settingPricePlanPopup.timeStart': '時 〜 ',
  'settingPricePlanPopup.timeEnd': '時',
  'settingPricePlanPopup.timeAlert': '時間帯に重複や漏れがないように設定してください',
  'settingPricePlanPopup.back': '戻る',
  'settingPricePlanPopup.close': '閉じる',
  'settingPricePlanPopup.caption': '時間帯{index}',
  'settingPricePlanPopup.addPlan': '時間帯を追加する',
};
