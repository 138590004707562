// @flow

import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import TopTimeItem from 'Client/components/TopTimeItem';
import UpDownButton from 'Client/components/TopTimeBarUpDownButton';
import { SELECT_NEXT_HOUR, SELECT_PREV_HOUR } from 'Client/actions/types';
import { loadTopPageLatestTwoHours, needsUpdate, isUsedAtHour } from 'Client/lib/topPageUtils';
import type { State, TimeRangeState } from 'Client/types/state';

const renderUpButton = (currentDate, currentTime, isPg, dispatch) => (
  <UpDownButton
    style={{ marginBottom: '8px' }}
    clickHandler={() =>
      needsUpdate(currentDate, currentTime)
        ? loadTopPageLatestTwoHours(dispatch, isPg)
        : dispatch({ type: SELECT_NEXT_HOUR })
    }
  >
    ▲
  </UpDownButton>
);

const renderTimeBar = (timeRange, aplFilter, hourlyDurations, isPg, dispatch) => (
  <div>
    {timeRange.range.map(hour => (
      <TopTimeItem
        key={hour}
        selectedHour={timeRange.selectedHour}
        currentDate={timeRange.currentDate}
        currentTime={timeRange.currentTime}
        hour={hour}
        used={isUsedAtHour(hour, aplFilter, hourlyDurations)}
        isPg={isPg}
        dispatch={dispatch}
      />
    ))}
  </div>
);

const renderDownButton = (currentDate, currentTime, isPg, dispatch) => (
  <UpDownButton
    style={{ marginTop: '8px' }}
    clickHandler={() =>
      needsUpdate(currentDate, currentTime)
        ? loadTopPageLatestTwoHours(dispatch, isPg)
        : dispatch({ type: SELECT_PREV_HOUR })
    }
  >
    ▼
  </UpDownButton>
);

type StateProps = {|
  timeRange: TimeRangeState,
  aplFilter: {
    id: string,
    display: boolean,
  }[],
  hourlyDurations: {
    hour: number,
    appliances: {
      id: string,
      duration: number,
    }[],
  }[],
  isPg: boolean,
|};
type OwnProps = {|
  dispatch: Dispatch,
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
|};

export const PureTopTimeBar = ({
  timeRange,
  aplFilter,
  hourlyDurations,
  isPg,
  dispatch,
}: Props): Node => (
  <div>
    {renderUpButton(timeRange.currentDate, timeRange.currentTime, isPg, dispatch)}
    {renderTimeBar(timeRange, aplFilter, hourlyDurations, isPg, dispatch)}
    {renderDownButton(timeRange.currentDate, timeRange.currentTime, isPg, dispatch)}
  </div>
);

const mapStateToProps = (state: State): StateProps => ({
  timeRange: state.timeRange,
  aplFilter: state.userSetting.aplFilter,
  hourlyDurations: state.applianceState.hourlyDurations,
  isPg: state.userSetting.isPg,
});

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  PureTopTimeBar
);
