// @flow
/* eslint-env browser */
/* global localStorage */

import React, { Component } from 'react';
import type { Dispatch } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import type { ContextRouter, Location } from 'react-router-dom';
import LoginPage from 'Client/components/LoginPage';
import AlertPopup from 'Client/components/AlertPopup';
import { apiCheckLogin, apiGetUserStatus, apiGetUserInfo } from 'Client/actions/apis';
import { FORCIBLY_TRANSITION_TO_LOGIN, CHECK_LOGIN } from 'Client/actions/types';
import { isEmbeded } from 'Client/lib/serviceCheck';
import { logout, getIdToken } from 'Client/lib/mbaasAuthentication';
import type { State, LoginInfoState, UserInfoState } from 'Client/types/state';

const cannotUseAppUserStatus = ['contract_expired', 'imt4_app_unavailable'];

const canUseApp = (loginState, userStatus) =>
  loginState === 'SUCCESS' &&
  userStatus !== 'no_data' &&
  !cannotUseAppUserStatus.includes(userStatus);

const userWillTransfer = (props, router) => {
  const { loginState, initialized } = props.loginInfo;
  const { status: userStatus, infoRequesting, statusRequesting } = props.userInfo;
  if (!initialized) {
    props.dispatch({ type: CHECK_LOGIN });
    getIdToken(token => {
      props.dispatch(apiCheckLogin(token));
    });
    return;
  }
  if (isEmbeded) {
    router.history.replace({
      pathname: '/error/expired',
      state: { nextPathname: props.location.pathname },
    });
    return;
  }
  if (
    loginState === 'SUCCESS' &&
    userStatus === 'no_data' &&
    !statusRequesting &&
    !infoRequesting
  ) {
    props.dispatch(apiCheckLogin());
    props.dispatch(apiGetUserStatus());
    props.dispatch(apiGetUserInfo());
    return;
  }
  if (canUseApp(loginState, userStatus)) {
    props.dispatch(apiCheckLogin());
    router.history.replace('/top');
    return;
  }
  if (loginState === 'SUCCESS' && cannotUseAppUserStatus.includes(userStatus)) {
    logout(props.dispatch);
    props.dispatch({ type: FORCIBLY_TRANSITION_TO_LOGIN });
  }
};

type StateProps = {|
  loginInfo: LoginInfoState,
  userInfo: UserInfoState,
  tutorialFinished: boolean,
|};
type OwnProps = {|
  location: Location,
  ...ContextRouter,
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

class LoginArea extends Component<Props> {
  componentDidMount() {
    userWillTransfer(this.props, this.context.router);
  }
  componentDidUpdate() {
    userWillTransfer(this.props, this.context.router);
  }
  render() {
    return (
      <div>
        <AlertPopup />
        <LoginPage />
      </div>
    );
  }
}

LoginArea.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state: State): StateProps => ({
  loginInfo: state.loginInfo,
  userInfo: state.userInfo,
  tutorialFinished: state.tutorial.tutorialFinished,
});

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  LoginArea
);
