/* eslint-disable jsx */
// @flow

import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { intlShape, injectIntl } from 'react-intl';
import { GO_TO_NORMAL_POP_UP, GO_TO_MULTISTAGE_POP_UP, CLOSING_POP_UP } from 'Client/actions/types';
import Prefixer from 'Client/lib/Prefixer';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  content: {
    position: 'relative',
    overflow: 'auto',
    padding: '20px 10px 25px 10px',
  },
  description: {
    marginTop: '15px',
    marginLeft: '5px',
    textAlign: 'left',
    fontSize: '0.9em',
    lineHeight: '180%',
    color: '#000000',
  },
  button: {
    padding: '10px 0',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  buttonInner: {
    display: 'flex',
    alignItems: 'center',
    width: 210,
    margin: '0 auto',
  },
  buttonVisual: {
    width: 34,
    height: 34,
  },
  buttonIcon: {
    width: '100%',
    height: 'auto',
    verticalAlign: 'bottom',
  },
  buttonTitle: {
    flex: 1,
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  cationMessage: {
    fontSize: '0.9em',
    color: '#444444',
  },
  buttonArea: {
    textAlign: 'center',
    fontSize: '0.85em',
  },
  title: {
    fontSize: '1.2em',
    fontWeight: '500',
    color: '#FFBD00',
    paddingBottom: '15px',
    borderBottom: 'thin solid #DDDDDD',
  },
  closeButton: {
    width: '34%',
    borderRadius: '5px',
    backgroundColor: '#A6A6A6',
    margin: '5% auto 0',
    padding: '8px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeIcon: {
    textAlign: 'center',
    marginRight: '3px',
  },
  closeText: {
    marginLeft: '3px',
    fontSize: '1em',
    fontWeight: '500',
    color: '#FFFFFF',
  },
});

const renderPricePlanSelect = (dispatch, intl) => {
  const icon_normal = require(`Client/images/24h-white.svg`);
  const icon_multistage = require(`Client/images/night-white.svg`);
  const icon_close = require(`Client/images/icon_cross.png`);

  return (
    <div style={style.content}>
      <div style={style.title}>{intl.formatMessage({ id: 'settingPricePlanPopup.title' })}</div>
      <div style={style.description}>
        {intl.formatMessage({ id: 'settingPricePlanPopup.selectD1' })}
        <br />
        <div style={style.cationMessage}>
          {intl.formatMessage({ id: 'settingPricePlanPopup.selectD4' })}
        </div>
        <div style={style.buttonArea}>
          <div
            style={{ ...style.button, marginTop: '5%', backgroundColor: '#ffbd00' }}
            onClick={() => dispatch({ type: GO_TO_NORMAL_POP_UP, locale: intl.locale })}
          >
            <div style={style.buttonInner}>
              <div style={style.buttonVisual}>
                <img src={icon_normal} alt="icon_normal" style={style.buttonIcon} />
              </div>
              <div style={style.buttonTitle}>
                {intl.formatMessage({ id: 'settingPricePlanPopup.nomalPricePlanName' })}
              </div>
            </div>
          </div>
          {intl.formatMessage({ id: 'settingPricePlanPopup.nomalPricePlanDescribe' })}
          <div
            style={{ ...style.button, marginTop: '5%', backgroundColor: '#58acec' }}
            onClick={() => dispatch({ type: GO_TO_MULTISTAGE_POP_UP, locale: intl.locale })}
          >
            <div style={style.buttonInner}>
              <div style={style.buttonVisual}>
                <img src={icon_multistage} alt="icon_multistage" style={style.buttonIcon} />
              </div>
              <div style={style.buttonTitle}>
                {intl.formatMessage({ id: 'settingPricePlanPopup.multistagePricePlanName' })}
              </div>
            </div>
          </div>
          {intl.formatMessage({ id: 'settingPricePlanPopup.multistagePricePlanDescribe' })}
          <div
            style={style.closeButton}
            className="styleBack"
            onClick={() => dispatch({ type: CLOSING_POP_UP, locale: intl.locale })}
          >
            <img style={style.closeIcon} alt="cross" src={icon_close} />
            <div style={style.closeText}>
              {intl.formatMessage({ id: 'settingPricePlanPopup.close' })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

type Props = {
  dispatch: Dispatch,
  intl: intlShape,
};

export const PureSettingPricePlanSelect = ({ dispatch, intl }: Props): Node => (
  <div>{renderPricePlanSelect(dispatch, intl)}</div>
);

export default injectIntl(PureSettingPricePlanSelect);
