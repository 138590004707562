// @flow

import type { Action } from 'redux';
import { aplDurationFormat } from 'Client/lib/localizedValues';
import type { ApplianceState as State } from 'Client/types/state';

import {
  ENTER_APPLICATION,
  LOAD_TOP_PAGE,
  REQUEST_API_USE_STATS,
  SUCCESS_API_USE_STATS,
  FAILURE_API_USE_STATS,
  REQUEST_API_MULTIFETCH,
  SUCCESS_API_MULTIFETCH,
  FAILURE_API_MULTIFETCH,
  SUCCESS_API_LOGOUT,
} from 'Client/actions/types';

const initialState = {
  disaggregated: true,
  requesting: false,
  useStatsRequesting: false,
  hourlyDurations: [], // hourlyDurationFormat will be in this array.
  idsToShow: ['304'],
  idsToShowForRanking: ['304'],
  locale: 'ja',
  alertInfo: {
    noUpload: false,
    noSolar: false,
  },
};

const getHourlyDurationFormat = () => ({
  hour: 0,
  appliances: aplDurationFormat.ja,
});

export default (state: State = initialState, action: Action = {}): State => {
  switch (action.type) {
    case ENTER_APPLICATION: {
      return {
        ...state,
        locale: action.locale,
      };
    }
    case LOAD_TOP_PAGE: {
      return {
        ...state,
        disaggregated: true,
        requesting: false,
        useStatsRequesting: false,
        hourlyDurations: [],
      };
    }
    case REQUEST_API_USE_STATS: {
      const requesting = !action.error;
      const useStatsRequesting = !action.error;
      return {
        ...state,
        requesting,
        useStatsRequesting,
      };
    }
    case SUCCESS_API_USE_STATS: {
      const { payload, meta } = action;
      if (meta && meta.timeUnit && meta.timeUnit === 'year') {
        const { count: usedCount, alertInfo } = payload;
        const newIdsToShow = Object.keys(usedCount)
          .filter(applianceId => !state.idsToShow.includes(applianceId))
          .map(applianceId => {
            if (usedCount[applianceId] > 0) {
              return applianceId;
            }
            return null;
          })
          .filter(value => value);
        // observedから取得したデータは波形除去ありかつ下流の場合、ランキングには表示しない
        return {
          ...state,
          useStatsRequesting: false,
          alertInfo: alertInfo || state.alertInfo,
          idsToShow: [...state.idsToShow, ...newIdsToShow].reduce((prev, now) => {
            const id =
              now && now.toString().includes('observed') ? now.toString().split('_')[0] : now;
            return prev.includes(id) ? prev : [...prev, id];
          }, []),
          idsToShowForRanking: [...state.idsToShowForRanking, ...newIdsToShow].filter(
            id => !id || (id && !id.includes('observed') && id)
          ),
        };
      }

      const { duration } = payload;
      const hourlyDurationFormat = getHourlyDurationFormat();
      const appliances = hourlyDurationFormat.appliances.map(appliance => ({
        ...appliance,
        duration:
          typeof duration[appliance.id] === 'undefined'
            ? appliance.duration
            : duration[appliance.id],
      }));
      const additionalHourlyDuration = {
        hour: meta.hour,
        appliances,
      };
      const result = {
        ...state,
        disaggregated: !!Object.keys(duration).length,
        requesting: false,
        useStatsRequesting: false,
        hourlyDurations: [...state.hourlyDurations, additionalHourlyDuration],
      };
      return result;
    }
    case FAILURE_API_USE_STATS: {
      return {
        ...state,
        requesting: false,
        useStatsRequesting: false,
      };
    }
    case REQUEST_API_MULTIFETCH: {
      const { meta = {} } = action || {};
      const targetKeys = Object.keys(meta).filter(key => key.match(/^useStats\d+$/));
      if (targetKeys.length === 0) {
        return state;
      }
      const requesting = !action.error;
      return {
        ...state,
        requesting,
      };
    }
    case SUCCESS_API_MULTIFETCH: {
      const { payload = {}, meta = {} } = action || {};

      const targets = Object.keys(payload)
        .filter(key => key.match(/^useStats\d+$/))
        .map(key => ({
          ...payload[key],
          name: key,
        }));
      if (targets.length === 0) {
        return state;
      }

      const hourlyDurationFormat = getHourlyDurationFormat();
      let hourlyDurations = targets.map(target => {
        const { duration = {} } = target.body || {};
        return {
          hour: meta[target.name].hour,
          appliances: hourlyDurationFormat.appliances.map(appliance => {
            const value =
              typeof duration[appliance.id] === 'undefined'
                ? appliance.duration
                : duration[appliance.id];
            // observedから取得したデータを稼働判定に含める
            const observed = Object.keys(duration).filter(id =>
              id.startsWith(`${appliance.id}_observed`)
            );
            return {
              ...appliance,
              duration: observed.length
                ? value + observed.reduce((total, current) => total + (duration[current] || 0), 0)
                : value,
            };
          }),
        };
      });
      if (hourlyDurations.length === 2) {
        const hoursToUpdate = hourlyDurations.map(value => value.hour);
        const rest10Hours = state.hourlyDurations.filter(
          value => hoursToUpdate.indexOf(value.hour) < 0
        );
        hourlyDurations = [...hourlyDurations, ...rest10Hours];
      }

      return {
        ...state,
        disaggregated:
          targets.findIndex(target => target.body && !!Object.keys(target.body.duration).length) !==
          -1,
        requesting: false,
        hourlyDurations,
      };
    }
    case FAILURE_API_MULTIFETCH: {
      const { meta = {} } = action || {};
      const targetKeys = Object.keys(meta).filter(key => key.match(/^useStats\d+$/));
      if (targetKeys.length === 0) {
        return state;
      }
      return {
        ...state,
        requesting: false,
      };
    }
    case SUCCESS_API_LOGOUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
