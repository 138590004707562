// @flow

import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { injectIntl, intlShape } from 'react-intl';

import Prefixer from 'Client/lib/Prefixer';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  notice: {
    padding: '30px 0px',
  },
  link: {
    textDecoration: 'none',
  },
  title: {
    fontSize: '1.2em',
  },
  description: {
    fontSize: '0.8em',
    marginTop: '20px',
  },
  buttonArea: {
    margin: '30px auto',
  },
  button: {
    background: '#a1a1a1',
    color: '#fff',
    fontWeight: 'bold',
    borderRadius: '5px',
    width: '140px',
    height: '42px',
    lineHeight: '42px',
    padding: '14px 13px',
    textAlign: 'center',
    textDecoration: 'none',
  },
});

export const CHART_NO_DATA = 'CHART_NO_DATA';
export const CHART_IMPERFECT_LEARNING = 'CHART_IMPERFECT_LEARNING';
export const CHART_LEARNING = 'CHART_LEARNING';

declare var config: { URLS: Object[] };

const renderLink = intl => {
  const { Q_NO_DATA } = config.URLS[intl.locale.toUpperCase()] || {};

  return (
    <a href={Q_NO_DATA} target="_blank" rel="noopener noreferrer" style={style.textDecoration}>
      {intl.formatMessage({ id: 'chartNoData.link' })}
    </a>
  );
};

const renderNoData = intl => (
  <div style={style.notice}>
    <div>
      {intl.formatMessage({ id: 'chartNoData.title1' })}
      <br />
      {intl.formatMessage({ id: 'chartNoData.title2' })}
    </div>
    <div>
      {intl.formatMessage({ id: 'chartNoData.desc1' })}
      <br />
      {intl.formatMessage({ id: 'chartNoData.desc2' })}
    </div>
    {renderLink(intl)}
  </div>
);

const renderImperfectLearning = intl => {
  const { Q_TARGET_APPLIANCES } = config.URLS[intl.locale.toUpperCase()] || {};
  return (
    <div style={style.notice}>
      <div style={style.title}>
        {intl.formatMessage({ id: 'chartImperfectLearning.title1' })}
        <br />
        {intl.formatMessage({ id: 'chartImperfectLearning.title2' })}
      </div>
      <div style={style.description}>
        {intl.formatMessage({ id: 'chartImperfectLearning.desc1' })}
        <br />
        {intl.formatMessage({ id: 'chartImperfectLearning.desc2' })}
      </div>
      <div style={style.buttonArea}>
        <a
          href={Q_TARGET_APPLIANCES}
          target="_blank"
          rel="noopener noreferrer"
          style={style.button}
        >
          {intl.formatMessage({ id: 'chartImperfectLearning.link' })}
        </a>
      </div>
    </div>
  );
};

const renderLearning = intl => (
  <div style={style.notice}>
    <div style={style.title}>{intl.formatMessage({ id: 'chartLearning.title' })}</div>
    <div style={style.description}>
      {intl.formatMessage({ id: 'chartLearning.desc1' })}
      <br />
      {intl.formatMessage({ id: 'chartLearning.desc2' })}
    </div>
  </div>
);

type OwnProps = {|
  type: string,
  intl: intlShape,
|};
type Props = {|
  ...OwnProps,
  dispatch: Dispatch,
|};

export const PureChartNoData = ({ type, intl }: Props): Node => {
  switch (type) {
    case CHART_NO_DATA:
      return renderNoData(intl);
    case CHART_IMPERFECT_LEARNING:
      return renderImperfectLearning(intl);
    case CHART_LEARNING:
      return renderLearning(intl);
    default:
      return <div />;
  }
};

export default injectIntl(PureChartNoData);
