// @flow

import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import Prefixer from 'Client/lib/Prefixer';
import { SWITCH_APL_DISPLAY_FLAG } from 'Client/actions/types';
import { switchImage } from 'Client/lib/globalUtils';
import 'Client/css/aplCheckboxLabel.css'; // CAUTION: this style is temporary one at v2 design.

const prefixer = new Prefixer();
const bgOff = require('Client/images/today_detail_content_box_bg_off.png');

const style = prefixer.prefix({
  applianceState: {
    position: 'relative',
    margin: '0 5px 15px',
  },
  state: {
    width: '85%',
    height: '85%',
    display: 'block',
    margin: 'auto',
  },
  appliance: {
    position: 'absolute',
    left: '13%',
    top: '8%',
    width: '73%',
  },
});

type Props = {
  appliance: {
    id: string,
    display: boolean,
  },
  syncIds: ?(string[]),
  dispatch: Dispatch,
};

const SettingApplianceCheckBox = ({ appliance, syncIds, dispatch }: Props): Node => (
  <div>
    <input
      type="checkbox"
      className="aplCheckbox"
      style={{ display: 'none' }}
      checked={appliance.display}
      readOnly
    />
    <div
      className="aplCheckboxLabel"
      onClick={() => dispatch({ type: SWITCH_APL_DISPLAY_FLAG, id: appliance.id, syncIds })}
    />
    <div style={style.applianceState}>
      <img src={bgOff} alt="bg" style={style.state} />
      <img
        src={switchImage(appliance.id)}
        alt="appliance"
        style={style.appliance}
        onClick={() => dispatch({ type: SWITCH_APL_DISPLAY_FLAG, id: appliance.id, syncIds })}
      />
    </div>
  </div>
);

export default SettingApplianceCheckBox;
