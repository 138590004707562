// @flow

import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { injectIntl, intlShape, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import TopHourlyChargePg from 'Client/components/TopHourlyChargePg';
import TopHourlyCharge from 'Client/components/TopHourlyCharge';
import TopHourlyApplianceState from 'Client/components/TopHourlyApplianceState';
import Prefixer from 'Client/lib/Prefixer';
import { withinOneHours } from 'Client/lib/topPageUtils';
import type { State } from 'Client/types/state';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  hourlyAnalysis: {
    width: '100%',
    height: 'auto',
    background: '#fff',
    padding: '4px 0px 40px 0',
    margin: '0px 16px 20px 0px',
  },
  time: {
    background: '#ffbd00',
    color: '#fff',
    width: '100%',
    fontSize: '1.0em',
    textAlign: 'center',
    margin: '0px auto',
    borderRadius: '30px',
    height: '25px',
    lineHeight: '25px',
  },
});

const hourlyEnergy = (hourlyEnergyList, selectedHour) => {
  const e = hourlyEnergyList.find(x => x.hour === selectedHour);
  const sellEnergy = e ? e.sellEnergy : 0.0;
  const buyEnergy = e ? e.buyEnergy : 0.0;
  return { sellEnergy, buyEnergy };
};

type StateProps = {|
  isPg: boolean,
  hourlyEnergyList: {
    hour: number,
    sellEnergy: number,
    buyEnergy: number,
  }[],
  currentDate: string,
  currentTime: string,
  selectedHour: number,
|};
type OwnProps = {|
  intl: intlShape,
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

const renderSelectedTimeTile = (intl, selectedHour, currentDate, currentTime) => {
  const hour = selectedHour % 100;
  const oclock = moment({ hour }).format('H');
  const hourFrom = moment({ hour }).format('h a');
  const hourTo = moment({ hour })
    .add(1, 'hour')
    .format('h a');
  const ukCurrentTime = moment(currentTime, 'HH:mm').format('hh:mm a');

  const messages = defineMessages({
    hour: {
      id: 'topHourlyAnalysisArea.hour',
      description: '今日 xx時台',
    },
    current: {
      id: 'topHourlyApplianceState.current',
      description: 'xx pm at presents',
    },
  });
  if (withinOneHours(currentDate, currentTime, selectedHour)) {
    return intl.formatMessage(messages.current, { ukCurrentTime, currentTime });
  }
  return intl.formatMessage(messages.hour, { oclock, hourFrom, hourTo });
};

export const PureTopHourlyAnalysisArea = ({
  isPg,
  hourlyEnergyList,
  selectedHour,
  currentDate,
  currentTime,
  dispatch,
  intl,
}: Props): Node => {
  const isToday = selectedHour % 100 <= moment().hour();
  return (
    <div>
      <div style={style.hourlyAnalysis}>
        <div style={style.time}>
          {isToday
            ? intl.formatMessage({ id: 'topHourlyAnalysisArea.today' })
            : intl.formatMessage({ id: 'topHourlyAnalysisArea.yesterday' })}
          &nbsp;
          {renderSelectedTimeTile(intl, selectedHour, currentDate, currentTime)}
        </div>
        <TopHourlyApplianceState selectedHour={selectedHour} intl={intl} dispatch={dispatch} />
        {isPg ? (
          <TopHourlyChargePg
            hourlyEnergy={hourlyEnergy(hourlyEnergyList, selectedHour)}
            intl={intl}
            selectedHour={selectedHour}
          />
        ) : (
          <TopHourlyCharge
            hourlyEnergy={hourlyEnergy(hourlyEnergyList, selectedHour)}
            intl={intl}
            selectedHour={selectedHour}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  isPg: state.userSetting.isPg,
  hourlyEnergyList: state.energy.hourly,
  currentDate: state.timeRange.currentDate,
  currentTime: state.timeRange.currentTime,
  selectedHour: state.timeRange.selectedHour,
});

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  injectIntl(PureTopHourlyAnalysisArea)
);
