// @flow

import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { createSelector } from 'reselect';
import SettingApplianceCheckBox from 'Client/components/SettingApplianceCheckBox';
import { CLOSING_POP_UP } from 'Client/actions/types';
import Prefixer from 'Client/lib/Prefixer';
import { syncData, idsToHide, triggerIds } from 'Client/lib/settingUtils';
import type { State } from 'Client/types/state';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  entireFixed: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: '100100',
    fontSize: 'initial',
  },
  background: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.3)',
    margin: '0px auto',
    display: 'table',
  },
  boxVerticalPosition: {
    verticalAlign: 'middle',
    display: 'table-cell',
  },
  box: {
    width: 310,
    maxWidth: '80%',
    maxHeight: '65%',
    backgroundColor: '#fff',
    textAlign: 'center',
    lineHeight: '150%',
    borderRadius: 5,
    color: '#000',
    position: 'relative',
    margin: '0px auto',
  },
  content: {
    position: 'relative',
    overflow: 'auto',
  },
  title: {
    color: '#ffbd00',
    fontWeight: 'bold',
    margin: '10px 0px 5px',
  },
  description: {
    fontSize: '0.8em',
    lineHeight: '150%',
  },
  checkBoxArea: {
    padding: '10px',
    height: '190px',
    overflow: 'scroll',
  },
  button: {
    margin: '15px 25%',
    padding: '9px 10px',
    backgroundColor: '#555',
    borderRadius: 5,
    fontWeight: 'bold',
    cursor: 'pointer',
    color: '#fff',
  },
  list: {
    textAlign: 'left',
  },
  listItem: {
    width: '33%',
    textAlign: 'center',
    display: 'inline-block',
  },
});

const renderCheckBoxList = (aplFilter, dispatch) => (
  <div style={style.list}>
    {aplFilter
      .filter(appliance => !idsToHide.includes(appliance.id))
      .map(appliance => (
        <div key={appliance.id} style={style.listItem}>
          <SettingApplianceCheckBox
            appliance={appliance}
            syncIds={triggerIds.includes(appliance.id) ? syncData[appliance.id] : null}
            dispatch={dispatch}
          />
        </div>
      ))}
  </div>
);

const renderBox = (contentType, aplFilter, dispatch, intl) => (
  <div
    style={style.box}
    onClick={e => {
      e.stopPropagation();
    }}
  >
    <div style={style.content}>
      <div style={style.title}>{intl.formatMessage({ id: 'settingAppliancePopup.title' })}</div>
      <div style={style.description}>
        {intl.formatMessage({ id: 'settingAppliancePopup.description1' })}
        <br />
        {intl.formatMessage({ id: 'settingAppliancePopup.description2' })}
      </div>
      <div style={style.checkBoxArea}>{renderCheckBoxList(aplFilter, dispatch)}</div>
      <div style={style.button} onClick={() => dispatch({ type: CLOSING_POP_UP })}>
        {intl.formatMessage({ id: 'settingAppliancePopup.close' })}
      </div>
    </div>
  </div>
);

type StateProps = {|
  contentType: string,
  aplFilter: {
    id: string,
    display: boolean,
  }[],
|};
type OwnProps = {|
  intl: intlShape,
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

export const PureSettingAppliancePopup = ({
  contentType,
  aplFilter,
  dispatch,
  intl,
}: Props): Node => (
  <div>
    <div style={style.entireFixed}>
      <div style={style.background} onClick={() => dispatch({ type: CLOSING_POP_UP })}>
        <div style={style.boxVerticalPosition}>
          {renderBox(contentType, aplFilter, dispatch, intl)}
        </div>
      </div>
    </div>
  </div>
);

const filterApplianceFilters = createSelector(
  [state => state.userSetting.aplFilter, state => state.applianceState.idsToShow],
  (aplFilter, idsToShow) => aplFilter.filter(({ id }) => idsToShow.includes(id))
);

const mapStateToProps = (state: State): StateProps => ({
  contentType: state.popup.contentType,
  aplFilter: filterApplianceFilters(state),
});

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  injectIntl(PureSettingAppliancePopup)
);
