// @flow

import type { Action } from 'redux';
/* global localStorage */
import {
  RESET_TUTORIAL,
  GO_TO_NEXT_PAGE,
  BACK_TO_PREV_PAGE,
  TUTORIAL_FINISHED,
  SUCCESS_API_LOGOUT,
  SUCCESS_API_USER_INFO,
  SUCCESS_API_LOGIN_INFO,
} from 'Client/actions/types';
import { overWriteStateWithFirebase } from 'Client/lib/settingUtils';
import type { TutorialState as State } from 'Client/types/state';

const initialState = {
  page: 0,
  tutorialFinished: false,
};

export default (state: State = initialState, action: Action = {}): State => {
  const { payload } = action;
  switch (action.type) {
    case SUCCESS_API_LOGIN_INFO:
    case SUCCESS_API_USER_INFO: {
      return {
        ...state,
        tutorialFinished:
          (payload.settings &&
            overWriteStateWithFirebase(state, payload.settings).tutorialFinished) ||
          payload.tutorialFinished ||
          state.tutorialFinished,
      };
    }
    case RESET_TUTORIAL: {
      return initialState;
    }
    case GO_TO_NEXT_PAGE: {
      return {
        ...state,
        page: state.page + 1,
      };
    }
    case BACK_TO_PREV_PAGE: {
      return {
        ...state,
        page: state.page - 1,
      };
    }
    case TUTORIAL_FINISHED: {
      return {
        ...state,
        tutorialFinished: true,
      };
    }
    case SUCCESS_API_LOGOUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
