export default {
  'loginPage.showForgetPass': 'true',
  'topBody.showNullRootPowerButton': 'true',
  'topBody.showPreInstallation': 'true',
  'topHourlyApplianceStateItem.showDurationByDefault': 'false',
  'settingPage.priceAlertPattern': '/^[0-9]*$/',
  'settingPage.priceAlertType': 'int',
  'settingPage.priceAlertMinLength': '1',
  'settingPage.priceAlertMaxLength': '3',
  'settingPage.priceAlertMinValue': '1',
  'settingPage.priceAlertMaxValue': '100',
  'settingPage.showPricePlan': 'true',
  'currency.fractionDigits': '0',
  'currency.lessThan': '10',
  'formatDate.day': 'YYYY-M-D',
  'formatDate.week': 'YYYY-M-D',
  'formatDate.month': 'YYYY-M',
  'formatDate.year': 'YYYY',
};
