import moment from 'moment-timezone';
import type { Store, Middleware, Action } from 'redux';
import { CALL_API } from 'redux-api-middleware';
import { logout } from './mbaasAuthentication';

export default (store: Store) => (next: Middleware) => (action: Action) => {
  const callApi = action[CALL_API];
  const state = store.getState();
  const contractEndDate = state.userInfo && state.userInfo.contractEndDate;
  if (!callApi || callApi.endpoint === '/api/v1/logout' || !contractEndDate) {
    next(action);
    return;
  }
  if (moment(contractEndDate).isBefore(moment())) {
    logout(store.dispatch);
    next(action);
    return;
  }
  next(action);
};
