// @flow

import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { intlShape } from 'react-intl';
import { connect } from 'react-redux';
import Loading from 'Client/components/utils/Loading';
import TopChargeItem from 'Client/components/TopChargeItem';
import TopNoData, { TOP_HOURLY_NO_DATA } from 'Client/components/TopNoData';
import Prefixer from 'Client/lib/Prefixer';
import { calcHourlyBuyCharge } from 'Client/lib/pricePlanUtils';
import type { State, PricePlanState } from 'Client/types/state';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  areaWrapper: {
    fontSize: '1em',
  },
  title: {
    color: '#908572',
    textAlign: 'center',
    padding: '0px',
    margin: '10px 0px',
    fontSize: '0.8em',
    width: '100%',
    height: '25px',
    lineHeight: '25px',
    background: '#f2efe0',
    borderRadius: '30px',
  },
  chargeArea: {
    fontSize: '1.3em',
  },
  sell: {
    color: '#4bbeae',
  },
  buy: {
    color: '#ff9086',
  },
  chargeTitle: {
    textAlign: 'left',
    verticalAlign: 'top',
    paddingRight: '5px',
    display: 'inline-block',
    width: '30%',
    fontSize: '0.8em',
  },
  cell: {
    display: 'inline-block',
    margin: '0px auto 0px',
    paddingBottom: '10px',
    width: '50%',
  },
  chargeItem: {
    display: 'inline-block',
    fontSize: '0.8em',
    padding: '0px 5px',
  },
});

const renderSell = (unitCharge, unitEnergy, intl) => (
  <div style={style.cell}>
    <div style={style.chargeTitle}>
      <div style={style.sell}>{intl.formatMessage({ id: 'topHourlyChargePg.sell' })}</div>
    </div>
    <div style={style.chargeItem}>
      <TopChargeItem unitCharge={unitCharge} unitEnergy={unitEnergy} intl={intl} />
    </div>
  </div>
);

const renderBuy = (unitCharge, unitEnergy, intl) => (
  <div style={style.cell}>
    <div style={style.chargeTitle}>
      <div style={style.buy}>{intl.formatMessage({ id: 'topHourlyChargePg.buy' })}</div>
    </div>
    <div style={style.chargeItem}>
      <TopChargeItem unitCharge={unitCharge} unitEnergy={unitEnergy} intl={intl} />
    </div>
  </div>
);

const charge = (price, energy) => price * energy;

const renderChargeArea = (sellPrice, hourlyEnergy, requesting, intl, selectedHour, pricePlan) => {
  if (requesting) {
    return <Loading space={0} />;
  }
  const { sellEnergy, buyEnergy } = hourlyEnergy;
  if (sellEnergy + buyEnergy === 0) {
    return (
      <div style={style.chargeArea}>
        <TopNoData type={TOP_HOURLY_NO_DATA} />
      </div>
    );
  }
  return (
    <div style={style.chargeArea}>
      {renderSell(charge(sellPrice, hourlyEnergy.sellEnergy), hourlyEnergy.sellEnergy, intl)}
      {renderBuy(
        calcHourlyBuyCharge(buyEnergy, selectedHour, pricePlan),
        hourlyEnergy.buyEnergy,
        intl
      )}
    </div>
  );
};

type StateProps = {|
  sellPrice: number,
  requesting: boolean,
  pricePlan: PricePlanState,
|};
type OwnProps = {|
  hourlyEnergy: {
    sellEnergy: number,
    buyEnergy: number,
  },
  intl: intlShape,
  selectedHour: number,
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

export const PureTopHourlyChargePg = ({
  sellPrice,
  hourlyEnergy,
  requesting,
  intl,
  selectedHour,
  pricePlan,
}: Props): Node => (
  <div>
    <div style={style.title}>{intl.formatMessage({ id: 'topHourlyChargePg.charge' })}</div>
    {renderChargeArea(sellPrice, hourlyEnergy, requesting, intl, selectedHour, pricePlan)}
  </div>
);

const mapStateToProps = (state: State): StateProps => ({
  sellPrice: state.userSetting.sellPrice,
  requesting: state.energy.hourlyRequesting,
  pricePlan: state.pricePlan,
});

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  PureTopHourlyChargePg
);
