// @flow

import type { Action } from 'redux';
import type { AplPowersState as State } from 'Client/types/state';

import {
  SUCCESS_API_DAY_STATS,
  FAILURE_API_DAY_STATS,
  REQUEST_API_DAY_STATS,
} from 'Client/actions/types';

const initialState = {
  powers: {},
  rootPower: [],
  requesting: false,
};

export default (state: State = initialState, action: Action = {}): State => {
  switch (action.type) {
    case REQUEST_API_DAY_STATS:
      return {
        ...state,
        requesting: !action.error,
      };
    case FAILURE_API_DAY_STATS:
      return initialState;
    case SUCCESS_API_DAY_STATS: {
      const { payload = {} } = action || {};
      return {
        ...state,
        requesting: false,
        rootPower: payload.rootpower || [],
        powers: payload.powers || {},
      };
    }
    default: {
      return state;
    }
  }
};
