import { applianceId } from 'Client/lib/id';

export const defaultEnergyPrice = {
  ja: { buy: 25, sell: 33 },
};

export const defaultMultiStageEnergyPrice = {
  ja: [{ startTime: 7, price: 25 }, { startTime: 23, price: 20 }],
};

export const energyPriceRange = { ja: { min: 1, max: 100 } };

export const aplFilterFormat = {
  ja: [
    { id: applianceId.airConditioner, display: true },
    { id: applianceId.highFever, display: true },
    { id: applianceId.microwave, display: true },
    { id: applianceId.riceCooker, display: true },
    { id: applianceId.ih, display: true },
    { id: applianceId.clothesWasher, display: true },
    { id: applianceId.vacuumCleaner, display: true },
    { id: applianceId.tv, display: true },
    { id: applianceId.ecoCute, display: true },
    { id: applianceId.chargedStorageBattery, display: true },
    { id: applianceId.dischargedStorageBattery, display: true },
    { id: applianceId.chargedHybridStorageBattery, display: true },
    { id: applianceId.dischargedHybridStorageBattery, display: true },
    { id: applianceId.eneFarm, display: true },
  ],
};

export const rankingFormat = {
  ja: [
    { id: applianceId.airConditioner, energy: 0 },
    { id: applianceId.clothesWasher, energy: 0 },
    { id: applianceId.microwave, energy: 0 },
    { id: applianceId.refrigerator, energy: 0 },
    { id: applianceId.riceCooker, energy: 0 },
    { id: applianceId.tv, energy: 0 },
    { id: applianceId.vacuumCleaner, energy: 0 },
    { id: applianceId.ih, energy: 0 },
    { id: applianceId.ecoCute, energy: 0 },
    { id: applianceId.highFever, energy: 0 },
    { id: applianceId.light, energy: 0 },
    { id: applianceId.background, energy: 0 },
  ],
};

export const aplDurationFormat = {
  ja: [
    { id: applianceId.airConditioner, duration: 0 },
    { id: applianceId.highFever, duration: 0 },
    { id: applianceId.microwave, duration: 0 },
    { id: applianceId.riceCooker, duration: 0 },
    { id: applianceId.ih, duration: 0 },
    { id: applianceId.clothesWasher, duration: 0 },
    { id: applianceId.vacuumCleaner, duration: 0 },
    { id: applianceId.tv, duration: 0 },
    { id: applianceId.eneFarm, duration: 0 },
    { id: applianceId.ecoCute, duration: 0 },
    { id: applianceId.chargedStorageBattery, duration: 0 },
    { id: applianceId.dischargedStorageBattery, duration: 0 },
    { id: applianceId.chargedHybridStorageBattery, duration: 0 },
    { id: applianceId.dischargedHybridStorageBattery, duration: 0 },
  ],
};
