// @flow

import React from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Prefixer from 'Client/lib/Prefixer';
import { RESET_RESET_PASSWORD } from 'Client/actions/types';
import { apiResetPassword } from 'Client/actions/apis';
import HeaderBar from 'Client/components/HeaderBar';
import ContentContainer from 'Client/components/utils/ContentContainer';
import { validateFormValues } from 'Client/lib/globalUtils';
import type { State } from 'Client/types/state';

const PAGE_TYPE = 'resetPassword';
const { mailAddress: validateMailAddress } = validateFormValues;
const prefixer = new Prefixer();

const style = prefixer.prefix({
  container: {
    padding: '75px 20px 100px',
    color: '#454545',
  },
  startLine: {
    marginBottom: 10,
    lineHeight: 1,
  },
  inputArea: {
    marginBottom: 20,
  },
  input: {
    width: '100%',
    height: 40,
    padding: '0 0.35em',
    borderRadius: 4,
    border: 'solid 1px #d8d8d8',
    fontSize: '16px',
    boxSizing: 'border-box',
  },
  disabledInput: {
    backgroundColor: '#eaeaea',
  },
  errorMessage: {
    margin: '5px 0 0',
    color: '#ff0000',
    fontSize: 12,
  },
  guideBox: {
    marginBottom: 40,
    padding: '10px 15px',
    backgroundColor: '#eaeaea',
    lineHeight: 1.7,
  },
  actionButton: {
    display: 'block',
    width: 280,
    height: 50,
    margin: '0 auto',
    padding: 0,
    borderRadius: 5,
    border: 0,
    outline: 0,
    backgroundColor: '#ffbd00',
    color: '#fff',
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: "'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif",
    lineHeight: '50px',
    textAlign: 'center',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  disabledActionButton: {
    backgroundColor: '#d8d8d8',
  },
});

type StateProps = {|
  resetPasswordState: string,
|};
type OwnProps = {|
  intl: intlShape,
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

type FormValues = {
  mailAddress: string,
};

/**
 * 入力画面
 */
const ResetPasswordForm = (props: Props) => {
  const isRequesting = props.resetPasswordState === 'REQUESTING';
  const initialFormValues: FormValues = { mailAddress: '' };
  return (
    <ContentContainer style={style.container}>
      <HeaderBar pageType={PAGE_TYPE} requesting={isRequesting} />
      <Formik
        initialValues={initialFormValues}
        validate={(values: FormValues) => validateMailAddress(values, PAGE_TYPE)}
        validateOnChange={false}
        onSubmit={({ mailAddress }: FormValues) => {
          props.dispatch(apiResetPassword(mailAddress));
        }}
        render={() => (
          <Form noValidate>
            <p style={style.startLine}>
              {props.intl.formatMessage({ id: `${PAGE_TYPE}.toAskAddress` })}
            </p>
            <div style={style.inputArea}>
              <Field
                type="email"
                name="mailAddress"
                disabled={isRequesting}
                style={{
                  ...style.input,
                  ...(isRequesting ? style.disabledInput : null),
                }}
              />
              <ErrorMessage
                name="mailAddress"
                render={id => <p style={style.errorMessage}>{props.intl.formatMessage({ id })}</p>}
              />
            </div>
            <p style={style.guideBox}>
              {props.intl.formatMessage({ id: `${PAGE_TYPE}.sendMailGuideL1` })}
              <br />
              {props.intl.formatMessage({ id: `${PAGE_TYPE}.sendMailGuideL2` })}
              <br />
            </p>
            <button
              type="submit"
              disabled={isRequesting}
              style={{
                ...style.actionButton,
                ...(isRequesting ? style.disabledActionButton : null),
              }}
            >
              {props.intl.formatMessage({ id: `${PAGE_TYPE}.toSendMail` })}
            </button>
          </Form>
        )}
      />
    </ContentContainer>
  );
};

class ResetPasswordSendMail extends React.Component<Props> {
  componentWillUnmount() {
    this.props.dispatch({ type: RESET_RESET_PASSWORD });
  }

  render() {
    if (this.props.resetPasswordState === 'ERROR') {
      return <Redirect to="/error/any" />;
    }

    return (
      <div>
        {this.props.resetPasswordState === 'SUCCESS' ? (
          <Redirect to="/completed/mailsent" />
        ) : (
          <ResetPasswordForm {...this.props} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: State): StateProps => ({
  resetPasswordState: state.loginInfo.resetPasswordState,
});

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  injectIntl(ResetPasswordSendMail)
);

export const ResetPasswordSendMailForTest = ResetPasswordSendMail;
