// @flow

/**
 * ミラーチャート用機能メモ
 *
 * isMirror が指定された場合は高さを縮める
 *
 * behaveAsMirror が指定された場合は下方向に伸びるグラフを描画する
 * behaveAsMirror は上方向に伸びるグラフと必ずセットで使用するものとし、重複する凡例や余白等は削除する
 *
 * hijackScroll コールバック関数はスクロールを停止させ、zoomDomain を取得する
 * hijackScroll で取得した値を sharedZoom で渡すことで複数グラフのスクロールの同期を実現する
 *
 * sharedActiveIndex を渡すことで複数グラフのバー選択状態の同期を実現する
 */

import React, { useState } from 'react';
import type { Node } from 'react';
import sizeMe from 'react-sizeme';
import {
  VictoryChart,
  VictoryZoomContainer,
  VictoryStack,
  VictoryBar,
  VictoryLine,
  VictoryScatter,
  VictoryAxis,
  VictoryLabel,
  Rect,
} from 'victory';
import type { ChartData, StackedData } from 'Client/types/types';

const VISIBLE_BAR_COUNT = 7;
const VIEW_BOX_WIDTH = 400;
const VIEW_BOX_HEIGHT = 240;
const MULTI_VIEW_BOX_HEIGHT = 180;
const labelStyles = {
  fontFamily: "'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif",
  fill: '#847965',
  fontWeight: 'normal',
};

type Size = {
  width: number,
  height: number,
};
type ChartPadding = { top: number, right: number, bottom: number, left: number };

type Domain = { x: number[] };
type UpdateZoomDomain = (zoomDomain: Domain) => void;
type Props = {
  size: Size,
  startHour: number,
  endHour: number,
  initialHour: number,
  axisUnit: string,
  horizonAxisUnit: string,
  stackedData: {
    [key: string]: StackedData,
  },
  pgEnergy: ?{
    [key: string]: StackedData,
  },
  maximumValue: number,
  handleSelect: (barData: null | { [key: string]: ChartData }) => void,
  isNotActive?: boolean,
  unit: ?string,
  isLine?: boolean,
  // 以下、ミラーチャート用プロパティ
  isMirror?: boolean,
  behaveAsMirror?: boolean,
  hijackScroll?: (zoomDomain: Domain) => void,
  sharedZoomDomain?: Domain,
  sharedActiveIndex?: number,
};

const throttle = (() => {
  let count = 0;
  let reserved = null;
  return (fn: () => void, options: { step: number, interval: number }): void => {
    count += 1;
    if (count % options.step === 0) {
      fn();
    }

    clearTimeout(reserved);
    reserved = setTimeout(() => {
      count = 0;
    }, options.interval);
  };
})();

/**
 * zoomContainer 用のドメイン範囲配列を返す
 * { initialHour }時が中央になる範囲を返す
 * 表示可能時間が VISIBLE_BAR_COUNT に満たなければ { startHour } ~ { startHour + VISIBLE_BAR_COUNT }時
 * 表示可能時間が initialHour + VISIBLE_BAR_COUNT / 2 に満たなければ { endHour - VISIBLE_BAR_COUNT } ~ { endHour }時
 */
const getInitialZoomDomain = ({
  startHour,
  endHour,
  initialHour,
}: {
  startHour: number,
  endHour: number,
  initialHour: number,
}): number[] => {
  if (endHour - startHour + 1 < VISIBLE_BAR_COUNT) {
    return [startHour - 0.5, startHour + VISIBLE_BAR_COUNT - 0.5];
  }
  if (endHour < Math.floor(initialHour + VISIBLE_BAR_COUNT / 2)) {
    return [endHour - VISIBLE_BAR_COUNT + 0.5, endHour + 0.5];
  }
  return [initialHour - VISIBLE_BAR_COUNT / 2, initialHour + VISIBLE_BAR_COUNT / 2];
};

/**
 * PC・SP 用グラフエリアスクロールハンドラ
 */
const touchScrollHandler = {
  prevTouchedPointForScroll: 0,
  isTouching: false,
  isMoved: false,
  setTouchedPoint(point: number) {
    touchScrollHandler.prevTouchedPointForScroll = point;
  },
  setTouchingState(isTouching: boolean) {
    touchScrollHandler.isTouching = isTouching;
    if (isTouching) {
      touchScrollHandler.setMovedState(false);
    }
  },
  setMovedState(isMoved: boolean) {
    touchScrollHandler.isMoved = isMoved;
  },
  handle({
    point,
    startHour,
    endHour,
    domain,
    zoomDomain,
    updateZoomDomain,
    shouldCheckTouching,
    hijackScroll,
  }: {
    point: number,
    startHour: number,
    endHour: number,
    domain: Domain,
    zoomDomain: Domain,
    updateZoomDomain: UpdateZoomDomain,
    shouldCheckTouching?: boolean,
    hijackScroll?: (zoomDomain: Domain) => void,
  }) {
    const handler = typeof hijackScroll === 'function' ? hijackScroll : updateZoomDomain;
    throttle(
      () => {
        // PC 操作時の タッチ判定
        if (shouldCheckTouching) {
          if (!touchScrollHandler.isTouching) {
            return;
          }
          touchScrollHandler.setMovedState(true);
        }

        if (endHour - startHour + 1 < VISIBLE_BAR_COUNT) {
          return;
        }

        const additionValue = (touchScrollHandler.prevTouchedPointForScroll - point) / 50;
        const newDomain = zoomDomain.x.map(value => value + additionValue);
        if (newDomain[0] < domain.x[0]) {
          if (newDomain[0] !== domain.x[0]) {
            handler({
              x: [startHour - 0.5, startHour + VISIBLE_BAR_COUNT - 0.5],
            });
          }
        } else if (newDomain[1] > domain.x[1]) {
          if (newDomain[1] !== domain.x[1]) {
            handler({
              x: [endHour - VISIBLE_BAR_COUNT + 0.5, endHour + 0.5],
            });
          }
        } else {
          handler({
            x: newDomain,
          });
        }
        touchScrollHandler.prevTouchedPointForScroll = point;
      },
      { step: 3, interval: 300 }
    );
  },
};

/**
 * X軸ラベルを塗りつぶす長方形
 * チャートのサイズ、余白から位置とサイズを求める
 */
const renderFilledAxisRect = ({
  size,
  chartPadding,
  axis,
}: {
  size: Size,
  chartPadding: ChartPadding,
  axis: Node,
}): Node => [
  <Rect
    key="filledRectGray"
    style={{
      width:
        size.width * (VIEW_BOX_WIDTH / size.width) - (chartPadding.left + chartPadding.right) || 0,
      height: chartPadding.bottom,
      fill: '#eaeaea',
    }}
    x={chartPadding.left}
    y={size.height * (VIEW_BOX_WIDTH / size.width) - chartPadding.bottom || 0}
  />,
  axis,
];

/**
 * Y軸ラベル
 */
const renderVerticalAxis = ({
  maximumValue,
  axisUnit,
  behaveAsMirror,
}: {
  maximumValue: number,
  axisUnit: string,
  behaveAsMirror: boolean,
}): Node => [
  !behaveAsMirror && (
    <VictoryLabel
      key={axisUnit}
      text={axisUnit}
      style={{
        ...labelStyles,
        fontSize: '10px',
        textAnchor: 'start',
        verticalAnchor: 'top',
      }}
      x={5}
      y={0}
    />
  ),
  <VictoryAxis
    key="verticalAxis"
    dependentAxis
    invertAxis={behaveAsMirror}
    tickValues={[maximumValue / 2, maximumValue]}
    tickLabelComponent={<VictoryLabel x={5} />}
    style={{
      axis: {
        stroke: 'none',
      },
      grid: {
        stroke: '#eaeaea',
        strokeDasharray: '3, 1',
        strokeWidth: 1.5,
      },
      tickLabels: {
        ...labelStyles,
        fontSize: '13px',
        textAnchor: 'start',
      },
    }}
  />,
];

/**
 * X軸ラベル
 */
const renderHorizonAxis = ({
  data,
  size,
  chartPadding,
  horizonAxisUnit,
  behaveAsMirror,
  unit,
}: {
  data: ChartData[],
  size: Size,
  chartPadding: ChartPadding,
  horizonAxisUnit: string,
  behaveAsMirror: boolean,
  unit?: ?string,
}): Node => [
  !behaveAsMirror &&
    renderFilledAxisRect({
      size,
      chartPadding,
      axis: (
        <VictoryAxis
          key="horizonAxis"
          tickValues={data.map(({ x }) => x)}
          tickLabelComponent={
            unit === 'day' ? (
              <VictoryLabel text={datum => `${datum || 0}${horizonAxisUnit}`} />
            ) : (
              <VictoryLabel text={datum => `${horizonAxisUnit.split(',')[datum || 0]}`} />
            )
          }
          offsetY={chartPadding.bottom + 4}
          style={{
            axis: {
              stroke: 'none',
            },
            tickLabels: labelStyles,
          }}
        />
      ),
    }),
];

const PureStackedBarChart = (props: Props): Node => {
  const {
    size,
    startHour,
    endHour,
    initialHour,
    axisUnit,
    horizonAxisUnit,
    stackedData,
    pgEnergy,
    maximumValue,
    handleSelect,
    isNotActive,
    unit,
    isLine,
    isMirror,
    behaveAsMirror,
    hijackScroll,
    sharedZoomDomain,
    sharedActiveIndex,
  } = props;

  const [activeIndex, updateActiveIndex] = useState<null | number, (index: null | number) => void>(
    null
  );

  const [zoomDomain, updateZoomDomain] = useState<Domain, UpdateZoomDomain>({
    x: getInitialZoomDomain({ startHour, endHour, initialHour }),
  });

  if (isNotActive && (activeIndex || activeIndex === 0)) {
    updateActiveIndex(null);
  }

  const usedActiveIndex =
    typeof sharedActiveIndex !== 'undefined' ? sharedActiveIndex : activeIndex;
  const usedZoomDomain = sharedZoomDomain || zoomDomain;

  const stackedDataKeys = Object.keys(stackedData);
  const pgEnergyKeys = pgEnergy && Object.keys(pgEnergy);
  const domain = {
    x: [startHour - 0.5, endHour + 0.5],
    y: [
      0,
      isMirror ? maximumValue + maximumValue * 0.02 + 0.1 : maximumValue + maximumValue * 0.02,
    ],
  };
  const chartPadding = {
    top: behaveAsMirror ? 0 : 20,
    right: 0,
    bottom: behaveAsMirror ? 5 : 25,
    left: 35,
  };
  const barWidth = (size.width / VISIBLE_BAR_COUNT) * 0.6;
  const canScroll = endHour - startHour + 1 > VISIBLE_BAR_COUNT;

  return (
    <VictoryChart
      padding={chartPadding}
      width={VIEW_BOX_WIDTH}
      height={(isMirror ? MULTI_VIEW_BOX_HEIGHT : VIEW_BOX_HEIGHT) - (behaveAsMirror ? 40 : 0)}
      containerComponent={
        <VictoryZoomContainer
          disable
          zoomDomain={usedZoomDomain}
          style={{ display: 'flex', touchAction: 'auto', cursor: canScroll ? 'move' : 'auto' }}
        />
      }
      events={[
        {
          childName: stackedDataKeys,
          target: 'data',
          eventHandlers: {
            onClick: (e: SyntheticEvent<HTMLElement>, { datum }) => {
              e.stopPropagation();

              // PC 操作時、mouseup で click が発火してしまうための対応
              if (touchScrollHandler.isMoved) {
                touchScrollHandler.setMovedState(false);
                return;
              }

              updateActiveIndex(datum.x);

              const activeBarData = {};
              stackedDataKeys.forEach(key => {
                activeBarData[key] = stackedData[key].data.find(data => data.x === datum.x);
              });
              handleSelect(activeBarData);
            },
          },
        },
        {
          target: 'parent',
          eventHandlers: {
            onClick: () => {
              // PC 操作時、mouseup で click が発火してしまうための対応
              if (touchScrollHandler.isMoved) {
                touchScrollHandler.setMovedState(false);
                return;
              }

              updateActiveIndex(null);
              handleSelect(null);
            },
            onMouseDown: (e: SyntheticMouseEvent<> & { target: HTMLElement }) => {
              touchScrollHandler.setTouchedPoint(e.clientX);
              touchScrollHandler.setTouchingState(true);
            },
            onMouseMove: (e: SyntheticMouseEvent<>) => {
              touchScrollHandler.handle({
                point: e.clientX,
                startHour,
                endHour,
                domain,
                zoomDomain: usedZoomDomain,
                updateZoomDomain,
                shouldCheckTouching: true,
                hijackScroll,
              });
            },
            onMouseUp: () => {
              touchScrollHandler.setTouchingState(false);
            },
            onMouseLeave: () => {
              touchScrollHandler.setTouchingState(false);
            },
            onTouchStart: (e: SyntheticTouchEvent<>) => {
              touchScrollHandler.setTouchedPoint(e.touches[0].pageX);
            },
            onTouchMove: (e: SyntheticTouchEvent<>) => {
              touchScrollHandler.handle({
                point: e.touches[0].pageX,
                startHour,
                endHour,
                domain,
                zoomDomain: usedZoomDomain,
                updateZoomDomain,
                hijackScroll,
              });
            },
          },
        },
      ]}
    >
      {renderVerticalAxis({ maximumValue, axisUnit, behaveAsMirror: !!behaveAsMirror })}
      {renderHorizonAxis({
        data: stackedData[stackedDataKeys[0]].data,
        size,
        chartPadding,
        horizonAxisUnit,
        behaveAsMirror: !!behaveAsMirror,
        unit: unit || 'day',
      })}
      <VictoryStack domain={domain}>
        {stackedDataKeys.map(key => (
          <VictoryBar
            key={key}
            name={key}
            data={stackedData[key].data}
            barWidth={barWidth}
            style={{
              data: {
                cursor: 'auto',
                fill: ({ x }) => {
                  const colorObject = Array.isArray(stackedData[key].colors)
                    ? stackedData[key].colors[x]
                    : stackedData[key].colors;
                  return colorObject[
                    usedActiveIndex === null || x === usedActiveIndex ? 'default' : 'disabled'
                  ];
                },
              },
            }}
          />
        ))}
      </VictoryStack>
      {isLine &&
        pgEnergy &&
        pgEnergyKeys &&
        pgEnergyKeys.map(key => [
          <VictoryLine
            key={key}
            name={key}
            data={pgEnergy[key].data}
            barWidth={barWidth}
            style={{
              data: {
                cursor: 'auto',
                strokeWidth: 2,
                stroke: ({ x }) => {
                  const colorObject = Array.isArray(pgEnergy[key].colors)
                    ? pgEnergy[key].colors[x]
                    : pgEnergy[key].colors;
                  return colorObject[
                    usedActiveIndex === null || x === usedActiveIndex ? 'default' : 'disabled'
                  ];
                },
              },
            }}
          />,
          <VictoryScatter
            data={pgEnergy[key].data}
            events={[
              {
                childName: pgEnergyKeys,
                target: 'data',
                eventHandlers: {
                  onClick: (e: SyntheticEvent<HTMLElement>, { datum }) => {
                    e.stopPropagation();

                    // PC 操作時、mouseup で click が発火してしまうための対応
                    if (touchScrollHandler.isMoved) {
                      touchScrollHandler.setMovedState(false);
                      return;
                    }

                    updateActiveIndex(datum.x);

                    if (isLine && pgEnergy && pgEnergyKeys) {
                      const activeBarData = {};
                      pgEnergyKeys.forEach(itemKey => {
                        activeBarData[itemKey] = pgEnergy[itemKey].data.find(
                          data => data.x === datum.x
                        );
                      });
                      handleSelect(activeBarData);
                    }
                  },
                },
              },
            ]}
            size={5}
            style={{
              data: {
                cursor: 'auto',
                fill: ({ x }) => {
                  const colorObject = Array.isArray(pgEnergy[key].colors)
                    ? pgEnergy[key].colors[x]
                    : pgEnergy[key].colors;
                  return colorObject[
                    usedActiveIndex === null || x === usedActiveIndex ? 'default' : 'disabled'
                  ];
                },
              },
            }}
          />,
        ])}
    </VictoryChart>
  );
};

PureStackedBarChart.defaultProps = {
  isNotActive: false,
  isLine: false,
  isMirror: false,
  behaveAsMirror: false,
  hijackScroll: null,
  sharedZoomDomain: null,
  sharedActiveIndex: undefined, // eslint-disable-line no-undefined
};

const sizeMeHOC = sizeMe({ monitorHeight: true });
export default sizeMeHOC(PureStackedBarChart);

export const getInitialZoomDomainForTest = getInitialZoomDomain;
export const PureStackedBarChartForTest = PureStackedBarChart;
