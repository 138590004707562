// @flow

import React from 'react';
import type { ContextRouter } from 'react-router-dom';
import FadeTransition from 'Client/components/utils/FadeTransition';
import Completed from 'Client/components/Completed';

type Props = {
  ...ContextRouter,
};

const CompletedPage = (props: Props) => (
  <FadeTransition pathname={props.location.pathname}>
    <Completed type={props.match.params.type} />
  </FadeTransition>
);

export default CompletedPage;
