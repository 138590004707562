// @flow

import React from 'react';
import type { Node } from 'react';
import { intlShape } from 'react-intl';
import { switchImage } from 'Client/lib/globalUtils';
import Prefixer from 'Client/lib/Prefixer';

const prefixer = new Prefixer();

const bgOff = require('Client/images/today_detail_content_box_bg_off.png');
const bgOnDefault = require('Client/images/today_detail_content_box_bg_on_default.png');
const bgOnUsing = require('Client/images/today_detail_content_box_bg_on_using.png');
const bgOnCharging = require('Client/images/today_detail_content_box_bg_on_charging.png');
const bgOnPg = require('Client/images/today_detail_content_box_bg_on_pg.png');
const search = require('Client/images/icn_search.png');

const style = prefixer.prefix({
  applianceState: {
    position: 'relative',
    margin: '10px 5px 0px 5px',
  },
  notUsing: {
    opacity: '0.6',
  },
  state: {
    width: '85%',
    height: '85%',
    display: 'block',
    margin: 'auto',
  },
  appliance: {
    position: 'absolute',
    left: '20%',
    top: '16%',
    width: '60%',
  },
  usageInMinutes: {
    marginTop: '3px',
    marginBottom: '0px',
    fontSize: '0.7em',
    color: '#FF8F62',
  },
});

/**
 * エネファームと蓄電池用にアイコン背景を出し分ける
 */
const getBGImage = (id): string => {
  const isEneFarm = /^996/.test(id);
  if (isEneFarm) {
    return bgOnPg;
  }

  const isBattery = /^994/.test(id) || /^9972/.test(id);
  if (!isBattery) {
    return bgOnDefault;
  }

  const isDischarged = /discharged$/.test(id);
  return isDischarged ? bgOnUsing : bgOnCharging;
};

const LearningUsing = () => (
  <div>
    <div style={style.applianceState}>
      <img src={bgOnDefault} alt="learning-using" style={style.state} />
      <img src={search} alt="search" style={style.appliance} />
    </div>
    <div style={style.usageInMinutes}>&nbsp;</div>
  </div>
);

const LearningNotUsing = () => (
  <div>
    <div style={{ ...style.applianceState, ...style.notUsing }}>
      <img src={bgOff} alt="learning-not-using" style={style.state} />
      <img src={search} alt="search" style={style.appliance} />
    </div>
    <div style={style.usageInMinutes}>&nbsp;</div>
  </div>
);

const Using = (props: {
  id: string,
  roundedDuration: number,
  serviceProviderId: string,
  intl: intlShape,
}) => {
  const { id, roundedDuration, serviceProviderId, intl } = props;
  const timeUnit =
    roundedDuration === 60
      ? intl.formatMessage({ id: 'TopHourlyApplianceStateItem.hourLabel' })
      : intl.formatMessage({ id: 'TopHourlyApplianceStateItem.minuteLabel' });

  const lessThan10Notion = roundedDuration === 10 ? '〜' : '';

  const displayDuration = roundedDuration === 60 ? 1 : roundedDuration;

  const showDurationByDefault = () =>
    intl.formatMessage({
      id: 'topHourlyApplianceStateItem.showDurationByDefault',
    }) === 'true';

  const durationElement =
    serviceProviderId === '0018' || serviceProviderId === '0044' || showDurationByDefault() ? (
      <div style={style.usageInMinutes}>
        {lessThan10Notion}
        {displayDuration}
        {timeUnit}
      </div>
    ) : (
      <div style={style.usageInMinutes}>&nbsp;</div>
    );

  return (
    <div>
      <div style={style.applianceState}>
        <img src={getBGImage(id)} alt="using" style={style.state} />
        <img src={switchImage(id)} alt="appliance" style={style.appliance} />
      </div>
      {durationElement}
    </div>
  );
};

const NotUsing = ({ id }) => (
  <div>
    <div style={{ ...style.applianceState, ...style.notUsing }}>
      <img src={bgOff} alt="not-using" style={style.state} />
      <img src={switchImage(id)} alt="appliance" style={style.appliance} />
    </div>
    <div style={style.usageInMinutes}>&nbsp;</div>
  </div>
);

type Props = {
  learning: boolean,
  display: boolean,
  appliance: {
    id: string,
    duration: number,
    roundedDuration: number,
  },
  serviceProviderId: string,
  intl: intlShape,
};

const TopHourlyApplianceStateItem = ({
  learning,
  display,
  appliance,
  serviceProviderId,
  intl,
}: Props): Node => {
  if (!display) {
    return null;
  }

  const { id, duration, roundedDuration } = appliance;
  if (learning && duration) {
    return <LearningUsing />;
  } else if (learning) {
    return <LearningNotUsing />;
  } else if (duration > 0) {
    return (
      <Using
        id={id}
        roundedDuration={roundedDuration}
        serviceProviderId={serviceProviderId}
        intl={intl}
      />
    );
  }
  return <NotUsing id={id} />;
};

export default TopHourlyApplianceStateItem;

export const TopHourlyApplianceStateItemForTest = TopHourlyApplianceStateItem;
export const UsingForTest = Using;
