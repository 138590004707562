// @flow

import React from 'react';
import type { Node } from 'react';
import Prefixer from 'Client/lib/Prefixer';

const prefixer = new Prefixer();

const styles = prefixer.prefix({
  card: {
    width: '90%',
    margin: '0px auto 10px',
    padding: '16px 10px',
    background: '#fff',
    color: '#847965',
    fontWeight: 'bold',
    border: '1px solid #e5e5e5',
    borderBottom: '2px solid #e5e5e5',
    borderRadius: '5px',
  },
  cardContent: {
    position: 'relative',
  },
});

type Props = {
  style?: Object,
  children: Node,
};

const Card = (props: Props): Node => (
  <div style={{ ...styles.card, ...props.style }}>
    <div style={styles.cardContent}>{props.children}</div>
  </div>
);

Card.defaultProps = {
  style: {},
};

export default Card;

export const CardForTest = Card;
