// @flow

import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { injectIntl, intlShape } from 'react-intl';
import moment from 'moment-timezone';
import { SELECT_CLICKED_HOUR } from 'Client/actions/types';
import Prefixer from 'Client/lib/Prefixer';
import { loadTopPageLatestTwoHours, needsUpdate } from 'Client/lib/topPageUtils';

const prefixer = new Prefixer();
const style = prefixer.prefix({
  todayWp: {
    display: 'table',
    width: '98%',
    margin: '0px auto',
  },
  timeBar: {
    width: '75%',
    verticalAlign: 'top',
    height: '24px',
    lineHeight: '24px',
    textAlign: 'center',
    display: 'table-cell',
    margin: '0px 0px 0px 0px',
    fontSize: '0.8em',
    padding: '0px',
    cursor: 'pointer',
  },
  selectedUsed: {
    borderBottom: '1px solid #fff',
    background: '#ff6528',
    color: '#fff',
  },
  selectedNotUsed: {
    borderBottom: '1px solid #847965',
    background: '#a1a1a1',
    color: '#fff',
  },
  Used: {
    borderBottom: '1px solid #fff',
    background: '#ffde94',
    color: '#847965',
  },
  notUsed: {
    borderBottom: '1px solid #847965',
    background: '#fff',
    color: '#847965',
  },
  rightMargin: {
    width: '25%',
    display: 'table-cell',
    textAlign: 'left',
    verticalAlign: 'top',
  },
  arrow: {
    width: '50%',
    height: '24px',
    verticalAlign: 'bottom',
  },
  arrowGray: {
    width: '50%',
    height: 0,
    verticalAlign: 'bottom',
    borderTop: '12px solid transparent',
    borderBottom: '12px solid transparent',
    borderLeft: '12px solid #a1a1a1',
    borderRight: '0px solid transparent',
    backgroundSize: 'cover',
    backgroundPosition: '0px 0px',
  },
  arrowOrange: {
    width: '50%',
    height: 0,
    verticalAlign: 'bottom',
    borderTop: '12px solid transparent',
    borderBottom: '12px solid transparent',
    borderLeft: '12px solid #FF6528',
    borderRight: '0px solid transparent',
    backgroundSize: 'cover',
    backgroundPosition: '0px 0px',
  },
  clear: {
    clear: 'both',
  },
});

const itemStyle = (selectedHour, hour, used) => {
  if (hour === selectedHour && used) {
    return style.selectedUsed;
  } else if (hour === selectedHour) {
    return style.selectedNotUsed;
  } else if (used) {
    return style.Used;
  }
  return style.notUsed;
};

const renderArrow = (selectedHour, hour, used) => {
  if (hour === selectedHour && used) {
    return <div style={style.arrowOrange} />;
  } else if (hour === selectedHour) {
    return <div style={style.arrowGray} />;
  }
  return '';
};

type Props = {
  selectedHour: number,
  currentDate: string,
  currentTime: string,
  hour: number,
  used: boolean,
  isPg: boolean,
  dispatch: Dispatch,
  intl: intlShape,
};

export const PureTopTimeItem = ({
  selectedHour,
  currentDate,
  currentTime,
  hour: itemLabelHour,
  used,
  isPg,
  dispatch,
  intl,
}: Props): Node => {
  const itemLabelFormat = intl.formatMessage({ id: 'topTimeItem.hour' });
  const itemLabelHourFormatedData = moment({ hour: itemLabelHour % 100 }).format(itemLabelFormat);
  return (
    <div style={style.todayWp}>
      <div style={style.timeBar}>
        <div
          className="timeItem"
          style={itemStyle(selectedHour, itemLabelHour, used)}
          onClick={() =>
            needsUpdate(currentDate, currentTime)
              ? loadTopPageLatestTwoHours(dispatch, isPg)
              : dispatch({ type: SELECT_CLICKED_HOUR, clickedHour: itemLabelHour })
          }
        >
          {itemLabelHourFormatedData}
        </div>
      </div>
      <div style={style.rightMargin}>{renderArrow(selectedHour, itemLabelHour, used)}</div>
    </div>
  );
};

export default injectIntl(PureTopTimeItem);
