// @flow

import React from 'react';
import type { Node } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { VictoryContainer, VictoryBar } from 'victory';
import sizeMe from 'react-sizeme';
import Currency from 'Client/components/utils/Currency';
import { applianceId } from 'Client/lib/id';
import Prefixer from 'Client/lib/Prefixer';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  ranking: {
    display: 'table',
    width: '100%',
    height: '35px',
    lineHeight: '30px',
  },
  lower: {
    color: '#847965',
    borderBottom: '1px solid #ccc',
    fontSize: '0.8em',
  },
  no1: {
    color: '#ff5354',
    borderBottom: '1px solid #ff5354',
    fontSize: '0.9em',
  },
  no2: {
    color: '#ff9657',
    borderBottom: '1px solid #ff9657',
    fontSize: '0.9em',
  },
  no3: {
    color: '#ffb657',
    borderBottom: '1px solid #ffb657',
    fontSize: '0.9em',
  },
  rank: {
    display: 'table-cell',
    width: '10%',
    verticalAlign: 'middle',
  },
  verySmall: {
    fontSize: '0.7em',
    marginLeft: '2px',
  },
  applianceName: {
    textAlign: 'left',
    padding: '0 2px 0 2px',
    width: '33%',
    display: 'table-cell',
    lineHeight: '1.2em',
    verticalAlign: 'middle',
  },
  chart: {
    height: '20px',
    width: '35%',
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  charge: {
    textAlign: 'right',
    width: '22%',
    display: 'table-cell',
    lineHeight: '1.2em',
    verticalAlign: 'middle',
  },
});

// TopApplianceRankingItemのgetApplianceNameFromId()の方が変更に強くて良い
// 何とかしてgetApplianceNameFromId()をglobalUtilsに移したい
const applianceName = (id, intl) => {
  switch (id) {
    case applianceId.airConditioner:
      return intl.formatMessage({ id: 'chartApplianceRankingItem.airConditioner' });
    case applianceId.highFever:
      return intl.formatMessage({ id: 'chartApplianceRankingItem.highFever' });
    case applianceId.microwave:
      return intl.formatMessage({ id: 'chartApplianceRankingItem.microwave' });
    case applianceId.dishwasher:
      return intl.formatMessage({ id: 'chartApplianceRankingItem.dishwasher' });
    case applianceId.oven:
      return intl.formatMessage({ id: 'chartApplianceRankingItem.oven' });
    case applianceId.riceCooker:
      return intl.formatMessage({ id: 'chartApplianceRankingItem.riceCooker' });
    case applianceId.ih:
      return intl.formatMessage({ id: 'chartApplianceRankingItem.ih' });
    case applianceId.ecoCute:
      return intl.formatMessage({ id: 'chartApplianceRankingItem.ecoCute' });
    case applianceId.clothesWasher:
      return intl.formatMessage({ id: 'chartApplianceRankingItem.clothesWasher' });
    case applianceId.vacuumCleaner:
      return intl.formatMessage({ id: 'chartApplianceRankingItem.vacuumCleaner' });
    case applianceId.tv:
      return intl.formatMessage({ id: 'chartApplianceRankingItem.tv' });
    case applianceId.light:
      return intl.formatMessage({ id: 'chartApplianceRankingItem.light' });
    case applianceId.refrigerator:
      return intl.formatMessage({ id: 'chartApplianceRankingItem.refrigerator' });
    case applianceId.background:
      return intl.formatMessage({ id: 'chartApplianceRankingItem.background' });
    default:
      return '';
  }
};

const renderApplianceCharge = (charge, intl) => {
  const lessThanPrice = intl.formatMessage({ id: 'currency.lessThan' });
  if (charge >= lessThanPrice) {
    return (
      <div>
        <Currency value={charge} />
      </div>
    );
  }
  return <div>{intl.formatMessage({ id: 'chartApplianceRankingItem.lessThan10' })}</div>;
};

const rankColor = rank => {
  switch (rank) {
    case 1:
      return '#ff5354';
    case 2:
      return '#ff9657';
    case 3:
      return '#ffb657';
    default:
      return '#d0cbc2';
  }
};

const renderEnergyChart = (energy, rank, max, width) => (
  <VictoryBar
    horizontal
    containerComponent={<VictoryContainer responsive={false} style={{ touchAction: 'auto' }} />}
    domain={{ x: [0, Math.round(max * 100)] }}
    width={width}
    height={20}
    style={{ data: { fill: rankColor(rank), width: 15 } }}
    padding={{ top: 15, bottom: 5 }}
    data={[{ x: 1, y: Math.round(energy * 100) }]}
  />
);

const rankingStyle = rank => {
  switch (rank) {
    case 1:
      return { ...style.ranking, ...style.no1 };
    case 2:
      return { ...style.ranking, ...style.no2 };
    case 3:
      return { ...style.ranking, ...style.no3 };
    default:
      return { ...style.ranking, ...style.lower };
  }
};

type Props = {|
  appliance: {
    id: string,
    energyValue: number,
  },
  rank: number,
  energyMax: number,
  intl: intlShape,
  size: {
    width: number,
    height: number,
  },
|};

export const PureChartApplianceRankingItem = ({
  appliance,
  rank,
  energyMax,
  intl,
  size,
}: Props): Node => (
  <div style={rankingStyle(rank)}>
    <div style={style.rank}>
      {rank}
      <span style={style.verySmall}>
        {intl.formatMessage({ id: 'chartApplianceRankingItem.place' }).split(',')[rank - 1]}
      </span>
    </div>
    <div style={style.applianceName}>{applianceName(appliance.id, intl)}</div>
    <div style={style.chart}>
      {renderEnergyChart(appliance.energyValue, rank, energyMax, size.width * 0.33)}
    </div>
    <div style={style.charge}>{renderApplianceCharge(appliance.energyValue, intl)}</div>
  </div>
);

const config = { monitorHeight: true };
const sizeMeHOC = sizeMe(config);

export default sizeMeHOC(injectIntl(PureChartApplianceRankingItem));
