// @flow

import React from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import Loading from 'Client/components/utils/Loading';
import Currency from 'Client/components/utils/Currency';
import ChartNodata, { CHART_NO_DATA } from 'Client/components/ChartNoData';
import Prefixer from 'Client/lib/Prefixer';
import { calcPrice } from 'Client/lib/pricePlanUtils';
import type { State, PricePlanState } from 'Client/types/state';
import { getTotalEnergy } from 'Client/lib/globalUtils';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  title: {
    verticalAlign: 'middle',
  },
  criterion: {
    fontSize: '0.7em',
    paddingLeft: '5px',
  },
  charge: {
    margin: '10px auto 0px',
    textAlign: 'center',
    fontSize: '2.0em',
    width: '60%',
    borderBottom: '2px solid #ff9086',
  },
  small: {
    fontSize: '0.8em',
  },
  loadingMessage: {
    margin: '10px auto 0px',
    textAlign: 'center',
    width: '60%',
    color: '#ff9086',
    borderBottom: '2px solid #ff9086',
  },
});

const criterion = intl => intl.formatMessage({ id: 'others.criterion' });
const title = (unit, intl) => {
  switch (unit) {
    case 'day':
      return intl.formatMessage({ id: 'chartCharge.dailyTitle' });
    case 'week':
      return intl.formatMessage({ id: 'chartCharge.weeklyTitle' });
    case 'month':
      return intl.formatMessage({ id: 'chartCharge.monthlyTitle' });
    case 'year':
      return intl.formatMessage({ id: 'chartCharge.yearlyTitle' });
    default:
      return '';
  }
};

const renderBuyCharge = (buyEnergy, intl, pricePlan) => (
  <div style={style.charge}>
    <Currency value={calcPrice(0, 24, pricePlan, buyEnergy)} style={style.small} />
  </div>
);

const renderBody = (requesting, buyEnergy, unit, intl, pricePlan) => {
  if (requesting) {
    return (
      <Loading space={50}>
        <div style={style.loadingMessage}>
          {intl.formatMessage({ id: 'application.dataLoading' })}
        </div>
      </Loading>
    );
  } else if (getTotalEnergy(buyEnergy) > 0) {
    return renderBuyCharge(buyEnergy, intl, pricePlan);
  }
  return <ChartNodata type={CHART_NO_DATA} />;
};

type StateProps = {|
  pricePlan: PricePlanState,
|};
type OwnProps = {|
  requesting: boolean,
  buyEnergy: number[],
  unit: string,
  intl: intlShape,
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

export const PureChartCharge = ({ requesting, buyEnergy, unit, intl, pricePlan }: Props) => (
  <div>
    <div>
      <span style={style.title}>{title(unit, intl)}</span>
      <span style={style.criterion}>{criterion(intl)}</span>
    </div>
    <div>{renderBody(requesting, buyEnergy, unit, intl, pricePlan)}</div>
  </div>
);

const mapStateToProps = (state: State): StateProps => ({
  pricePlan: state.pricePlan,
});

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  injectIntl(PureChartCharge)
);
