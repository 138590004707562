// @flow

import React, { Component } from 'react';
import type { Element } from 'react';
import type { Dispatch } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import type { Location } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import moment from 'moment-timezone';
import { apiCheckLogin, apiGetUserStatus } from 'Client/actions/apis';
import gaSendPageView from 'Client/lib/gaSendPageView';
import { isEmbeded } from 'Client/lib/serviceCheck';
import { ENTER_APPLICATION, CHECK_LOGIN } from 'Client/actions/types';
import { logout, getIdToken } from 'Client/lib/mbaasAuthentication';
import type { State, LoginInfoState, UserInfoState } from 'Client/types/state';

const cannotLoginStatus = ['FAILED', 'NONE', 'API_ERROR'];

const expired = date => {
  if (!date) return false;
  return moment().isAfter(moment(date).add(30, 'days'));
};

const guestWillTransfer = (props, router) => {
  const { loginState, logoutState, initialized } = props.loginInfo;
  const { status: userStatus, infoRequesting, statusRequesting, lastLoginDate } = props.userInfo;
  if (!initialized) {
    props.dispatch({ type: CHECK_LOGIN });
    getIdToken(token => {
      props.dispatch(apiCheckLogin(token));
    });
  } else if (expired(lastLoginDate)) {
    // ログイン継続が30日以上の場合、ログアウト
    logout(props.dispatch);
    if (!isEmbeded) {
      router.history.replace({
        pathname: '/login',
        state: { nextPathname: props.location.pathname },
      });
    } else {
      router.history.replace({
        pathname: '/error/expired',
        state: { nextPathname: props.location.pathname },
      });
    }
  } else if (
    loginState === 'SUCCESS' &&
    userStatus === 'no_data' &&
    !statusRequesting &&
    !infoRequesting
  ) {
    props.dispatch(apiGetUserStatus());
  } else if (cannotLoginStatus.includes(loginState) || logoutState === 'SUCCESS') {
    logout(props.dispatch);
    if (!isEmbeded) {
      router.history.replace({
        pathname: '/login',
        state: { nextPathname: props.location.pathname },
      });
    } else {
      router.history.replace({
        pathname: '/error/expired',
        state: { nextPathname: props.location.pathname },
      });
    }
  }
};

const trackPageview = props => {
  gaSendPageView(
    props.location.pathname,
    props.userInfo.contractorId,
    props.userInfo.serviceProviderId,
    props.userInfo.appUserId,
    props.userInfo.status
  );
};

const initializeStateByLocale = ({ intl, dispatch }) => {
  dispatch({ type: ENTER_APPLICATION, locale: intl.locale });
};

type StateProps = {|
  loginInfo: LoginInfoState,
  userInfo: UserInfoState,
|};
type OwnProps = {|
  location: Location,
  children?: Element<Object>,
  intl: intlShape,
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

class AuthArea extends Component<Props> {
  componentDidMount() {
    initializeStateByLocale(this.props);
    guestWillTransfer(this.props, this.context.router);
    trackPageview(this.props);
  }
  componentDidUpdate(prevProps) {
    guestWillTransfer(this.props, this.context.router);
    if (
      prevProps.location.pathname !== this.props.location.pathname ||
      prevProps.userInfo.contractorId !== this.props.userInfo.contractorId ||
      prevProps.userInfo.appUserId !== this.props.userInfo.appUserId ||
      prevProps.userInfo.status !== this.props.userInfo.status
    ) {
      trackPageview(this.props);
    }
  }
  render() {
    const { loginState } = this.props.loginInfo;
    const { statusRequesting, statusInitialized } = this.props.userInfo;
    return (
      loginState === 'SUCCESS' &&
      !!statusInitialized &&
      !statusRequesting && <div>{this.props.children}</div>
    );
  }
}

AuthArea.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state: State): StateProps => ({
  loginInfo: state.loginInfo,
  userInfo: state.userInfo,
});

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  injectIntl(AuthArea)
);
