// @flow

import React, { useState } from 'react';
import type { Node } from 'react';
import StackedBarChart from 'Client/components/StackedBarChart';
import type { ChartData, StackedData } from 'Client/types/types';

type Domain = { x: number[] };
type UpdateSharedZoomDomain = (sharedZoomDomain: Domain) => void;

type Props = {
  startHour: number,
  endHour: number,
  initialHour: number,
  axisUnit: string,
  horizonAxisUnit: string,
  unit: string,
  stackedData: StackedData,
  stackedMirrorData: StackedData,
  pgEnergy: StackedData,
  maximumValue: number,
  handleSelect: (
    barData: null | {
      regular: ChartData,
      mirror: ChartData,
      line?: ChartData,
    }
  ) => void,
};

const PureStackedMirrorBarChart = (props: Props): Node => {
  const {
    startHour,
    endHour,
    initialHour,
    axisUnit,
    horizonAxisUnit,
    unit,
    stackedData,
    stackedMirrorData,
    pgEnergy,
    maximumValue,
    handleSelect,
  } = props;

  const [sharedActiveIndex, updateSharedActiveIndex] = useState(null);
  (sharedActiveIndex: null | string | number);
  (updateSharedActiveIndex: (index: null | number) => void);

  const [sharedZoomDomain, updateSharedZoomDomain] = useState(null);
  (sharedZoomDomain: null | Domain);
  (updateSharedZoomDomain: UpdateSharedZoomDomain);

  const isLine = pgEnergy.data.length > 0;

  const commonProps = {
    startHour,
    endHour,
    initialHour,
    axisUnit,
    horizonAxisUnit,
    maximumValue,
    isMirror: true,
    handleSelect: (
      barData: null | {
        [key: string]: ChartData,
      }
    ) => {
      const selectedKey = barData ? Object.keys(barData)[0] : null;
      if (!selectedKey) {
        updateSharedActiveIndex(null);
        handleSelect(null);
        return;
      }

      const castedBarData = ((barData: any): {
        [key: string]: ChartData,
      });

      updateSharedActiveIndex(castedBarData[selectedKey].x);

      const coupleKey = selectedKey === 'regular' ? 'mirror' : 'regular';
      const coupleStackedData = selectedKey === 'regular' ? stackedMirrorData : stackedData;
      const coupleBarData = coupleStackedData.data.find(
        data => data.x === castedBarData[selectedKey].x
      );
      if (isLine) {
        if (selectedKey !== 'line') {
          const pgEnergyData = pgEnergy.data.find(data => data.x === castedBarData[selectedKey].x);
          handleSelect({
            [selectedKey]: castedBarData[selectedKey],
            [coupleKey]: ((coupleBarData: any): ChartData), // stackedData と stackedMirrorData の配列長は同じとし、必ず見つかるためキャストする
            line: ((pgEnergyData
              ? (pgEnergyData: any)
              : { x: castedBarData[selectedKey].x, y: 0 }): ChartData), // pgEnergyData は stackedData と stackedMirrorData の配列長より短い
          });
        } else {
          const mirrorData = stackedMirrorData.data.find(
            data => data.x === castedBarData[selectedKey].x
          );
          handleSelect({
            [coupleKey]: ((coupleBarData: any): ChartData), // pgEnergyData は stackedData と stackedMirrorData の配列長より短いとし、必ず見つかるためキャストする
            mirror: ((mirrorData: any): ChartData), // pgEnergyData は stackedData と stackedMirrorData の配列長より短いとし、必ず見つかるためキャストする
            [selectedKey]: castedBarData[selectedKey], // pgEnergyData は stackedData と stackedMirrorData の配列長より短い
          });
        }
      } else {
        handleSelect({
          [selectedKey]: castedBarData[selectedKey],
          [coupleKey]: ((coupleBarData: any): ChartData), // stackedData と stackedMirrorData の配列長は同じとし、必ず見つかるためキャストする
        });
      }
    },
    hijackScroll: (zoomDomain: Domain) => {
      updateSharedZoomDomain(zoomDomain);
    },
    sharedZoomDomain,
    sharedActiveIndex,
  };

  return (
    <div>
      <StackedBarChart
        {...commonProps}
        stackedData={{ regular: stackedData }}
        pgEnergy={{ line: pgEnergy }}
        unit={unit}
        isLine={isLine}
      />
      <StackedBarChart
        {...commonProps}
        stackedData={{ mirror: stackedMirrorData }}
        behaveAsMirror
      />
    </div>
  );
};

export default PureStackedMirrorBarChart;

export const PureStackedMirrorBarChartForTest = PureStackedMirrorBarChart;
