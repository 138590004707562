// @flow

import React from 'react';
import type { Node } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import Currency from 'Client/components/utils/Currency';
import Prefixer from 'Client/lib/Prefixer';
import { switchImage } from 'Client/lib/globalUtils';
import { applianceId as applianceIdArray } from 'Client/lib/id';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  container: {
    width: '85%',
    height: '2.6rem',
    display: 'table',
    paddingRight: '5px',
    paddingLeft: '5px',
    marginTop: '5px',
    marginBottom: '10px',
  },
  rank: {
    width: '18%',
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  picture: {
    width: '25%',
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  icon: {
    width: '40px',
    verticalAlign: 'middle',
  },
  detail: {
    width: '55%',
    display: 'table-cell',
    verticalAlign: 'middle',
    textAlign: 'right',
  },
  first: {
    color: '#ff5e5e',
    borderBottom: '1px solid #ff5e5e',
  },
  second: {
    color: '#ffa162',
    borderBottom: '1px solid #ffa162',
  },
  third: {
    color: '#ffbe62',
    borderBottom: '1px solid #ffbe62',
  },
  fontSmall: {
    fontSize: '1.0em',
    fontWeight: 'bold',
  },
  fontBold: {
    fontSize: '1.6em',
    fontWeight: 'bolder',
    height: '1.2em',
  },
});

const getContainerStyle = rank => {
  if (rank === 1) {
    return { ...style.container, ...style.first };
  }
  if (rank === 2) {
    return { ...style.container, ...style.second };
  }
  if (rank === 3) {
    return { ...style.container, ...style.third };
  }

  return style.container;
};

const applianceNameMemo = [];
export const getApplianceNameFromId = (idString: string, intl: intlShape) => {
  const id = Number(idString);
  if (applianceNameMemo[id]) {
    return applianceNameMemo[id];
  }

  const foundItem = Object.entries(applianceIdArray).find(arrayItem => {
    const [applianceName, applianceIndex] = arrayItem;
    if (String(id) === applianceIndex) {
      return applianceName;
    }
    return false;
  });

  if (foundItem) {
    const name = foundItem[0];
    applianceNameMemo[id] = intl.formatMessage({ id: `topApplianceRankingItem.${name}` });
  } else {
    applianceNameMemo[id] = ' - ';
  }

  return applianceNameMemo[id];
};

type Props = {
  rankItem: {
    id: string,
    energyValue: number,
  },
  rank: number,
  intl: intlShape,
};

export const PureTopApplianceRankingItem = ({ rankItem, rank, intl }: Props): Node => (
  <div style={getContainerStyle(rank)} key={`appliance-ranking-${rank}`}>
    <div style={style.rank}>
      <span style={style.fontBold}>{rank}</span>
      <span style={style.fontSmall}>
        {intl.formatMessage({ id: 'topApplianceRankingItem.place' }).split(',')[rank - 1]}
      </span>
    </div>
    <div style={style.picture}>
      {rankItem.id ? (
        <img style={style.icon} src={switchImage(rankItem.id)} alt={rankItem.id} />
      ) : (
        ''
      )}
    </div>
    <div style={style.detail}>
      {rankItem.id ? (
        <div style={style.fontSmall}>{getApplianceNameFromId(rankItem.id, intl)}</div>
      ) : (
        ''
      )}
      {rankItem.id ? <Currency value={rankItem.energyValue} style={style.fontBold} /> : ''}
    </div>
  </div>
);

export default injectIntl(PureTopApplianceRankingItem);
