// @flow

import type { Action } from 'redux';

import {
  REQUEST_API_USER_INFO,
  SUCCESS_API_USER_INFO,
  FAILURE_API_USER_INFO,
  REQUEST_API_USER_STATUS,
  SUCCESS_API_USER_STATUS,
  FAILURE_API_USER_STATUS,
  FORCIBLY_TRANSITION_TO_LOGIN,
  SUCCESS_API_LOGIN,
  SUCCESS_API_LOGIN_INFO,
  SUCCESS_API_LOGOUT,
  REQUEST_API_LOGIN,
  REQUEST_API_LOGIN_INFO,
  UPDATE_ID_TOKEN,
} from 'Client/actions/types';
import type { UserInfoState as State } from 'Client/types/state';

const initialState = {
  statusInitialized: false,
  infoRequesting: false,
  statusRequesting: false,
  contractorId: '', // the first 4 characters are the provider ID
  appUserId: '',
  serviceProviderId: '',
  macAddress: '',
  accountStartDate: '',
  learningStartDate: '',
  status: 'no_data', // contract_expired, imt4_app_unavailable, pre_installation, null_root_power, learning, imperfect_learning, normal
  generatingEnergy: false,
  isZeh: false,
  lastLoginDate: null,
  contractEndDate: '',
  idTokenState: '',
  refreshTokenState: '',
};

export default (state: State = initialState, action: Action = {}): State => {
  switch (action.type) {
    case REQUEST_API_LOGIN:
    case REQUEST_API_LOGIN_INFO: {
      const { idToken = '', refreshToken = '' } = action.meta || {};
      return {
        ...state,
        idTokenState: idToken || state.idTokenState,
        refreshTokenState: refreshToken || state.refreshTokenState,
      };
    }
    case REQUEST_API_USER_INFO: {
      const infoRequesting = !action.error;
      return {
        ...state,
        infoRequesting,
      };
    }
    case SUCCESS_API_USER_INFO: {
      const { user = {} } = action.payload || {};
      return {
        ...state,
        infoRequesting: false,
        contractorId: user.contractor_id,
        macAddress: user.mac_address,
      };
    }
    case FAILURE_API_USER_INFO: {
      return {
        ...state,
        infoRequesting: false,
      };
    }
    case REQUEST_API_USER_STATUS: {
      const statusRequesting = !action.error;
      return {
        ...state,
        statusInitialized: true,
        statusRequesting,
      };
    }
    case SUCCESS_API_USER_STATUS: {
      const { user = {} } = action.payload || {};
      return {
        ...state,
        statusInitialized: true,
        statusRequesting: false,
        accountStartDate: user.account_start_date,
        learningStartDate: user.learning_start_date,
        status: user.contractor_state,
        generatingEnergy: user.power_generator_flag,
        isZeh: user.zero_energy_house_flag,
        contractEndDate: user.contract_end_date,
      };
    }
    case FAILURE_API_USER_STATUS: {
      return {
        ...state,
        status: 'error',
        statusInitialized: true,
        statusRequesting: false,
      };
    }
    case FORCIBLY_TRANSITION_TO_LOGIN: {
      return initialState;
    }
    case SUCCESS_API_LOGIN: {
      const { lastLoginDate } = action.payload || {};
      return {
        ...state,
        lastLoginDate,
      };
    }
    case SUCCESS_API_LOGIN_INFO: {
      const { user = {}, lastLoginDate } = action.payload || {};
      return {
        ...state,
        contractorId: user.contractorId,
        appUserId: user.appUserId,
        serviceProviderId: user.serviceProviderId,
        lastLoginDate,
      };
    }
    case UPDATE_ID_TOKEN: {
      return {
        ...state,
        idTokenState: action.payload,
      };
    }
    case SUCCESS_API_LOGOUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
