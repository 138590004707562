// @flow

import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import Loading from 'Client/components/utils/Loading';
import { createSelector } from 'reselect';
import ChartApplianceRankingItem from 'Client/components/ChartApplianceRankingItem';
import ChartNodata, {
  CHART_NO_DATA,
  CHART_IMPERFECT_LEARNING,
  CHART_LEARNING,
} from 'Client/components/ChartNoData';
import QuestionIcon from 'Client/components/utils/QuestionIcon';
import { HELP_CHART_RANKING } from 'Client/actions/helpPopupTypes';
import { OPEN_POP_UP } from 'Client/actions/types';
import Prefixer from 'Client/lib/Prefixer';
import { sortRanking, getTotalEnergy } from 'Client/lib/globalUtils';
import { rankingFormat } from 'Client/lib/localizedValues';
import { timeUnitStyle } from 'Client/lib/chartUtils';
import type { State, PricePlanState } from 'Client/types/state';
import NotEstimable from 'Client/components/NotEstimable';
import { applianceId } from 'Client/lib/id';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  wrapper: {
    position: 'relative',
  },
  ranking: {
    width: '97%',
    margin: '10px auto 0px',
  },
  info: {
    top: '-12px',
    right: '-5px',
    zIndex: '2',
    position: 'absolute',
  },
});

const renderRanking = (
  applianceEnergy: {
    id: string,
    energy: number[],
  }[],
  pricePlan: {
    pricePlan: string,
    normalPlanPrice: number,
    multistagePlanSetting: Object[],
  }
) => (
  <div style={style.ranking}>
    {sortRanking(applianceEnergy, pricePlan).map((appliance, index) => (
      <ChartApplianceRankingItem
        key={appliance.id}
        appliance={appliance}
        rank={index + 1}
        energyMax={sortRanking(applianceEnergy, pricePlan)[0].energyValue}
      />
    ))}
  </div>
);

const energyTotal = energy => getTotalEnergy(energy.map(target => getTotalEnergy(target)));

const renderBody = (requesting, applianceEnergy, userStatus, intl, pricePlan) => {
  if (userStatus === 'imperfect_learning') {
    return <ChartNodata type={CHART_IMPERFECT_LEARNING} />;
  } else if (userStatus === 'learning') {
    return <ChartNodata type={CHART_LEARNING} />;
  } else if (requesting) {
    return (
      <Loading space={80}>
        <div>{intl.formatMessage({ id: 'application.dataLoading' })}</div>
      </Loading>
    );
  } else if (userStatus === 'not_estimable') {
    const filtered = applianceEnergy.filter(target => target.id !== applianceId.light);
    if (energyTotal(filtered.map(target => target.energy)) > 0) {
      return renderRanking(filtered, pricePlan);
    }
    return null;
  } else if (energyTotal(applianceEnergy.map(target => target.energy)) > 0) {
    return renderRanking(applianceEnergy, pricePlan);
  }
  return <ChartNodata type={CHART_NO_DATA} />;
};

type StateProps = {|
  applianceEnergy: {
    id: string,
    energy: number[],
  }[],
  userStatus: string,
  pricePlan: PricePlanState,
|};
type OwnProps = {|
  requesting: boolean,
  intl: intlShape,
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

export const PureChartApplianceRanking = ({
  requesting,
  applianceEnergy,
  userStatus,
  intl,
  dispatch,
  pricePlan,
}: Props): Node => (
  <div style={style.wrapper}>
    <div style={style.info}>
      <QuestionIcon
        onClick={e => {
          dispatch({
            type: OPEN_POP_UP,
            contentType: HELP_CHART_RANKING,
            target: e.target,
          });
        }}
      />
    </div>
    {intl.formatMessage({ id: 'chartApplianceRanking.title' })}
    {renderBody(requesting, applianceEnergy, userStatus, intl, pricePlan)}
    <NotEstimable type="ranking" style={{ marginTop: '10px' }} />
  </div>
);

const filteredAppliances = createSelector(
  [
    state => state.applianceState.idsToShowForRanking,
    state =>
      state.chartTime.unit !== 'day'
        ? state.applianceEnergy[timeUnitStyle[state.chartTime.unit].toUnit].find(
            ele => state.chartTime.period === ele.dateTime
          )
        : false,
    state => state.applianceEnergy.locale,
  ],
  (idsToShowForRanking, applianceEnergy) => {
    if (!applianceEnergy) {
      return [];
    }
    return rankingFormat.ja
      .map(rankingAppliance => {
        const matchedAppliance = applianceEnergy.applianceEnergy.find(
          appliance => appliance.id === rankingAppliance.id
        );
        return {
          ...rankingAppliance,
          energy: matchedAppliance ? matchedAppliance.energy : 0,
        };
      })
      .filter(item => idsToShowForRanking.includes(item.id));
  }
);

const mapStateToProps = (state: State): StateProps => ({
  applianceEnergy: filteredAppliances(state),
  userStatus: state.userInfo.status,
  pricePlan: state.pricePlan,
});

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  injectIntl(PureChartApplianceRanking)
);
