// @flow

import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape, defineMessages } from 'react-intl';
import { Link } from 'react-router-dom';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import Prefixer from 'Client/lib/Prefixer';
import HeaderBar from 'Client/components/HeaderBar';
import SettingUserPopup from 'Client/components/SettingUserPopup';
import SettingAppliancePopup from 'Client/components/SettingAppliancePopup';
import ContentContainer from 'Client/components/utils/ContentContainer';
import { logout } from 'Client/lib/mbaasAuthentication';
import {
  CHANGE_SELL_PRICE,
  SWITCH_PG_FLAG,
  OPEN_POP_UP,
  OPEN_PRICE_PLAN_POP_UP,
  RESET_TUTORIAL,
} from 'Client/actions/types';
import { toSetUpPriceValue } from 'Client/lib/globalUtils';
import { energyPriceRange } from 'Client/lib/localizedValues';
import type { State, UserSettingState, PopupState, PricePlanState } from 'Client/types/state';

const prefixer = new Prefixer();
const companyLogo = require('Client/images/informetis_logo.png');

const style = prefixer.prefix({
  content: {
    padding: '55px 0 100px',
    textAlign: 'center',
    color: '#847965',
  },
  listItem: {
    padding: '0 10px 0 10px',
    margin: '0 0 0 0',
    borderTop: 'none',
    borderBottom: '1px solid #ddd',
    color: '#1f1f21',
    minHeight: '44px',
    lineHeight: '44px',
  },
  selectPlanItem: {
    padding: '0 10px 0 10px',
    margin: '0 0 0 0',
    borderTop: 'none',
    borderBottom: '1px solid #ddd',
    color: '#1f1f21',
    minHeight: '70px',
    lineHeight: '44px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  itemKey: {
    float: 'left',
  },
  pricePlanItemKey: {
    float: 'left',
    display: 'flex',
    flexDirection: 'column',
  },
  itemValue: {
    textAlign: 'right',
  },
  button: {
    backgroundColor: '#ffbd00',
    color: '#fff',
    width: '50px',
    height: '30px',
    borderRadius: '3px',
  },
  buttonItem: {
    float: 'right',
    fontSize: '0.9em',
    lineHeight: '30px',
    margin: '7px 0px',
    cursor: 'pointer',
  },
  buttonLogout: {
    width: '150px',
    height: '40px',
    fontSize: '1.2em',
    lineHeight: '39px',
    margin: '0 auto 0',
    borderRadius: '5px',
  },
  input: {
    width: '45px',
    border: '1px solid #ccc',
    textAlign: 'right',
    borderRadius: '4px',
    height: '25px',
    padding: '2px 8px 2px 0px',
    fontSize: '15px',
  },
  toggle: {
    float: 'right',
    height: '30px',
    margin: '5px 0px 10px',
  },
  logout: {
    margin: '35px auto 35px',
  },
  companyLogo: {
    textAlign: 'center',
    margin: '0px auto',
    display: 'block',
    padding: '10px 0px',
    bottom: '0',
    left: '0',
    width: '100%',
  },
  companyImage: {
    width: '120px',
  },
  priceError: {
    color: 'red',
    fontSize: '0.8em',
  },
  selectPlanKey: {
    height: '40%',
    maxHeight: '40px',
    minHeight: '40px',
    float: 'left',
    display: 'flex',
  },
  selectedPlanName: {
    height: '40%',
    maxHeight: '45px',
    minHeight: '35px',
    float: 'left',
    display: 'flex',
  },
  selectPlanbtn: {
    alignSelf: 'center',
    minHeight: '30px',
    minWidth: '50px',
  },
  SelectedPricePlan: {
    display: 'flex',
    minHeight: '50%',
  },
  SelectedPricePlanItem: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '45px',
    minwidth: '60%',
  },
  placePlanImage: {
    marginLeft: '5px',
    maxWidth: '25px',
    height: '25px',
    alignSelf: 'flex-start',
  },
  settingPlanPrice: {
    margin: '0px 0px 0px 9px',
    lineHeight: '13px',
    height: '13px',
    float: 'left',
    fontSize: '0.8em',
    display: 'flex',
  },
  multistagePlan: {
    fontSize: '0.9em',
    height: '25px',
    lineHeight: '25px',
  },
  multistagePlanItem: {
    flexDirection: 'row',
  },
});

const messages = defineMessages({
  normalPlanPrice: {
    id: 'settingPage.normalPlanPrice',
    description: '通常プラン設定',
  },
  multistagePlanPrice: {
    id: 'settingPage.multistagePlanPrice',
    description: '深夜料金プラン設定',
  },
});

const price_plan_normal = require('Client/images/24h.svg');
const price_plan_multistage = require('Client/images/night.svg');

declare var config: { URLS: Object[] };
const getUrl = ({ type, intl }: { type: string, intl: intlShape }): string => {
  const { FAQ: FAQ_FOR_IMT } = config.URLS[intl.locale.toUpperCase()] || {};

  switch (type) {
    case 'faq': {
      return FAQ_FOR_IMT;
    }
    default: {
      return '';
    }
  }
};

const renderStaticItem = (key, value, isDisplay) => (
  <div className={isDisplay ? '' : 'settingDisplay'} style={style.listItem}>
    <div style={style.itemKey}>{key}</div>
    <div style={style.itemValue}>{value}</div>
  </div>
);

const renderExternalLink = ({
  key,
  value,
  href,
}: {
  key: string,
  value: string,
  href: string,
}): Node => (
  <div className="settingDisplay" style={style.listItem}>
    <div style={style.itemKey}>{key}</div>
    <div>
      <a href={href} target="_blank" rel="noopener noreferrer">
        <div style={{ ...style.button, ...style.buttonItem }}>{value}</div>
      </a>
    </div>
  </div>
);

const renderPopupItem = (key, contentType, dispatch, intl) => (
  <div className="settingDisplay" style={style.listItem}>
    <div style={style.itemKey}>{key}</div>
    <div
      className={`${contentType}Button`}
      style={{ ...style.button, ...style.buttonItem }}
      onClick={() => dispatch({ type: OPEN_POP_UP, contentType })}
    >
      {intl.formatMessage({ id: 'settingPage.change' })}
    </div>
  </div>
);

const renderSelectedPricePlanItem = (pricePlan, intl) => {
  if (pricePlan.pricePlan === 'NORMAL') {
    return (
      <div style={style.SelectedPricePlan}>
        <img style={style.placePlanImage} src={price_plan_normal} alt="price_plan_normal" />
        <div style={style.SelectedPricePlanItem}>
          <div style={style.settingPlanPrice}>
            {intl.formatMessage({ id: 'settingPage.nomalPlan' })}
          </div>
          <div style={style.settingPlanPrice}>
            {intl.formatMessage(messages.normalPlanPrice, { planPrice: pricePlan.normalPlanPrice })}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div style={style.SelectedPricePlan}>
      <img style={style.placePlanImage} src={price_plan_multistage} alt="price_plan_multistage" />
      <div style={{ ...style.SelectedPricePlanItem, ...style.multistagePlanItem }}>
        <div style={{ ...style.settingPlanPrice, ...style.multistagePlan }}>
          {intl.formatMessage({ id: 'settingPage.multistagePlan' })}
        </div>
      </div>
    </div>
  );
};

const renderPricePlanPopupItem = (key, contentType, dispatch, intl, pricePlan) => (
  <div style={style.selectPlanItem}>
    <div style={style.pricePlanItemKey}>
      <div style={style.selectPlanKey}>{key}</div>
      <div style={style.selectedPlanName}>{renderSelectedPricePlanItem(pricePlan, intl)}</div>
    </div>
    <div
      className={`${contentType}Button`}
      style={{ ...style.button, ...style.buttonItem, ...style.selectPlanbtn }}
      onClick={() => dispatch({ type: OPEN_PRICE_PLAN_POP_UP, contentType })}
    >
      {intl.formatMessage({ id: 'settingPage.change' })}
    </div>
  </div>
);

const renderPriceItem = (key, price, dispPrice, actionType, dispatch, intl) => {
  const type = intl.formatMessage({ id: 'settingPage.priceAlertType' });
  const pattern = intl.formatMessage({ id: 'settingPage.priceAlertPattern' });
  const minLength = intl.formatMessage({ id: 'settingPage.priceAlertMinLength' });
  const maxLength = intl.formatMessage({ id: 'settingPage.priceAlertMaxLength' });
  const minValue = energyPriceRange.ja.min;
  const maxValue = energyPriceRange.ja.max;
  const isFloat = value => Math.round(value) !== value;
  const isErr = value => value < minValue || value > maxValue || (type === 'int' && isFloat(value));
  const inputError = isErr(dispPrice) ? (
    <div style={style.priceError}>{intl.formatMessage({ id: 'settingPage.priceAlert' })}</div>
  ) : (
    ''
  );
  return (
    <div style={style.listItem}>
      <div style={style.itemKey}>{key}</div>
      <div style={style.itemValue}>
        <input
          type="text"
          name="price"
          style={style.input}
          pattern={pattern}
          maxLength={maxLength}
          minLength={minLength}
          value={dispPrice}
          onChange={e => {
            const inputElement = e.target;
            toSetUpPriceValue(inputElement, type, actionType, dispatch);
          }}
        />
        {intl.formatMessage({ id: 'settingPage.yen' })}
      </div>
      {inputError}
    </div>
  );
};

const renderPgSwitch = (isPg, dispatch, intl) => (
  <div style={style.listItem}>
    <div style={style.itemKey}>{intl.formatMessage({ id: 'settingPage.pgSwitch' })}</div>
    <div style={style.toggle}>
      <Toggle
        className="toggleBGC"
        icons={false}
        checked={isPg}
        onChange={e =>
          dispatch({
            type: SWITCH_PG_FLAG,
            isPg: e.target.checked,
          })
        }
      />
    </div>
  </div>
);

const renderEmailChange = (intl: intlShape): Node => (
  <div id="emailChangeSetting" className="settingDisplay" style={style.listItem}>
    <div style={style.itemKey}>{intl.formatMessage({ id: 'settingPage.emailAddress' })}</div>
    <Link to="/change/email">
      <div style={{ ...style.button, ...style.buttonItem }}>
        {intl.formatMessage({ id: 'settingPage.change' })}
      </div>
    </Link>
  </div>
);

const renderPasswordChange = (intl: intlShape): Node => (
  <div id="passwordChangeSetting" className="settingDisplay" style={style.listItem}>
    <div style={style.itemKey}>{intl.formatMessage({ id: 'settingPage.password' })}</div>
    <Link to="/change/password">
      <div style={{ ...style.button, ...style.buttonItem }}>
        {intl.formatMessage({ id: 'settingPage.change' })}
      </div>
    </Link>
  </div>
);

const renderStartTutorial = (dispatch, intl) => (
  <div className="settingDisplay" style={style.listItem}>
    <div style={style.itemKey}>{intl.formatMessage({ id: 'settingPage.tutorial' })}</div>
    <Link to="/tutorial" className="TutorialPage">
      <div
        style={{ ...style.button, ...style.buttonItem }}
        onClick={() => dispatch({ type: RESET_TUTORIAL })}
      >
        {intl.formatMessage({ id: 'settingPage.display' })}
      </div>
    </Link>
  </div>
);

const renderLogout = (dispatch, intl) => (
  <div id="logoutButton" className="settingDisplay" style={style.logout}>
    <div style={{ ...style.button, ...style.buttonLogout }} onClick={() => logout(dispatch)}>
      {intl.formatMessage({ id: 'settingPage.logout' })}
    </div>
  </div>
);

const renderCompanyLogo = () => (
  <div id="companyLogo" style={style.companyLogo}>
    <img src={companyLogo} alt="companyLogo" style={style.companyImage} />
  </div>
);

const renderPopup = contentType => {
  if (contentType === 'aplsDisplay') {
    return <SettingAppliancePopup />;
  }
  const userPopupTypes = ['emailAddress', 'password'];
  return userPopupTypes.includes(contentType) ? <SettingUserPopup /> : '';
};

type StateProps = {|
  userSetting: UserSettingState,
  contractorId: string,
  macAddress: string,
  accountStartDate: string,
  userStatus: string,
  popup: PopupState,
  pricePlan: PricePlanState,
|};
type OwnProps = {|
  intl: intlShape,
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

export const PureSettingPage = ({
  userSetting,
  contractorId,
  macAddress,
  accountStartDate,
  userStatus,
  popup,
  pricePlan,
  dispatch,
  intl,
}: Props): Node => {
  const [serviceProviderId, house] = contractorId.split('_');
  return (
    <div>
      <HeaderBar pageType="setting" />
      {popup.open && renderPopup(popup.contentType)}
      <ContentContainer style={style.content}>
        {renderStaticItem(
          intl.formatMessage({ id: 'settingPage.serviceProviderId' }),
          serviceProviderId
        )}
        {renderStaticItem(intl.formatMessage({ id: 'settingPage.house' }), house)}
        {renderStaticItem(intl.formatMessage({ id: 'settingPage.macAddress' }), macAddress)}
        {renderStaticItem(
          intl.formatMessage({ id: 'settingPage.accountStartDate' }),
          accountStartDate
        )}
        {renderEmailChange(intl)}
        {renderPasswordChange(intl)}
        {userStatus === 'normal'
          ? renderPopupItem(
              intl.formatMessage({ id: 'settingPage.aplsDisplay' }),
              'aplsDisplay',
              dispatch,
              intl
            )
          : ''}
        {intl.formatMessage({ id: 'settingPage.showPricePlan' }) === 'true' &&
          renderPricePlanPopupItem(
            intl.formatMessage({ id: 'settingPage.pricePlan' }),
            'pricePlan',
            dispatch,
            intl,
            pricePlan
          )}
        {renderPgSwitch(userSetting.isPg, dispatch, intl)}
        {userSetting.isPg &&
          renderPriceItem(
            intl.formatMessage({ id: 'settingPage.sellPrice' }),
            userSetting.sellPrice,
            userSetting.dispSellPrice,
            CHANGE_SELL_PRICE,
            dispatch,
            intl
          )}
        {renderStartTutorial(dispatch, intl)}
        {renderExternalLink({
          key: intl.formatMessage({ id: 'settingPage.faq' }),
          value: intl.formatMessage({ id: 'settingPage.display' }),
          href: getUrl({ type: 'faq', intl }),
        })}
        {renderLogout(dispatch, intl)}
        {renderCompanyLogo()}
      </ContentContainer>
    </div>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  userSetting: state.userSetting,
  contractorId: state.userInfo.contractorId,
  macAddress: state.userInfo.macAddress,
  accountStartDate: state.userInfo.accountStartDate,
  userStatus: state.userInfo.status,
  popup: state.popup,
  pricePlan: state.pricePlan,
});

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  injectIntl(PureSettingPage)
);
