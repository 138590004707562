// @flow

import React, { Component } from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { CLOSING_POP_UP, OPEN_PRICE_PLAN_POP_UP } from 'Client/actions/types';
import Prefixer from 'Client/lib/Prefixer';
import SettingPricePlanStart from 'Client/components/SettingPricePlanStart';
import SettingPricePlanSelect from 'Client/components/SettingPricePlanSelect';
import SettingPricePlanNormal from 'Client/components/SettingPricePlanNormal';
import SettingPricePlanMultistage from 'Client/components/SettingPricePlanMultistage';
import type { State, PopupState } from 'Client/types/state';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  entireFixed: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: '100100',
    fontSize: 'initial',
    display: 'flex',
  },
  background: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    height: '100vh',
    background: 'rgba(0,0,0,0.3)',
    margin: '0px auto',
  },
  boxVerticalPosition: {
    width: 310,
    maxWidth: '80%',
    maxHeight: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  box: {
    maxWidth: '100%',
    maxHeight: '100%',
    backgroundColor: '#fff',
    textAlign: 'center',
    verticalAlign: 'center',
    lineHeight: '150%',
    borderRadius: 5,
    color: '#000',
    position: 'relative',
    margin: '0px auto',
  },
  nomalTitle: {
    marginTop: '15px',
    fontSize: '1.2em',
    fontWeight: '500',
    color: '#FFBD00',
    paddingBottom: '15px',
    borderBottom: 'thin solid #DDDDDD',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: '15%',
    marginRight: '5px',
  },
  multistageTitle: {
    marginTop: '15px',
    fontSize: '1.2em',
    fontWeight: '500',
    color: '#58acec',
    paddingBottom: '15px',
    borderBottom: 'thin solid #DDDDDD',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const renderBody = (page, dispatch, intl) => {
  if (page === 'START') {
    return <SettingPricePlanStart dispatch={dispatch} intl={intl} />;
  } else if (page === 'SELECT') {
    return <SettingPricePlanSelect dispatch={dispatch} intl={intl} />;
  } else if (page === 'NORMAL') {
    return <SettingPricePlanNormal dispatch={dispatch} intl={intl} />;
  }
  return <SettingPricePlanMultistage dispatch={dispatch} intl={intl} />;
};

type StateProps = {|
  popup: PopupState,
  page: string,
|};
type OwnProps = {|
  intl: intlShape,
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

export class PureSettingPricePlanPopup extends Component<Props> {
  componentDidUpdate(prevProps: Object = {}) {
    const { open, haveShownSettingPricePlanDialog, haveShownStartDialog } = this.props.popup;
    const { contentType } = prevProps.popup;
    const pricePlanPopupDuration = 300;
    if (haveShownStartDialog && !haveShownSettingPricePlanDialog && !open && contentType === '') {
      setTimeout(() => {
        this.props.dispatch({ type: OPEN_PRICE_PLAN_POP_UP, contentType: 'pricePlan' });
      }, pricePlanPopupDuration);
    }
  }
  render() {
    const { popup, page, intl, dispatch } = this.props;
    const { open, contentType } = popup;
    if (!open || contentType !== 'pricePlan') {
      return null;
    }
    const icon_normal = require(`Client/images/24h.svg`);
    const icon_multistage = require(`Client/images/night.svg`);
    return (
      <div>
        <div style={style.entireFixed}>
          <div style={style.background} onClick={() => dispatch({ type: CLOSING_POP_UP })}>
            <div style={style.boxVerticalPosition}>
              <div
                style={style.box}
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                {page === 'NORMAL' && (
                  <div style={{ ...style.nomalTitle }}>
                    <img style={style.icon} src={icon_normal} alt="icon_normal" />
                    {intl.formatMessage({ id: 'settingPricePlanPopup.nomalPricePlanName' })}
                  </div>
                )}
                {page === 'MULTISTAGE' && (
                  <div style={{ ...style.multistageTitle }}>
                    <img style={style.icon} src={icon_multistage} alt="icon_multistage" />
                    {intl.formatMessage({ id: 'settingPricePlanPopup.multistagePricePlanName' })}
                  </div>
                )}
                {renderBody(page, dispatch, intl)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: State): StateProps => ({
  popup: state.popup,
  page: state.pricePlan.page,
});

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  injectIntl(PureSettingPricePlanPopup)
);
