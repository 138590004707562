// @flow

/**
 * 設定同期用オブジェクト
 * key: トリガーとなる家電ID
 * value: key の家電設定と同期させる家電ID配列
 */
export const syncData: { [key: string]: string[] } = {
  '994_discharged': ['994_charged'],
  '9972_discharged': ['9972_charged'],
};

export const idsToHide: string[] = Object.values(syncData).reduce(
  (flatList, current) => [...flatList, ...((current: any): string[])],
  []
);

export const triggerIds: string[] = Object.keys(syncData);

export const overWriteStateWithFirebase = (state: Object, firebaseSettings: Object): Object => {
  const newState = { ...state };
  const stateKeys = Object.keys(state);
  stateKeys.forEach(stateKey => {
    switch (stateKey) {
      // aplFilter の各データの値のみ上書きする
      case 'aplFilter': {
        const hasSettings =
          Array.isArray(firebaseSettings.aplFilter) && firebaseSettings.aplFilter.length;
        if (!hasSettings) {
          break;
        }
        newState.aplFilter = newState.aplFilter.map(filter => {
          const matchedFilter = firebaseSettings.aplFilter.find(
            filterOnFirebase => filterOnFirebase.id === filter.id
          );
          return matchedFilter || filter;
        });
        break;
      }

      // 配列長の有無で multistagePlanSetting を上書きする
      case 'multistagePlanSetting': {
        const hasSettings =
          Array.isArray(firebaseSettings.multistagePlanSetting) &&
          firebaseSettings.multistagePlanSetting.length;
        if (hasSettings) {
          newState.multistagePlanSetting = firebaseSettings.multistagePlanSetting;
        }
        break;
      }

      // 単に値を上書きする
      default: {
        const hasSetting = typeof firebaseSettings[stateKey] !== 'undefined';
        if (hasSetting) {
          newState[stateKey] = firebaseSettings[stateKey];
        }
        break;
      }
    }
  });
  return newState;
};
