// @flow

import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import Loading from 'Client/components/utils/Loading';
import TopDailyBalance from 'Client/components/TopDailyBalance';
import TopDailyCharge from 'Client/components/TopDailyCharge';
import QuestionIcon from 'Client/components/utils/QuestionIcon';
import TopNoData, { TOP_DAILY_NO_DATA } from 'Client/components/TopNoData';
import Prefixer from 'Client/lib/Prefixer';
import { calcTodaySellData, calcTodayBuyData } from 'Client/lib/pricePlanUtils';
import { OPEN_POP_UP } from 'Client/actions/types';
import { HELP_HOME_TOP_PG } from 'Client/actions/helpPopupTypes';
import type { State, UserSettingState, PricePlanState } from 'Client/types/state';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  relative: {
    position: 'relative',
  },
  info: {
    padding: '3px 10px 0 0',
    textAlign: 'right',
  },
  tableWp: {
    display: 'table',
    width: '100%',
    margin: '0px auto',
    zIndex: '2',
  },
  tableLeft: {
    width: '50%',
    display: 'table-cell',
    height: '100%',
    verticalAlign: 'top',
  },
  tableRight: {
    width: '50%',
    display: 'table-cell',
    height: '100%',
    verticalAlign: 'middle',
  },
});

type StateProps = {|
  userSetting: UserSettingState,
  sellPrice: number,
  hourlyEnergyList: {
    hour: number,
    sellEnergy: number,
    buyEnergy: number,
  }[],
  hourlyRequesting: boolean,
  userStatus: string,
  pricePlan: PricePlanState,
|};
type OwnProps = {|
  intl: intlShape,
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

export const PureTopDailyChargeArea = ({
  sellPrice,
  hourlyRequesting,
  dispatch,
  userStatus,
  hourlyEnergyList,
  pricePlan,
}: Props): Node => {
  const { sellEnergy, sellCharge } = calcTodaySellData(sellPrice, hourlyEnergyList);
  const { buyEnergy, buyCharge } = calcTodayBuyData(hourlyEnergyList, pricePlan);
  const todaysBalance = sellCharge - buyCharge;
  const dailyInfo = (
    <div style={style.tableWp}>
      <div style={style.tableLeft}>
        <TopDailyBalance todaysBalance={todaysBalance} dailyRequesting={hourlyRequesting} />
      </div>
      <div style={style.tableRight}>
        {!hourlyRequesting && sellEnergy + buyEnergy === 0 ? (
          <TopNoData type={TOP_DAILY_NO_DATA} />
        ) : (
          <TopDailyCharge
            sellCharge={sellCharge}
            sellEnergy={sellEnergy}
            buyCharge={buyCharge}
            buyEnergy={buyEnergy}
            requesting={hourlyRequesting}
            userStatus={userStatus}
            pricePlan={pricePlan}
          />
        )}
      </div>
    </div>
  );

  return (
    <div style={style.relative}>
      <div style={style.info}>
        <QuestionIcon
          onClick={e => {
            dispatch({
              type: OPEN_POP_UP,
              contentType: HELP_HOME_TOP_PG,
              target: e.target,
            });
          }}
        />
      </div>
      {hourlyRequesting && <Loading positionAbsolute />}
      {dailyInfo}
    </div>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  userSetting: state.userSetting,
  sellPrice: state.userSetting.sellPrice,
  hourlyEnergyList: state.energy.hourly,
  hourlyRequesting: state.energy.hourlyRequesting,
  userStatus: state.userInfo.status,
  pricePlan: state.pricePlan,
});

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  injectIntl(PureTopDailyChargeArea)
);
