// @flow

import React from 'react';
import type { Node } from 'react';
import Prefixer from 'Client/lib/Prefixer';

const prefixer = new Prefixer();
const loadingImage = require('Client/images/loading.gif');

const styles = prefixer.prefix({
  loading: {
    textAlign: 'center',
  },
  positionAbsolute: {
    width: 48,
    height: 48,
    zIndex: '100',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
  },
});

type Props = {
  space?: number,
  positionAbsolute?: boolean,
  children?: Node,
};

const getSpace = (space?: number): number =>
  typeof space === 'number' && !Number.isNaN(space) ? space : 50;

const Loading = (props: Props): Node => (
  <div>
    <div
      style={{
        ...styles.loading,
        ...{
          margin: `${getSpace(props.space)}px 0`,
        },
        ...(props.positionAbsolute ? styles.positionAbsolute : {}),
      }}
    >
      <img src={loadingImage} width="48" height="48" alt="loading" />
    </div>
    {props.children || null}
  </div>
);

Loading.defaultProps = {
  space: 50,
  positionAbsolute: false,
  children: null,
};

export default Loading;

export const LoadingForTest = Loading;
