// @flow
/* eslint-env browser */

import type { Action } from 'redux';
import {
  ENTER_APPLICATION,
  CHANGE_SELL_PRICE,
  SWITCH_PG_FLAG,
  LOAD_SETTING_PAGE,
  SWITCH_APL_DISPLAY_FLAG,
  SUCCESS_API_USER_STATUS,
  SUCCESS_API_USER_INFO,
  SUCCESS_API_LOGOUT,
  SUCCESS_API_LOGIN_INFO,
  REQUEST_API_SETTINGS_UPDATE,
  SUCCESS_API_SETTINGS_UPDATE,
  FAILURE_API_SETTINGS_UPDATE,
  REQUEST_CHANGE_MAIL,
  SUCCESS_CHANGE_MAIL,
  FAILURE_CHANGE_MAIL,
  RESET_CHANGE_MAIL,
  REQUEST_CHANGE_PASSWORD,
  SUCCESS_CHANGE_PASSWORD,
  FAILURE_CHANGE_PASSWORD,
  RESET_CHANGE_PASSWORD,
} from 'Client/actions/types';
import { defaultEnergyPrice, energyPriceRange, aplFilterFormat } from 'Client/lib/localizedValues';
import { toggleApplianceFilter } from 'Client/lib/globalUtils';
import { overWriteStateWithFirebase } from 'Client/lib/settingUtils';
import type { UserSettingState as State } from 'Client/types/state';

const initialState = {
  requesting: false,
  isPg: false,
  isPgChangedByUser: false,
  sellPrice: 33,
  dispSellPrice: 33,
  mailChangeState: '',
  passwordChangeState: '',
  certificationState: '',
  aplFilter: [],
};

const isSameAplFilterFormat = aplFilter => {
  const localizedAplFilter = aplFilterFormat.ja;
  const isSameLength = aplFilter.length === localizedAplFilter.length;
  const includesSameElements = () => {
    const idsShouldBeIncluded = localizedAplFilter.map(elem => elem.id);
    const filteredArray = aplFilter.filter(elem => idsShouldBeIncluded.includes(elem.id));
    return filteredArray.length === aplFilter.length;
  };
  return isSameLength && includesSameElements();
};

const isWithinPriceRange = price => {
  const { min, max } = energyPriceRange.ja;
  return price >= min && price <= max;
};

export default (state: State = initialState, action: Action = {}): State => {
  switch (action.type) {
    case SUCCESS_API_USER_INFO:
    case SUCCESS_API_LOGIN_INFO: {
      const { payload } = action;
      if (payload.settings) {
        return overWriteStateWithFirebase(state, action.payload.settings);
      }
      return {
        ...state,
        isPg: payload.isPg ? payload.isPg : state.isPg,
        isPgChangedByUser: payload.isPgChangedByUser
          ? payload.isPgChangedByUser
          : state.isPgChangedByUser,
        dispSellPrice: payload.sellPrice ? payload.sellPrice : state.sellPrice,
        sellPrice: payload.sellPrice ? payload.sellPrice : state.sellPrice,
        aplFilter: payload.aplFilter ? payload.aplFilter : state.aplFilter,
      };
    }
    case REQUEST_API_SETTINGS_UPDATE: {
      return {
        ...state,
        requesting: true,
      };
    }
    case SUCCESS_API_SETTINGS_UPDATE: {
      return {
        ...state,
        requesting: false,
      };
    }
    case FAILURE_API_SETTINGS_UPDATE: {
      return {
        ...state,
        requesting: false,
      };
    }
    case ENTER_APPLICATION: {
      const { aplFilter, sellPrice } = state;
      const correctSellPrice = isWithinPriceRange(sellPrice)
        ? sellPrice
        : defaultEnergyPrice.ja.sell;
      return {
        ...state,
        aplFilter:
          aplFilter.length && isSameAplFilterFormat(aplFilter) ? aplFilter : aplFilterFormat.ja,
        sellPrice: correctSellPrice,
        dispSellPrice: correctSellPrice,
      };
    }
    case SUCCESS_API_USER_STATUS: {
      if (state.isPgChangedByUser === false) {
        return {
          ...state,
          isPg: action.payload.user.power_generator_flag,
        };
      }
      return state;
    }
    case LOAD_SETTING_PAGE:
      return {
        ...state,
        dispSellPrice: state.sellPrice,
      };
    case CHANGE_SELL_PRICE: {
      return {
        ...state,
        dispSellPrice: action.price,
        sellPrice: action.isErr ? state.sellPrice : action.price,
      };
    }
    case SWITCH_PG_FLAG: {
      return {
        ...state,
        isPg: action.isPg,
        isPgChangedByUser: true,
      };
    }
    case SWITCH_APL_DISPLAY_FLAG: {
      const { id, syncIds } = action;
      const aplFilter = toggleApplianceFilter(state.aplFilter, id, syncIds);
      return {
        ...state,
        aplFilter,
      };
    }
    case SUCCESS_API_LOGOUT: {
      return initialState;
    }
    case REQUEST_CHANGE_MAIL: {
      return {
        ...state,
        mailChangeState: action.error ? 'ERROR' : 'REQUESTING',
      };
    }
    case SUCCESS_CHANGE_MAIL: {
      return {
        ...state,
        mailChangeState: 'SUCCESS',
      };
    }
    case FAILURE_CHANGE_MAIL: {
      return {
        ...state,
        mailChangeState: 'ERROR',
      };
    }
    case RESET_CHANGE_MAIL: {
      return {
        ...state,
        mailChangeState: '',
      };
    }
    case REQUEST_CHANGE_PASSWORD: {
      return {
        ...state,
        passwordChangeState: 'REQUESTING',
      };
    }
    case SUCCESS_CHANGE_PASSWORD: {
      return {
        ...state,
        passwordChangeState: 'SUCCESS',
      };
    }
    case FAILURE_CHANGE_PASSWORD: {
      return {
        ...state,
        passwordChangeState: 'ERROR',
      };
    }
    case RESET_CHANGE_PASSWORD: {
      return {
        ...state,
        passwordChangeState: '',
      };
    }
    default: {
      return state;
    }
  }
};
