// @flow

import type { Action } from 'redux';
import moment from 'moment-timezone';
import { timeUnitId } from 'Client/lib/id';
import type { ApplianceEnergyState as State } from 'Client/types/state';
import {
  ENTER_APPLICATION,
  LOAD_TOP_PAGE,
  REQUEST_API_ENERGY_HOURLY,
  SUCCESS_API_ENERGY_HOURLY,
  FAILURE_API_ENERGY_HOURLY,
} from 'Client/actions/types';

const initialState = {
  requesting: false,
  daily: [],
  weekly: [],
  monthly: [],
  yearly: [],
  locale: 'ja',
};

const extractionAplEnergy = payload => {
  const totalApls = {};

  // payload から consumed_energy_apl を抜き出して合計していく
  payload.forEach(target => {
    const consumedApls = target.data.consumed_energy_apl;
    Object.keys(consumedApls).forEach(id => {
      if (!totalApls[id]) {
        totalApls[id] = Array(24).fill(0);
      }
      consumedApls[id].forEach((energy, index) => {
        totalApls[id][index] += energy;
      });
    });
  });

  // ヒーターを高熱家電に個別結合
  if (totalApls.apl_301) {
    totalApls.apl_301.forEach((energy, index) => {
      totalApls.apl_300[index] += energy;
    });
  }

  return Object.keys(totalApls).map(id => {
    const matched = id.match(/^apl_(.+)$/);
    return {
      id: matched ? matched[1] : id,
      energy: totalApls[id],
    };
  });
};

export default (state: State = initialState, action: Action = {}): State => {
  switch (action.type) {
    case ENTER_APPLICATION: {
      return {
        ...state,
        locale: action.locale,
      };
    }
    case LOAD_TOP_PAGE: {
      return {
        ...state,
        requesting: false,
        daily: [],
        weekly: [],
        monthly: [],
        yearly: [],
      };
    }
    case REQUEST_API_ENERGY_HOURLY: {
      const requesting = !action.error;
      return {
        ...state,
        requesting,
      };
    }
    case SUCCESS_API_ENERGY_HOURLY: {
      const { payload = {}, meta = {} } = action || {};
      switch (meta.reqUnit) {
        case timeUnitId.day: {
          if (meta.subUnitFlg) {
            const dailyArray = state.daily.filter(d => !meta.dateTimeList.includes(d.dateTime));
            meta.dateTimeList.forEach(startWeekDay => {
              if (dailyArray.filter(d => d.dateTime !== startWeekDay)) {
                const dailyData = [];
                const found = payload.find(
                  target => target.unix === moment(startWeekDay, 'YYYY-MM-DD').unix()
                );
                if (found) {
                  dailyData.push(found);
                }
                if (dailyData.length > 0) {
                  const applianceEnergy = extractionAplEnergy(dailyData);
                  dailyArray.push({
                    dateTime: startWeekDay,
                    applianceEnergy,
                  });
                }
              }
            });
            return {
              ...state,
              requesting: false,
              daily: dailyArray,
            };
          }
          const dailyArray = state.daily.filter(d => d.dateTime !== meta.dateTime);
          dailyArray.push({
            dateTime: meta.dateTime,
            applianceEnergy: extractionAplEnergy(payload),
          });
          return {
            ...state,
            requesting: false,
            daily: dailyArray,
          };
        }
        case timeUnitId.week: {
          if (state.weekly.find(item => meta.dateTime === item.dateTime)) {
            return {
              ...state,
              requesting: false,
            };
          }
          if (meta.subUnitFlg) {
            const weeklyArray = [];
            meta.dateTimeList.forEach(startWeekDay => {
              const weekData = [];
              [0, 1, 2, 3, 4, 5, 6].forEach(ele => {
                const found = payload.find(
                  target =>
                    target.unix ===
                    moment(startWeekDay, 'YYYY-MM-DD')
                      .add(ele, 'day')
                      .unix()
                );
                if (found) {
                  weekData.push(found);
                }
              });
              const applianceEnergy = extractionAplEnergy(weekData);
              weeklyArray.push({
                dateTime: startWeekDay,
                applianceEnergy,
              });
            });
            return {
              ...state,
              requesting: false,
              weekly: [...state.weekly, ...weeklyArray],
            };
          }
          const applianceEnergy = extractionAplEnergy(payload);
          const weeklyArray = [
            ...state.weekly,
            {
              dateTime: meta.dateTime,
              applianceEnergy,
            },
          ];
          return {
            ...state,
            requesting: false,
            weekly: weeklyArray,
          };
        }
        case timeUnitId.month: {
          if (state.monthly.find(item => meta.dateTime === item.dateTime)) {
            return {
              ...state,
              requesting: false,
            };
          }
          if (meta.subUnitFlg) {
            const monthlyArray = [];
            payload.forEach(ele => {
              const oneMonthData = extractionAplEnergy([ele]);
              monthlyArray.push({
                dateTime: moment(ele.unix * 1000).format('YYYY-MM'),
                applianceEnergy: oneMonthData,
              });
            });
            return {
              ...state,
              requesting: false,
              monthly: [...state.monthly, ...monthlyArray],
            };
          }
          const applianceEnergy = extractionAplEnergy(payload);
          const monthlyArray = [
            ...state.monthly,
            {
              dateTime: meta.dateTime,
              applianceEnergy,
            },
          ];
          return {
            ...state,
            requesting: false,
            monthly: monthlyArray,
          };
        }
        case timeUnitId.year: {
          if (state.yearly.find(item => meta.dateTime === item.dateTime)) {
            return {
              ...state,
              requesting: false,
            };
          }
          const applianceEnergy = extractionAplEnergy(payload);
          const yearlyArray = [
            ...state.yearly,
            {
              dateTime: meta.dateTime,
              applianceEnergy,
            },
          ];
          return {
            ...state,
            requesting: false,
            yearly: yearlyArray,
          };
        }
        default:
          return {
            ...state,
            requesting: false,
          };
      }
    }

    case FAILURE_API_ENERGY_HOURLY: {
      return {
        ...state,
        requesting: false,
      };
    }
    default: {
      return state;
    }
  }
};
