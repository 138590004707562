// @flow

import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import Prefixer from 'Client/lib/Prefixer';
import AlertPopup from 'Client/components/AlertPopup';
import { isEmbeded } from 'Client/lib/serviceCheck';
import { OPEN_POP_UP } from 'Client/actions/types';
import { SESSION_EXPIRED } from 'Client/actions/alertPopupTypes';

const imageHappenedError = require('Client/images/img_happened_error.png');

const prefixer = new Prefixer();
const ACCEPTABLE_TYPE: string[] = ['mail', 'password', 'resetpassword', 'expired', 'any'];

const style = prefixer.prefix({
  centeringContainer: {
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    color: '#454545',
  },
  errorImageBox: {
    width: '100%',
    marginBottom: 5,
    textAlign: 'center',
  },
  errorMessage: {
    margin: '0 0 55px',
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: 1.6,
    textAlign: 'center',
  },
  errorGuideMessage: {
    width: 280,
    margin: '0 auto 45px',
    lineHeight: 1.7,
  },
  actionButton: {
    display: 'block',
    width: 280,
    height: 50,
    margin: '0 auto',
    borderRadius: 5,
    backgroundColor: '#ffbd00',
    color: '#fff',
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: '50px',
    textAlign: 'center',
    cursor: 'pointer',
    textDecoration: 'none',
  },
});

type LimitedTypes = 'mail' | 'password' | 'resetpassword' | 'expired' | 'any';
type Props = {
  type: LimitedTypes,
  intl: intlShape,
  dispatch: Dispatch,
};

const getToBackPath = (type: string): string => {
  switch (type) {
    case 'mail':
    case 'password':
      return '/setting';
    case 'expired':
    default:
      return '/login';
  }
};

const ErrorMessage = ({ type, intl }: Props) => {
  switch (type) {
    case 'expired':
      return (
        <p style={style.errorMessage}>
          {intl.formatMessage({ id: 'changeOnError.expiredL1' })}
          <br />
          {intl.formatMessage({ id: 'changeOnError.expiredL2' })}
          <br />
        </p>
      );
    case 'mail':
    case 'password':
    case 'resetpassword':
      return (
        <p style={style.errorMessage}>
          {intl.formatMessage({ id: 'changeOnError.setting' })}
          <br />
        </p>
      );
    default:
      return (
        <p style={style.errorMessage}>
          {intl.formatMessage({ id: 'changeOnError.anything' })}
          <br />
        </p>
      );
  }
};

const ErrorExpiredMeessage = () => (
  <div>
    <AlertPopup />
  </div>
);

const Error = (props: Props) => {
  const { type, intl, dispatch } = props;
  if (isEmbeded) {
    dispatch({ type: OPEN_POP_UP, contentType: SESSION_EXPIRED });
    return <ErrorExpiredMeessage />;
  } else if (!ACCEPTABLE_TYPE.includes(type)) {
    return <Redirect to="/login" />;
  }
  return (
    <div style={style.centeringContainer}>
      <div>
        <div style={style.errorImageBox}>
          <img src={imageHappenedError} width="120" height="120" alt="" />
        </div>
        <ErrorMessage type={type} intl={intl} dispatch={dispatch} />
        <p style={style.errorGuideMessage}>
          {intl.formatMessage({ id: 'changeOnError.guideMessage' })}
          <br />
        </p>
        <Link style={style.actionButton} to={getToBackPath(type)}>
          {intl.formatMessage({ id: 'changeOnError.toBack' })}
        </Link>
      </div>
    </div>
  );
};

export default connect<{}, {}, {}, {}, {}, Dispatch>()(injectIntl(Error));

export const ErrorMessageForTest = ErrorMessage;
export const ErrorForTest = Error;
