// @flow
import type { Action } from 'redux';
import type { EnergyReducerState as State } from 'Client/types/state';
import {
  LOAD_TOP_PAGE,
  RESET_CHART_PAGE,
  SUCCESS_API_TIME_SERIES,
  SUCCESS_API_MULTIFETCH,
  SUCCESS_API_ENERGY_HOURLY,
} from 'Client/actions/types';
import successApiTimeSeries from './successApiTimeSeries';
import successApiMultifetch from './successApiMultifetch';
import successApiEnergyHourly from './successApiEnergyHourly';

export const initialEnergyState: State = {
  hourly: [],
  daily: [],
  weekly: [],
  monthly: [],
  yearly: [],
};

type PowerParams = {
  whp?: ?(number[]),
  buy_p?: ?(number[]),
  consume_p?: ?(number[]),
  pg_p?: ?(number[]),
  root_p?: ?(number[]),
  root_powers?: ?(number[]),
  sell_p?: ?(number[]),
  charged_p?: ?(number[]),
  discharged_p?: ?(number[]),
};

export const getWhpValue = (v: PowerParams): number => (v && v.whp && v.whp[0]) || 0;
export const getSellPValue = (v: PowerParams): number => (v && v.sell_p && v.sell_p[0]) || 0;
export const getBuyPValue = (v: PowerParams): number => (v && v.buy_p && v.buy_p[0]) || 0;
export const total = (array: number[]): number =>
  (array || []).reduce((prev, current) => prev + current, 0);

export default (energyState: State = initialEnergyState, action: Action = {}): State => {
  switch (action.type) {
    case SUCCESS_API_TIME_SERIES:
      return successApiTimeSeries(energyState, action);
    case SUCCESS_API_MULTIFETCH:
      return successApiMultifetch(energyState, action);
    case LOAD_TOP_PAGE:
      return initialEnergyState;
    case RESET_CHART_PAGE:
      return initialEnergyState;
    case SUCCESS_API_ENERGY_HOURLY:
      return successApiEnergyHourly(energyState, action);
    default: {
      return energyState;
    }
  }
};
