// @flow
/* eslint-env browser */

import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import AlertPopup from 'Client/components/AlertPopup';
import HelpPopup from 'Client/components/HelpPopup';
import SettingPricePlanPopup from 'Client/components/SettingPricePlanPopup';
import AuthArea from './AuthArea';
import RouteLogin from './RouteLogin';
import RouteResetPasswordPage from './RouteResetPasswordPage';
import RouteTopPage from './RouteTopPage';
import RouteChartPage from './RouteChartPage';
import RouteSettingPage from './RouteSettingPage';
import RouteChangePage from './RouteChangePage';
import RouteCertification from './RouteCertification';
import RouteCompletedPage from './RouteCompletedPage';
import RouteErrorPage from './RouteErrorPage';
import RouteTutorialPage from './RouteTutorialPage';

const Router = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/login" render={props => <RouteLogin {...props} />} />
      <Route path="/reset/password/:type" component={RouteResetPasswordPage} />
      <Route path="/certification" component={RouteCertification} />
      <Route path="/completed/:type" component={RouteCompletedPage} />
      <Route path="/error/:type" component={RouteErrorPage} />
      <AuthArea>
        <AlertPopup />
        <HelpPopup />
        <SettingPricePlanPopup />
        <Switch>
          <Route path="/tutorial" component={RouteTutorialPage} />
          <Route path="/chart/:unit?/:period?" component={RouteChartPage} />
          <Route path="/setting" component={RouteSettingPage} />
          <Route path="/change/:type" component={RouteChangePage} />
          <Route path="/top" component={RouteTopPage} />
          <Redirect to="/top" />
        </Switch>
      </AuthArea>
    </Switch>
  </BrowserRouter>
);

export default Router;
