// @flow
import type { State, Action } from 'redux';
import { timeUnitId } from 'Client/lib/id';
import { total, getWhpValue, getSellPValue, getBuyPValue } from './energyReducer';

const getTotalsOfEachParam = payload => {
  const { whp, sell_p, buy_p, consume_p, pg_p, charged_p, discharged_p } = payload;
  return {
    whp: total(whp),
    sellEnergy: total(sell_p),
    buyEnergy: total(buy_p),
    consumeEnergy: total(consume_p),
    pgEnergy: total(pg_p),
    chargedEnergy: total(charged_p),
    dischargedEnergy: total(discharged_p),
  };
};

const successApiTimeSeriesHourly = (energyState, dateTime, payload) => {
  if (energyState.hourly.find(hour => dateTime === hour)) {
    return energyState;
  }
  const result = {
    ...energyState,
    hourly: [
      ...energyState.hourly,
      {
        hour: Number(dateTime),
        whp: getWhpValue(payload),
        sellEnergy: getSellPValue(payload),
        buyEnergy: getBuyPValue(payload),
      },
    ],
  };
  return result;
};

const successApiTimeSeriesDaily = (energyState, dateTime, payload) => {
  if (energyState.daily.find(item => dateTime === item.day)) {
    return energyState;
  }
  const { consume_p, pg_p, charged_p, discharged_p } = payload;
  const result = {
    ...energyState,
    daily: [
      ...energyState.daily,
      {
        day: dateTime,
        whp: getWhpValue(payload),
        sellEnergy: getSellPValue(payload),
        buyEnergy: getBuyPValue(payload),
        consumeEnergy: total(consume_p),
        pgEnergy: total(pg_p),
        chargedEnergy: total(charged_p),
        dischargedEnergy: total(discharged_p),
      },
    ],
  };
  return result;
};

const successApiTimeSeriesWeekly = (energyState, dateTime, payload) => {
  if (energyState.weekly.find(item => dateTime === item.week)) {
    return energyState;
  }
  const weeklyArray = [
    ...energyState.weekly,
    {
      week: dateTime,
      ...getTotalsOfEachParam(payload),
    },
  ];
  const result = {
    ...energyState,
    weekly: weeklyArray,
  };
  return result;
};

const successApiTimeSeriesMonthly = (energyState, dateTime, payload) => {
  if (energyState.monthly.find(item => dateTime === item.month)) {
    return energyState;
  }
  const monthlyArray = [
    ...energyState.monthly,
    {
      month: dateTime,
      ...getTotalsOfEachParam(payload),
    },
  ];
  const result = {
    ...energyState,
    monthly: monthlyArray,
  };
  return result;
};

const successApiTimeSeriesYearly = (energyState, dateTime, payload) => {
  if (energyState.yearly.find(item => dateTime === item.year)) {
    return energyState;
  }
  const yearlyArray = [
    ...energyState.yearly,
    {
      year: dateTime,
      ...getTotalsOfEachParam(payload),
    },
  ];
  const result = {
    ...energyState,
    yearly: yearlyArray,
  };
  return result;
};

export default (energyState: State, action: Action = {}): State => {
  const { payload, meta } = action || {};
  const { unit, dateTime } = meta || {};
  switch (unit) {
    case timeUnitId.hour:
      return successApiTimeSeriesHourly(energyState, dateTime, payload);
    case timeUnitId.day:
      return successApiTimeSeriesDaily(energyState, dateTime, payload);
    case timeUnitId.week:
      return successApiTimeSeriesWeekly(energyState, dateTime, payload);
    case timeUnitId.month:
      return successApiTimeSeriesMonthly(energyState, dateTime, payload);
    case timeUnitId.year:
      return successApiTimeSeriesYearly(energyState, dateTime, payload);
    default:
      return energyState;
  }
};
