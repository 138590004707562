// @flow

import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import Loading from 'Client/components/utils/Loading';
import ChartSubUnitChart from 'Client/components/ChartSubUnitChart';
import ChartSubUnitChargeItem from 'Client/components/ChartSubUnitChargeItem';
import QuestionIcon from 'Client/components/utils/QuestionIcon';
import ChartNodata, { CHART_NO_DATA } from 'Client/components/ChartNoData';
import { HELP_CHART_DETAIL } from 'Client/actions/helpPopupTypes';
import { OPEN_POP_UP } from 'Client/actions/types';
import { startDates } from 'Client/lib/chartUtils';
import Prefixer from 'Client/lib/Prefixer';
import type { State } from 'Client/types/state';
import { getTotalEnergy } from 'Client/lib/globalUtils';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  wrapper: {
    position: 'relative',
  },
  body: {
    marginTop: '10px',
  },
  areaTitle: {
    verticalAlign: 'middle',
  },
  criterion: {
    fontSize: '0.7em',
    paddingLeft: '5px',
  },
  chartArea: {
    maxHeight: '320px',
    postion: 'relative',
  },
  clear: {
    clear: 'both',
  },
  info: {
    top: '-11px',
    right: '-5px',
    zIndex: '2',
    position: 'absolute',
  },
});

const areaTitle = (unit, intl) => {
  switch (unit) {
    case 'week':
      return intl.formatMessage({ id: 'chartSubUnit.dailyTitle' });
    case 'month':
      return intl.formatMessage({ id: 'chartSubUnit.weeklyTitle' });
    case 'year':
      return intl.formatMessage({ id: 'chartSubUnit.monthlyTitle' });
    default:
      return '';
  }
};
const criterion = intl => intl.formatMessage({ id: 'others.criterion' });

const renderChargeTable = (unitEnergyList, unit, period, sellPrice) => {
  const startPeriodList = startDates(unit, period);
  return (
    <div>
      {unitEnergyList.map((unitEnergy, index) => {
        const key = `${unitEnergy.period || ''}-${index}`;
        return (
          <ChartSubUnitChargeItem
            key={key}
            unitEnergy={unitEnergy}
            unit={unit}
            period={startPeriodList[index]}
            itemIndex={index}
            sellPrice={sellPrice}
          />
        );
      })}
      <div style={style.clear} />
    </div>
  );
};

const renderChartArea = (unitEnergyList, unit, period, sellPrice) => (
  <div>
    <ChartSubUnitChart unitEnergyList={unitEnergyList} unit={unit} sellPrice={sellPrice} />
    {renderChargeTable(unitEnergyList, unit, period, sellPrice)}
  </div>
);

const energyTotal = energyList =>
  (energyList || []).reduce(
    (prev, current) => prev + current.sellEnergy + getTotalEnergy(current.buyEnergy),
    0
  );

const renderBody = (requesting, unitEnergyList, unit, period, sellPrice, intl) => {
  if (requesting) {
    return (
      <Loading space={80}>
        <div>{intl.formatMessage({ id: 'application.dataLoading' })}</div>
      </Loading>
    );
  } else if (energyTotal(unitEnergyList) > 0) {
    return renderChartArea(unitEnergyList, unit, period, sellPrice);
  }
  return <ChartNodata type={CHART_NO_DATA} />;
};

type StateProps = {|
  sellPrice: number,
|};
type OwnProps = {|
  requesting: boolean,
  unitEnergyList: {
    period?: string,
    sellEnergy: number,
    buyEnergy: number[],
  }[],
  unit: string,
  period: string,
  intl: intlShape,
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

export const PureChartSubUnit = ({
  requesting,
  unitEnergyList,
  unit,
  period,
  sellPrice,
  intl,
  dispatch,
}: Props): Node => (
  <div style={style.wrapper}>
    <div style={style.info}>
      <QuestionIcon
        onClick={e => {
          dispatch({
            type: OPEN_POP_UP,
            contentType: HELP_CHART_DETAIL,
            target: e.target,
          });
        }}
      />
    </div>
    <div>
      <span style={style.areaTitle}>{areaTitle(unit, intl)}</span>
      <span style={style.criterion}>{criterion(intl)}</span>
    </div>
    <div style={style.body}>
      {renderBody(requesting, unitEnergyList, unit, period, sellPrice, intl)}
    </div>
  </div>
);

const mapStateToProps = (state: State): StateProps => ({
  sellPrice: state.userSetting.sellPrice,
});

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  injectIntl(PureChartSubUnit)
);
