// @flow

import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import { subTimeUnit, timeUnitStyle } from 'Client/lib/chartUtils';
import Prefixer from 'Client/lib/Prefixer';
import type { State } from 'Client/types/state';

const prefixer = new Prefixer();
const settingButton = require('Client/images/icn_setting.png');

const style = prefixer.prefix({
  headerFixed: {
    position: 'fixed',
    zIndex: '10000',
    left: '0px',
    right: '0px',
    top: '0px',
    height: '55px',
    width: '100%',
  },
  headerBar: {
    backgroundColor: '#fff',
    width: '100%',
    height: '100%',
    borderBottom: '2px solid #eee',
  },
  headerLeft: {
    float: 'left',
    maxWidth: '50%',
    width: '27%',
    height: '100%',
  },
  headerCenter: {
    float: 'left',
    width: '46%',
    height: '100%',
    lineHeight: '39px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  headerRight: {
    float: 'left',
    maxWidth: '50%',
    width: '27%',
    height: '100%',
    textAlign: 'right',
  },
  logoMargin: {
    marginTop: '12px',
  },
  logoImage: {
    width: '110px',
  },
  settingMargin: {
    margin: '16px 10px 0px 0px',
    width: 'auto',
  },
  settingImage: {
    height: '25px',
    align: 'middle',
  },
  back: {
    color: '#ffbd00',
    lineHeight: '55px',
    paddingLeft: '15px',
  },
  settingTitle: {
    color: '#ffbd00',
    fontWeight: 'bold',
    fontSize: '1.1em',
    lineHeight: '32px',
  },
  textDecorationNone: {
    textDecoration: 'none',
  },
  backIcon: {
    marginRight: '2px',
    verticalAlign: 'text-top',
    height: '1.2em',
    width: '1.2em',
  },
  backButtonText: {
    verticalAlign: 'middle',
  },
  actionDisable: {
    opacity: '0.3',
  },
});

const getReturnToPath = type => {
  switch (type) {
    case 'resetPassword':
      return '/login';
    case 'changeMail':
    case 'changePassword':
      return '/setting';
    default:
      return '/top';
  }
};

const renderButton = (intl, requesting) => {
  const button = require('Client/images/chebron-left.svg');
  return (
    <div style={requesting ? { ...style.back, ...style.actionDisable } : style.back}>
      <img src={button} alt="chebron-left" style={style.backIcon} />
      <span style={style.backButtonText}>{intl.formatMessage({ id: 'headerBar.back' })}</span>
    </div>
  );
};

const renderHeaderLeft = (type, requesting, intl) => {
  if (requesting) {
    return <div style={style.headerLeft}>{renderButton(intl, requesting)}</div>;
  }

  const pagesCanViewHeader: string[] = [
    'resetPassword',
    'chart',
    'setting',
    'changeMail',
    'changePassword',
  ];
  if (pagesCanViewHeader.includes(type)) {
    return (
      <div style={style.headerLeft}>
        <Link to={getReturnToPath(type)} className="TopPage" style={style.textDecorationNone}>
          {renderButton(intl, requesting)}
        </Link>
      </div>
    );
  }

  return <div style={style.headerLeft} />;
};

const setHeaderCenter = (type, intl) => {
  switch (type) {
    case 'resetPassword':
      return (
        <div style={style.settingTitle}>
          {intl.formatMessage({ id: 'headerBar.resetPassword' })}
        </div>
      );
    case 'setting':
      return (
        <div style={style.settingTitle}>{intl.formatMessage({ id: 'headerBar.setting' })}</div>
      );
    case 'changeMail':
      return (
        <div style={style.settingTitle}>{intl.formatMessage({ id: 'headerBar.changeMail' })}</div>
      );
    case 'changePassword':
      return (
        <div style={style.settingTitle}>
          {intl.formatMessage({ id: 'headerBar.changePassword' })}
        </div>
      );
    default: {
      const logo = require(`Client/images/logo_ja.png`);
      return (
        <img
          src={logo}
          alt={'logo'}
          style={style.logoImage}
          id="headerLogoImage"
          className="headerDisplay"
        />
      );
    }
  }
};

const renderHeaderCenter = (type, intl) => (
  <div style={style.headerCenter}>
    <div style={style.logoMargin}>{setHeaderCenter(type, intl)}</div>
  </div>
);

const renderHeaderRight = (type, requesting) => {
  if (type !== 'top') {
    return null;
  }

  const settingImage = requesting ? (
    <img
      src={settingButton}
      alt="setting"
      style={{ ...style.settingImage, ...style.actionDisable }}
    />
  ) : (
    <Link to="/setting" className="SettingPage">
      <img src={settingButton} alt="setting" style={style.settingImage} />
    </Link>
  );

  return (
    <div style={style.headerRight}>
      <div style={style.settingMargin}>{settingImage}</div>
    </div>
  );
};

type StateProps = {|
  topRequesting: boolean,
  chartRequesting: boolean,
|};
type OwnProps = {|
  pageType: string,
  requesting?: boolean,
  intl: intlShape,
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

export const PureHeaderBar = ({
  pageType,
  requesting,
  topRequesting,
  chartRequesting,
  intl,
}: Props): Node => (
  <div style={style.headerFixed}>
    <div style={style.headerBar}>
      {renderHeaderLeft(pageType, chartRequesting || requesting, intl)}
      {renderHeaderCenter(pageType, intl)}
      {renderHeaderRight(pageType, topRequesting || requesting)}
    </div>
  </div>
);

PureHeaderBar.defaultProps = {
  topRequesting: false,
  chartRequesting: false,
};

const mapStateToProps = (state: State): StateProps => ({
  topRequesting: state.energy.hourlyRequesting,
  chartRequesting: state.energy[timeUnitStyle[subTimeUnit[state.chartTime.unit]].toRequesting],
});

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  injectIntl(PureHeaderBar)
);
