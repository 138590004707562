// @flow

import React from 'react';
import type { Node } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import TopChargeItem from 'Client/components/TopChargeItem';
import Prefixer from 'Client/lib/Prefixer';
import USER_STATUS from '../../common/user-status';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  dailyChargeContainer: {
    display: 'block',
    width: '100%',
    height: '100%',
  },
  cell: {
    display: 'block',
    textAlign: 'left',
    fontWeight: 'bold',
    paddingBottom: '5px',
    margin: '0px 20px 20px 0px',
    height: '50%',
    fontSize: '1.7em',
  },
  sell: {
    borderBottom: '1px solid #4bbeae',
    color: '#4bbeae',
  },
  buy: {
    borderBottom: '1px solid #ff9086',
    color: '#ff9086',
  },
  charge: {
    display: 'inline-block',
    textAlign: 'left',
    float: 'left',
    fontWeight: 'bold',
    minWidth: '30px',
    paddingTop: '2px',
    fontSize: '0.7em',
  },
  img: {
    width: '100%',
  },
});

const { IMPERFECT_LEARNING } = USER_STATUS;

type Props = {
  sellCharge: number,
  sellEnergy: number,
  buyCharge: number,
  buyEnergy: number,
  intl: intlShape,
  requesting: boolean,
  userStatus: string,
};

export const PureTopDailyCharge = ({
  sellCharge,
  sellEnergy,
  buyCharge,
  buyEnergy,
  intl,
  requesting,
  userStatus,
}: Props): Node => {
  if (requesting) {
    style.cell.paddingBottom = '31%';
    return (
      <div>
        <div style={{ ...style.cell, ...style.sell }} />
        <div style={{ ...style.cell, ...style.buy }} />
      </div>
    );
  }

  if (userStatus === IMPERFECT_LEARNING) {
    const imperfectLearningCharge = require('Client/images/img_study_pv_s.png');
    return (
      <div style={style.dailyChargeContainer}>
        <img style={style.img} src={imperfectLearningCharge} alt="imperfect_learning" />
      </div>
    );
  }

  style.cell.paddingBottom = '5px';
  return (
    <div>
      <div style={{ ...style.cell, ...style.sell }}>
        <div style={style.charge}>{intl.formatMessage({ id: 'topDailyCharge.sell' })}</div>
        <TopChargeItem unitCharge={sellCharge} unitEnergy={sellEnergy} intl={intl} />
      </div>
      <div style={{ ...style.cell, ...style.buy }}>
        <div style={style.charge}>{intl.formatMessage({ id: 'topDailyCharge.buy' })}</div>
        <TopChargeItem unitCharge={buyCharge} unitEnergy={buyEnergy} intl={intl} />
      </div>
    </div>
  );
};

export default injectIntl(PureTopDailyCharge);
