// @flow

import React from 'react';
import { Redirect } from 'react-router-dom';
import type { ContextRouter } from 'react-router-dom';
import FadeTransition from 'Client/components/utils/FadeTransition';
import ResetPasswordSendMail from 'Client/components/ResetPasswordSendMail';
import ResetPasswordRegister from 'Client/components/ResetPasswordRegister';

type Props = {
  ...ContextRouter,
};

const ResetPasswordPage = (props: Props) => {
  switch (props.match.params.type) {
    case 'sendmail':
      return (
        <FadeTransition pathname={props.location.pathname}>
          <ResetPasswordSendMail />
        </FadeTransition>
      );
    case 'register':
      return (
        <FadeTransition pathname={props.location.pathname}>
          <div>
            <ResetPasswordRegister location={props.location} />
          </div>
        </FadeTransition>
      );
    default:
      return <Redirect to="/login" />;
  }
};

export default ResetPasswordPage;
