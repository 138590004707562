// @flow
/* eslint-disable import/prefer-default-export */

import type { Dispatch } from 'redux';
import firebase from 'firebase/app';
import 'firebase/auth';
import {
  REQUEST_CHANGE_PASSWORD,
  SUCCESS_CHANGE_PASSWORD,
  FAILURE_CHANGE_PASSWORD,
} from 'Client/actions/types';
import { isEmbeded } from 'Client/lib/serviceCheck';

const reauthenticatePassword = (password: string, cb: (err: Object) => void): void => {
  firebase
    .auth()
    .currentUser.reauthenticateWithCredential(
      firebase.auth.EmailAuthProvider.credential(firebase.auth().currentUser.email, password)
    )
    .then(cb)
    .catch(err => {
      cb(err);
    });
};

const tryUpdatePassword = (password: string, cb: (err: Object) => void): void => {
  firebase
    .auth()
    .currentUser.updatePassword(password)
    .then(cb)
    .catch(err => {
      cb(err);
    });
};

export const updatePassword = ({
  oldPassword,
  newPassword,
  dispatch,
}: {
  oldPassword: string,
  newPassword: string,
  dispatch: Dispatch,
}) => {
  if (isEmbeded) return;
  dispatch({ type: REQUEST_CHANGE_PASSWORD });
  reauthenticatePassword(oldPassword, authError => {
    if (authError) {
      dispatch({ type: FAILURE_CHANGE_PASSWORD });
      return;
    }

    tryUpdatePassword(newPassword, updateError => {
      if (updateError) {
        dispatch({ type: FAILURE_CHANGE_PASSWORD });
        return;
      }

      dispatch({ type: SUCCESS_CHANGE_PASSWORD });
    });
  });
};
