import loginInfo from './loginInfo';
import certification from './certification';
import userInfo from './userInfo';
import userSetting from './userSetting';
import timeRange from './timeRange';
import energy from './energy';
import applianceState from './applianceState';
import tutorial from './tutorial';
import popup from './popup';
import applianceEnergy from './applianceEnergy';
import chartTime from './chartTime';
import aplPowers from './aplPowers';
import pricePlan from './pricePlan';

const reducers = {
  loginInfo,
  certification,
  userInfo,
  userSetting,
  timeRange,
  energy,
  applianceState,
  tutorial,
  popup,
  applianceEnergy,
  chartTime,
  aplPowers,
  pricePlan,
};

export default reducers;
