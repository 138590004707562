import { energyPriceRange } from 'Client/lib/localizedValues';

// todo バリデーション
export default (price, type) => {
  const minValue = energyPriceRange.ja.min;
  const maxValue = energyPriceRange.ja.max;
  const isFloat = value => Math.round(value) !== value;
  const isErr = value => value < minValue || value > maxValue || (type === 'int' && isFloat(value));
  return Array.isArray(price) ? !price.every(ele => !isErr(ele.price)) : isErr(price);
};
