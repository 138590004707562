import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import { OPEN_PRICE_PLAN_POP_UP } from 'Client/actions/types';
import Prefixer from 'Client/lib/Prefixer';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  content: {
    position: 'relative',
    overflow: 'auto',
  },
  description: {
    fontSize: '0.8em',
    lineHeight: '150%',
  },
  button: {
    margin: '15px 25%',
    padding: '9px 10px',
    backgroundColor: '#ffbd00',
    borderRadius: 5,
    fontWeight: 'bold',
    cursor: 'pointer',
    color: '#fff',
  },
});

const renderPricePlanStart = (dispatch, intl) => (
  <div style={style.content}>
    <div style={style.description}>
      {intl.formatMessage({ id: 'settingPricePlanPopup.popup1D1' })}
      <br />
      {intl.formatMessage({ id: 'settingPricePlanPopup.popup1D2' })}
      <br />
      {intl.formatMessage({ id: 'settingPricePlanPopup.popup1D3' })}
    </div>
    <div
      style={style.button}
      className="styleButton"
      onClick={() => dispatch({ type: OPEN_PRICE_PLAN_POP_UP })}
    >
      {intl.formatMessage({ id: 'settingPricePlanPopup.popup1B' })}
    </div>
  </div>
);

export const PureSettingPricePlanStart = ({ dispatch, intl }) => (
  <div>{renderPricePlanStart(dispatch, intl)}</div>
);

PureSettingPricePlanStart.propTypes = {
  dispatch: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(PureSettingPricePlanStart);
