// @flow

import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { VictoryPie, VictoryLabel, Line, Path } from 'victory';
import sizeMe from 'react-sizeme';
import Currency from 'Client/components/utils/Currency';
import Loading from 'Client/components/utils/Loading';
import ChartNodata, { CHART_NO_DATA } from 'Client/components/ChartNoData';
import QuestionIcon from 'Client/components/utils/QuestionIcon';
import { HELP_CHART_PG } from 'Client/actions/helpPopupTypes';
import { OPEN_POP_UP, CHANGE_CHART_TYPE } from 'Client/actions/types';
import Prefixer from 'Client/lib/Prefixer';
import { applianceId, pgIds } from 'Client/lib/id';
import { getTotalEnergy } from 'Client/lib/globalUtils';
import { calcPrice, balance } from 'Client/lib/pricePlanUtils';
import type { State, ApplianceEnergyDetailState, PricePlanState } from 'Client/types/state';
import { timeUnitStyle, getAppliancePgEnergy, formatEnergy } from 'Client/lib/chartUtils';
import 'Client/css/chartTree.css';

const prefixer = new Prefixer();

const pgBoxUp = 'M2,0H14a2,2,0,0,1,2,2V8H0V2A2,2,0,0,1,2,0Z';
const pgBoxDown = 'M0,8H16v6a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V8Z';

const style = prefixer.prefix({
  wrapper: {
    position: 'relative',
  },
  body: {
    marginTop: '10px',
  },
  chart: {
    width: '100%',
    display: 'table',
    marginTop: '15px',
  },
  generateColor: {
    color: '#62ba58',
  },
  buyColor: {
    color: '#ffbd00',
  },
  consumeColor: {
    color: '#d0d0d0',
  },
  generate: {
    color: '#4bbeae',
    float: 'left',
  },
  buy: {
    color: '#ff9086',
    float: 'right',
  },
  balance: {
    fontSize: '1.2em',
  },
  resultArea: {
    margin: '10px auto 0px',
    textAlign: 'center',
    fontSize: '0.9em',
    width: '96%',
  },
  resultNegative: {
    color: '#ff9086',
    borderBottom: '2px solid #ff9086',
  },
  clear: {
    clear: 'both',
  },
  info: {
    top: '-20px',
    right: '-5px',
    zIndex: '2',
    position: 'absolute',
  },

  chartTypeSwitch: {
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'normal',
  },
  chartType: {
    cursor: 'pointer',
    marginTop: '10px',
    padding: '4px 10px',
    border: '1px solid #eaeaea',
    color: '#bebebe',
    width: '40%',
  },
  chartTypeLeft: {
    borderRadius: '5px 0 0 5px',
  },
  chartTypeRight: {
    borderRadius: '0 5px 5px 0',
  },
  selectedChartType: {
    backgroundColor: '#eaeaea',
    color: '#454545',
  },
  legendArea: {
    display: 'table',
    width: '100%',
    textAlign: 'left',
    margin: '30px 10px 20px',
  },
  legendAreaPg: {
    display: 'table',
    marginTop: '10px',
    marginLeft: '10px',
    padding: 0,
    width: '100%',
    textAlign: 'left',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    margin: '3px 20px 0px 0px',
    fontWeight: 'normal',
  },
  legendItemPv: {
    display: 'flex',
    alignItems: 'center',
    margin: '3px 10px 0px 0px',
    fontWeight: 'normal',
  },
  legendSubItem: {
    position: 'relative',
    margin: '0 10px 0 7px',
  },
  legendIcon: {
    width: 16,
    height: 16,
    marginRight: 5,
    backgroundSize: 'cover',
  },
  legendSoldIcon: {
    backgroundImage:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAAIVBMVEUAAABKvq5Kvq5Lva1Kva1Kv69Kvq5Kv69Iv69Kva1Lvq7u1tPtAAAACnRSTlMA8MBwgDCwfyCvXTsbnAAAAF9JREFUKM9jYGAIV1wFBUKlDEDAvAoBFhoABbJWIYGVUAUIYMAQhSogyuCFKrCEQQtVYBGDFKrAQoZVaGCkC6AHEEYQYgZyIKqABEZEoUXlMlBkS6FFNkMIIjl4MDAAAMhEO2WKCaPxAAAAAElFTkSuQmCC)',
  },
  legendBoughtIcon: {
    backgroundImage:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAAIVBMVEUAAAD/mZL/mpL/mZL/mZH/mo//mpH/m5P/l4//mZL/mpLKzfsxAAAACnRSTlMA8MBwgDCwfyCvXTsbnAAAAF9JREFUKM9jYGAIV1wFBUKlDEDAvAoBFhoABbJWIYGVUAUIYMAQhSogyuCFKrCEQQtVYBGDFKrAQoZVaGCkC6AHEEYQYgZyIKqABEZEoUXlMlBkS6FFNkMIIjl4MDAAAMhEO2WKCaPxAAAAAElFTkSuQmCC)',
  },
  legendConsumedIcon: {
    backgroundImage:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAAIVBMVEUAAADd3d3c3Nzd3d3d3d3f39/c3Nzd3d3f39/c3Nzd3d1wHqkQAAAACnRSTlMA8MBwgDCwfyCvXTsbnAAAAF9JREFUKM9jYGAIV1wFBUKlDEDAvAoBFhoABbJWIYGVUAUIYMAQhSogyuCFKrCEQQtVYBGDFKrAQoZVaGCkC6AHEEYQYgZyIKqABEZEoUXlMlBkS6FFNkMIIjl4MDAAAMhEO2WKCaPxAAAAAElFTkSuQmCC)',
  },
  legendGeneratedIcon: {
    backgroundImage:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAAIVBMVEXc3NxKvq4AAADd3d1Lvq7c3NxJv6/f399Mv6/d3d1Lvq5598D+AAAACXRSTlPAwADw71BQQEAvB7lOAAAAPElEQVQoz2NQE1mFBBY2MWStQgErGKRQBRYyrEIFS9AFVg1lgZloYBgJTGbgRBWYwBCJKjCdQZUZRUERAJo4Pskj+q9wAAAAAElFTkSuQmCC)',
  },
  legendValueArea: {
    display: 'flex',
    alignItems: 'center',
    width: '32%',
  },
  legendValueAreaPv: {
    display: 'flex',
    alignItems: 'center',
    width: '35%',
  },
  legendValueLeft: {
    textAlign: 'left',
  },
  legendValueCenter: {
    fontSize: '0.9em',
    width: '34%',
    textAlign: 'right',
  },
  legendValueRight: {
    fontWeight: 'bold',
    width: '34%',
    textAlign: 'right',
  },
  legendValueRightPv: {
    fontWeight: 'bold',
    width: '65%',
    textAlign: 'right',
  },
});

const chartColor = {
  sellEnergy: '#4bbeae',
  buyEnergy: '#ff9086',
  consumeEnergy: '#d0d0d0',
  resultSame: '#7c7c7c',
  pgBoxDown: '#ddd',
};
const chartLavelStyle = {
  fill: '#847965',
  fontFamily: 'inherit',
  fontWeight: 'normal',
  fontSize: 18,
};

const currencyStyle = roundB =>
  (roundB > 0 && chartColor.sellEnergy) ||
  (roundB < 0 && chartColor.buyEnergy) ||
  chartColor.resultSame;

const chartTypeStyle = (chartType, selectedChartType) => ({
  ...style.chartType,
  ...(selectedChartType === chartType ? style.selectedChartType : {}),
});

const renderEnergyChart = (unitEnergy, sellPrice, b, intl, pricePlan, isCharge = false) => {
  const roundB = Math.round(b);
  const absB = Math.abs(b);

  const fractionDigits = intl.formatMessage({ id: 'currency.fractionDigits' });
  const currency = intl.formatNumber(absB, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
  const plusOrMinus = (roundB > 0 && '+') || (roundB < 0 && '-') || '';

  const colorScale = isCharge
    ? [chartColor.sellEnergy, chartColor.buyEnergy]
    : [chartColor.sellEnergy, chartColor.consumeEnergy, chartColor.buyEnergy];

  const pieData = isCharge
    ? [
        {
          x: 1,
          y: unitEnergy.sellEnergy * sellPrice,
        },
        { x: 1, y: calcPrice(0, 24, pricePlan, unitEnergy.buyEnergy) },
      ]
    : [
        {
          x: 1,
          y: unitEnergy.sellEnergy,
        },
        { x: 1, y: unitEnergy.consumeEnergy },
        { x: 1, y: getTotalEnergy(unitEnergy.buyEnergy) },
      ];
  return (
    <div style={style.chart}>
      <svg viewBox="40 40 320 160">
        <VictoryPie
          standalone={false}
          colorScale={colorScale}
          startAngle={270}
          endAngle={450}
          innerRadius={90}
          style={{
            data: {
              stroke: '#fafafa',
              strokeWidth: 3,
            },
          }}
          labels={() => null}
          data={pieData}
        />
        {isCharge
          ? [
              <VictoryLabel
                key="balance"
                textAnchor="middle"
                text={intl.formatMessage({ id: 'chartPowerGeneration.balance' })}
                x={200}
                y={145}
                style={chartLavelStyle}
              />,
              <VictoryLabel
                key="currency"
                textAnchor="middle"
                text={intl.formatMessage(
                  { id: 'chartPowerGeneration.currency' },
                  { plusOrMinus, currency }
                )}
                x={200}
                y={175}
                style={{
                  fill: currencyStyle(roundB),
                  fontFamily: chartLavelStyle.fontFamily,
                  fontSize: 24,
                }}
              />,
            ]
          : [
              <VictoryLabel
                key="pg"
                textAnchor="middle"
                text={intl.formatMessage({ id: 'chartPowerGeneration.pg' })}
                x={210}
                y={145}
                style={chartLavelStyle}
              />,
              <VictoryLabel
                key="energy"
                textAnchor="middle"
                text={formatEnergy({
                  intl,
                  energy: unitEnergy.pgEnergy,
                  unit: intl.formatMessage({ id: 'chartPowerGeneration.axisUnit' }),
                })}
                x={200}
                y={175}
                style={{
                  fill: chartLavelStyle.fill,
                  fontFamily: chartLavelStyle.fontFamily,
                  fontSize: 22,
                }}
              />,
            ]}
        <Line
          x1={51}
          y1={200}
          x2={349}
          y2={200}
          style={{ stroke: chartColor.consumeEnergy, strokeWidth: 8 }}
        />

        {!isCharge && (
          <svg viewBox="-138 -63 200 100" width={240} height={240}>
            <Path d={pgBoxUp} style={{ fill: chartColor.sellEnergy }} />
            <Path d={pgBoxDown} style={{ fill: chartColor.pgBoxDown }} />
          </svg>
        )}
      </svg>
    </div>
  );
};

const renderEnergy = (
  unitEnergy,
  sellPrice,
  intl,
  chartWidth,
  pricePlan,
  idsToShow,
  pv,
  eneFarmData
) => {
  // 発電機器が複数存在するか
  const isPv =
    (idsToShow || []).filter(
      id => pgIds.includes(id) && applianceId.dischargedHybridStorageBattery !== id
    ).length > 1;

  const eneFarmTotal =
    (eneFarmData && eneFarmData.energy && getTotalEnergy(eneFarmData.energy)) || 0;
  const eneFarmEnergy = eneFarmTotal * sellPrice;
  const pvTotal = (pv && pv.energy && getTotalEnergy(pv.energy)) || 0;
  const pvEnergy = pvTotal * sellPrice;
  const legendAreaPg =
    chartWidth > 325 && pvEnergy < 1000000 && eneFarmEnergy < 1000000
      ? style.legendAreaPg
      : { ...style.legendAreaPg, fontSize: '0.8em' };

  return (
    <div>
      {renderEnergyChart(unitEnergy, sellPrice, 0, intl, pricePlan)}
      <div style={legendAreaPg}>
        {isPv && [
          <div key="legendItem" style={style.legendItemPv}>
            <div style={style.legendValueAreaPv}>
              <div style={{ ...style.legendIcon, ...style.legendGeneratedIcon }} />
              <div style={style.legendValueLeft}>
                {intl.formatMessage({ id: 'chartPowerGeneration.pg' })}
              </div>
            </div>
          </div>,

          <div key="legendItemPg" className="pg-tree" style={style.legendSubItem}>
            <div className={eneFarmData ? 'pg-list' : 'pg-last-list'} style={style.legendItemPv}>
              <div style={style.legendValueAreaPv}>
                <div style={style.legendValueLeft}>
                  {intl.formatMessage({ id: 'chartPowerGeneration.solarPower' })}
                </div>
              </div>
              <div style={style.legendValueRightPv}>
                {formatEnergy({
                  intl,
                  energy: pvTotal,
                  unit: intl.formatMessage({ id: 'chartPowerGeneration.axisUnit' }),
                })}
              </div>
            </div>

            {eneFarmData && (
              <div className="pg-last-list" style={style.legendItemPv}>
                <div style={style.legendValueAreaPv}>
                  <div style={style.legendValueLeft}>
                    {intl.formatMessage({ id: 'chartPowerGeneration.eneFarm' })}
                  </div>
                </div>
                <div style={style.legendValueRightPv}>
                  {formatEnergy({
                    intl,
                    energy: eneFarmTotal,
                    unit: intl.formatMessage({ id: 'chartPowerGeneration.axisUnit' }),
                  })}
                </div>
              </div>
            )}
          </div>,
        ]}

        <div style={style.legendItem}>
          <div style={style.legendValueAreaPv}>
            <div style={{ ...style.legendIcon, ...style.legendSoldIcon }} />
            <div style={style.legendValueLeft}>
              {intl.formatMessage({ id: 'chartPowerGeneration.sellCharge' })}
            </div>
          </div>
          <div style={style.legendValueRightPv}>
            {formatEnergy({
              intl,
              energy: unitEnergy.sellEnergy,
              unit: intl.formatMessage({ id: 'chartPowerGeneration.axisUnit' }),
            })}
          </div>
        </div>

        <div style={style.legendItem}>
          <div style={style.legendValueAreaPv}>
            <div style={{ ...style.legendIcon, ...style.legendConsumedIcon }} />
            <div style={style.legendValueLeft}>
              {intl.formatMessage({ id: 'chartPowerGeneration.self' })}
            </div>
          </div>
          <div style={style.legendValueRightPv}>
            {formatEnergy({
              intl,
              energy: unitEnergy.consumeEnergy,
              unit: intl.formatMessage({ id: 'chartPowerGeneration.axisUnit' }),
            })}
          </div>
        </div>

        <div style={style.legendItem}>
          <div style={style.legendValueAreaPv}>
            <div style={{ ...style.legendIcon, ...style.legendBoughtIcon }} />
            <div style={style.legendValueLeft}>
              {intl.formatMessage({ id: 'chartPowerGeneration.buy' })}
            </div>
          </div>
          <div style={style.legendValueRightPv}>
            {formatEnergy({
              intl,
              energy: getTotalEnergy(unitEnergy.buyEnergy),
              unit: intl.formatMessage({ id: 'chartPowerGeneration.axisUnit' }),
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const renderCharge = (unitEnergy, sellPrice, b, intl, chartWidth, pricePlan) => (
  <div>
    {renderEnergyChart(unitEnergy, sellPrice, b, intl, pricePlan, true)}
    <div style={chartWidth > 325 ? style.legendArea : { ...style.legendArea, fontSize: '0.8em' }}>
      <div style={style.legendItem}>
        <div style={style.legendValueArea}>
          <div style={{ ...style.legendIcon, ...style.legendSoldIcon }} />
          <div style={style.legendValueLeft}>
            {intl.formatMessage({ id: 'chartPowerGeneration.sellCharge' })}
          </div>
        </div>
        <div style={style.legendValueCenter}>
          {formatEnergy({
            intl,
            energy: unitEnergy.sellEnergy,
            unit: intl.formatMessage({ id: 'chartPowerGeneration.axisUnit' }),
          })}
        </div>
        <div style={style.legendValueRight}>
          <Currency value={unitEnergy.sellEnergy * sellPrice} />
        </div>
      </div>

      <div style={style.legendItem}>
        <div style={style.legendValueArea}>
          <div style={{ ...style.legendIcon, ...style.legendBoughtIcon }} />
          <div style={style.legendValueLeft}>
            {intl.formatMessage({ id: 'chartPowerGeneration.buy' })}
          </div>
        </div>
        <div style={style.legendValueCenter}>
          {formatEnergy({
            intl,
            energy: getTotalEnergy(unitEnergy.buyEnergy),
            unit: intl.formatMessage({ id: 'chartPowerGeneration.axisUnit' }),
          })}
        </div>
        <div style={style.legendValueRight}>
          <Currency value={calcPrice(0, 24, pricePlan, unitEnergy.buyEnergy)} />
        </div>
      </div>
    </div>
  </div>
);

const renderChartArea = (
  unitEnergy,
  sellPrice,
  b,
  intl,
  chartWidth,
  pricePlan,
  chartType,
  idsToShow,
  pv,
  eneFarmData,
  dispatch
) => (
  <div>
    <div style={style.chartTypeSwitch}>
      <div
        style={{ ...chartTypeStyle('balance', chartType), ...style.chartTypeLeft }}
        onClick={() => dispatch({ type: CHANGE_CHART_TYPE, contentType: 'balance' })}
      >
        {intl.formatMessage({ id: 'chartPowerGeneration.balance' })}
      </div>
      <div
        style={{ ...chartTypeStyle('details', chartType), ...style.chartTypeRight }}
        onClick={() => dispatch({ type: CHANGE_CHART_TYPE, contentType: 'details' })}
      >
        {intl.formatMessage({ id: 'chartPowerGeneration.pg' })}
      </div>
    </div>

    {chartType === 'balance' && renderCharge(unitEnergy, sellPrice, b, intl, chartWidth, pricePlan)}
    {chartType === 'details' &&
      renderEnergy(unitEnergy, sellPrice, intl, chartWidth, pricePlan, idsToShow, pv, eneFarmData)}
  </div>
);

const renderLoadingMessage = intl => (
  <div style={{ ...style.resultArea, ...style.resultNegative }}>
    {intl.formatMessage({ id: 'application.dataLoading' })}
  </div>
);

const renderBody = (
  requesting,
  unitEnergy,
  sellPrice,
  period,
  intl,
  size,
  pricePlan,
  chartType,
  idsToShow,
  applianceEnergy,
  dispatch
) => {
  const { sellEnergy, buyEnergy } = unitEnergy;
  const pv = getAppliancePgEnergy(idsToShow, applianceEnergy, period, applianceId.pv);
  const eneFarmData = getAppliancePgEnergy(idsToShow, applianceEnergy, period, applianceId.eneFarm);

  if (requesting) {
    return (
      <div>
        <Loading space={20} />
        <div style={style.clear}>{requesting && renderLoadingMessage(intl)}</div>
      </div>
    );
  } else if (sellEnergy + getTotalEnergy(buyEnergy) > 0) {
    return (
      <div>
        {renderChartArea(
          unitEnergy,
          sellPrice,
          balance(sellPrice, sellEnergy, buyEnergy, pricePlan),
          intl,
          size.width,
          pricePlan,
          chartType,
          idsToShow,
          pv,
          eneFarmData,
          dispatch
        )}
        <div style={style.clear}>{requesting && renderLoadingMessage(intl)}</div>
      </div>
    );
  }
  return <ChartNodata type={CHART_NO_DATA} />;
};

type StateProps = {|
  sellPrice: number,
  pricePlan: PricePlanState,
  chartType: string,
  idsToShow: (string | null)[],
  applianceEnergy: ApplianceEnergyDetailState[],
|};
type OwnProps = {|
  requesting: boolean,
  unitEnergy: {
    sellEnergy: number,
    buyEnergy: number[],
    consumeEnergy: number,
    pgEnergy: number,
  },
  period: string,
  intl: intlShape,
  size: {
    width: number,
    height: number,
  },
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

export const PureChartPowerGeneration = ({
  requesting,
  unitEnergy,
  sellPrice,
  period,
  intl,
  size,
  chartType,
  idsToShow,
  applianceEnergy,
  dispatch,
  pricePlan,
}: Props): Node => (
  <div style={style.wrapper}>
    <div style={requesting ? { ...style.info, top: '-30px' } : style.info}>
      <QuestionIcon
        onClick={e => {
          dispatch({
            type: OPEN_POP_UP,
            contentType: HELP_CHART_PG,
            target: e.target,
          });
        }}
      />
    </div>
    <div style={style.body}>
      {renderBody(
        requesting,
        unitEnergy,
        sellPrice,
        period,
        intl,
        size,
        pricePlan,
        chartType,
        idsToShow,
        applianceEnergy,
        dispatch
      )}
    </div>
  </div>
);

const mapStateToProps = (state: State): StateProps => {
  const { unit } = state.chartTime;
  const applianceEnergy = unit ? state.applianceEnergy[timeUnitStyle[unit].toUnit] : [];
  return {
    sellPrice: state.userSetting.sellPrice,
    pricePlan: state.pricePlan,
    chartType: state.chartTime.chartType,
    idsToShow: state.applianceState.idsToShow,
    applianceEnergy,
  };
};

const config = { monitorHeight: true };
const sizeMeHOC = sizeMe(config);

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  sizeMeHOC(injectIntl(PureChartPowerGeneration))
);
