// @flow

import React from 'react';
import type { Node } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import moment from 'moment-timezone';
import Scroll from 'react-scroll';
import Card from 'Client/components/utils/Card';
import ChartTimeUnit from 'Client/components/ChartTimeUnit';
import ChartPeriod from 'Client/components/ChartPeriod';
import ChartPowerGeneration from 'Client/components/ChartPowerGeneration';
import ChartEnergyDetails from 'Client/components/ChartEnergyDetails';
import ChartChargeAtDay from 'Client/components/ChartChargeAtDay';
import ChartGeneratedAtDay from 'Client/components/ChartGeneratedAtDay';
import ChartStorageBattery from 'Client/components/ChartStorageBattery';
import ChartDayStats from 'Client/components/ChartDayStats';
import ChartCharge from 'Client/components/ChartCharge';
import ChartSubUnit from 'Client/components/ChartSubUnit';
import ChartIndividualMeasurement from 'Client/components/ChartIndividualMeasurement';
import ChartApplianceRanking from 'Client/components/ChartApplianceRanking';
import ContentContainer from 'Client/components/utils/ContentContainer';
import {
  getEnergy,
  subTimeUnit,
  timeUnitStyle,
  startDates,
  isDailyChartAccessibleProvider,
} from 'Client/lib/chartUtils';
import { applianceId } from 'Client/lib/id';
import Prefixer from 'Client/lib/Prefixer';
import type { ApplianceEnergyState } from 'Client/types/state';
import type { SplitEnergiesData } from 'Client/types/types';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  content: {
    background: '#efefef',
    paddingBottom: '10px',
  },
  footer: {
    width: '100%',
    margin: '20px auto 0px',
    padding: '8px 0px',
    textAlign: 'center',
    borderTop: '1px solid #e5e5e5',
    background: '#fff',
    fontSize: '0.8em',
    fontWeight: 'bold',
    clear: 'both',
  },
});

type UnitEnergyList = {
  day?: string,
  week?: string,
  month?: string,
  year?: string,
  sellEnergy: number,
  buyEnergy: 0 | (number | null)[],
  consumeEnergy: number,
  pgEnergy: number,
  ...SplitEnergiesData,
}[];

type Props = {
  requesting: boolean,
  subRequesting: boolean,
  applianceEnergy: ApplianceEnergyState,
  unitEnergyList: UnitEnergyList,
  subUnitEnergyList: {
    week: string,
    month: string,
    year: string,
    sellEnergy: number,
    buyEnergy: number[],
    consumeEnergy: number,
    pgEnergy: number,
  }[],
  unitDailyEnergyList: UnitEnergyList,
  unit: string,
  period: string,
  idsToShow: (string | null)[],
  serviceProviderId: string,
  isPg: boolean,
  isZeh: Boolean,
  intl: intlShape,
};

const unitEnergy = (unitEnergyList, unit, argPeriod) => {
  const period = argPeriod || moment().format('YYYY-MM');
  const energy = getEnergy(unitEnergyList, unit, period) || {
    whp: 0,
    sellEnergy: 0,
    buyEnergy: 0,
    consumeEnergy: 0,
    pgEnergy: 0,
    chargedEnergy: 0,
    dischargedEnergy: 0,
  };
  return {
    whp: energy.whp,
    sellEnergy: energy.sellEnergy,
    buyEnergy: energy.buyEnergy,
    consumeEnergy: energy.consumeEnergy,
    pgEnergy: energy.pgEnergy,
    chargedEnergy: energy.chargedEnergy,
    dischargedEnergy: energy.dischargedEnergy,
  };
};

const subUnitEnergyListOfPeirod = (subUnitEnergyList, unit, period) => {
  const subUnit = subTimeUnit[unit];
  const startDateList = startDates(unit, period);
  const result = startDateList.map(startDate => {
    const energy = getEnergy(subUnitEnergyList, subUnit, startDate) || {
      period: '',
      whp: 0,
      sellEnergy: 0,
      buyEnergy: 0,
    };
    return {
      period: energy.period || energy[subUnit],
      whp: energy.whp,
      sellEnergy: energy.sellEnergy,
      buyEnergy: energy.buyEnergy,
    };
  });
  return result;
};

const hasPg = (
  unitEnergyList: UnitEnergyList,
  period: string,
  idsToShow: (string | null)[]
): boolean => {
  const matchedEnergy = unitEnergyList.find(energy => energy.day === period);
  if (!matchedEnergy) {
    return false;
  }
  return (
    !!matchedEnergy.pgEnergy ||
    (idsToShow.includes(applianceId.dischargedHybridStorageBattery) &&
      !!matchedEnergy.dischargedEnergy)
  );
};

const hasBattery = (
  applianceData: Object[],
  period: string,
  unit: string,
  idsToShow: (string | null)[]
): boolean => {
  if (unit === 'day') {
    const matchedApplianceEnergy = applianceData.find(data => data.dateTime === period);
    if (!matchedApplianceEnergy) {
      return false;
    }
    return matchedApplianceEnergy.applianceEnergy.some(
      // (994_charged || 9972_charged): 充電 / (994_discharged || 9972_discharged): 放電。いずれかが見つかればデータ有りとする
      appliance =>
        ((idsToShow.find(id => id && /^994/.test(id)) && /^994/.test(appliance.id)) ||
          (idsToShow.find(id => id && /^9972/.test(id)) && /^9972/.test(appliance.id))) &&
        appliance.energy.some(energy => energy)
    );
  }

  const startDateList = startDates(unit, period);
  const result = startDateList.map(startDate =>
    applianceData.find(data => data.dateTime === startDate)
  );

  return (
    result.filter(
      data =>
        data &&
        data.applianceEnergy &&
        data.applianceEnergy.some(
          // (994_charged || 9972_charged): 充電 / (994_discharged || 9972_discharged): 放電。いずれかが見つかればデータ有りとする
          appliance =>
            (/^994/.test(appliance.id) || /^9972/.test(appliance.id)) &&
            appliance.energy.some(energy => energy)
        )
    ).length > 0
  );
};

const isStorageBattery = (idsToShow: (string | null)[]) =>
  !!idsToShow.find(id => id && (/^994/.test(id) || /^9972/.test(id)));

const getBatteryEnergies = (
  applianceData: Object[],
  unit: string,
  period: string,
  idsToShow: (string | null)[]
): {
  storageBattery: {
    dataTime: string,
    chargedEnergies: number[],
    dischargedEnergies: number[],
  }[],
} => {
  const startDateList = startDates(unit, period);
  const storageBattery = {
    storageBattery: startDateList.map(startDate => {
      const matchedApplianceEnergy = applianceData.find(data => data.dateTime === startDate);

      if (!matchedApplianceEnergy) {
        return {
          dataTime: startDate,
          chargedEnergies: [],
          dischargedEnergies: [],
        };
      }

      const chargedStorageBattery = matchedApplianceEnergy.applianceEnergy.find(
        appliance =>
          (idsToShow.includes(applianceId.chargedStorageBattery) &&
            appliance.id === applianceId.chargedStorageBattery) ||
          (idsToShow.includes(applianceId.chargedHybridStorageBattery) &&
            appliance.id === applianceId.chargedHybridStorageBattery)
      );
      const dischargedStorageBattery = matchedApplianceEnergy.applianceEnergy.find(
        appliance =>
          (idsToShow.includes(applianceId.dischargedStorageBattery) &&
            appliance.id === applianceId.dischargedStorageBattery) ||
          (idsToShow.includes(applianceId.dischargedHybridStorageBattery) &&
            appliance.id === applianceId.dischargedHybridStorageBattery)
      );

      return {
        dataTime: startDate,
        chargedEnergies: chargedStorageBattery ? chargedStorageBattery.energy : [],
        dischargedEnergies: dischargedStorageBattery ? dischargedStorageBattery.energy : [],
      };
    }),
  };

  return storageBattery;
};

/**
 * 日付がマッチする日データから、時間分割されたデータを選別して返す
 */
const getSplitEnergy = (
  unitEnergyList: UnitEnergyList,
  period: string
): { [splitEnergy: string]: (number | null)[] } => {
  const matchedEnergy = unitEnergyList.find(energy => energy.day === period);
  if (!matchedEnergy) {
    return {};
  }

  const splitEnergy = {};
  Object.keys(matchedEnergy)
    .filter(key => /^split/.test(key))
    .forEach(splitEnergyKey => {
      // /^split/.test(key) で型保証済み
      const castedEnergies = ((matchedEnergy[splitEnergyKey]: any): (number | null)[]);
      splitEnergy[splitEnergyKey] = castedEnergies;
    });

  return splitEnergy;
};

const getStorageBatterySplitEnergy = (
  unitEnergyList: UnitEnergyList,
  period: string,
  unit: string
): {
  splitEnergies: {
    dataTime: string,
    [splitEnergy: string]: (number | null)[],
  }[],
} => {
  const startDateList = startDates(unit, period);
  const splitEnergies = {
    splitEnergies: startDateList.map(startDate => {
      const matchedEnergy = unitEnergyList.find(energy => energy.day === startDate);

      if (!matchedEnergy) {
        return {
          dataTime: startDate,
          splitWhp: [],
          splitSellEnergy: [],
          splitBuyEnergy: [],
          splitConsumeEnergy: [],
          splitPgEnergy: [],
        };
      }

      const splitEnergy = {};
      Object.keys(matchedEnergy)
        .filter(key => /^split/.test(key))
        .forEach(splitEnergyKey => {
          // /^split/.test(key) で型保証済み
          const castedEnergies = ((matchedEnergy[splitEnergyKey]: any): (number | null)[]);
          splitEnergy[splitEnergyKey] = castedEnergies;
        });

      return {
        dataTime: startDate,
        ...splitEnergy,
      };
    }),
  };

  return splitEnergies;
};

const PureChartBody = ({
  requesting,
  subRequesting,
  applianceEnergy,
  unitEnergyList,
  subUnitEnergyList,
  unitDailyEnergyList,
  unit,
  period,
  idsToShow,
  serviceProviderId,
  isPg,
  isZeh,
  intl,
}: Props): Node => {
  const isVisiblePowerGeneration = isPg && !isStorageBattery(idsToShow);
  const isVisibleEnergyDetails = isPg && isStorageBattery(idsToShow);
  const isVisibleChargeChartAtDay = !isPg && unit === 'day';
  const isVisibleGeneratedAtDay =
    isPg && unit === 'day' && (hasPg(unitEnergyList, period, idsToShow) || isZeh);
  const isVisibleStorageBatteryChart =
    (unit === 'day' || (isZeh && unit === 'week')) &&
    hasBattery(applianceEnergy.daily, period, unit, idsToShow);
  const isVisibleDayStats = unit === 'day' && isDailyChartAccessibleProvider(serviceProviderId);
  const isVisibleCharge = !isPg && unit !== 'day';
  const isVisibleSubUnit = unit !== 'day';
  const isVisibleIndividualChart = isZeh && (unit === 'day' || unit === 'week');
  const isVisibleApplianceRanking = unit !== 'day';

  return (
    <div>
      <ChartTimeUnit requesting={requesting || subRequesting || applianceEnergy.requesting} />
      <ContentContainer style={style.content}>
        <ChartPeriod period={period} />
        {isVisiblePowerGeneration && (
          <Card>
            <ChartPowerGeneration
              requesting={requesting}
              unitEnergy={unitEnergy(unitEnergyList, unit, period)}
              period={period}
            />
          </Card>
        )}
        {isVisibleEnergyDetails && (
          <Card>
            <ChartEnergyDetails
              requesting={requesting}
              unitEnergy={unitEnergy(unitEnergyList, unit, period)}
              period={period}
            />
          </Card>
        )}
        {isVisibleChargeChartAtDay && (
          <ChartChargeAtDay
            requesting={requesting}
            buyEnergy={unitEnergy(unitEnergyList, unit, period).buyEnergy}
            period={period}
          />
        )}
        {isVisibleGeneratedAtDay && (
          <ChartGeneratedAtDay
            requesting={requesting}
            {...getSplitEnergy(unitEnergyList, period)}
            period={period}
          />
        )}
        {isVisibleDayStats && (
          <Card>
            <ChartDayStats period={period} />
          </Card>
        )}
        {isVisibleCharge && (
          <Card>
            <ChartCharge
              requesting={requesting}
              buyEnergy={unitEnergy(unitEnergyList, unit, period).buyEnergy}
              unit={unit}
            />
          </Card>
        )}
        {isVisibleSubUnit && (
          <Card>
            <ChartSubUnit
              requesting={subRequesting}
              unitEnergyList={subUnitEnergyListOfPeirod(subUnitEnergyList, unit, period)}
              unit={unit}
              period={period}
            />
          </Card>
        )}
        {isVisibleStorageBatteryChart && (
          <Card>
            <ChartStorageBattery
              {...getStorageBatterySplitEnergy(unitDailyEnergyList, period, unit)}
              {...getBatteryEnergies(applianceEnergy.daily, unit, period, idsToShow)}
              unit={unit}
              period={period}
            />
          </Card>
        )}
        {isVisibleIndividualChart && (
          <Card>
            <ChartIndividualMeasurement
              requesting={requesting || applianceEnergy.requesting}
              unitEnergyList={subUnitEnergyListOfPeirod(subUnitEnergyList, unit, period)}
              applianceEnergy={unit ? applianceEnergy[timeUnitStyle[subTimeUnit[unit]].toUnit] : []}
              unit={unit}
              period={period}
            />
          </Card>
        )}
        {isVisibleApplianceRanking && (
          <Card>
            <ChartApplianceRanking requesting={applianceEnergy.requesting} />
          </Card>
        )}
      </ContentContainer>
      <div style={style.footer}>
        <div onClick={() => Scroll.animateScroll.scrollToTop({ duration: 500 })}>
          {intl.formatMessage({ id: 'chartBody.toTop' })}
        </div>
      </div>
    </div>
  );
};

export default injectIntl(PureChartBody);

export const PureChartBodyForTest = PureChartBody;
