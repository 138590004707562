// @flow
/* eslint-env browser */

import type { Action } from 'redux';
import { overWriteStateWithFirebase } from 'Client/lib/settingUtils';
import type { PopupState as State } from 'Client/types/state';
import moment from 'moment-timezone';
import {
  OPEN_POP_UP,
  CLOSING_POP_UP,
  CLOSED_POP_UP,
  TUTORIAL_FINISHED,
  FAILURE_API_USER_STATUS,
  SUCCESS_API_USER_STATUS,
  FAILURE_API_TIME_SERIES,
  REQUEST_API_TIME_SERIES,
  REQUEST_API_ENERGY_HOURLY,
  SUCCESS_API_ENERGY_HOURLY,
  FAILURE_API_ENERGY_HOURLY,
  REQUEST_API_USE_STATS,
  FAILURE_API_USE_STATS,
  FAILURE_API_LOGIN,
  SUCCESS_API_LOGIN_INFO,
  SUCCESS_API_LOGOUT,
  OPEN_PRICE_PLAN_POP_UP,
  GO_TO_NORMAL_POP_UP,
  GO_TO_MULTISTAGE_POP_UP,
  SETTING_NORMAL_PRICE_PLAN_POP_UP,
  SETTING_MULTISTAGE_PRICE_PLAN_POP_UP,
} from 'Client/actions/types';
import {
  SERVICE_UNAVAILABLE,
  NETWORK_DISCONNECTED,
  APPLIANCE_FOUND,
  CONTRACT_EXPIRED,
  USER_STATUS_ERROR,
  SESSION_EXPIRED,
} from 'Client/actions/alertPopupTypes';
import { isEmbeded } from '../lib/serviceCheck';

const initialState = {
  open: false,
  contentType: '', // dialogType
  status: null,
  information: null,
  target: {},
  haveShownStartDialog: false,
  haveShownSettingPricePlanDialog: false,
  lastDateNoDataFor24HoursShown: 0,
  lastDateUnavailableShown: 0,
};

export default (state: State = initialState, action: Action = {}): State => {
  const { error, type, contentType, payload, target } = action;
  switch (type) {
    case REQUEST_API_USE_STATS:
    case REQUEST_API_ENERGY_HOURLY:
    case REQUEST_API_TIME_SERIES: {
      return error
        ? {
            ...state,
            open: true,
            contentType: NETWORK_DISCONNECTED,
            status: payload.status,
          }
        : state;
    }
    case FAILURE_API_USE_STATS:
    case FAILURE_API_ENERGY_HOURLY:
    case FAILURE_API_TIME_SERIES: {
      if (moment(state.lastDateUnavailableShown).isSame(moment().toISOString(), 'day')) {
        return state;
      }
      if (isEmbeded && action.payload.status === 401) {
        return {
          ...state,
          open: true,
          contentType: SESSION_EXPIRED,
          lastDateUnavailableShown: moment().toISOString(),
        };
      }
      return error
        ? {
            ...state,
            open: true,
            contentType: SERVICE_UNAVAILABLE,
            lastDateUnavailableShown: moment().toISOString(),
          }
        : state;
    }
    case FAILURE_API_USER_STATUS: {
      if (action.payload.status === 502 || action.payload.status === 500) {
        return {
          ...state,
          open: true,
          contentType: USER_STATUS_ERROR,
          lastDateUnavailableShown: moment().toISOString(),
        };
      }
      return state;
    }
    case SUCCESS_API_ENERGY_HOURLY: {
      if (moment(state.lastDateUnavailableShown).isSame(moment().toISOString(), 'day')) {
        return state;
      }
      return payload.length === 0 // payload が無いときはdarastoreAccessに失敗している
        ? {
            ...state,
            open: true,
            contentType: SERVICE_UNAVAILABLE,
            lastDateUnavailableShown: moment().toISOString(),
          }
        : state;
    }
    case TUTORIAL_FINISHED: {
      if (!state.haveShownStartDialog) {
        return {
          ...state,
          open: true,
          contentType: APPLIANCE_FOUND,
          haveShownStartDialog: true,
        };
      }
      return state;
    }
    case SUCCESS_API_LOGIN_INFO: {
      if (payload.settings) {
        return overWriteStateWithFirebase(state, payload.settings);
      }
      return state;
    }
    case SUCCESS_API_USER_STATUS: {
      const { user = {} } = action.payload || {};
      if (
        user.contractor_state === 'contract_expired' ||
        user.contractor_state === 'imt4_app_unavailable'
      ) {
        return {
          ...state,
          open: true,
          contentType: CONTRACT_EXPIRED,
        };
      }
      return {
        ...state,
        open: false,
        contentType: '',
        status: null,
        information: null,
        target: {},
      };
    }
    case OPEN_POP_UP: {
      return {
        ...state,
        open: true,
        contentType,
        target,
      };
    }
    case OPEN_PRICE_PLAN_POP_UP: {
      return {
        ...state,
        haveShownSettingPricePlanDialog: true,
        open: true,
        contentType: 'pricePlan',
      };
    }
    case GO_TO_NORMAL_POP_UP: {
      return {
        ...state,
      };
    }
    case GO_TO_MULTISTAGE_POP_UP: {
      return {
        ...state,
      };
    }
    case SETTING_NORMAL_PRICE_PLAN_POP_UP: {
      return {
        ...state,
        open: action.isErr || false,
      };
    }
    case SETTING_MULTISTAGE_PRICE_PLAN_POP_UP: {
      return {
        ...state,
        open: action.isErr || false,
      };
    }
    case CLOSING_POP_UP: {
      return {
        ...state,
        open: false,
      };
    }
    case CLOSED_POP_UP: {
      return {
        ...state,
        open: false,
        contentType: '',
        status: null,
        information: null,
        target: {},
      };
    }
    case SUCCESS_API_LOGOUT: {
      switch (state.contentType) {
        case CONTRACT_EXPIRED:
          return state;
        case SESSION_EXPIRED:
          return state;
        default:
          return initialState;
      }
    }
    case FAILURE_API_LOGIN: {
      if (
        action.payload.status === 403 &&
        action.payload.response &&
        action.payload.response.error &&
        action.payload.response.error === 'plan 1010 is expired'
      ) {
        return {
          ...state,
          open: true,
          contentType: CONTRACT_EXPIRED,
        };
      }
      return state;
    }
    default: {
      return state;
    }
  }
};
