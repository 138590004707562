// @flow

import moment from 'moment-timezone';
import React, { Component, Fragment } from 'react';
import type { Dispatch } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import type { ContextRouter } from 'react-router-dom';
import FadeTransition from 'Client/components/utils/FadeTransition';
import HeaderBar from 'Client/components/HeaderBar';
import Prefixer from 'Client/lib/Prefixer';
import ChartBody from 'Client/components/ChartBody';
import {
  apiGetUseStatsForLastYear,
  apiGetPowersByDay,
  apiGetEnergyHourlyByUnit,
  apiGetEnergyHourlyBySubUnit,
} from 'Client/actions/apis';
import {
  subTimeUnit,
  timeUnitStyle,
  timeFormat,
  isDailyChartAccessibleProvider,
  isValidDateFormat,
  hourlyRequestUnit,
  getSubUnitDateTime,
  getEnergy,
  timeUnitList,
} from 'Client/lib/chartUtils';
import {
  makeValidPeriod,
  getHourlyRequestMeta,
  getHourlyRequestMetaSub,
} from 'Client/lib/globalUtils';
import { applianceId } from 'Client/lib/id';
import { LOAD_CHART_PAGE, CLOSING_POP_UP } from 'Client/actions/types';
import type { State, ApplianceEnergyState, CastedSplitEnergies } from 'Client/types/state';
import type { SplitEnergiesData } from 'Client/types/types';

import AlertTriangle from 'Client/images/alert_white@2x.png';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  content: {
    textAlign: 'center',
    marginTop: '25px',
    maxWidth: '980px',
    margin: '0px auto',
    color: '#847965',
  },
  boxUp: {
    width: '100%',
    padding: '105px 0px 0px',
  },
  noSolarAlert: {
    background: '#F44E4E',
    color: 'white',
    padding: '0.8rem',
    textAlign: 'left',
    margin: '0.6rem 0.6rem 0 0.6rem',
    borderRadius: '0.2rem',
    fontSize: 'smaller',
  },
  alertTriangle: {
    height: '1.4rem',
    verticalAlign: 'top',
    marginRight: 10,
  },
});

type UnitEnergyList = {
  week?: string,
  month?: string,
  year?: string,
  sellEnergy: number,
  buyEnergy: number[],
  consumeEnergy?: number,
  pgEnergy?: number,
  ...SplitEnergiesData,
}[];
type StateProps = {|
  requesting: boolean,
  subRequesting: boolean,
  applianceEnergy: ApplianceEnergyState,
  isPg: boolean,
  unitEnergyList: UnitEnergyList,
  subUnitEnergyList: UnitEnergyList,
  unitDailyEnergyList: (CastedSplitEnergies & {
    day: string,
    sellEnergy: number,
    buyEnergy: number[],
    consumeEnergy: number,
    pgEnergy: number,
  })[],
  unit: string,
  period: string,
  idsToShow: (string | null)[],
  isZeh: boolean,
  learningStartDate: string,
  serviceProviderId: string,
  alertInfo: {
    noUpload: boolean,
    noSolar: boolean,
  },
|};
type OwnProps = {|
  ...ContextRouter,
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

const createValidUnit = (unit: string): { isValidUnit: boolean, validUnit: string } => {
  const isValidUnit = !!timeUnitList.find(ele => ele === unit);
  return { isValidUnit, validUnit: isValidUnit ? unit : 'day' };
};

const createValidPeriod = (
  unit: string,
  period: string,
  learningStartDate: string
): { isValidPeriod: boolean, validPeriod: string } => {
  // フォーマットチェック
  if (!isValidDateFormat(unit, period) || !moment(period, timeFormat[unit]).isValid()) {
    return { isValidPeriod: false, validPeriod: makeValidPeriod(unit, moment()) };
  }

  // 未来日チェック
  const inputInMoment = moment(period, timeFormat[unit]);
  if (moment().isBefore(inputInMoment)) {
    return { isValidPeriod: false, validPeriod: makeValidPeriod(unit, moment()) };
  }

  // 学習開始日チェック
  const roundedStartDate = moment(learningStartDate).format(timeFormat[unit]);
  const isStartDateAfterInput =
    unit === 'week'
      ? moment(roundedStartDate, timeFormat[unit])
          .subtract(7, 'days')
          .isAfter(inputInMoment)
      : moment(roundedStartDate, timeFormat[unit]).isAfter(inputInMoment);
  if (isStartDateAfterInput) {
    return {
      isValidPeriod: false,
      validPeriod: makeValidPeriod(unit, moment(roundedStartDate, timeFormat[unit])),
    };
  }

  // 曜日チェック
  if (unit === 'week' && moment(period).day() !== 0) {
    return { isValidPeriod: false, validPeriod: makeValidPeriod(unit, moment(period)) };
  }

  return { isValidPeriod: true, validPeriod: period };
};

const getValidatedData = (
  unit: ?string,
  period: ?string,
  learningStartDate: string
): {
  isValidUnit: boolean,
  validUnit: string,
  isValidPeriod: boolean,
  validPeriod: string,
} => {
  const { isValidUnit, validUnit } = createValidUnit(unit || 'month');
  const { isValidPeriod, validPeriod } = createValidPeriod(
    validUnit,
    period || moment().format('YYYY-MM'),
    learningStartDate
  );
  return { isValidUnit, validUnit, isValidPeriod, validPeriod };
};

const NoSolarAlert = ({ noSolar }) => {
  if (noSolar) {
    return (
      <Fragment>
        <div style={style.noSolarAlert}>
          <img src={AlertTriangle} style={style.alertTriangle} alt="houseAlert" />
          <span>太陽光が長期間発電していません</span>
        </div>
      </Fragment>
    );
  }
  return <div />;
};

class ChartPageClass extends Component<Props> {
  componentDidMount() {
    this.props.dispatch({ type: CLOSING_POP_UP });

    const {
      match: {
        params: { unit, period },
      },
      learningStartDate,
    } = this.props;
    const { isValidUnit, validUnit, isValidPeriod, validPeriod } = getValidatedData(
      unit,
      period,
      learningStartDate
    );
    if (!isValidUnit || !isValidPeriod) {
      this.context.router.history.replace(`/chart/${validUnit}/${validPeriod}`);
      return;
    }

    this.dispatchActions(validUnit, validPeriod);
  }

  componentDidUpdate(prevProps: Props) {
    // ブラウザの戻るボタンを押したとき、ここでthis.props.periodが更新されていないので、this.props.match.params.periodを見るようにする
    const {
      match: {
        params: { unit, period },
      },
      learningStartDate,
    } = this.props;

    const { isValidUnit, validUnit, isValidPeriod, validPeriod } = getValidatedData(
      unit,
      period,
      learningStartDate
    );
    if (!isValidUnit || !isValidPeriod) {
      this.context.router.history.replace(`/chart/${validUnit}/${validPeriod}`);
      return;
    }

    // check each props to prevent infinite loops
    if (validUnit !== prevProps.unit || validPeriod !== prevProps.period) {
      this.dispatchActions(validUnit, validPeriod);
    }
  }

  componentWillUnmount() {
    this.props.dispatch({ type: CLOSING_POP_UP });
  }

  dispatchActions(unit: string, period: string) {
    const {
      requesting,
      subRequesting,
      applianceEnergy,
      subUnitEnergyList,
      unitEnergyList,
      idsToShow,
      learningStartDate,
      dispatch,
      serviceProviderId,
    } = this.props;

    if (requesting || subRequesting) {
      return;
    }

    this.props.dispatch({
      type: LOAD_CHART_PAGE,
      payload: { unit, period },
    });

    if (unit === 'day' && isDailyChartAccessibleProvider(serviceProviderId)) {
      dispatch(apiGetPowersByDay(period));
    }

    const shouldRequestHourlyByUnit =
      !getEnergy(unitEnergyList, unit, period) ||
      (unit === 'day' && !applianceEnergy.daily.some(appliance => appliance.dateTime === period));
    if (shouldRequestHourlyByUnit) {
      dispatch(
        apiGetEnergyHourlyByUnit(getHourlyRequestMeta(unit), unit, hourlyRequestUnit[unit], period)
      );
    }
    const subUnitDateTimeList = getSubUnitDateTime(
      unit,
      period,
      learningStartDate,
      subUnitEnergyList
    );
    if (subUnitDateTimeList.length > 0) {
      dispatch(
        apiGetEnergyHourlyBySubUnit(
          getHourlyRequestMetaSub(subTimeUnit[unit]),
          subTimeUnit[unit],
          hourlyRequestUnit[unit],
          subUnitDateTimeList
        )
      );
    }
    // only LIGHT means the initial state
    const { light: LIGHT } = applianceId;
    if (idsToShow && idsToShow.length === 1 && idsToShow[0] === LIGHT) {
      dispatch(apiGetUseStatsForLastYear());
    }
  }

  render() {
    const {
      location,
      requesting,
      subRequesting,
      applianceEnergy,
      unitEnergyList,
      subUnitEnergyList,
      unitDailyEnergyList,
      unit,
      period,
      idsToShow,
      serviceProviderId,
      isPg,
      isZeh,
      alertInfo,
    } = this.props;
    return (
      <FadeTransition pathname={location.pathname}>
        <div key="chartPage">
          <HeaderBar pageType="chart" />
          <div style={style.content}>
            <div style={style.boxUp}>
              <NoSolarAlert noSolar={alertInfo.noSolar} />
              <ChartBody
                requesting={requesting}
                subRequesting={subRequesting}
                applianceEnergy={applianceEnergy}
                unitEnergyList={unitEnergyList}
                subUnitEnergyList={subUnitEnergyList}
                unitDailyEnergyList={unitDailyEnergyList}
                unit={unit}
                period={period}
                idsToShow={idsToShow}
                serviceProviderId={serviceProviderId}
                isPg={isPg}
                isZeh={isZeh}
              />
            </div>
          </div>
        </div>
      </FadeTransition>
    );
  }
}

ChartPageClass.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state: State): StateProps => {
  const energy = state.energy;
  const { unit, period } = state.chartTime;
  const applianceStateRequesting = state.applianceState.useStatsRequesting;
  const requesting =
    (unit && energy[timeUnitStyle[unit].toRequesting]) || applianceStateRequesting || false;
  const subRequesting = unit ? energy[timeUnitStyle[subTimeUnit[unit]].toRequesting] : false;
  const unitEnergyList = unit ? energy[timeUnitStyle[unit].toUnit] : [];
  const subUnitEnergyList = unit ? energy[timeUnitStyle[subTimeUnit[unit]].toUnit] : [];
  const unitDailyEnergyList = unit ? energy.daily : [];
  return {
    requesting,
    subRequesting,
    applianceEnergy: state.applianceEnergy,
    isPg: state.userSetting.isPg,
    unitEnergyList,
    subUnitEnergyList,
    unitDailyEnergyList,
    unit,
    period,
    idsToShow: state.applianceState.idsToShow,
    isZeh: state.userInfo.isZeh,
    learningStartDate: state.userInfo.learningStartDate,
    serviceProviderId: state.userInfo.serviceProviderId,
    alertInfo: state.applianceState.alertInfo,
  };
};

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  ChartPageClass
);

export const createValidUnitForTest = createValidUnit;
export const createValidPeriodForTest = createValidPeriod;
export const ChartPageClassForTest = ChartPageClass;
