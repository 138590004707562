// @flow
import type { State } from 'Client/types/state';

export const HAVE_SHOWN_START_DIALOG = 'haveShownStartDialog';
export const HAVE_SHOWN_SETTING_PRICE_PLAN_DIALOG = 'haveShownSettingPricePlanDialog';
export const LAST_DATE_NO_DATA_FOR_24HOURS_SHOWN = 'lastDateNoDataFor24HoursShown';
export const LAST_DATE_UNAVAILABLE_SHOWN = 'lastDateUnavailableShown';
export const IS_TUTORIAL_FINISHED = 'tutorialFinished';
export const PRICE_PLAN = 'pricePlan';
export const NORMAL_PLAN_PRICE = 'normalPlanPrice';
export const MULTISTAGE_PLAN_SETTING = 'multistagePlanSetting';
export const SELL_PRICE = 'sellPrice';
export const IS_PG = 'isPg';
export const IS_PG_CHANGED_BY_USER = 'isPgChangedByUser';
export const SELECTED_APPLIANCE_ID = 'selectedApplianceTypeId';
export const APL_FILTER = 'aplFilter';
export const LAST_LOGIN_DATE = 'lastLoginDate';

const getItem = id => (key: string, state: Object): any => {
  const stateValue = Array.isArray(state[key]) ? JSON.stringify(state[key]) : state[key];
  if (id) {
    const value = localStorage.getItem(`${id}-${key}`);
    if (value === 'true') {
      return true;
    }
    if (value === 'false') {
      return false;
    }
    return value || stateValue;
  }
  return stateValue;
};

export const loadSettingsFromLocalStorage = (contractorId: string, state: State) => {
  const { popup, userSetting, tutorial, pricePlan, chartTime } = state;
  const getItemByKey = getItem(contractorId);

  // prettier-ignore
  return {
    haveShownStartDialog            : getItemByKey(HAVE_SHOWN_START_DIALOG, popup),
    haveShownSettingPricePlanDialog : getItemByKey(HAVE_SHOWN_SETTING_PRICE_PLAN_DIALOG, popup),
    lastDateNoDataFor24HoursShown   : getItemByKey(LAST_DATE_NO_DATA_FOR_24HOURS_SHOWN, popup),
    lastDateUnavailableShown        : getItemByKey(LAST_DATE_UNAVAILABLE_SHOWN, popup),
    isPg                            : getItemByKey(IS_PG, userSetting),
    isPgChangedByUser               : getItemByKey(IS_PG_CHANGED_BY_USER, userSetting),
    sellPrice                       : Number(getItemByKey(SELL_PRICE, userSetting)),
    aplFilter                       : JSON.parse(getItemByKey(APL_FILTER, userSetting)),
    tutorialFinished                : getItemByKey(IS_TUTORIAL_FINISHED, tutorial),
    pricePlan                       : getItemByKey(PRICE_PLAN, pricePlan) || '',
    normalPlanPrice                 : Number(getItemByKey(NORMAL_PLAN_PRICE, pricePlan)),
    multistagePlanSetting           : JSON.parse(getItemByKey(MULTISTAGE_PLAN_SETTING, pricePlan)),
    selectedApplianceTypeId                : getItemByKey(SELECTED_APPLIANCE_ID, chartTime),
  };
};

const getLocalStorage = id => (key: string): any => {
  if (id) {
    const value = localStorage.getItem(`${id}-${key}`);
    if (value === 'true') {
      return true;
    }
    if (value === 'false') {
      return false;
    }
    return (value && { key: value }) || null;
  }
  return null;
};

// localStorageにデータが存在するかチェックする
export const checkItem = (uid: string) => {
  const getItemByKey = getLocalStorage(uid);

  const haveShownStartDialog = getItemByKey(HAVE_SHOWN_START_DIALOG);
  const haveShownSettingPricePlanDialog = getItemByKey(HAVE_SHOWN_SETTING_PRICE_PLAN_DIALOG);
  const lastDateNoDataFor24HoursShown = getItemByKey(LAST_DATE_NO_DATA_FOR_24HOURS_SHOWN);
  const lastDateUnavailableShown = getItemByKey(LAST_DATE_UNAVAILABLE_SHOWN);
  const isPg = getItemByKey(IS_PG);
  const isPgChangedByUser = getItemByKey(IS_PG_CHANGED_BY_USER);
  const sellPrice = Number(getItemByKey(SELL_PRICE));
  const aplFilter = JSON.parse(getItemByKey(APL_FILTER));
  const tutorialFinished = getItemByKey(IS_TUTORIAL_FINISHED);
  const pricePlan = getItemByKey(PRICE_PLAN) || '';
  const normalPlanPrice = Number(getItemByKey(NORMAL_PLAN_PRICE));
  const multistagePlanSetting = JSON.parse(getItemByKey(MULTISTAGE_PLAN_SETTING));
  const selectedApplianceTypeId = getItemByKey(SELECTED_APPLIANCE_ID);

  return {
    ...haveShownStartDialog,
    ...haveShownSettingPricePlanDialog,
    ...lastDateNoDataFor24HoursShown,
    ...lastDateUnavailableShown,
    ...isPg,
    ...isPgChangedByUser,
    ...sellPrice,
    ...aplFilter,
    ...tutorialFinished,
    ...pricePlan,
    ...normalPlanPrice,
    ...multistagePlanSetting,
    ...selectedApplianceTypeId,
  };
};
