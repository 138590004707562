// @flow

import React from 'react';
import { Redirect } from 'react-router-dom';
import type { ContextRouter } from 'react-router-dom';
import FadeTransition from 'Client/components/utils/FadeTransition';
import ChangeMail from 'Client/components/ChangeMail';
import ChangePassword from 'Client/components/ChangePassword';

type Props = {
  ...ContextRouter,
};

const ChangePage = (props: Props) => {
  switch (props.match.params.type) {
    case 'email':
      return (
        <FadeTransition pathname={props.location.pathname}>
          <ChangeMail />
        </FadeTransition>
      );
    case 'password':
      return (
        <FadeTransition pathname={props.location.pathname}>
          <ChangePassword />
        </FadeTransition>
      );
    default:
      return <Redirect to="/top" />;
  }
};

export default ChangePage;
