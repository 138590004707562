import {
  ENTER_APPLICATION,
  SUCCESS_API_LOGIN_INFO,
  SUCCESS_API_USER_INFO,
  OPEN_PRICE_PLAN_POP_UP,
  GO_TO_NORMAL_POP_UP,
  GO_TO_MULTISTAGE_POP_UP,
  CHANGE_NORMAL_PLAN_PRICE,
  SETTING_NORMAL_PRICE_PLAN_POP_UP,
  SETTING_MULTISTAGE_PRICE_PLAN_POP_UP,
  ADD_PRICE_PLAN,
  SUB_PRICE_PLAN,
  CHANGE_PLAN_PRICE,
  CHANGE_PLAN_TIME_START,
} from 'Client/actions/types';
import { overWriteStateWithFirebase } from 'Client/lib/settingUtils';
import {
  defaultEnergyPrice,
  energyPriceRange,
  defaultMultiStageEnergyPrice,
} from 'Client/lib/localizedValues';
import type { PricePlanState as State } from 'Client/types/state';

const initialState = {
  page: '',
  pricePlan: '',
  normalPlanPrice: 25,
  dispNormalPlanPrice: 25,
  multistagePlanSetting: [{ startTime: 7, price: 25 }, { startTime: 23, price: 20 }],
  dispMultistagePlanSetting: [{ startTime: 7, price: 25 }, { startTime: 23, price: 20 }],
  numberOfPricePlan: 2,
};

const isWithinPriceRangeNomal = price => {
  const { min, max } = energyPriceRange.ja;
  return price >= min && price <= max;
};

const isWithinPriceRangeMultistage = multistagePlanSetting => {
  if (!multistagePlanSetting.length) {
    return false;
  }
  const { min, max } = energyPriceRange.ja;
  return multistagePlanSetting.every(ele => ele.price >= min && ele.price <= max);
};

export default (state: State = initialState, action): State => {
  const { payload } = action;
  switch (action.type) {
    case SUCCESS_API_LOGIN_INFO:
    case SUCCESS_API_USER_INFO: {
      if (payload.settings) {
        const newState = overWriteStateWithFirebase(state, action.payload.settings);
        return {
          ...newState,
          numberOfPricePlan: newState.multistagePlanSetting.length,
        };
      }
      if (payload.pricePlan) {
        return {
          ...state,
          pricePlan: payload.pricePlan,
          normalPlanPrice: payload.normalPlanPrice,
          dispNormalPlanPrice: payload.normalPlanPrice,
          multistagePlanSetting: payload.multistagePlanSetting,
          dispMultistagePlanSetting: [...payload.multistagePlanSetting],
          numberOfPricePlan: payload.multistagePlanSetting.length,
        };
      }
      return {
        ...state,
      };
    }

    case OPEN_PRICE_PLAN_POP_UP: {
      return {
        ...state,
        page: 'SELECT',
      };
    }
    case GO_TO_NORMAL_POP_UP: {
      return {
        ...state,
        page: 'NORMAL',
        dispNormalPlanPrice: isWithinPriceRangeNomal(state.normalPlanPrice)
          ? state.normalPlanPrice
          : defaultEnergyPrice.ja.buy,
      };
    }
    case GO_TO_MULTISTAGE_POP_UP: {
      const dispMultistagePlanSetting = isWithinPriceRangeMultistage(state.multistagePlanSetting)
        ? [...state.multistagePlanSetting]
        : defaultMultiStageEnergyPrice.ja;
      return {
        ...state,
        page: 'MULTISTAGE',
        dispMultistagePlanSetting,
        numberOfPricePlan: dispMultistagePlanSetting.length,
      };
    }
    case CHANGE_NORMAL_PLAN_PRICE: {
      return {
        ...state,
        dispNormalPlanPrice: action.price,
      };
    }
    case SETTING_NORMAL_PRICE_PLAN_POP_UP: {
      if (action.isErr) {
        return state;
      }
      return {
        ...state,
        pricePlan: 'NORMAL',
        normalPlanPrice: action.normalPlanPrice,
        isErr: action.isErr,
      };
    }
    case SETTING_MULTISTAGE_PRICE_PLAN_POP_UP: {
      if (action.isErr) {
        return state;
      }
      return {
        ...state,
        pricePlan: 'MULTISTAGE',
        multistagePlanSetting: action.multistagePlanSetting,
        isErr: action.isErr,
      };
    }
    case ENTER_APPLICATION: {
      const normalPlanPrice = isWithinPriceRangeNomal(state.normalPlanPrice)
        ? state.normalPlanPrice
        : defaultEnergyPrice.ja.buy;
      const multistagePlanSetting = isWithinPriceRangeMultistage(state.multistagePlanSetting)
        ? state.multistagePlanSetting
        : defaultMultiStageEnergyPrice.ja;
      return {
        ...state,
        normalPlanPrice,
        dispNormalPlanPrice: normalPlanPrice,
        multistagePlanSetting,
        dispMultistagePlanSetting: [...multistagePlanSetting],
      };
    }
    case ADD_PRICE_PLAN: {
      const dispMultistagePlanSetting = state.dispMultistagePlanSetting;
      dispMultistagePlanSetting.push({ startTime: '', price: '' });
      if (dispMultistagePlanSetting.some(el => Number(el.startTime) === 7)) {
        return {
          ...state,
          numberOfPricePlan: dispMultistagePlanSetting.length,
          dispMultistagePlanSetting,
        };
      }
      return {
        ...state,
        dispMultistagePlanSetting,
        numberOfPricePlan: dispMultistagePlanSetting.length,
      };
    }
    case SUB_PRICE_PLAN: {
      const dispMultistagePlanSetting = state.dispMultistagePlanSetting.filter(
        (ele, index) => index !== action.index
      );
      return {
        ...state,
        dispMultistagePlanSetting,
        numberOfPricePlan: dispMultistagePlanSetting.length,
      };
    }
    case CHANGE_PLAN_TIME_START: {
      const sameNunber = state.dispMultistagePlanSetting.some(
        el => Number(el.startTime) === Number(action.startTime)
      );
      const dispMultistagePlanSetting = state.dispMultistagePlanSetting.map((ele, index) => {
        if (index === action.index) return { startTime: action.startTime, price: ele.price };
        return ele;
      });
      if (!sameNunber) {
        return {
          ...state,
          dispMultistagePlanSetting,
        };
      }
      return {
        ...state,
        dispMultistagePlanSetting,
      };
    }
    case CHANGE_PLAN_PRICE: {
      const dispMultistagePlanSetting = state.dispMultistagePlanSetting.map((ele, index) => {
        if (index === action.index) return { startTime: ele.startTime, price: action.price };
        return ele;
      });
      return {
        ...state,
        dispMultistagePlanSetting,
      };
    }
    default: {
      return state;
    }
  }
};
