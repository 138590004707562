// @flow

import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { VictoryPie, VictoryLabel, Line } from 'victory';
import sizeMe from 'react-sizeme';
import Currency from 'Client/components/utils/Currency';
import Loading from 'Client/components/utils/Loading';
import ChartNodata, { CHART_NO_DATA } from 'Client/components/ChartNoData';
import QuestionIcon from 'Client/components/utils/QuestionIcon';
import { HELP_CHART_PG, HELP_CHART_HYBRID } from 'Client/actions/helpPopupTypes';
import { OPEN_POP_UP, CHANGE_CHART_TYPE } from 'Client/actions/types';
import Prefixer from 'Client/lib/Prefixer';
import { applianceId } from 'Client/lib/id';
import { convertEnergy, getTotalEnergy } from 'Client/lib/globalUtils';
import { calcPrice, balance } from 'Client/lib/pricePlanUtils';
import type { State, ApplianceEnergyDetailState, PricePlanState } from 'Client/types/state';
import { timeUnitStyle, getAppliancePgEnergy, formatEnergy } from 'Client/lib/chartUtils';
import 'Client/css/chartTree.css';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  wrapper: {
    position: 'relative',
  },
  body: {
    marginTop: '10px',
  },
  chart: {
    width: '100%',
    display: 'table',
    marginTop: '15px',
  },
  generateColor: {
    color: '#62ba58',
  },
  buyColor: {
    color: '#ffbd00',
  },
  consumeColor: {
    color: '#d0d0d0',
  },
  generate: {
    color: '#4bbeae',
    float: 'left',
  },
  buy: {
    color: '#ff9086',
    float: 'right',
  },
  balance: {
    fontSize: '1.2em',
  },
  resultArea: {
    margin: '10px auto 0px',
    textAlign: 'center',
    fontSize: '0.9em',
    width: '96%',
  },
  resultNegative: {
    color: '#ff9086',
    borderBottom: '2px solid #ff9086',
  },
  clear: {
    clear: 'both',
  },
  info: {
    top: '-20px',
    right: '-5px',
    zIndex: '2',
    position: 'absolute',
  },

  chartTypeSwitch: {
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'normal',
  },
  chartType: {
    cursor: 'pointer',
    marginTop: '10px',
    padding: '4px 10px',
    border: '1px solid #eaeaea',
    color: '#bebebe',
    width: '40%',
  },
  chartTypeLeft: {
    borderRadius: '5px 0 0 5px',
  },
  chartTypeRight: {
    borderRadius: '0 5px 5px 0',
  },
  selectedChartType: {
    backgroundColor: '#eaeaea',
    color: '#454545',
  },
  legendArea: {
    display: 'table',
    width: '100%',
    textAlign: 'left',
    margin: '30px 0 20px',
  },
  legendAreaTable: {
    display: 'table',
    marginTop: '10px',
    padding: 0,
    width: '100%',
    textAlign: 'left',
  },
  legendAreaFlex: {
    display: 'flex',
  },
  legendAreaDetails: {
    display: 'block',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    margin: '3px 5px',
    fontWeight: 'normal',
  },
  legendDitalsItem: {
    alignItems: 'center',
    margin: '3px 5px',
    fontWeight: 'normal',
  },
  legendItemHalf: {
    width: '50%',
  },
  legendSubItem: {
    position: 'relative',
    marginLeft: '7px',
  },
  legendIcon: {
    width: 16,
    height: 16,
    marginRight: 5,
    backgroundSize: 'cover',
  },
  legendSoldIcon: {
    backgroundImage:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAAIVBMVEUAAABKvq5Kvq5Lva1Kva1Kv69Kvq5Kv69Iv69Kva1Lvq7u1tPtAAAACnRSTlMA8MBwgDCwfyCvXTsbnAAAAF9JREFUKM9jYGAIV1wFBUKlDEDAvAoBFhoABbJWIYGVUAUIYMAQhSogyuCFKrCEQQtVYBGDFKrAQoZVaGCkC6AHEEYQYgZyIKqABEZEoUXlMlBkS6FFNkMIIjl4MDAAAMhEO2WKCaPxAAAAAElFTkSuQmCC)',
  },
  legendBoughtIcon: {
    backgroundImage:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAAIVBMVEUAAAD/mZL/mpL/mZL/mZH/mo//mpH/m5P/l4//mZL/mpLKzfsxAAAACnRSTlMA8MBwgDCwfyCvXTsbnAAAAF9JREFUKM9jYGAIV1wFBUKlDEDAvAoBFhoABbJWIYGVUAUIYMAQhSogyuCFKrCEQQtVYBGDFKrAQoZVaGCkC6AHEEYQYgZyIKqABEZEoUXlMlBkS6FFNkMIIjl4MDAAAMhEO2WKCaPxAAAAAElFTkSuQmCC)',
  },
  legendConsumedIcon: {
    backgroundImage:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAAIVBMVEUAAADd3d3c3Nzd3d3d3d3f39/c3Nzd3d3f39/c3Nzd3d1wHqkQAAAACnRSTlMA8MBwgDCwfyCvXTsbnAAAAF9JREFUKM9jYGAIV1wFBUKlDEDAvAoBFhoABbJWIYGVUAUIYMAQhSogyuCFKrCEQQtVYBGDFKrAQoZVaGCkC6AHEEYQYgZyIKqABEZEoUXlMlBkS6FFNkMIIjl4MDAAAMhEO2WKCaPxAAAAAElFTkSuQmCC)',
  },
  legendGeneratedIcon: {
    backgroundImage:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAKJJREFUOBFjZAACt8zOgP///+czMDIYMDIwCIDE8IH//xkO/Gf437hnRuUBRpBmBob/6/FpwCX37/9/R6b/DP8KcCkgJM7IyNDAxMjAaE9IIW55Rn0m3JKEZUDhRZEBICtGDRjyYQDMEx+BSfn/QcJJBocKxv8XgEmZaQIOaYLCQBc0MN89s+eGsqnrRaBLJBn+MwoCMwgHIZ0gVwM1J4KyMwDldTEbG4dVrAAAAABJRU5ErkJggg==)',
  },
  legendWhpIcon: {
    backgroundImage:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAJZJREFUOBFjZACCm2FzAv4zMuQz/GcwYGBkEACJEQAH/jP+b9RckXqAEap5PQENWKWBhjgy/WP4X4BVlhjBfwwNTIyMjPbEqMWmhpGBUZ8JmwTRYsDwoswAoE2jBgz9MPj//yPT////DxKdcNAU/mdguMDExMA4AU2ceC4TMC+or0rZwPifIRDsEqCTiNENUgvKiaDsDADX2DAA8+P/WQAAAABJRU5ErkJggg==)',
  },
  legendChargedIcon: {
    backgroundImage:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAJVJREFUOBHtU0ERwzAMs4ygTLYh6I1BB2EINggZgzEohBVCg6ClUgJRbQBJdulrd9PLZ1uKz7EghnEZBhAPC88QdJ4rgcKZ4Ot+mWY4WamfEiFXS0hXVeKZa6jljRtUBH2tMVenyMkE2uH7OiTgT/8Ffn4HpGz2jYytpwRw1QS+WwWMG9QsOZmrbj6Jj/SdGKM70e28A6yJMAz5o15lAAAAAElFTkSuQmCC)',
  },
  legendDischargedIcon: {
    backgroundImage:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAALNJREFUOBHtU7kNwkAQnDkdloigBKdEmA5oiUpwK+6ADnBGajqACAk/w60gcHAWvoyAjVZzM7urvR0ixOOS523njqIKgnmAJkNQQ7Fe+OGw3DQNTfzseCa5nlRFHiTdMq+db3uWqWKrZ5q2R+kk7iMNZkECto7EahY7QrJ9uQieBP0LAD+wAwn3pH8bkYMvruGQdBphSSmB+mMmV6depE2e+aFwZklLAFU20rcR3hxVpjHtCwqrQuX98KVEAAAAAElFTkSuQmCC)',
  },
  legendValueArea: {
    display: 'flex',
    alignItems: 'center',
    width: '32%',
  },
  legendValueAreaDetails: {
    display: 'flex',
    alignItems: 'center',
  },
  legendValueAreaTitle: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  legendValueLeft: {
    textAlign: 'left',
  },
  legendValueCenter: {
    fontSize: '0.9em',
    width: '37%',
    textAlign: 'right',
  },
  legendValueRight: {
    fontWeight: 'bold',
    width: '34%',
    textAlign: 'right',
  },
  legendValueLeftDetails: {
    textAlign: 'left',
  },
  legendValueRightDetails: {
    fontWeight: 'bold',
    margin: '0 0 0 auto',
    textAlign: 'right',
  },
});

const chartColor = {
  generated: '#466988',
  bought: '#ff9086',
  discharged: '#f2d422',
  whp: '#D8579C',
  sold: '#4bbeae',
  charged: '#b8dd1f',
  sellEnergy: '#4bbeae',
  buyEnergy: '#ff9086',
  consumeEnergy: '#d0d0d0',
  resultSame: '#7c7c7c',
  pgBoxDown: '#ddd',
};
const chartLavelStyle = {
  fill: '#847965',
  fontFamily: 'inherit',
  fontWeight: 'normal',
  fontSize: 18,
};

const currencyStyle = roundB =>
  (roundB > 0 && chartColor.sellEnergy) ||
  (roundB < 0 && chartColor.buyEnergy) ||
  chartColor.resultSame;

const chartTypeStyle = (chartType, selectedChartType) => ({
  ...style.chartType,
  ...(selectedChartType === chartType ? style.selectedChartType : {}),
});

const renderEnergyChart = (
  unitEnergy,
  sellPrice,
  isHybrid,
  b,
  intl,
  pricePlan,
  isCharge = false
) => {
  const roundB = Math.round(b);
  const absB = Math.abs(b);

  const fractionDigits = intl.formatMessage({ id: 'currency.fractionDigits' });
  const currency = intl.formatNumber(absB, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
  const plusOrMinus = (roundB > 0 && '+') || (roundB < 0 && '-') || '';
  const inColor = isHybrid
    ? [chartColor.discharged, chartColor.bought]
    : [chartColor.generated, chartColor.bought, chartColor.discharged];
  const outColor = [chartColor.whp, chartColor.sold, chartColor.charged];

  const pgEnergy = unitEnergy.pgEnergy;
  const buyEnergy = getTotalEnergy(unitEnergy.buyEnergy);
  const dischargedEnergy = unitEnergy.dischargedEnergy;
  const inEnergyTotal = pgEnergy + buyEnergy + dischargedEnergy;

  const whp = (unitEnergy.whp && getTotalEnergy(convertEnergy(unitEnergy.whp))) || 0;
  const sellEnergy = unitEnergy.sellEnergy;
  const chargedEnergy = unitEnergy.chargedEnergy;
  const outEnergyTotal = whp + sellEnergy + chargedEnergy;

  const inData = isHybrid
    ? [{ x: 1, y: pgEnergy + dischargedEnergy }, { x: 1, y: buyEnergy }]
    : [{ x: 1, y: pgEnergy }, { x: 1, y: buyEnergy }, { x: 1, y: dischargedEnergy }];
  const outData = [{ x: 1, y: whp }, { x: 1, y: sellEnergy }, { x: 1, y: chargedEnergy }];

  return (
    <div style={style.chart}>
      {isCharge ? (
        <svg viewBox="40 40 320 160">
          <VictoryPie
            standalone={false}
            colorScale={[chartColor.sellEnergy, chartColor.buyEnergy]}
            startAngle={270}
            endAngle={450}
            innerRadius={90}
            style={{
              data: {
                stroke: '#fafafa',
                strokeWidth: 3,
              },
            }}
            labels={() => null}
            data={[
              { x: 1, y: unitEnergy.sellEnergy * sellPrice },
              { x: 1, y: calcPrice(0, 24, pricePlan, unitEnergy.buyEnergy) },
            ]}
          />

          <VictoryLabel
            key="balance"
            textAnchor="middle"
            text={intl.formatMessage({ id: 'chartEnergyDetails.balance' })}
            x={200}
            y={145}
            style={chartLavelStyle}
          />
          <VictoryLabel
            key="currency"
            textAnchor="middle"
            text={intl.formatMessage(
              { id: 'chartEnergyDetails.currency' },
              { plusOrMinus, currency }
            )}
            x={200}
            y={175}
            style={{
              fill: currencyStyle(roundB),
              fontFamily: chartLavelStyle.fontFamily,
              fontSize: 24,
            }}
          />
          <Line
            x1={51}
            y1={200}
            x2={349}
            y2={200}
            style={{ stroke: chartColor.consumeEnergy, strokeWidth: 8 }}
          />
        </svg>
      ) : (
        <svg viewBox="40 40 320 310">
          <VictoryPie
            standalone={false}
            colorScale={inColor}
            startAngle={270}
            endAngle={450}
            innerRadius={90}
            style={{
              data: {
                stroke: '#fafafa',
                strokeWidth: 3,
              },
            }}
            labels={() => null}
            data={inData}
          />
          <VictoryPie
            standalone={false}
            colorScale={outColor}
            startAngle={270}
            endAngle={90}
            innerRadius={90}
            style={{
              data: {
                stroke: '#fafafa',
                strokeWidth: 3,
              },
            }}
            labels={() => null}
            data={outData}
          />
          <VictoryLabel
            key="in"
            textAnchor="middle"
            text={intl.formatMessage({ id: 'chartEnergyDetails.in' })}
            x={200}
            y={145}
            style={chartLavelStyle}
          />
          <VictoryLabel
            key="in_energy"
            textAnchor="middle"
            text={formatEnergy({
              intl,
              energy: inEnergyTotal,
              unit: intl.formatMessage({ id: 'chartEnergyDetails.axisUnit' }),
            })}
            x={200}
            y={175}
            style={{
              ...chartLavelStyle,
              fontWeight: 'bold',
              fontSize: 22,
            }}
          />
          <VictoryLabel
            key="out"
            textAnchor="middle"
            text={intl.formatMessage({ id: 'chartEnergyDetails.out' })}
            x={200}
            y={220}
            style={chartLavelStyle}
          />
          <VictoryLabel
            key="out_energy"
            textAnchor="middle"
            text={formatEnergy({
              intl,
              energy: outEnergyTotal,
              unit: intl.formatMessage({ id: 'chartEnergyDetails.axisUnit' }),
            })}
            x={200}
            y={250}
            style={{
              ...chartLavelStyle,
              fontWeight: 'bold',
              fontSize: 22,
            }}
          />
          <Line
            x1={51}
            y1={200}
            x2={349}
            y2={200}
            style={{ stroke: chartColor.consumeEnergy, strokeWidth: 8 }}
          />
        </svg>
      )}
    </div>
  );
};

const renderEnergy = (unitEnergy, sellPrice, intl, chartWidth, pricePlan, pv, isHybrid) => {
  const pvTotal = (pv && pv.energy && getTotalEnergy(pv.energy)) || 0;
  const pvEnergy = pvTotal * sellPrice;
  const legendArea =
    chartWidth > 325 && pvEnergy < 1000000
      ? style.legendAreaTable
      : { ...style.legendAreaTable, fontSize: '0.8em' };

  return (
    <div>
      {renderEnergyChart(unitEnergy, sellPrice, isHybrid, 0, intl, pricePlan)}
      <div style={legendArea}>
        <div style={style.legendAreaFlex}>
          <div style={{ ...style.legendItem, ...style.legendItemHalf }}>
            <div style={style.legendValueAreaTitle}>
              <div style={style.legendValueLeftDetails}>
                {intl.formatMessage({ id: 'chartEnergyDetails.inDetail' })}
              </div>
            </div>
          </div>

          <div style={{ ...style.legendItem, ...style.legendItemHalf }}>
            <div style={style.legendValueAreaTitle}>
              <div style={style.legendValueLeftDetails}>
                {intl.formatMessage({ id: 'chartEnergyDetails.outDetail' })}
              </div>
            </div>
          </div>
        </div>

        <div style={style.legendAreaFlex}>
          <div style={{ ...style.legendAreaDetails, ...style.legendItemHalf }}>
            <div style={style.legendDitalsItem}>
              <div style={style.legendValueAreaDetails}>
                <div
                  style={
                    isHybrid
                      ? { ...style.legendIcon, ...style.legendDischargedIcon }
                      : { ...style.legendIcon, ...style.legendGeneratedIcon }
                  }
                />
                <div style={style.legendValueLeftDetails}>
                  {isHybrid
                    ? intl.formatMessage({ id: 'chartEnergyDetails.hybridPg' })
                    : intl.formatMessage({ id: 'chartEnergyDetails.pg' })}
                </div>
              </div>
              <div style={style.legendValueRightDetails}>
                {formatEnergy({
                  intl,
                  energy: isHybrid
                    ? unitEnergy.pgEnergy + unitEnergy.dischargedEnergy
                    : unitEnergy.pgEnergy,
                  unit: intl.formatMessage({ id: 'chartEnergyDetails.axisUnit' }),
                })}
              </div>
            </div>

            <div style={style.legendDitalsItem}>
              <div style={style.legendValueAreaDetails}>
                <div style={{ ...style.legendIcon, ...style.legendBoughtIcon }} />
                <div style={style.legendValueLeftDetails}>
                  {intl.formatMessage({ id: 'chartEnergyDetails.buy' })}
                </div>
              </div>
              <div style={style.legendValueRightDetails}>
                {formatEnergy({
                  intl,
                  energy: getTotalEnergy(unitEnergy.buyEnergy),
                  unit: intl.formatMessage({ id: 'chartEnergyDetails.axisUnit' }),
                })}
              </div>
            </div>

            {!isHybrid && (
              <div style={style.legendDitalsItem}>
                <div style={style.legendValueAreaDetails}>
                  <div style={{ ...style.legendIcon, ...style.legendDischargedIcon }} />
                  <div style={style.legendValueLeftDetails}>
                    {intl.formatMessage({ id: 'chartEnergyDetails.discharge' })}
                  </div>
                </div>
                <div style={style.legendValueRightDetails}>
                  {formatEnergy({
                    intl,
                    energy: unitEnergy.dischargedEnergy,
                    unit: intl.formatMessage({ id: 'chartEnergyDetails.axisUnit' }),
                  })}
                </div>
              </div>
            )}
          </div>

          <div style={{ ...style.legendAreaDetails, ...style.legendItemHalf }}>
            <div style={style.legendDitalsItem}>
              <div style={style.legendValueAreaDetails}>
                <div style={{ ...style.legendIcon, ...style.legendWhpIcon }} />
                <div style={style.legendValueLeftDetails}>
                  {intl.formatMessage({ id: 'chartEnergyDetails.whp' })}
                </div>
              </div>
              <div style={style.legendValueRightDetails}>
                {formatEnergy({
                  intl,
                  energy: (unitEnergy.whp && getTotalEnergy(convertEnergy(unitEnergy.whp))) || 0,
                  unit: intl.formatMessage({ id: 'chartEnergyDetails.axisUnit' }),
                })}
              </div>
            </div>

            <div style={style.legendDitalsItem}>
              <div style={style.legendValueAreaDetails}>
                <div style={{ ...style.legendIcon, ...style.legendSoldIcon }} />
                <div style={style.legendValueLeftDetails}>
                  {intl.formatMessage({ id: 'chartEnergyDetails.sellCharge' })}
                </div>
              </div>
              <div style={style.legendValueRightDetails}>
                {formatEnergy({
                  intl,
                  energy: unitEnergy.sellEnergy,
                  unit: intl.formatMessage({ id: 'chartEnergyDetails.axisUnit' }),
                })}
              </div>
            </div>

            <div style={style.legendDitalsItem}>
              <div style={style.legendValueAreaDetails}>
                <div style={{ ...style.legendIcon, ...style.legendChargedIcon }} />
                <div style={style.legendValueLeftDetails}>
                  {intl.formatMessage({ id: 'chartEnergyDetails.charge' })}
                </div>
              </div>
              <div style={style.legendValueRightDetails}>
                {formatEnergy({
                  intl,
                  energy: unitEnergy.chargedEnergy,
                  unit: intl.formatMessage({ id: 'chartEnergyDetails.axisUnit' }),
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const renderCharge = (unitEnergy, sellPrice, b, intl, chartWidth, pricePlan) => (
  <div>
    {renderEnergyChart(unitEnergy, sellPrice, false, b, intl, pricePlan, true)}
    <div style={chartWidth > 325 ? style.legendArea : { ...style.legendArea, fontSize: '0.8em' }}>
      <div style={style.legendItem}>
        <div style={style.legendValueArea}>
          <div style={{ ...style.legendIcon, ...style.legendSoldIcon }} />
          <div style={style.legendValueLeft}>
            {intl.formatMessage({ id: 'chartEnergyDetails.sellCharge' })}
          </div>
        </div>
        <div style={style.legendValueCenter}>
          {formatEnergy({
            intl,
            energy: unitEnergy.sellEnergy,
            unit: intl.formatMessage({ id: 'chartEnergyDetails.axisUnit' }),
          })}
        </div>
        <div style={style.legendValueRight}>
          <Currency value={unitEnergy.sellEnergy * sellPrice} />
        </div>
      </div>

      <div style={style.legendItem}>
        <div style={style.legendValueArea}>
          <div style={{ ...style.legendIcon, ...style.legendBoughtIcon }} />
          <div style={style.legendValueLeft}>
            {intl.formatMessage({ id: 'chartEnergyDetails.buy' })}
          </div>
        </div>
        <div style={style.legendValueCenter}>
          {formatEnergy({
            intl,
            energy: getTotalEnergy(unitEnergy.buyEnergy),
            unit: intl.formatMessage({ id: 'chartEnergyDetails.axisUnit' }),
          })}
        </div>
        <div style={style.legendValueRight}>
          <Currency value={calcPrice(0, 24, pricePlan, unitEnergy.buyEnergy)} />
        </div>
      </div>
    </div>
  </div>
);

const renderChartArea = (
  unitEnergy,
  sellPrice,
  b,
  intl,
  chartWidth,
  pricePlan,
  chartType,
  pv,
  isHybrid,
  dispatch
) => (
  <div>
    <div style={style.chartTypeSwitch}>
      <div
        style={{ ...chartTypeStyle('balance', chartType), ...style.chartTypeLeft }}
        onClick={() => dispatch({ type: CHANGE_CHART_TYPE, contentType: 'balance' })}
      >
        {intl.formatMessage({ id: 'chartEnergyDetails.balance' })}
      </div>
      <div
        style={{ ...chartTypeStyle('details', chartType), ...style.chartTypeRight }}
        onClick={() => dispatch({ type: CHANGE_CHART_TYPE, contentType: 'details' })}
      >
        {isHybrid
          ? intl.formatMessage({ id: 'chartEnergyDetails.hybridDetails' })
          : intl.formatMessage({ id: 'chartEnergyDetails.details' })}
      </div>
    </div>

    {chartType === 'balance' && renderCharge(unitEnergy, sellPrice, b, intl, chartWidth, pricePlan)}
    {chartType === 'details' &&
      renderEnergy(unitEnergy, sellPrice, intl, chartWidth, pricePlan, pv, isHybrid)}
  </div>
);

const renderLoadingMessage = intl => (
  <div style={{ ...style.resultArea, ...style.resultNegative }}>
    {intl.formatMessage({ id: 'application.dataLoading' })}
  </div>
);

const renderBody = (
  requesting,
  unitEnergy,
  sellPrice,
  period,
  intl,
  size,
  pricePlan,
  chartType,
  idsToShow,
  applianceEnergy,
  dispatch
) => {
  const { sellEnergy, buyEnergy } = unitEnergy;
  const pv = getAppliancePgEnergy(idsToShow, applianceEnergy, period, applianceId.pv);
  const isHybrid = idsToShow.find(id => id && /^9972/.test(id));

  if (requesting) {
    return (
      <div>
        <Loading space={50} />
        <div style={style.clear}>{requesting && renderLoadingMessage(intl)}</div>
      </div>
    );
  } else if (sellEnergy + getTotalEnergy(buyEnergy) > 0) {
    return (
      <div>
        {renderChartArea(
          unitEnergy,
          sellPrice,
          balance(sellPrice, sellEnergy, buyEnergy, pricePlan),
          intl,
          size.width,
          pricePlan,
          chartType,
          pv,
          isHybrid,
          dispatch
        )}
        <div style={style.clear}>{requesting && renderLoadingMessage(intl)}</div>
      </div>
    );
  }
  return <ChartNodata type={CHART_NO_DATA} />;
};

type StateProps = {|
  sellPrice: number,
  pricePlan: PricePlanState,
  chartType: string,
  idsToShow: (string | null)[],
  applianceEnergy: ApplianceEnergyDetailState[],
|};
type OwnProps = {|
  requesting: boolean,
  unitEnergy: {
    whp: (number | null)[],
    sellEnergy: number,
    buyEnergy: number[],
    consumeEnergy: number,
    pgEnergy: number,
    chargedEnergy: number,
    dischargedEnergy: number,
  },
  period: string,
  intl: intlShape,
  size: {
    width: number,
    height: number,
  },
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

export const PureChartEnergyDetails = ({
  requesting,
  unitEnergy,
  sellPrice,
  period,
  intl,
  size,
  chartType,
  idsToShow,
  applianceEnergy,
  dispatch,
  pricePlan,
}: Props): Node => (
  <div style={style.wrapper}>
    <div style={requesting ? { ...style.info, top: '-30px' } : style.info}>
      <QuestionIcon
        onClick={e => {
          dispatch({
            type: OPEN_POP_UP,
            contentType: idsToShow.find(id => id && /^9972/.test(id))
              ? HELP_CHART_HYBRID
              : HELP_CHART_PG,
            target: e.target,
          });
        }}
      />
    </div>
    <div style={style.body}>
      {renderBody(
        requesting,
        unitEnergy,
        sellPrice,
        period,
        intl,
        size,
        pricePlan,
        chartType,
        idsToShow,
        applianceEnergy,
        dispatch
      )}
    </div>
  </div>
);

const mapStateToProps = (state: State): StateProps => {
  const { unit } = state.chartTime;
  const applianceEnergy = unit ? state.applianceEnergy[timeUnitStyle[unit].toUnit] : [];
  return {
    sellPrice: state.userSetting.sellPrice,
    pricePlan: state.pricePlan,
    chartType: state.chartTime.chartType,
    idsToShow: state.applianceState.idsToShow,
    applianceEnergy,
  };
};

const config = { monitorHeight: true };
const sizeMeHOC = sizeMe(config);

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  sizeMeHOC(injectIntl(PureChartEnergyDetails))
);
