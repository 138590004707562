// @flow

import 'firebase/auth';
import qs from 'qs';
import moment from 'moment-timezone';
import type { Store, Middleware, Action } from 'redux';
import { CALL_API } from 'redux-api-middleware';
import { getIdToken } from 'Client/lib/mbaasAuthentication';
import { UPDATE_ID_TOKEN } from 'Client/actions/types';

const jwt = require('jsonwebtoken');

declare var config: { FIREBASE_SDK: Object };

const parseUrlHash = () => ({
  idTokenQuery: qs.parse((location.search || '').slice(1)).token,
  refreshTokenQuery: qs.parse((location.search || '').slice(1)).refreshToken,
});

const isExpired = token => {
  if (!token) {
    return true;
  }
  const decoded = jwt.decode(token);
  return moment().isAfter(decoded.exp * 1000);
};

const dispatchRequestActionFirst = (dispatch, actions) => {
  const requestAction = actions
    .filter(x => (x.type ? x.type.indexOf('REQUEST') === 0 : x.indexOf('REQUEST') === 0))
    .map(x => {
      if (typeof x === 'string') {
        return { type: x };
      }
      return x;
    })[0];
  if (requestAction) {
    dispatch(requestAction);
  }
};

export default (store: Store) => (next: Middleware) => (action: Action) => {
  const callApi = action[CALL_API];

  if (!callApi || callApi.endpoint === '/api/v1/logout') {
    next(action);
    return;
  }

  const { userInfo } = store.getState();
  const { idTokenState = '' } = userInfo;
  const { idTokenQuery = '' } = parseUrlHash();
  const idToken = idTokenState || idTokenQuery;

  // web版の初回アクセス時、あるいはidTokenの期限切れ時にidTokenを取得する処理
  if (idTokenState && isExpired(idToken)) {
    dispatchRequestActionFirst(store.dispatch, callApi.types);
    getIdToken(token => {
      // FIXME getIdTokenでエラーが無視されているのは良いか？
      if (token) {
        store.dispatch({ type: UPDATE_ID_TOKEN, payload: token });
        callApi.headers = Object.assign({}, callApi.headers, {
          Authorization: `Bearer ${token}`,
        });
      }
      next(action);
    });
    return;
  }

  if (!idToken) {
    next(action);
    return;
  }

  callApi.headers = Object.assign({}, callApi.headers, {
    Authorization: `Bearer ${idToken}`,
  });

  next(action);
};
