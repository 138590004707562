// @flow
/* eslint-env browser */

import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import Popup from 'Client/components/utils/Popup';
import { CLOSING_POP_UP, CLOSED_POP_UP } from 'Client/actions/types';
import Prefixer from 'Client/lib/Prefixer';
import {
  HELP_CHART_DETAIL,
  HELP_CHART_PG,
  HELP_CHART_HYBRID,
  HELP_CHART_RANKING,
  HELP_HOME_BOTTOM,
  HELP_HOME_TOP,
  HELP_HOME_TOP_PG,
  HELP_CHART_DAY,
  HELP_CHART_DAILY,
  HELP_STACKED_CHART,
} from 'Client/actions/helpPopupTypes';
import type { State, PopupState } from 'Client/types/state';

const closePng = require('Client/images/btn_close.png');

const prefixer = new Prefixer();

const arrowSize = 18;
const popupPadding = 10;
const popupWidth = 250;
const popupWidthWithPadding = popupWidth + popupPadding * 2;

const styles = prefixer.prefix({
  title: {
    color: '#ffb835',
    fontWeight: 'bold',
    fontSize: 17,
    textAlign: 'center',
  },
  content: {
    color: '#1f1f21',
    margin: '10px auto',
    backgroundClip: 'padding-box',
    padding: '4px 12px 8px 12px',
    fontSize: '14px',
    textAlign: 'center',
    wordWrap: 'break-word',
  },
  subTitle: {
    color: '#1f1f21',
    fontWeight: 'bold',
    marginTop: '8px',
    backgroundClip: 'padding-box',
    fontSize: '14px',
    textAlign: 'center',
    wordWrap: 'break-word',
  },
  button: {
    background: '#ffb835',
    fontSize: 13,
    borderRadius: 3,
    textAlign: 'center',
    margin: '0 auto',
    padding: '4px 15px',
    verticalAlign: 'middle',
    color: '#fff',
    border: 0,
    textDecoration: 'none',
  },
  close: {
    width: 20,
    height: 20,
    position: 'absolute',
    bottom: 5,
    right: 5,
  },
  topArrow: {
    position: 'absolute',
    left: 190,
    width: arrowSize,
    height: arrowSize,
    transitionOrigin: '50% 50% 0',
    backgroundColor: 'transparent',
    backgroundImage: 'linear-gradient(45deg, #fff, #fff, 50%, transparent 50%)',
  },
});

const getArrowPosition = (x, y, popupLeft) => {
  const { innerHeight } = window;
  const position = {};

  if (y > innerHeight / 2) {
    position.bottom = -(arrowSize / 2) + 1;
    position.transform = 'rotate(-45deg)';
  } else {
    position.top = -(arrowSize / 2) + 1;
    position.transform = 'rotate(135deg)';
  }

  position.left = x - popupLeft;
  return position;
};

const getPopupPosition = (x, y) => {
  const position = {};
  const { innerHeight, innerWidth } = window;

  if (y < innerHeight / 2) {
    position.top = y + arrowSize * 2;
  } else {
    position.bottom = innerHeight - y + arrowSize;
  }

  if (x < innerWidth / 2) {
    if (x + popupWidthWithPadding > innerWidth) {
      position.left = innerWidth - popupWidthWithPadding;
    } else {
      position.left = x;
    }
    position.left -= popupPadding * 2;
  } else {
    position.left = Math.abs(x - popupWidth + popupPadding);
  }

  return position;
};

declare var config: { URLS: Object[] };

const getI18nMessages = (contentType, intl) => {
  const { Q_CALC_BALANCE, Q_HYBRID_STORAGE_BATTERY, Q_CALC_BILL, Q_TARGET_APPLIANCES } =
    config.URLS[intl.locale.toUpperCase()] || {};

  switch (contentType) {
    case HELP_CHART_DETAIL:
      return {
        title: intl.formatMessage({ id: 'helpPopup.transitionT' }),
        message: intl.formatMessage({ id: 'helpPopup.transitionD' }),
        linkUrl: null,
        linkLabel: null,
      };
    case HELP_CHART_PG:
      return {
        title: intl.formatMessage({ id: 'helpPopup.buySellT' }),
        message: intl.formatMessage({ id: 'helpPopup.buySellD' }),
        linkUrl: Q_CALC_BALANCE,
        linkLabel: intl.formatMessage({ id: 'helpPopup.forDetails' }),
      };
    case HELP_CHART_HYBRID:
      return {
        title: intl.formatMessage({ id: 'helpPopup.hybridSellT' }),
        message: intl.formatMessage({ id: 'helpPopup.hybridSellD' }),
        linkUrl: Q_HYBRID_STORAGE_BATTERY || null,
        linkLabel:
          (Q_HYBRID_STORAGE_BATTERY && intl.formatMessage({ id: 'helpPopup.forDetails' })) || null,
      };
    case HELP_CHART_RANKING:
      return {
        title: intl.formatMessage({ id: 'helpPopup.chartRankingT' }),
        message: intl.formatMessage({ id: 'helpPopup.chartRankingD' }),
        linkUrl: Q_CALC_BILL,
        linkLabel: intl.formatMessage({ id: 'helpPopup.forDetails' }),
      };
    case HELP_HOME_BOTTOM:
      return {
        title: intl.formatMessage({ id: 'helpPopup.hourlyStateT' }),
        message: intl.formatMessage({ id: 'helpPopup.hourlyStateD' }),
        linkUrl: Q_TARGET_APPLIANCES,
        linkLabel: intl.formatMessage({ id: 'helpPopup.targetApls' }),
      };
    case HELP_HOME_TOP:
      return {
        title: intl.formatMessage({ id: 'helpPopup.topRankingT' }),
        message: intl.formatMessage({ id: 'helpPopup.topRankingD' }),
        linkUrl: Q_CALC_BILL,
        linkLabel: intl.formatMessage({ id: 'helpPopup.forDetails' }),
      };
    case HELP_HOME_TOP_PG:
      return {
        title: intl.formatMessage({ id: 'helpPopup.balanceT' }),
        message: intl.formatMessage({ id: 'helpPopup.balanceD' }),
        linkUrl: Q_CALC_BALANCE,
        linkLabel: intl.formatMessage({ id: 'helpPopup.forDetails' }),
      };
    case HELP_CHART_DAY:
      return {
        title: intl.formatMessage({ id: 'helpPopup.dayChartT' }),
        message: null,
        linkUrl: null,
        linkLabel: null,
      };
    case HELP_CHART_DAILY:
      return {
        title: intl.formatMessage({ id: 'helpPopup.dayCircleT' }),
        message: intl.formatMessage({ id: 'helpPopup.dayCircleD' }),
        linkUrl: null,
        linkLabel: null,
      };
    case HELP_STACKED_CHART:
      return {
        title: intl.formatMessage({ id: 'helpPopup.stackedChartT' }),
        message: intl.formatMessage({ id: 'helpPopup.stackedChartD' }),
        linkUrl: null,
        linkLabel: null,
      };
    default:
      return {};
  }
};

const renderDayHelp = intl => {
  const body = {
    message1: intl.formatMessage({ id: 'helpPopup.dayChartD1' }),
    message2: intl.formatMessage({ id: 'helpPopup.dayChartD2' }),
    subTitlePC: intl.formatMessage({ id: 'helpPopup.dayChartSTP' }),
    messagePinch: intl.formatMessage({ id: 'helpPopup.dayChartSMP' }),
    messagePinchPC: intl.formatMessage({ id: 'helpPopup.dayChartSMPP' }),
    messageScroll: intl.formatMessage({ id: 'helpPopup.dayChartSMS' }),
    messageScrolePC1: intl.formatMessage({ id: 'helpPopup.dayChartSMSP1' }),
    messageScrolePC2: intl.formatMessage({ id: 'helpPopup.dayChartSMSP2' }),
  };
  return (
    <div style={styles.content}>
      <div>{body.message1}</div>
      <div>{body.message2}</div>
      <div style={styles.subTitle}>{body.subTitlePC}</div>
      <div>{body.messagePinch}</div>
      <div>{body.messagePinchPC}</div>
      <div>{body.messageScroll}</div>
      <div>{body.messageScrolePC1}</div>
      <div>{body.messageScrolePC2}</div>
    </div>
  );
};

const renderButton = (linkLabel, linkUrl) => {
  if (linkLabel) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <a style={styles.button} href={linkUrl} target="_blank" rel="noopener noreferrer">
          {linkLabel}
        </a>
      </div>
    );
  }

  return '';
};

type StateProps = {|
  popup: PopupState,
|};
type OwnProps = {|
  intl: intlShape,
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

export const PureHelpPopup = ({ popup, intl, dispatch }: Props): Node => {
  const animationDuration = 200;
  const ClosePopup = () => {
    dispatch({
      type: CLOSING_POP_UP,
    });
    setTimeout(() => {
      dispatch({ type: CLOSED_POP_UP });
    }, animationDuration);
  };

  const { open, contentType, target } = popup;
  if (!open && !contentType) {
    return null;
  }

  const targetRect = (target && target.getBoundingClientRect && target.getBoundingClientRect()) || {
    left: 0,
    top: 0,
  };

  const { title, message, linkUrl, linkLabel } = getI18nMessages(contentType, intl);
  if (!title && !message && !linkUrl) return null;

  const popupPosition = getPopupPosition(targetRect.left, targetRect.top);
  const arrowPosition = getArrowPosition(targetRect.left, targetRect.top, popupPosition.left);

  return (
    <Popup
      open={open}
      closeHandler={ClosePopup}
      animationDuration={animationDuration}
      style={{
        popupContainer: {
          display: 'block',
          justifyContent: '',
          alignItems: '',
        },
        popup: {
          position: 'fixed',
          width: popupWidth,
          padding: popupPadding,
          ...popupPosition,
        },
      }}
    >
      <div>
        <div style={{ ...styles.topArrow, ...arrowPosition }} />
        <div>
          <div style={styles.title}> {title} </div>
          {contentType === 'helpChartDay' ? (
            renderDayHelp(intl)
          ) : (
            <div style={styles.content}> {message} </div>
          )}
        </div>
        {renderButton(linkLabel, linkUrl)}
        <div>
          <img alt="close" style={styles.close} src={closePng} onClick={ClosePopup} />
        </div>
      </div>
    </Popup>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  popup: state.popup,
});

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  injectIntl(PureHelpPopup)
);
