// @flow

import React from 'react';
import { connect } from 'react-redux';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { injectIntl, intlShape } from 'react-intl';
import Loading from 'Client/components/utils/Loading';
import TopMonthlyTotalAmount from 'Client/components/TopMonthlyTotalAmount';
import TopApplianceRanking from 'Client/components/TopApplianceRanking';
import QuestionIcon from 'Client/components/utils/QuestionIcon';
import { OPEN_POP_UP } from 'Client/actions/types';
import { HELP_HOME_TOP } from 'Client/actions/helpPopupTypes';
import Prefixer from 'Client/lib/Prefixer';
import type { State } from 'Client/types/state';
import USER_STATUS from '../../common/user-status';

const { IMPERFECT_LEARNING, LEARNING } = USER_STATUS;

const prefixer = new Prefixer();
const style = prefixer.prefix({
  areaWrapper: {
    position: 'relative',
  },
  container: {
    display: 'table',
    width: '100%',
  },
  leftCell: {
    display: 'table-cell',
    width: '45%',
    verticalAlign: 'middle',
    fontSize: '1.5em',
    fontWeight: 'bold',
  },
  rightCell: {
    display: 'table-cell',
    width: '55%',
    verticalAlign: 'middle',
    paddingBottom: '20px',
  },
  tableHeader: {
    paddingRight: 10,
    paddingTop: 5,
    textAlign: 'right',
  },
  img: {
    width: '100%',
  },
});

const renderTopApplianceRanking = (requesting, userStatus) => {
  if ([IMPERFECT_LEARNING, LEARNING].includes(userStatus)) {
    const imperfectLearningRanking = require('Client/images/img_study_s.png');
    return (
      <div style={{ ...style.rightCell, paddingBottom: 0 }}>
        <img style={style.img} src={imperfectLearningRanking} alt="imperfect_learning" />
      </div>
    );
  }
  return <TopApplianceRanking requesting={requesting} />;
};

type StateProps = {|
  requesting: boolean,
  userStatus: string,
|};
type OwnProps = {|
  intl: intlShape,
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

export const PureTopTotalAmountArea = ({ requesting, userStatus, dispatch }: Props): Node => (
  <div style={style.areaWrapper}>
    <div style={style.tableHeader}>
      <QuestionIcon
        onClick={e => {
          dispatch({
            type: OPEN_POP_UP,
            contentType: HELP_HOME_TOP,
            target: e.target,
          });
        }}
      />
    </div>
    {requesting && <Loading positionAbsolute />}
    <div style={style.container}>
      <div style={style.leftCell}>
        <TopMonthlyTotalAmount monthlyRequesting={requesting} />
      </div>
      {userStatus !== 'not_estimable' && (
        <div style={style.rightCell}>{renderTopApplianceRanking(requesting, userStatus)}</div>
      )}
    </div>
  </div>
);

const mapStateToProps = (state: State): StateProps => ({
  requesting: state.energy.monthlyRequesting,
  userStatus: state.userInfo.status,
});

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  injectIntl(PureTopTotalAmountArea)
);
