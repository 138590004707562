// @flow

import moment from 'moment-timezone';
import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';

import Prefixer from 'Client/lib/Prefixer';
import type { State } from 'Client/types/state';

const prefixer = new Prefixer();

const dailyStyles = prefixer.prefix({
  noDataNotice: {
    padding: '0px 20px 0 0',
  },
  noDataNoticeTitle: {
    color: 'gray',
  },
  noDataNoticeDesc: {
    fontSize: '0.6em',
    margin: '5px 0',
    color: 'gray',
  },
});

const hourlyStyles = prefixer.prefix({
  noDataNotice: {
    padding: 0,
  },
  noDataNoticeTitle: {
    fontSize: '0.6em',
    color: 'gray',
  },
  noDataNoticeDesc: {
    fontSize: '0.5em',
    marginBottom: '5px',
    color: 'gray',
  },
  questionIcon: {
    height: '2.2em',
    width: '2.2em',
    color: 'gray',
  },
});

export const TOP_DAILY_NO_DATA = 'TOP_DAILY_NO_DATA';
export const TOP_HOURLY_NO_DATA = 'TOP_HOURLY_NO_DATA';
export const TOP_HOURLY_LOADING = 'TOP_HOURLY_LOADING';

declare var config: { URLS: Object[] };
const dailyNoData = (selectedHour, styles, intl) => {
  const { Q_NO_DATA, FAQ } = config.URLS[intl.locale.toUpperCase()] || {};
  let messagePrefix = 'topDailyNoData';
  const now = moment();
  if (now.hour() === selectedHour && now.minute() <= 10) {
    messagePrefix = 'topDailyLoading';
  }
  return (
    <div>
      <div style={styles.noDataNoticeTitle}>
        {intl.formatMessage({ id: `${messagePrefix}.Title` })}
      </div>
      <div style={styles.noDataNoticeDesc}>
        {intl.formatMessage({ id: `${messagePrefix}.Desc` })}
      </div>
      <div>
        <a
          href={messagePrefix === 'topDailyNoData' ? Q_NO_DATA : FAQ}
          target="_blank"
          rel="noopener noreferrer"
        >
          {intl.formatMessage({ id: `${messagePrefix}.Link` })}
        </a>
      </div>
    </div>
  );
};

const renderLink = (messagePrefix, styles, intl) => {
  const QuestionImage = require('Client/images/question.svg');
  const { Q_NO_DATA, FAQ } = config.URLS[intl.locale.toUpperCase()] || {};
  return (
    <a
      href={messagePrefix === 'topHourlyNoData' ? Q_NO_DATA : FAQ}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={QuestionImage} style={styles.questionIcon} alt="QuestionIcon" />
    </a>
  );
};

const hourlyNoData = (selectedHour, styles, type, intl) => {
  let messagePrefix = 'topHourlyNoData';
  const now = moment();
  if (now.hour() === selectedHour && now.minute() <= 10) {
    messagePrefix = 'topHourlyLoading';
  }
  return (
    <div>
      <div style={styles.noDataNoticeTitle}>
        {intl.formatMessage({ id: `${messagePrefix}.Title` })}
      </div>
      <div style={styles.noDataNoticeDesc}>
        {intl.formatMessage({ id: `${messagePrefix}.Desc` })}
        &nbsp;
        {renderLink(messagePrefix, styles, intl)}
      </div>
    </div>
  );
};

type StateProps = {|
  selectedHour: number,
|};
type OwnProps = {|
  type: string,
  intl: intlShape,
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

export const PureTopNoData = ({ selectedHour, type, intl }: Props): Node => {
  const styles = type === TOP_DAILY_NO_DATA ? dailyStyles : hourlyStyles;
  return (
    <div style={styles.noDataNotice}>
      {type === TOP_DAILY_NO_DATA
        ? dailyNoData(selectedHour, styles, intl)
        : hourlyNoData(selectedHour, styles, type, intl)}
    </div>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  selectedHour: Number(state.timeRange.selectedHour),
});

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  injectIntl(PureTopNoData)
);
