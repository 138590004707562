// @flow

import React, { Component } from 'react';
import type { Dispatch } from 'redux';
import type { ContextRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SettingPage from 'Client/components/SettingPage';
import FadeTransition from 'Client/components/utils/FadeTransition';
import { apiGetUseStatsForLastYear, apiGetUserInfo } from 'Client/actions/apis';
import { applianceId } from 'Client/lib/id';
import { CLOSING_POP_UP, LOAD_SETTING_PAGE } from 'Client/actions/types';
import type { State } from 'Client/types/state';

const settingPageOnEnter = ({ idsToShow, dispatch }) => {
  // only LIGHT means the initial state
  const { light: LIGHT } = applianceId;
  if (idsToShow && idsToShow.length === 1 && idsToShow[0] === LIGHT) {
    dispatch(apiGetUseStatsForLastYear());
  }
};

type StateProps = {|
  idsToShow: (string | null)[],
|};
type OwnProps = {|
  ...ContextRouter,
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

class ExtendSettingPageBase extends Component<Props> {
  componentDidMount() {
    settingPageOnEnter(this.props);
    this.props.dispatch(apiGetUserInfo());
    this.props.dispatch({ type: CLOSING_POP_UP });
    this.props.dispatch({ type: LOAD_SETTING_PAGE });
  }

  componentWillUnmount() {
    this.props.dispatch({ type: CLOSING_POP_UP });
  }

  render() {
    return (
      <FadeTransition pathname={this.props.location.pathname}>
        <SettingPage key="settingPage" />
      </FadeTransition>
    );
  }
}

const mapStateToProps = (state: State): StateProps => ({
  idsToShow: state.applianceState.idsToShow,
});

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  ExtendSettingPageBase
);
