// @flow

import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape, defineMessages } from 'react-intl';
import Currency from 'Client/components/utils/Currency';
import Prefixer from 'Client/lib/Prefixer';
import { calcPrice } from 'Client/lib/pricePlanUtils';
import type { State, PricePlanState } from 'Client/types/state';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  tableLine: {
    borderTop: '1px solid #ccc',
    verticalAlign: 'middle',
    textAlign: 'center',
    width: '70%',
    margin: '0px auto',
    clear: 'both',
  },
  lineTitle: {
    width: '50%',
    float: 'left',
    textAlign: 'left',
  },
  week: {
    fontSize: '0.8em',
  },
  charge: {
    textAlign: 'right',
    width: '50%',
    float: 'left',
  },
  sell: {
    color: '#4bbeae',
  },
  buy: {
    color: '#ff9086',
  },
});

const dateList = (index, intl) => {
  switch (index) {
    case 0:
      return intl.formatMessage({ id: 'chartSubUnitChargeItem.sun' });
    case 1:
      return intl.formatMessage({ id: 'chartSubUnitChargeItem.mon' });
    case 2:
      return intl.formatMessage({ id: 'chartSubUnitChargeItem.tue' });
    case 3:
      return intl.formatMessage({ id: 'chartSubUnitChargeItem.wed' });
    case 4:
      return intl.formatMessage({ id: 'chartSubUnitChargeItem.thu' });
    case 5:
      return intl.formatMessage({ id: 'chartSubUnitChargeItem.fri' });
    default:
      return intl.formatMessage({ id: 'chartSubUnitChargeItem.sat' });
  }
};

const parentheses = period => {
  if (!period) {
    return '';
  }
  const [, month, day] = period.split('-');
  return `${month.replace(/^0/, '')}/${day.replace(/^0/, '')}`;
};

const messages = defineMessages({
  week: {
    id: 'chartSubUnitChargeItem.week',
    description: '週',
  },
  month: {
    id: 'chartSubUnitChargeItem.month',
    description: '月',
  },
  periodDate: {
    id: 'chartSubUnitChargeItem.period',
    description: '(periodDate~) or none',
  },
});

const renderPeriodTitle = (unit, period, index, intl) => {
  switch (unit) {
    case 'month': {
      const periodDate = parentheses(period);
      return (
        <div>
          {intl.formatMessage({ id: 'chartSubUnitChargeItem.place' }).split(',')[index]}
          {intl.formatMessage({ id: 'chartSubUnitChargeItem.week' })}
          <span style={style.week}>{intl.formatMessage(messages.periodDate, { periodDate })}</span>
        </div>
      );
    }
    case 'year': {
      return (
        <div>{intl.formatMessage({ id: 'chartSubUnitChargeItem.month' }).split(',')[index]}</div>
      );
    }
    default:
      return <div>{dateList(index, intl)}</div>;
  }
};

const renderSellCharge = (sellEnergy, sellPrice) => (
  <div style={style.sell}>
    <Currency value={sellEnergy * sellPrice} />
  </div>
);

const buyCharge = (buyEnergy, pricePlan) => (
  <Currency value={calcPrice(0, 24, pricePlan, buyEnergy)} />
);

const renderBuyCharge = (buyEnergy, pricePlan) => (
  <div style={style.buy}>{buyCharge(buyEnergy, pricePlan)}</div>
);

const renderCharge = (unitEnergy, unit, sellPrice, isPg, intl, pricePlan) => {
  if (isPg) {
    return (
      <div style={style.charge}>
        {renderSellCharge(unitEnergy.sellEnergy, sellPrice)}
        {renderBuyCharge(unitEnergy.buyEnergy, pricePlan)}
      </div>
    );
  }
  return <div style={style.charge}>{buyCharge(unitEnergy.buyEnergy, pricePlan)}</div>;
};

type StateProps = {|
  isPg: boolean,
  pricePlan: PricePlanState,
|};
type OwnProps = {|
  unitEnergy: {
    period?: string,
    sellEnergy: number,
    buyEnergy: number[],
  },
  unit: string,
  period: string,
  itemIndex: number,
  sellPrice: number,
  intl: intlShape,
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

export const PureChartSubUnitChargeItem = ({
  unitEnergy,
  unit,
  period,
  itemIndex,
  sellPrice,
  isPg,
  intl,
  pricePlan,
}: Props): Node => (
  <div style={style.tableLine}>
    <div style={style.lineTitle}>{renderPeriodTitle(unit, period, itemIndex, intl)}</div>
    {renderCharge(unitEnergy, unit, sellPrice, isPg, intl, pricePlan)}
  </div>
);

const mapStateToProps = (state: State): StateProps => ({
  isPg: state.userSetting.isPg,
  pricePlan: state.pricePlan,
});

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  injectIntl(PureChartSubUnitChargeItem)
);
