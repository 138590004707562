// @flow

import React from 'react';
import type { Element } from 'react';
import { RouteTransition, presets } from 'react-router-transition';
import ReactScroll from 'react-scroll';

const isRouteChanged = (() => {
  let prevChildrenKey = '';
  return children => {
    if (!children) {
      return false;
    }

    if (prevChildrenKey === children.key) {
      return false;
    }
    prevChildrenKey = children.key || '';
    return true;
  };
})();

type Props = {
  pathname: string,
  children: Element<any>,
};

const FadeTransition = ({ pathname, children }: Props): Element<any> => (
  <RouteTransition
    pathname={pathname}
    component={'div'}
    {...presets.fade}
    mapStyles={styles => ({
      minHeight: '100vh',
      opacity: styles.opacity,
    })}
  >
    {children}
    {isRouteChanged(children)
      ? ReactScroll.animateScroll.scrollToTop({ delay: 0, duration: 0 })
      : null}
  </RouteTransition>
);

export default FadeTransition;
