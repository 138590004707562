// @flow
import type { Action } from 'redux';
import type { EnergyState as State } from 'Client/types/state';
import requestsReducer, { initialRequestsState } from './requestsReducer';
import energyReducer, { initialEnergyState } from './energyReducer';

const initialState = {
  ...initialRequestsState,
  ...initialEnergyState,
};

export default (state: State = initialState, action: Action = {}): State => {
  const requestsState = {
    hourlyRequesting: state.hourlyRequesting,
    dailyRequesting: state.dailyRequesting,
    weeklyRequesting: state.weeklyRequesting,
    monthlyRequesting: state.monthlyRequesting,
    yearlyRequesting: state.yearlyRequesting,
  };
  const energyState = {
    hourly: state.hourly,
    daily: state.daily,
    weekly: state.weekly,
    monthly: state.monthly,
    yearly: state.yearly,
  };
  return {
    ...requestsReducer(requestsState, action),
    ...energyReducer(energyState, action),
  };
};
