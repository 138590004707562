// @flow

import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { intlShape, injectIntl } from 'react-intl';
import {
  SETTING_NORMAL_PRICE_PLAN_POP_UP,
  CHANGE_NORMAL_PLAN_PRICE,
  OPEN_PRICE_PLAN_POP_UP,
} from 'Client/actions/types';
import Prefixer from 'Client/lib/Prefixer';
import checkPrice from 'Client/lib/checkPrice';
import { toSetUpPriceValue } from 'Client/lib/globalUtils';
import type { State } from 'Client/types/state';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  content: {
    position: 'relative',
    overflow: 'auto',
  },
  description: {
    fontSize: '0.8em',
    lineHeight: '150%',
    padding: '35px 0px 10px',
  },
  button: {
    margin: '20px 5% 15px',
    padding: '20px 10px',
    backgroundColor: '#ffbd00',
    borderRadius: 5,
    fontSize: '1.3em',
    fontWeight: 'bold',
    cursor: 'pointer',
    color: '#fff',
  },
  listItem: {
    padding: '0 10px 0 10px',
    margin: '0 0 0 0',
    color: '#1f1f21',
    lineHeight: '60px',
  },
  itemValue: {
    textAlign: 'center',
    verticalAlign: 'text-bottom',
  },
  input: {
    width: '40px',
    border: '1px solid #ccc',
    textAlign: 'right',
    borderRadius: '4px',
    height: '22px',
    padding: '2px 8px 2px 0px',
    fontSize: '18px',
  },
  priceError: {
    color: 'red',
    fontSize: '0.8em',
  },
  backButton: {
    width: '20%',
    borderRadius: '5px',
    backgroundColor: '#A6A6A6',
    margin: '5% 5%',
    padding: '4px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  backIcon: {
    textAlign: 'center',
    marginRight: '3px',
    width: '20%',
  },
  backText: {
    marginLeft: '3px',
    fontSize: '1em',
    fontWeight: '500',
    color: '#FFFFFF',
  },
  backButtonArea: {
    padding: '5px',
    borderTop: 'thin solid #DDDDDD',
  },
});

const renderPriceItem = (dispNormalPlanPrice, actionType, dispatch, intl) => {
  const type = intl.formatMessage({ id: 'settingPage.priceAlertType' });
  const pattern = intl.formatMessage({ id: 'settingPage.priceAlertPattern' });
  const minLength = intl.formatMessage({ id: 'settingPage.priceAlertMinLength' });
  const maxLength = intl.formatMessage({ id: 'settingPage.priceAlertMaxLength' });
  return (
    <div style={style.listItem}>
      <div style={style.itemValue}>
        <input
          type="text"
          name="normalPlanPrice"
          style={style.input}
          pattern={pattern}
          maxLength={maxLength}
          minLength={minLength}
          value={dispNormalPlanPrice}
          onChange={e => {
            const inputElement = e.target;
            toSetUpPriceValue(inputElement, type, actionType, dispatch);
          }}
        />
        {intl.formatMessage({ id: 'settingPage.yen' })}
      </div>
    </div>
  );
};

const renderButton = (dispatch, intl) => {
  const button = require('Client/images/chebron-l-white.svg');
  return (
    <div
      style={style.backButton}
      className="styleBack"
      onClick={() =>
        dispatch({
          type: OPEN_PRICE_PLAN_POP_UP,
        })
      }
    >
      <img src={button} alt="chebron-left" style={style.backIcon} />
      <div style={style.backText}>{intl.formatMessage({ id: 'settingPricePlanPopup.back' })}</div>
    </div>
  );
};

const renderPricePlanNormal = (dispatch, intl, dispNormalPlanPrice) => {
  const type = intl.formatMessage({ id: 'settingPage.priceAlertType' });
  return (
    <div style={style.content}>
      <div style={style.description}>
        {intl.formatMessage({ id: 'settingPricePlanPopup.guideNormal' })}
        {renderPriceItem(dispNormalPlanPrice, CHANGE_NORMAL_PLAN_PRICE, dispatch, intl)}
        {checkPrice(dispNormalPlanPrice, type) ? (
          <div style={style.priceError}>{intl.formatMessage({ id: 'settingPage.priceAlert' })}</div>
        ) : (
          ''
        )}
        <div
          style={
            checkPrice(dispNormalPlanPrice, type) ? { ...style.button, opacity: 0.3 } : style.button
          }
          onClick={() =>
            dispatch({
              type: SETTING_NORMAL_PRICE_PLAN_POP_UP,
              pricePlan: 'NORMAL',
              normalPlanPrice: dispNormalPlanPrice,
              isErr: checkPrice(dispNormalPlanPrice, type),
            })
          }
        >
          {intl.formatMessage({ id: 'settingPricePlanPopup.saveSetting' })}
        </div>
        <div style={style.backButtonArea}>{renderButton(dispatch, intl)}</div>
      </div>
    </div>
  );
};

type StateProps = {|
  dispNormalPlanPrice: number,
|};
type OwnProps = {|
  intl: intlShape,
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

export const PureSettingPricePlanNormal = ({
  dispatch,
  intl,
  dispNormalPlanPrice,
}: Props): Node => <div>{renderPricePlanNormal(dispatch, intl, dispNormalPlanPrice)}</div>;

const mapStateToProps = (state: State): StateProps => ({
  dispNormalPlanPrice: state.pricePlan.dispNormalPlanPrice,
});

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  injectIntl(PureSettingPricePlanNormal)
);
