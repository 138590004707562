// @flow

import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { Link, Redirect } from 'react-router-dom';
import Prefixer from 'Client/lib/Prefixer';

const imageSentMail = require('Client/images/img_sent_mail.png');
const imageChangedMail = require('Client/images/img_changed_mail.png');
const imageChangedPassword = require('Client/images/img_changed_password.png');

const prefixer = new Prefixer();
const ACCEPTABLE_TYPE: string[] = ['mailsent', 'mailchanged', 'password'];

const style = prefixer.prefix({
  centeringContainer: {
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    color: '#454545',
  },
  completedImageBox: {
    width: '100%',
    marginBottom: 5,
    textAlign: 'center',
  },
  completedMessage: {
    margin: '0 0 55px',
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: 1.6,
    textAlign: 'center',
  },
  actionButton: {
    display: 'block',
    width: 280,
    height: 50,
    margin: '0 auto',
    borderRadius: 5,
    backgroundColor: '#ffbd00',
    color: '#fff',
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: '50px',
    textAlign: 'center',
    cursor: 'pointer',
    textDecoration: 'none',
  },
});

type LimitedTypes = 'mailsent' | 'mailchanged' | 'password';
type Props = {
  type: LimitedTypes,
  intl: intlShape,
};

const getImage = type => {
  switch (type) {
    case 'mailsent':
      return imageSentMail;
    case 'mailchanged':
      return imageChangedMail;
    case 'password':
    default:
      return imageChangedPassword;
  }
};

const CompletedMessage = ({ type, intl }: { type: LimitedTypes, intl: intlShape }) => {
  switch (type) {
    case 'mailsent':
      return (
        <p style={style.completedMessage}>
          {intl.formatMessage({ id: 'changeMail.sentMessageL1' })}
          <br />
        </p>
      );
    case 'mailchanged':
      return (
        <p style={style.completedMessage}>
          {intl.formatMessage({ id: 'changeMail.changedMessageL1' })}
          <br />
          {intl.formatMessage({ id: 'changeMail.changedMessageL2' })}
          <br />
        </p>
      );
    case 'password':
    default:
      return (
        <p style={style.completedMessage}>
          {intl.formatMessage({ id: 'changePassword.changedMessageL1' })}
          <br />
          {intl.formatMessage({ id: 'changePassword.changedMessageL2' })}
          <br />
        </p>
      );
  }
};

/**
 * 完了画面
 */
const Completed = (props: Props) => {
  if (!ACCEPTABLE_TYPE.includes(props.type)) {
    return <Redirect to="/login" />;
  }
  return (
    <div style={style.centeringContainer}>
      <div>
        <div style={style.completedImageBox}>
          <img src={getImage(props.type)} width="120" height="120" alt="" />
        </div>
        <CompletedMessage type={props.type} intl={props.intl} />
        <Link to="/login" replace style={style.actionButton}>
          {props.intl.formatMessage({ id: 'changeOnCompleted.toBack' })}
        </Link>
      </div>
    </div>
  );
};

export default injectIntl(Completed);

export const CompletedMessageForTest = CompletedMessage;
export const CompletedForTest = Completed;
