// @flow

import type { Action } from 'redux';
import moment from 'moment-timezone';
import type { TimeRangeState as State } from 'Client/types/state';

import {
  ENTER_APPLICATION,
  SELECT_NEXT_HOUR,
  SELECT_PREV_HOUR,
  SELECT_CLICKED_HOUR,
  LOAD_TOP_PAGE,
  LOAD_TOP_PAGE_LATEST_TWO_HOURS,
} from 'Client/actions/types';

const initialState = {
  currentDate: '',
  currentTime: '15:00',
  selectedHour: 15,
  range: [],
  locale: 'ja',
};

const DISPLAY_RANGE = 12;

const createRangesByMoment = () => {
  const now = moment();
  const timeRange = Array.from(Array(12).keys()).map(value => {
    if (value === 0) {
      return now.hours();
    }
    return now.subtract(1, 'hour').hours();
  });
  return timeRange;
};

const prevHour = (selectedHour, range) => {
  const index = range.findIndex(hour => hour === selectedHour);
  return index === DISPLAY_RANGE - 1 ? range[0] : range[index + 1];
};

const nextHour = (selectedHour, range) => {
  const index = range.findIndex(hour => hour === selectedHour);
  return index === 0 ? range[DISPLAY_RANGE - 1] : range[index - 1];
};

export default (state: State = initialState, action: Action = {}): State => {
  switch (action.type) {
    case ENTER_APPLICATION: {
      return {
        ...state,
        locale: action.locale,
      };
    }
    case SELECT_NEXT_HOUR: {
      const selectedHour = nextHour(state.selectedHour, state.range);
      return {
        ...state,
        selectedHour,
      };
    }
    case SELECT_PREV_HOUR: {
      const selectedHour = prevHour(state.selectedHour, state.range);
      return {
        ...state,
        selectedHour,
      };
    }
    case SELECT_CLICKED_HOUR: {
      return {
        ...state,
        selectedHour: action.clickedHour,
      };
    }
    case LOAD_TOP_PAGE:
    case LOAD_TOP_PAGE_LATEST_TWO_HOURS: {
      // BSTの計算はmomentにおまかせ
      const range = createRangesByMoment();
      const now = moment();
      return {
        ...state,
        currentDate: now.format('YYYY-MM-DD'),
        currentTime: now.format('HH:mm'),
        selectedHour: Number(now.format('HH')),
        range,
      };
    }
    default: {
      return state;
    }
  }
};
