import React, { Fragment } from 'react';
import type { Dispatch } from 'redux';
import { withRouter } from 'react-router-dom';
import type { History } from 'react-router-dom';
import Prefixer from 'Client/lib/Prefixer';
import { houseAnimationType } from 'Client/lib/topPageUtils';
import houseAlert from 'Client/images/house-alert@2x.png';
import { OPEN_POP_UP } from 'Client/actions/types';
import { NO_DATA_FOR_24_HOURS } from 'Client/actions/alertPopupTypes';

const prefixer = new Prefixer();
const style = prefixer.prefix({
  houseAnimeArea: {
    position: 'relative',
    left: 0,
    padding: '0px',
    margin: '0px',
    clear: 'both',
    width: '100%',
    display: 'block',
    height: 'auto',
  },
  houseAnimeImage: {
    top: 0,
    margin: '0px',
    width: '100%',
    verticalAlign: 'bottom',
  },
  alertBubble: {
    position: 'absolute',
    background: '#f9cdc7',
    color: '#f44e4e',
    top: '6%',
    left: '28%',
    fontSize: 13,
    fontWeight: 'bold',
    border: 'solid 1px #fff',
    borderRadius: 4,
    padding: '7px 8px 6px',
    lineHeight: 1,
  },
  triangleDown: {
    position: 'absolute',
    width: 0,
    height: 0,
    border: 'solid 5px transparent',
    borderTop: 'solid 6px #fff',
    left: 8,
    bottom: -11,
  },
  rightTriangle: {
    display: 'inline-block',
    width: 0,
    height: 0,
    position: 'relative',
    top: -1,
    marginLeft: 8,
    border: 'solid 3.5px transparent',
    borderLeft: 'solid 6px #f44e4e',
    borderRightWidth: 0,
  },
  exclamation: {
    position: 'absolute',
    top: '46%',
    left: '26%',
    width: '8.6%',
  },
});

const AlertBox = ({ noUpload, noSolar, dispatch, history }) => {
  if (noUpload) {
    const clickHanlder = e =>
      dispatch({ type: OPEN_POP_UP, contentType: NO_DATA_FOR_24_HOURS, target: e.target });
    return (
      // ポップアップ表示
      <Fragment>
        <div style={style.alertBubble} onClick={clickHanlder}>
          データが送信できていません
          <span style={style.rightTriangle} />
          <div style={style.triangleDown} />
        </div>
        <img src={houseAlert} style={style.exclamation} alt="houseAlert" onClick={clickHanlder} />
      </Fragment>
    );
  }

  if (noSolar) {
    // 週ページに遷移
    const clickHanlder = () => history.replace(`/chart/week/`);
    return (
      <Fragment>
        <div style={style.alertBubble} onClick={clickHanlder}>
          太陽光発電が動いていません
          <span style={style.rightTriangle} />
          <div style={style.triangleDown} />
        </div>
        <img src={houseAlert} style={style.exclamation} alt="houseAlert" onClick={clickHanlder} />
      </Fragment>
    );
  }

  return <div />;
};

type Props = {
  range: string[],
  aplFilter: {
    id: string,
    display: boolean,
  }[],
  hourlyDurations: {
    hour: number,
    appliances: {
      id: string,
      duration: number,
    }[],
  }[],
  alertInfo: {
    noUpload: boolean,
    noSolar: boolean,
  },
  dispatch: Dispatch,
  history: History,
};

export default withRouter(
  ({ range, aplFilter, hourlyDurations, alertInfo, dispatch, history }: Props) => (
    <div style={style.houseAnimeArea}>
      <AlertBox
        noUpload={alertInfo.noUpload}
        noSolar={alertInfo.noSolar}
        dispatch={dispatch}
        history={history}
      />
      <img
        src={houseAnimationType(range, aplFilter, hourlyDurations)}
        alt={'anime'}
        style={style.houseAnimeImage}
      />
    </div>
  )
);
