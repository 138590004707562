// @flow

import React from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import type { Location } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Prefixer from 'Client/lib/Prefixer';
import { RESET_RESET_PASSWORD } from 'Client/actions/types';
import { updatePassword } from 'Client/actions/apis';
import HeaderBar from 'Client/components/HeaderBar';
import ContentContainer from 'Client/components/utils/ContentContainer';
import { validateFormValues } from 'Client/lib/globalUtils';
import type { State } from 'Client/types/state';

const PAGE_TYPE = 'resetPassword';
const {
  newPassword: validateNewPassword,
  confirmationPassword: validateConfirmationPassword,
} = validateFormValues;
const prefixer = new Prefixer();

const style = prefixer.prefix({
  container: {
    padding: '75px 20px 100px',
    color: '#454545',
  },
  startLine: {
    marginBottom: 10,
    lineHeight: 1,
  },
  inputArea: {
    marginBottom: 20,
  },
  lastInputArea: {
    marginBottom: 40,
  },
  input: {
    width: '100%',
    height: 40,
    padding: '0 0.35em',
    borderRadius: 4,
    border: 'solid 1px #d8d8d8',
    fontSize: '16px',
    boxSizing: 'border-box',
  },
  disabledInput: {
    backgroundColor: '#eaeaea',
  },
  errorMessage: {
    margin: '5px 0 0',
    color: '#ff0000',
    fontSize: 12,
  },
  guideBox: {
    marginBottom: 40,
    padding: '12px 15px',
    backgroundColor: '#eaeaea',
    fontSize: 12,
    lineHeight: 1.7,
  },
  actionButton: {
    display: 'block',
    width: 280,
    height: 50,
    margin: '0 auto',
    borderRadius: 5,
    border: 0,
    outline: 0,
    backgroundColor: '#ffbd00',
    color: '#fff',
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: "'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif",
    lineHeight: '50px',
    textAlign: 'center',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  disabledActionButton: {
    backgroundColor: '#d8d8d8',
  },
});

type StateProps = {|
  resetPasswordState: string,
|};
type OwnProps = {|
  location: Location,
  intl: intlShape,
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

type FormValues = {
  newPassword: string,
  confirmationPassword: string,
};

const parseSearch = (search: string): { [key: string]: string } => {
  const searchStr = search.substr(1);
  const params = {};
  searchStr.split('&').forEach(x => {
    const i = x.indexOf('=');
    const key = x.slice(0, i);
    const val = x.slice(i + 1);
    params[key] = decodeURIComponent(val);
  });
  return params;
};

/**
 * 入力画面
 */
const ResetPasswordForm = (props: Props) => {
  const isRequesting = props.resetPasswordState === 'REQUESTING';
  const initialFormValues: FormValues = { newPassword: '', confirmationPassword: '' };
  return (
    <ContentContainer style={style.container}>
      <HeaderBar pageType={PAGE_TYPE} requesting={isRequesting} />
      <Formik
        initialValues={initialFormValues}
        validate={(values: FormValues) => ({
          ...validateNewPassword(values, PAGE_TYPE),
          ...validateConfirmationPassword(values, PAGE_TYPE),
        })}
        validateOnChange={false}
        onSubmit={({ newPassword }: FormValues) => {
          props.dispatch(updatePassword(parseSearch(props.location.search).oobCode, newPassword));
        }}
        render={() => (
          <Form noValidate>
            <p style={style.startLine}>
              {props.intl.formatMessage({ id: `${PAGE_TYPE}.toAskNewPassword` })}
            </p>
            <div style={style.inputArea}>
              <Field
                type="password"
                name="newPassword"
                disabled={isRequesting}
                style={{
                  ...style.input,
                  ...(isRequesting ? style.disabledInput : null),
                }}
              />
              <ErrorMessage
                name="newPassword"
                component="p"
                render={id => <p style={style.errorMessage}>{props.intl.formatMessage({ id })}</p>}
              />
            </div>
            <p style={style.guideBox}>
              {props.intl.formatMessage({ id: `${PAGE_TYPE}.registerGuideL1` })}
              <br />
              {props.intl.formatMessage({ id: `${PAGE_TYPE}.registerGuideL2` })}
              <br />
            </p>
            <p style={style.startLine}>
              {props.intl.formatMessage({ id: `${PAGE_TYPE}.toConfirmNewPassword` })}
            </p>
            <div style={{ ...style.inputArea, ...style.lastInputArea }}>
              <Field
                type="password"
                name="confirmationPassword"
                disabled={isRequesting}
                style={{
                  ...style.input,
                  ...(isRequesting ? style.disabledInput : null),
                }}
              />
              <ErrorMessage
                name="confirmationPassword"
                render={id => <p style={style.errorMessage}>{props.intl.formatMessage({ id })}</p>}
              />
            </div>
            <button
              type="submit"
              disabled={isRequesting}
              style={{
                ...style.actionButton,
                ...(isRequesting ? style.disabledActionButton : null),
              }}
            >
              {props.intl.formatMessage({ id: `${PAGE_TYPE}.toRegisterMail` })}
            </button>
          </Form>
        )}
      />
    </ContentContainer>
  );
};

class ResetPasswordRegister extends React.Component<Props> {
  componentWillUnmount() {
    this.props.dispatch({ type: RESET_RESET_PASSWORD });
  }

  render() {
    if (!parseSearch(this.props.location.search).oobCode) {
      return <Redirect to="/login" />;
    }

    if (this.props.resetPasswordState === 'ERROR') {
      return <Redirect to="/error/resetpassword" />;
    }

    return (
      <div>
        {this.props.resetPasswordState === 'SUCCESS' ? (
          <Redirect to="/completed/password" />
        ) : (
          <ResetPasswordForm {...this.props} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: State): StateProps => ({
  resetPasswordState: state.loginInfo.resetPasswordState,
});

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  injectIntl(ResetPasswordRegister)
);

export const ResetPasswordRegisterForTest = ResetPasswordRegister;
