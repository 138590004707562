// @flow
import moment from 'moment';
import { getTotalEnergy } from './globalUtils';

export const minNumberOfPlan = 2;
export const maxNumberOfPlan = 4;

const createEnergyArrayDay = (array, sellBuy) => {
  const sellBuyEnergy = [];
  const sortedArray = array.sort((a, b) => Number(a.hour) - Number(b.hour));
  Array.from(new Array(24)).forEach((ele, index) => {
    // 昨日のデータが含まれている場合があるので現在時刻よりもhourが大きい場合は0にする
    if (
      index < sortedArray.length &&
      parseInt(moment().format('HH'), 10) >= sortedArray[index].hour
    ) {
      sellBuyEnergy.push(sortedArray[index][`${sellBuy}Energy`]);
      return;
    }
    sellBuyEnergy.push(0);
  });
  return sellBuyEnergy;
};

const nomalTimeTotal = (array: number[], from: number, to: number) => {
  const nomalTimeArray = array.slice(from, to);
  return getTotalEnergy(nomalTimeArray);
};

const LastTimeTotal = (array: number[], from: number, to: number) => {
  const firstArray = array.slice(0, from);
  const secondArray = array.slice(to, 24);
  return getTotalEnergy(firstArray) + getTotalEnergy(secondArray);
};

export const calcPrice = (
  startTime: number,
  endTime: number,
  pricePlan: Object,
  energy: 0 | number[]
) => {
  if (energy === 0) return 0;

  if (pricePlan.pricePlan === 'MULTISTAGE') {
    // 電力の有効範囲を startTime ~ endTime にする（範囲外の数値を 0 にする）
    const validEnergy = energy.map((v, i) => {
      if (startTime <= i && i < endTime) {
        return v;
      }
      return 0;
    });
    const multistagePlanSetting = [...pricePlan.multistagePlanSetting];
    const sortedMultiplePlanSetting = multistagePlanSetting.sort(
      (a, b) => Number(a.startTime) - Number(b.startTime)
    );
    const result = sortedMultiplePlanSetting.reduce((prev, ele, index, array) => {
      if (array.length - 1 === index) {
        return prev + LastTimeTotal(validEnergy, array[0].startTime, ele.startTime) * ele.price;
      }
      return (
        prev + nomalTimeTotal(validEnergy, ele.startTime, array[index + 1].startTime) * ele.price
      );
    }, 0);
    return result;
  }
  // 同一料金プランの場合
  const totalEnergy = nomalTimeTotal(energy, startTime, endTime);
  return pricePlan.normalPlanPrice * totalEnergy;
};

export const calcPriceRanking = (pricePlan: Object, energy: number[]): number =>
  calcPrice(0, 24, pricePlan, energy);

export const calcHourlyBuyCharge = (
  buyEnergy: number,
  selectedHour: number,
  pricePlan: Object
): number => {
  if (pricePlan.pricePlan === 'NORMAL') {
    return buyEnergy * pricePlan.normalPlanPrice;
  }
  const multistagePlanSetting = [...pricePlan.multistagePlanSetting];
  const sortedMultiplePlanSetting = multistagePlanSetting.sort(
    (a, b) => Number(a.startTime) - Number(b.startTime)
  );
  const targetPricePlan = sortedMultiplePlanSetting.find((ele, index, array) => {
    if (array.length - 1 === index) {
      return true;
    }
    return ele.startTime <= selectedHour && selectedHour < array[index + 1].startTime;
  });
  return buyEnergy * targetPricePlan.price;
};

const calcBillBuyChargeToday = (
  hourlyEnergyList: {
    hour: number,
    sellEnergy: number,
    buyEnergy: number,
  }[],
  pricePlan: Object
): number => {
  const buyEnergyList = createEnergyArrayDay(hourlyEnergyList, 'buy');
  if (pricePlan.pricePlan === 'NORMAL') {
    const buyEnergy = getTotalEnergy(buyEnergyList);
    return buyEnergy * pricePlan.normalPlanPrice;
  }
  return calcPrice(0, 24, pricePlan, buyEnergyList);
};

export const calcTodaySellData = (
  sellPrice: number,
  hourlyEnergyList: {
    hour: number,
    sellEnergy: number,
    buyEnergy: number,
  }[]
) => {
  const sellEnergy = getTotalEnergy(createEnergyArrayDay(hourlyEnergyList, 'sell'));
  const sellCharge = sellPrice * sellEnergy;
  return { sellEnergy, sellCharge };
};

export const calcTodayBuyData = (
  hourlyEnergyList: {
    hour: number,
    sellEnergy: number,
    buyEnergy: number,
  }[],
  pricePlan: Object
) => {
  const buyEnergy = getTotalEnergy(createEnergyArrayDay(hourlyEnergyList, 'buy'));
  const buyCharge = calcBillBuyChargeToday(hourlyEnergyList, pricePlan);
  return { buyEnergy, buyCharge };
};

const calcBillBuyCharge = (energy: number[], pricePlan: Object): number =>
  calcPrice(0, 24, pricePlan, energy);

export const balance = (
  sellPrice: number,
  sellEnergy: number,
  buyEnergy: number[],
  pricePlan: Object
): number => {
  const sellCharge = sellPrice * sellEnergy;
  const buyCharge = calcBillBuyCharge(buyEnergy, pricePlan);
  return sellCharge - buyCharge;
};
