// @flow

import React, { useEffect } from 'react';
import type { ChildrenArray, Node } from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import Router from 'Client/components/router/Router';
import Help from './Help';

type OwnProps = {| intl: intlShape, children: ChildrenArray<Node> |};
type Props = {|
  ...OwnProps,
  dispatch: Dispatch,
|};

const AppBase = (props: Props) => {
  useEffect(() => {
    document.title = props.intl.formatMessage({ id: 'application.title' });
  }, []);
  return (
    <div>
      {props.children}
      <Help />
    </div>
  );
};

const IntlInjectApp = injectIntl(AppBase);

export const App = () => (
  <IntlInjectApp>
    <Router />
  </IntlInjectApp>
);

export default connect<Props, OwnProps, {}, {}, {}, Dispatch>()(App);
