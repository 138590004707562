// @flow

import React, { useState } from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import Currency from 'Client/components/utils/Currency';
import Loading from 'Client/components/utils/Loading';
import Card from 'Client/components/utils/Card';
import CircleChart from 'Client/components/CircleChart';
import StackedBarChart from 'Client/components/StackedBarChart';
import ChartNodata, { CHART_NO_DATA } from 'Client/components/ChartNoData';
import QuestionIcon from 'Client/components/utils/QuestionIcon';
import { applianceId } from 'Client/lib/id';
import { calcPrice } from 'Client/lib/pricePlanUtils';
import { HELP_CHART_DAILY, HELP_STACKED_CHART } from 'Client/actions/helpPopupTypes';
import { OPEN_POP_UP } from 'Client/actions/types';
import Prefixer from 'Client/lib/Prefixer';
import type { State, PricePlanState } from 'Client/types/state';
import type { ChartData, StackedData } from 'Client/types/types';
import { convertEnergy, getTotalEnergy } from 'Client/lib/globalUtils';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  headline: {
    marginBottom: 20,
  },
  title: {
    verticalAlign: 'middle',
  },
  criterion: {
    fontSize: '0.7em',
    paddingLeft: '5px',
  },
  chartCircleBody: {
    width: '70%',
    margin: '0 auto',
  },
  legendContainer: {
    margin: '15px 10px 20px',
    lineHeight: 1,
  },
  legendItem: {
    height: 16,
    display: 'flex',
    alignItems: 'center',
  },
  adjacentLegendItem: {
    marginTop: 10,
  },
  legendIcon: {
    width: 16,
    height: 16,
    marginRight: 5,
    backgroundSize: 'cover',
  },
  legendChargeIcon: {
    backgroundImage:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAANlBMVEUAAABRrux0eefy1CFzeeny1iBQr++fz1BRre10eeigzlHx0yGhzlGgzlFRru10euihzlLy1CLd7ldRAAAADnRSTlMAwMDAUFBAQPDw7+/AvwHyqMUAAABJSURBVDjL7co5DsAgEMBAc+cO/P+zKRchZekQBW49ENxZqnLdnSDIEyAlnA4uig6OHsgLjANv09O0wDRgw+rA4HUQwdv9F5jIByA9PQQ9wzH4AAAAAElFTkSuQmCC)',
  },
  legendBackgroundIcon: {
    backgroundImage:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAAHlBMVEUAAADb2Njb2Njb2Njb19fa2trb2Njb2dnf19fb2NjsiOw5AAAACXRSTlMA8MBwgDCwfyBcau5zAAAAXUlEQVQoz2NgYAhXnAkFQqUMQMA8EwEmGgAFMmcigWlQBQhgwBCJKiDK4IkqMIVBE1VgEoMkqsBEhploYKQLoAcQRhBiBnIgqoAERkRhRiUDsyRaZDOEIJKDBwMDAMDYHF99NqdGAAAAAElFTkSuQmCC)',
  },
  legendTotalIcon: {
    backgroundImage:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAM1BMVEXb19cAAADb2Nh0eejc2dny1iB0eOfb19dzeujx0yHy0yHy1CHb2NhRru2hzlJ0eujy1CLTUgXGAAAADHRSTlPAAPDAUFBAQO/vwL+aYLiTAAAAS0lEQVQ4y+3LNxIAIQxDUZm4gXT/02JmaLE7hoLf6gnk/7dxZZZndWQDwfO4AlzAJwOLJoNHA/WCfSApXXAMMFAE4GQQQQ5m/Y/UAaKMNBf8/NGyAAAAAElFTkSuQmCC)',
  },
  legendLabel: {
    width: '4em',
    margin: 0,
    marginRight: '1em',
    fontSize: 14,
    fontWeight: 'normal',
    textAlign: 'left',
  },
  legendValue: {
    margin: 0,
    fontSize: 14,
    textAlign: 'right',
  },
  info: {
    top: '-11px',
    right: '-5px',
    zIndex: '2',
    position: 'absolute',
  },
});

type StateProps = {|
  requestingApplianceEnergy: boolean,
  applianceEnergy: Object[],
  pricePlan: PricePlanState,
|};
type OwnProps = {|
  requesting: boolean,
  buyEnergy: 0 | (number | null)[],
  period: string,
  intl: intlShape,
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

type BarData = null | { [key: 'background' | 'charge']: ChartData };
type UpdateActiveBarData = (barData: BarData) => void;

/**
 * 電力配列を料金配列に変換する
 */
const convertEnergyToCharge = (
  energies: (number | null)[],
  pricePlan: PricePlanState
): number[] => {
  const numericEnergies: number[] = convertEnergy(energies);
  return numericEnergies.map((_, i) => calcPrice(i, i + 1, pricePlan, numericEnergies));
};

/**
 * 各時間帯に分けた電力料金の合計値配列を返す
 * midnight: 0 ~ 5 時
 * morning: 6 ~ 11 時
 * noon: 12 ~ 17 時
 * night: 18 ~ 23 時
 */
const getSplitCharges = (charges: number[]): { [timeLabel: string]: number } => ({
  midnight: getTotalEnergy(charges.slice(0, 6)),
  morning: getTotalEnergy(charges.slice(6, 12)),
  noon: getTotalEnergy(charges.slice(12, 18)),
  night: getTotalEnergy(charges.slice(18, 24)),
});

/**
 * 円グラフ描画に必要なデータを生成して返す
 * 発電量によっては backgroundCharges が boughtCharges を上回る場合があるため、考慮する
 */
const getChartDataForCircle = (
  applianceCharges: number[],
  backgroundCharges: number[],
  intl: intlShape
): {
  x: 'midnight' | 'morning' | 'noon' | 'night' | 'background',
  y: number,
  color: string,
}[] => {
  // 各時間帯に分けた電力の合計値配列
  const splitApplianceCharges = getSplitCharges(applianceCharges);

  return [
    {
      x: intl.formatMessage({ id: 'chartCircle.midnight' }),
      y: splitApplianceCharges.midnight,
      color: '#747ae8',
    },
    {
      x: intl.formatMessage({ id: 'chartCircle.morning' }),
      y: splitApplianceCharges.morning,
      color: '#f2d422',
    },
    {
      x: intl.formatMessage({ id: 'chartCircle.noon' }),
      y: splitApplianceCharges.noon,
      color: '#b8dd1f',
    },
    {
      x: intl.formatMessage({ id: 'chartCircle.night' }),
      y: splitApplianceCharges.night,
      color: '#51aeed',
    },
    {
      x: intl.formatMessage({ id: 'chartCircle.background' }),
      y: getTotalEnergy(backgroundCharges),
      color: '#ddd',
    },
  ];
};

/**
 * 積算棒グラフ描画に必要なデータを生成して返す
 * 発電量によっては backgroundCharges が boughtCharges を上回る場合があるため、考慮する
 */
const getChartDataForBar = (
  applianceCharges: number[],
  backgroundCharges: number[],
  validTimeRange: number
): {
  [key: 'background' | 'charge']: StackedData,
} => ({
  background: {
    data: backgroundCharges.slice(0, validTimeRange).map((value, i) => ({
      x: i,
      y: value,
    })),
    colors: {
      default: '#ddd',
      disabled: '#eaeaea',
    },
  },
  charge: {
    data: applianceCharges.slice(0, validTimeRange).map((value, i) => ({
      x: i,
      y: value,
    })),
    colors: [
      ...Array(6).fill({
        default: '#747ae8',
        disabled: '#ccd0ea',
      }),
      ...Array(6).fill({
        default: '#f2d422',
        disabled: '#efe7ab',
      }),
      ...Array(6).fill({
        default: '#b8dd1f',
        disabled: '#d4e888',
      }),
      ...Array(6).fill({
        default: '#51aeed',
        disabled: '#c3ddea',
      }),
    ],
  },
});

const InfoButton = (props: { contentType: string, dispatch: Dispatch }): Node => (
  <div style={style.info}>
    <QuestionIcon
      onClick={e => {
        props.dispatch({
          type: OPEN_POP_UP,
          contentType: props.contentType,
          target: e.target,
        });
      }}
    />
  </div>
);

const CardWithTitle = (props: { children: Node, messageKey: string, intl: intlShape }): Node => (
  <Card>
    <div style={style.headline}>
      <span style={style.title}>
        {props.intl.formatMessage({ id: `${props.messageKey}.title` })}
      </span>
      <span style={style.criterion}>{props.intl.formatMessage({ id: 'others.criterion' })}</span>
    </div>
    {props.children}
  </Card>
);

/**
 * 円グラフエリア、棒グラフエリア表示用カセットコンポーネント
 */
const Cards = (props: {|
  ...Props,
  component?: Node,
  circleComponent?: Node,
  barComponent?: Node,
|}): Node => (
  <div>
    <CardWithTitle messageKey="chartChargeAtDayOnCircle" intl={props.intl}>
      <InfoButton contentType={HELP_CHART_DAILY} dispatch={props.dispatch} />
      {props.component || props.circleComponent || null}
    </CardWithTitle>
    <CardWithTitle messageKey="chartChargeAtDayOnBar" intl={props.intl}>
      <InfoButton contentType={HELP_STACKED_CHART} dispatch={props.dispatch} />
      {props.component || props.barComponent || null}
    </CardWithTitle>
  </div>
);

Cards.defaultProps = {
  component: null,
  circleComponent: null,
  barComponent: null,
};

const LegendForBar = ({
  activeBarData,
  intl,
}: {
  activeBarData: BarData,
  intl: intlShape,
}): Node => (
  <div style={style.legendContainer}>
    <div style={style.legendItem}>
      <div style={{ ...style.legendIcon, ...style.legendChargeIcon }} />
      <p style={style.legendLabel}>{intl.formatMessage({ id: 'chartChargeAtDayOnBar.charge' })}</p>
      {activeBarData && (
        <p style={style.legendValue}>
          <Currency value={activeBarData.charge.y} />
        </p>
      )}
    </div>
    <div style={{ ...style.legendItem, ...style.adjacentLegendItem }}>
      <div style={{ ...style.legendIcon, ...style.legendBackgroundIcon }} />
      <p style={style.legendLabel}>
        {intl.formatMessage({ id: 'chartChargeAtDayOnBar.background' })}
      </p>
      {activeBarData && (
        <p style={style.legendValue}>
          <Currency value={activeBarData.background.y} />
        </p>
      )}
    </div>
    <div style={{ ...style.legendItem, ...style.adjacentLegendItem }}>
      {activeBarData && [
        <div key="legendIcon" style={{ ...style.legendIcon, ...style.legendTotalIcon }} />,
        <p key="legendLabel" style={style.legendLabel}>
          {intl.formatMessage({ id: 'chartChargeAtDayOnBar.total' })}
        </p>,
        <p key="legendValue" style={style.legendValue}>
          <Currency value={activeBarData.charge.y + activeBarData.background.y} />
        </p>,
      ]}
    </div>
  </div>
);

/**
 * 円グラフ描画エリア
 * 必要データを生成して CircleChart に渡す
 */
const ChartCircle = (props: {
  intl: intlShape,
  applianceCharges: number[],
  backgroundCharges: number[],
}): Node => {
  const { intl, applianceCharges, backgroundCharges } = props;
  const chartData = getChartDataForCircle(applianceCharges, backgroundCharges, intl);
  return (
    <div style={style.chartCircleBody}>
      <CircleChart
        data={chartData}
        totalCharge={chartData.reduce((total, data) => total + data.y, 0)}
        intl={intl}
      />
    </div>
  );
};

/**
 * 積算棒グラフ・凡例描画エリア
 * 必要データを生成して StackedBarChart に渡す
 */
const ChartStackedBar = (props: {
  intl: intlShape,
  boughtCharges: number[],
  applianceCharges: number[],
  backgroundCharges: number[],
}): Node => {
  const [activeBarData, updateActiveBarData] = useState<BarData, UpdateActiveBarData>(null);

  const { intl, boughtCharges, applianceCharges, backgroundCharges } = props;
  // 0 を取り除いて必要な範囲のみ描画させる
  const validTimeRange =
    boughtCharges.length - [...boughtCharges].reverse().findIndex(energy => energy);
  const chartData = getChartDataForBar(applianceCharges, backgroundCharges, validTimeRange);
  // buyEnergy 内の最大値
  const maximumValue = Math.ceil(Math.max(...boughtCharges));
  // 2 で割り切れる値に変換
  const divisibleMaximumValue = maximumValue % 2 === 0 ? maximumValue : maximumValue + 1;

  return (
    <div>
      <LegendForBar activeBarData={activeBarData} intl={intl} />
      <StackedBarChart
        startHour={0}
        endHour={validTimeRange - 1}
        initialHour={8}
        axisUnit={intl.formatMessage({ id: 'chartChargeAtDayOnBar.axisUnit' })}
        horizonAxisUnit={intl.formatMessage({
          id: 'chartChargeAtDayOnBar.horizonAxisUnit',
        })}
        stackedData={chartData}
        maximumValue={divisibleMaximumValue}
        handleSelect={barData => {
          updateActiveBarData(barData);
        }}
      />
    </div>
  );
};

const PureChartChargeAtDay = (props: Props): Node => {
  const {
    requesting,
    requestingApplianceEnergy,
    applianceEnergy,
    pricePlan,
    buyEnergy,
    period,
    intl,
  } = props;
  if (requesting || requestingApplianceEnergy) {
    return (
      <Cards
        {...props}
        component={
          <Loading space={50}>
            <div>{props.intl.formatMessage({ id: 'application.dataLoading' })}</div>
          </Loading>
        }
      />
    );
  }
  if (!Array.isArray(buyEnergy) || getTotalEnergy(convertEnergy(buyEnergy)) === 0) {
    return <Cards {...props} component={<ChartNodata type={CHART_NO_DATA} />} />;
  }

  const targetData = applianceEnergy.find(d => d.dateTime === period);
  const backgroundData =
    targetData &&
    targetData.applianceEnergy &&
    Array.isArray(targetData.applianceEnergy) &&
    targetData.applianceEnergy.find(energy => energy.id === applianceId.background);
  const backgroundEnergy = backgroundData && backgroundData.energy;
  if (!backgroundEnergy) {
    return <Cards {...props} component={<ChartNodata type={CHART_NO_DATA} />} />;
  }

  const boughtCharges = convertEnergyToCharge(buyEnergy, pricePlan);
  const backgroundCharges = convertEnergyToCharge(backgroundEnergy, pricePlan);
  const applianceCharges = boughtCharges.map((value, i) =>
    // 発電状況によっては「購入金額 - 常に消費」がマイナスになることがあるため、考慮
    Math.max(value - backgroundCharges[i], 0)
  );
  // 発電が考慮された待機電力
  const calculatedBackgroundCharges = boughtCharges.map((value, i) => value - applianceCharges[i]);
  return (
    <Cards
      {...props}
      circleComponent={
        <ChartCircle
          intl={intl}
          applianceCharges={applianceCharges}
          backgroundCharges={calculatedBackgroundCharges}
        />
      }
      barComponent={
        <ChartStackedBar
          intl={intl}
          boughtCharges={boughtCharges}
          applianceCharges={applianceCharges}
          backgroundCharges={calculatedBackgroundCharges}
        />
      }
    />
  );
};

const mapStateToProps = (state: State): StateProps => ({
  requestingApplianceEnergy: state.applianceEnergy.requesting,
  applianceEnergy: state.applianceEnergy.daily,
  pricePlan: state.pricePlan,
});

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  injectIntl(PureChartChargeAtDay)
);

export const PureChartChargeAtDayForTest = PureChartChargeAtDay;
