// @flow

import React from 'react';
import type { Node } from 'react';
import Prefixer from 'Client/lib/Prefixer';

const prefixer = new Prefixer();

const styles = prefixer.prefix({
  container: {
    maxWidth: '480px',
    margin: '0 auto',
  },
});

const ContentContainer = ({ children, style }: { children: Node, style?: Object }): Node => (
  <div style={{ ...styles.container, ...style }}>{children}</div>
);

ContentContainer.defaultProps = {
  style: {},
};

export default ContentContainer;

export const ContentContainerForTest = ContentContainer;
