// @flow

import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { CLOSING_POP_UP } from 'Client/actions/types';
import Prefixer from 'Client/lib/Prefixer';
import type { State } from 'Client/types/state';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  entireFixed: {
    position: 'fixed',
    top: 0,
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
    right: 0,
    zIndex: '100100',
    fontSize: 'initial',
  },
  background: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    height: '100vh',
    background: 'rgba(0,0,0,0.5)',
    margin: '0px auto',
  },
  boxVerticalPosition: {
    padding: '38vh 0px',
  },
  box: {
    width: 270,
    maxWidth: '80%',
    maxHeight: '65%',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    textAlign: 'center',
    lineHeight: '150%',
    borderRadius: 5,
    color: '#000',
    position: 'relative',
    margin: '0px auto',
  },
  content: {
    position: 'relative',
    overflow: 'auto',
  },
  title: {
    color: '#ffbd00',
    fontWeight: 'bold',
    borderBottom: '1px solid #cecece',
    padding: '15px 0px',
    background: '#fff',
    borderRadius: '5px 5px 0px 0px',
  },
  bodyPadding: {
    padding: '10px 0px 20px',
  },
  buttonArea: {
    margin: '0px auto 10px',
    padding: '0px 20%',
  },
  button: {
    padding: '8px 10px 6px',
    backgroundColor: '#ffbd00',
    borderRadius: 5,
    fontWeight: 'bold',
    cursor: 'pointer',
    color: '#fff',
  },
  nonUnderLine: {
    textDecoration: 'none',
  },
  back: {
    margin: '0px 40%',
  },
});

const title = (type, intl) => {
  if (type === 'emailAddress') {
    return intl.formatMessage({ id: 'settingUserPopup.emailChange' });
  }
  return intl.formatMessage({ id: 'settingUserPopup.passChange' });
};

declare var config: { URLS: Object[] };
const url = (type, serviceProviderId, intl) => {
  const { CHANGE_MAILADDRESS, CHANGE_PASSWORD } = config.URLS[intl.locale.toUpperCase()] || {};

  if (type === 'emailAddress') {
    if (typeof CHANGE_MAILADDRESS === 'string') {
      return CHANGE_MAILADDRESS;
    }
    return serviceProviderId === '0018' ? CHANGE_MAILADDRESS.MB : CHANGE_MAILADDRESS.IMT;
  }

  if (typeof CHANGE_PASSWORD === 'string') {
    return CHANGE_PASSWORD;
  }
  return serviceProviderId === '0018' ? CHANGE_PASSWORD.MB : CHANGE_PASSWORD.IMT;
};

const renderTitle = (type, intl) => <div style={style.title}>{title(type, intl)}</div>;

const renderBody = (contentType, serviceProviderId, dispatch, intl) => (
  <div style={style.bodyPadding}>
    <div style={style.buttonArea}>
      <a
        href={url(contentType, serviceProviderId, intl)}
        target="_blank"
        rel="noopener noreferrer"
        style={style.nonUnderLine}
      >
        <div style={style.button}>{intl.formatMessage({ id: 'settingUserPopup.button' })}</div>
      </a>
    </div>
    <div
      className="styleBack"
      style={style.back}
      onClick={() => dispatch({ type: CLOSING_POP_UP })}
    >
      {intl.formatMessage({ id: 'settingUserPopup.back' })}
    </div>
  </div>
);

const renderBox = (contentType, serviceProviderId, dispatch, intl) => (
  <div
    style={style.box}
    onClick={e => {
      e.stopPropagation();
    }}
  >
    <div style={style.content}>
      {renderTitle(contentType, intl)}
      {renderBody(contentType, serviceProviderId, dispatch, intl)}
    </div>
  </div>
);

type StateProps = {|
  contentType: string,
  serviceProviderId: string,
|};
type OwnProps = {|
  intl: intlShape,
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

export const PureSettingUserPopup = ({
  contentType,
  serviceProviderId,
  dispatch,
  intl,
}: Props): Node => (
  <div>
    <div style={style.entireFixed}>
      <div
        className="styleBackground"
        style={style.background}
        onClick={() => dispatch({ type: CLOSING_POP_UP })}
      >
        <div style={style.boxVerticalPosition}>
          {renderBox(contentType, serviceProviderId, dispatch, intl)}
        </div>
      </div>
    </div>
  </div>
);

const mapStateToProps = (state: State): StateProps => ({
  contentType: state.popup.contentType,
  serviceProviderId: state.userInfo.contractorId.slice(0, 4),
});

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  injectIntl(PureSettingUserPopup)
);
