export const HELP_CHART_DETAIL = 'helpChartDetail';
export const HELP_CHART_PG = 'helpChartPg';
export const HELP_CHART_HYBRID = 'helpChartHybrid';
export const HELP_CHART_RANKING = 'HelpChartRanking';
export const HELP_HOME_BOTTOM = 'helpHomeBottm';
export const HELP_HOME_TOP = 'helpHomeTop';
export const HELP_HOME_TOP_PG = 'helpHomeTopPg';
export const HELP_CHART_DAY = 'helpChartDay';
export const HELP_CHART_DAILY = 'helpChartDaily';
export const HELP_STACKED_CHART = 'helpStackedChart';
