// @flow
/* eslint-env browser */

import { createElement as ele } from 'react';
import { render } from 'react-dom';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import { apiMiddleware } from 'redux-api-middleware';
import { IntlProvider, addLocaleData } from 'react-intl';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import ja from 'react-intl/locale-data/ja';
import messagesJa from './lib/messages-ja';
import controllersJa from './lib/controllers-ja';
import cancelActionMiddleware from './lib/cancelActionMiddleware';
import { gaReduxMiddleware } from './lib/gaReduxMiddleware';
import isSupportedBrowser from './lib/browserCheck';
import mbaasmiddleware from './lib/mbaasMiddleware';
import mbaasUserSettingsMiddleware from './lib/mbaasUserSettingsMiddleware';
import forceLogoutMiddleware from './lib/forceLogoutMiddleware';

if (!isSupportedBrowser(navigator.userAgent)) {
  location.href = 'notsupport.html';
}

const locationHref = window.location.href;
declare var config: Object;
declare var ga: Function;
const gaLink =
  (locationHref.indexOf('imt.uchiwake.house') >= 0 && 'account.informetis.com') ||
  (locationHref.indexOf('mb.uchiwake.house') >= 0 && 'mb-account.informetis.com') ||
  (locationHref.indexOf('imt-beta.uchiwake.house') >= 0 && 'account.informetis.com') ||
  (locationHref.indexOf('mb-beta.uchiwake.house') >= 0 && 'mb-account.informetis.com') ||
  (locationHref.indexOf('mb-imt4-stg.informetis.com') >= 0 && 'mb-account-stg.informetis.com') ||
  (locationHref.indexOf('imt4-stg.informetis.com') >= 0 && 'account-stg.informetis.com');
if (gaLink) {
  ga('create', config.GA_TRACKING_ID, 'auto', { allowLinker: true });
  ga('require', 'linker');
  ga('linker:autoLink', [gaLink]);
} else {
  ga('create', config.GA_TRACKING_ID, 'auto');
}

if (!global.intl) {
  require('intl');
  require('intl/locale-data/jsonp/ja.js');
}

addLocaleData([...ja]);

const messagesMap = {
  ja: { ...messagesJa, ...controllersJa },
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const getReducer = () => {
  const reducers = require('./reducers').default;
  return combineReducers({
    ...reducers,
  });
};

const persistConfig = {
  key: 'IMT4',
  whitelist: ['userInfo'],
  storage,
};

const store = createStore(
  persistReducer(persistConfig, getReducer()),
  composeEnhancers(
    applyMiddleware(
      forceLogoutMiddleware,
      mbaasmiddleware,
      apiMiddleware,
      cancelActionMiddleware,
      gaReduxMiddleware,
      mbaasUserSettingsMiddleware
    )
  )
);

persistStore(store);

const renderApp = () => {
  const AppComponent = require('./components/App.jsx').default;
  const rootElement = document.getElementById('app');
  if (!rootElement) {
    throw new Error('missing root element.');
  }

  declare var navigator: Object;
  const language = 'ja';
  render(
    ele(
      Provider,
      { store },
      ele(IntlProvider, { locale: language, messages: messagesMap[language] }, ele(AppComponent))
    ),
    rootElement
  );
};

renderApp();

if (module.hot) {
  module.hot.accept('./components/App.jsx', renderApp);
  module.hot.accept('./reducers', () => {
    store.replaceReducer(getReducer());
  });
}
