import firebase from 'firebase/app';
import 'firebase/auth';
import { apiLoginExecute, apiLogoutExecute } from 'Client/actions/apis';
import { isEmbeded } from 'Client/lib/serviceCheck';
import { OPEN_POP_UP } from 'Client/actions/types';
import { SESSION_EXPIRED } from 'Client/actions/alertPopupTypes';

declare var config: { FIREBASE_SDK: Object };

firebase.initializeApp(config.FIREBASE_SDK);

export const getIdToken = callback => {
  if (firebase.auth().currentUser) {
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(idTokenAlpaca => {
        callback(idTokenAlpaca);
      })
      .catch(() => {
        callback(null);
      });
  } else {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      unsubscribe();
      if (user) {
        firebase
          .auth()
          .currentUser.getIdToken()
          .then(idTokenAlpaca => {
            callback(idTokenAlpaca);
          })
          .catch(() => {
            callback(null);
          });
      } else {
        callback(null);
      }
    });
  }
};

const mbaasLogin = (mailaddress, password, callback) => {
  firebase
    .auth()
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(() => {
      firebase
        .auth()
        .signInWithEmailAndPassword(mailaddress, password)
        .then(() => {
          getIdToken(callback);
        })
        .catch(() => {
          callback(null);
        });
    })
    .catch(() => {
      callback(null);
    });
};

export const login = (mailaddress, password, dispatch) => {
  dispatch({ type: 'TRY_LOGIN' });
  mbaasLogin(mailaddress, password, (idTokenAlpaca = null) => {
    dispatch(apiLoginExecute(mailaddress, password, mailaddress, idTokenAlpaca));
  });
};

export const logout = dispatch => {
  dispatch(apiLogoutExecute());
  firebase.auth().signOut();
  if (isEmbeded) {
    dispatch({ type: OPEN_POP_UP, contentType: SESSION_EXPIRED });
  }
};

export const getUid = () => firebase.auth().currentUser && firebase.auth().currentUser.uid;
