// @flow

import moment from 'moment-timezone';
import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import sizeMe from 'react-sizeme';
import Currency from 'Client/components/utils/Currency';
import Prefixer from 'Client/lib/Prefixer';
import { makeValidPeriod } from 'Client/lib/globalUtils';
import { calcPrice } from 'Client/lib/pricePlanUtils';
import type { State, PricePlanState } from 'Client/types/state';

const prefixer = new Prefixer();
const style = prefixer.prefix({
  messagesWrap: {
    display: 'inline-block',
  },
  criterion: {
    textAlign: 'right',
    fontSize: '0.5em',
  },
  month: {
    fontSize: '1.4em',
  },
  cost: {
    fontSize: '1.3em',
    height: '1.5em',
    marginLeft: '6px',
  },
  buttonWrapper: {
    marginTop: '12px',
  },
  detailButton: {
    border: 'none',
  },
  detailButtonDisable: {
    opacity: '0.3',
  },
  overlay: {
    color: 'white',
    opacity: 0.3,
    zIndex: 999,
  },
  contents: {
    marginBottom: '0.3em',
    fontSize: '1.5em',
    color: '#7c7c7c',
  },
  small: {
    fontSize: '0.8em',
  },
  loading: {
    fontSize: '0.6em',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    color: '#FFBD00',
    padding: '7.5px 0px',
  },
  withMarginBottom: {
    marginBottom: '20px',
  },
});

const renderDetailButton = (image, requesting, width, userStatus) => {
  const period = makeValidPeriod('day', moment());
  const detailButtonWidth = () => {
    if (userStatus === 'not_estimable') {
      return width >= 150 ? '30%' : '35%';
    }
    return width >= 150 ? '60%' : '70%';
  };
  const buttonStyle = requesting
    ? { ...style.detailButton, ...style.detailButtonDisable }
    : style.detailButton;
  const detailButtonImage = () => (
    <img
      style={{
        ...buttonStyle,
        width: detailButtonWidth(),
        ...(userStatus === 'not_estimable' ? style.withMarginBottom : {}),
      }}
      src={image}
      alt="detail"
    />
  );
  return requesting ? (
    <div>{detailButtonImage()}</div>
  ) : (
    <Link to={`/chart/day/${period}`}>{detailButtonImage()}</Link>
  );
};

type StateProps = {|
  buyEnergy: number[],
  hourlyRequesting: boolean,
  pricePlan: PricePlanState,
  userStatus: string,
|};
type OwnProps = {|
  monthlyRequesting: boolean,
  intl: intlShape,
  size: {
    width: number,
    height: number,
  },
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

const renderLoading = intl => (
  <div>
    <div style={style.contents}>
      <span style={style.small}>
        <div style={style.loading}>{intl.formatMessage({ id: 'topDailyBalance.loading' })}</div>
      </span>
    </div>
  </div>
);

const PureTopMonthlyTotalAmount = ({
  buyEnergy,
  hourlyRequesting,
  monthlyRequesting,
  intl,
  size,
  pricePlan,
  userStatus,
}: Props): Node => {
  const month = intl.formatNumber(moment().month() + 1);
  const criterion = intl.formatMessage({ id: 'others.criterion' });
  const detail = require(`Client/images/btn_detail_ja.png`);
  const someRequesting = hourlyRequesting || monthlyRequesting;
  return (
    <div>
      <div>
        <div style={style.messagesWrap}>
          <div style={style.criterion}>{criterion}</div>
          <div>{intl.formatMessage({ id: 'topMonthlyTotalAmount.title' }, { month })}</div>
        </div>
      </div>
      <div style={style.cost}>
        {monthlyRequesting || buyEnergy.length === 0 ? (
          renderLoading(intl)
        ) : (
          <Currency value={calcPrice(0, 24, pricePlan, buyEnergy)} />
        )}
      </div>
      <div style={style.buttonWrapper}>
        {renderDetailButton(detail, someRequesting, size.width, userStatus)}
      </div>
    </div>
  );
};

const mapStateToProps = (state: State): StateProps => {
  const thisMonthInteger = moment().month() + 1;
  const thisMonthEnergy = state.energy.monthly.find(monthlyEnergy => {
    const monthInteger = Number(monthlyEnergy.month.substr(-2, 2));
    return thisMonthInteger === monthInteger;
  });
  const buyEnergy = thisMonthEnergy ? thisMonthEnergy.buyEnergy : [];
  return {
    buyEnergy,
    hourlyRequesting: state.energy.hourlyRequesting,
    pricePlan: state.pricePlan,
    userStatus: state.userInfo.status,
  };
};

const sizeMeHOC = sizeMe({ monitorHeight: true });

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  sizeMeHOC(injectIntl(PureTopMonthlyTotalAmount))
);

export const PureTopMonthlyTotalAmountForTest = PureTopMonthlyTotalAmount;
