// @flow

import React, { useState } from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import Currency from 'Client/components/utils/Currency';
import Loading from 'Client/components/utils/Loading';
import QuestionIcon from 'Client/components/utils/QuestionIcon';
import ChartNodata, { CHART_NO_DATA } from 'Client/components/ChartNoData';
import StackedBarChart from 'Client/components/StackedBarChart';
import { HELP_STACKED_CHART } from 'Client/actions/helpPopupTypes';
import { OPEN_POP_UP, CHANGE_SELECTED_APPLIANCE } from 'Client/actions/types';
import Prefixer from 'Client/lib/Prefixer';
import { applianceId } from 'Client/lib/id';
import { calcPrice } from 'Client/lib/pricePlanUtils';
import type { State, PricePlanState } from 'Client/types/state';
import type { ChartData, StackedKeyData } from 'Client/types/types';
import { startDates, formatEnergy } from 'Client/lib/chartUtils';
import { convertEnergy, getTotalEnergy } from 'Client/lib/globalUtils';

const prefixer = new Prefixer();

const AIRCONDITIONER = applianceId.airConditionerObserved;
const ECOCUTE = applianceId.ecoCuteObserved;
const EV = applianceId.ev;

const style = prefixer.prefix({
  areaTitle: {
    verticalAlign: 'middle',
  },
  criterion: {
    fontSize: '0.7em',
    paddingLeft: '5px',
  },
  info: {
    top: '-11px',
    right: '-5px',
    zIndex: '2',
    position: 'absolute',
  },
  legendContainer: {
    margin: '15px 10px 20px',
    lineHeight: 1,
  },
  legendItem: {
    height: 16,
    display: 'flex',
    alignItems: 'center',
  },
  legendIcon: {
    width: 16,
    height: 16,
    marginRight: 5,
    backgroundSize: 'cover',
  },
  legendObserved: {
    backgroundImage:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAJZJREFUOBFjZACCm2FzAv4zMuQz/GcwYGBkEACJEQAH/jP+b9RckXqAEap5PQENWKWBhjgy/WP4X4BVlhjBfwwNTIyMjPbEqMWmhpGBUZ8JmwTRYsDwoswAoE2jBgz9MPj//yPT////DxKdcNAU/mdguMDExMA4AU2ceC4TMC+or0rZwPifIRDsEqCTiNENUgvKiaDsDADX2DAA8+P/WQAAAABJRU5ErkJggg==)',
  },
  legendSelect: {
    color: '#847965',
    width: '11em',
    height: 30,
    margin: 0,
    marginRight: 10,
    padding: 0,
    paddingLeft: 5,
    fontSize: 13,
    fontWeight: 'normal',
    textAlign: 'left',
    border: ' solid 1px #eaeaea',
    borderRadius: '5px',
    outline: 'none',
    appearance: 'none',
    backgroundColor: '#fff',
    backgroundPosition: 'right 6px center',
    backgroundSize: '8px',
    backgroundRepeat: 'no-repeat',
    backgroundImage:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAwCAYAAAALiLqjAAAAAXNSR0IArs4c6QAAAt5JREFUWAntWD1vE0EQ9fkjVRorZWpESlJEoiJ/IJGoXCIkwD7LxgVQIRANggZEgS1/KBQRnUuSH0CgQYqUUIbQxmXkxg3nL95zbp29Y+6851wkCq902t3Z997sjO/m9pxIRGjNZvMurwiUhGUKrtVqy6lU6oT44XC4ViqVeibcpAmImHQ6/c6yrFVeHJvyjCJoNBp3IHygi47H403btr/pNmk8M4J2u70E4o5A3nHXhKVL00wH3W73OXZ/45JyMaKNa367fx6aonq9vp5MJn+AxCik5oxGo9vFYvFYWqQtMAKEn8IuPwETJE7+EjHEciK1QAcI/zHI6xJJtxFDrG7Tx2KKkBrejie4lnVw0Bh3VA/XGlLV8WPECCDcMBWnILHk+MU5/8cBdn8f4C0JHGYjh1w/xpOiarW6gqf0F8ArfqDJHGk6HwwGN8vl8rnCeyLIZDLv5xWnILnUUOITm5q0Wq0t7GBPza/Sw9F2Pp/fnzpQlRILq1cRVlxstKMq7iRFKMOv4xKf7BobpeZk7JaDQ0wCn0a1s4j9EGVkI41acw8hfY9INoJT2wi4AC0ysMjAf54BC4fZDygVt65jnyigP9MQ/owBTwXXUeyeJAuFwhEiqMYdATV5IJu8D/ByeAEHZ3E5gThfONS8OFXwrI80leJygDJtq++H6Usf79AvcLAbg5Nd9T6m1tQBJ/1+/ynCmx45aIvSyKWGzvE44HkGoGc6IMqYXP1MRK7n4KXE8Gx8xXhTzU16iO/ji2fbj/VEoBbxg+dB6Kn5rJ5YXLaEEx3gRzqFk5cSQbIRK52siRUdcCGbzX5Ed8RxWMPOj12sCAt0kMvlhmA8wsU+qDlw8MDFiphAB0S7ZSTsm/ht2PcZNUIdEOA4zit0pxzrDTv/jdS80W3SeKaDSqXyh3eVQH6I1DiC3WOa6YBo3FUH6PQy0jT5yifXyAGBbgk4Q2o6+IqZ+2mnVmCb5++cv3SUEkKE27RZAAAAAElFTkSuQmCC)',
  },
  legendLabel: {
    margin: 0,
    marginRight: '1em',
    fontSize: 13,
    fontWeight: 'normal',
    textAlign: 'left',
  },
  legendValue: {
    margin: 0,
    fontSize: 14,
    textAlign: 'right',
  },
  legendValueCenter: {
    margin: 0,
    marginRight: '1em',
    fontSize: 13,
    fontWeight: 'normal',
    textAlign: 'right',
  },
});

type ValidApplianceData = {
  startHour: number,
  endHour: number,
  applianceEnergy: number[],
};

type CastedApplianceEnergies = {|
  id: string,
  energy: (number | null)[],
|};

type ValidApplianceEnergyData = {
  dateTime: string,
  applianceEnergies: CastedApplianceEnergies[],
}[];

type BarData = null | {
  [key: 'selected']: ChartData,
};
type UpdateActiveBarData = (barData: BarData) => void;

type StateProps = {|
  idsToShow: (string | null)[],
  pricePlan: PricePlanState,
  selectedApplianceTypeId: string,
|};
type OwnProps = {|
  requesting: boolean,
  unitEnergyList: {
    period?: string,
    whp: (number | null)[],
  }[],
  applianceEnergy: Object[],
  unit: string,
  period: string,
  intl: intlShape,
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

const getHours = (energy: (number | null)[], unit: string) => {
  const initialHour: { startHour: number | null, endHour: number | null } = {
    startHour: null,
    endHour: null,
  };
  switch (unit) {
    case 'day': {
      const hours = energy.reduce(
        (foundHour, value, index) => ({
          startHour: value && foundHour.startHour === null ? index : foundHour.startHour,
          endHour: value ? index : foundHour.endHour,
        }),
        initialHour
      );
      if (
        (hours.startHour === null || hours.endHour === null) &&
        energy.find(value => value !== null) === 0
      )
        return { startHour: 9, endHour: 15 };
      return hours;
    }
    case 'week':
      return {
        startHour: 0,
        endHour: 6,
      };
    default:
      return initialHour;
  }
};

/**
 * 24時間のうち、最初と最後の電力発生を見つけて、その間のデータを返す
 * applianceEnergy で指定されたプロパティから検出し、他のデータは applianceEnergy から検出された範囲に習う
 * 週の場合、曜日に合わせて返す
 */
const getValidEnergy = (
  applianceEnergy: (number | null)[],
  unit: string
): ValidApplianceData | null => {
  const { startHour, endHour } = getHours(applianceEnergy, unit);

  if (startHour === null || endHour === null) {
    return null;
  }

  const validApplianceEnergy = applianceEnergy
    .slice(startHour, endHour + 1)
    .reduce((prev, now) => [...prev, now || 0], []);
  return {
    startHour,
    endHour,
    applianceEnergy: validApplianceEnergy,
  };
};

/**
 * VictoryBar 用データ作成
 * x = 時, y = energy
 */
const createChartData = ({
  validApplianceData,
}: {
  validApplianceData: ValidApplianceData,
}): ChartData => {
  const runningTime = validApplianceData.endHour + 1 - validApplianceData.startHour;
  return new Array(runningTime).fill().map((_, i) => ({
    x: validApplianceData.startHour + i,
    y: validApplianceData.applianceEnergy[i],
  }));
};

/**
 * 個別計測グラフ用データ生成
 * selected: 現在選択されている個別計測のデータ
 */
const createChartPropsForGeneration = (
  validApplianceData: ValidApplianceData
): { stackedData: StackedKeyData, maximumValue: number } => {
  const applianceData = createChartData({ validApplianceData });
  // generatedData 内の最大値
  const maximumValue =
    Math.ceil(Math.max(...[...validApplianceData.applianceEnergy]) * 10) / 10 || 0.2;
  // 0.2 で割り切れる値に変換
  const divisibleMaximumValue =
    (maximumValue * 10) % 2 === 0 ? maximumValue : (maximumValue * 10 + 1) / 10;

  return {
    stackedData: {
      selected: {
        data: applianceData,
        colors: {
          default: '#e06cb8',
          disabled: '#ebcce0',
        },
      },
    },
    maximumValue: divisibleMaximumValue,
  };
};

const getApplianceIdObserved = (
  applianceEnergy: Object[],
  unit: string,
  period: string,
  idsToShow: (string | null)[],
  ApplianceIds: string[]
): string[] => {
  const startDateList = startDates(unit, period);
  const applianceIdObserved = startDateList
    .reduce((prev, now) => {
      const targetData = applianceEnergy.find(d => d.dateTime === now);
      if (!targetData) {
        return [...prev];
      }
      const applianceEnergyData = targetData.applianceEnergy.reduce((item, currentItem) => {
        const includeObserved = ApplianceIds.filter(
          id => currentItem.id.indexOf(id) !== -1 && currentItem.id !== EV
        );
        const isEv = currentItem.id === EV && idsToShow.includes(EV);
        if (!includeObserved || (includeObserved.length === 0 && !isEv)) {
          return [...item];
        }
        return [...item, currentItem.id];
      }, []);
      return [...prev, ...applianceEnergyData];
    }, [])
    .filter((data, index, self) => self.indexOf(data) === index)
    .sort();

  return ['whp', ...applianceIdObserved];
};

const getObservedData = (
  applianceEnergy: Object[],
  unit: string,
  period: string,
  ApplianceIds: string[]
): ValidApplianceEnergyData => {
  const startDateList = startDates(unit, period);
  if (!startDateList.length) {
    return [];
  }

  const applianceEnergies = startDateList.map(startDate => {
    const targetData = applianceEnergy.find(d => d.dateTime === startDate);
    const applianceData =
      targetData &&
      targetData.applianceEnergy &&
      targetData.applianceEnergy.filter(
        item => ApplianceIds.includes(item.id) && getTotalEnergy(item.energy) > 0
      );
    return {
      dateTime: startDate,
      applianceEnergies: applianceData || [],
    };
  });

  return applianceEnergies;
};

const getApplianceEnergy = (
  unitEnergyList: {
    period?: string,
    whp: (number | null)[],
  }[],
  applianceList: ValidApplianceEnergyData,
  unit: string,
  period: string,
  pricePlan: PricePlanState,
  selectedId: string
): {
  selectedAppliance: (number | null)[],
  selectedApplianceBuyEnergy: number[],
} => {
  if (unit === 'day') {
    const targetData = applianceList.find(data => data.dateTime === period);
    const targetWhp = selectedId === 'whp' && unitEnergyList.find(data => data.period === period);
    const applianceEnergiesDay =
      targetData && targetData.applianceEnergies.find(data => data.id === selectedId);
    const selectedApplianceBuyEnergy =
      (targetWhp && targetWhp.whp) ||
      (applianceEnergiesDay && applianceEnergiesDay.energy) ||
      Array(24).fill(0);

    return {
      selectedAppliance: selectedApplianceBuyEnergy,
      selectedApplianceBuyEnergy: selectedApplianceBuyEnergy.reduce(
        (buyEnergy, current, index) => [
          ...buyEnergy,
          calcPrice(
            index,
            index + 1,
            pricePlan,
            selectedApplianceBuyEnergy.reduce(
              (prevEnergy, nowEnergy) => [...prevEnergy, nowEnergy || 0],
              []
            )
          ),
        ],
        []
      ),
    };
  }

  const startDateList = startDates(unit, period);
  if (!applianceList || applianceList.length === 0 || startDateList.length === 0) {
    return {
      selectedAppliance: Array(7).fill(0),
      selectedApplianceBuyEnergy: Array(7).fill(0),
    };
  }

  const applianceEnergyData = startDateList.reduce(
    (prev, now) => {
      if (selectedId === 'whp') {
        const targetData = unitEnergyList.find(data => data.period === now);
        const targetEnergy = targetData && targetData.whp;
        const totalEnergy = targetEnergy ? getTotalEnergy(convertEnergy(targetEnergy)) : 0;
        const totalBuyEnergy = targetEnergy
          ? calcPrice(0, 24, pricePlan, convertEnergy(targetEnergy))
          : 0;
        return {
          selectedAppliance: [...prev.selectedAppliance, totalEnergy],
          selectedApplianceBuyEnergy: [...prev.selectedApplianceBuyEnergy, totalBuyEnergy],
        };
      }
      const applianceData = applianceList.find(data => data.dateTime === now);
      const applianceEnergy =
        applianceData && applianceData.applianceEnergies.find(data => data.id === selectedId);
      const applianceEnergyTotal = applianceEnergy
        ? getTotalEnergy(convertEnergy(applianceEnergy.energy))
        : 0;
      const applianceTotalEnergy = applianceEnergy
        ? calcPrice(0, 24, pricePlan, convertEnergy(applianceEnergy.energy))
        : 0;
      return {
        selectedAppliance: [...prev.selectedAppliance, applianceEnergyTotal],
        selectedApplianceBuyEnergy: [...prev.selectedApplianceBuyEnergy, applianceTotalEnergy],
      };
    },
    {
      selectedAppliance: [],
      selectedApplianceBuyEnergy: [],
    }
  );

  return applianceEnergyData;
};

const InfoButton = (props: { contentType: string, dispatch: Dispatch }): Node => (
  <div style={style.info}>
    <QuestionIcon
      onClick={e => {
        props.dispatch({
          type: OPEN_POP_UP,
          contentType: props.contentType,
          target: e.target,
        });
      }}
    />
  </div>
);

const changeselectedApplianceTypeId = (
  element,
  updateActiveBarData,
  activeBarData,
  updateActiveFlg,
  applianceIdData,
  dispatch
) => {
  updateActiveBarData(null);
  if (activeBarData) {
    updateActiveFlg(true);
  }
  if (element && element.value && applianceIdData.includes(element.value)) {
    dispatch({
      type: CHANGE_SELECTED_APPLIANCE,
      selectedApplianceTypeId: element.value,
    });
  }
};

/**
 * セレクトボックスのoptionを生成
 */
const getOption = (applianceIdData: string[], intl: intlShape): Node[] =>
  applianceIdData.map(data => {
    const indexData = {};
    if (data === 'whp') {
      applianceIdData
        .filter(id => id === 'whp')
        .forEach((key, index) => {
          indexData[key] = index + 1;
        });
      const applianceIndex = Object.keys(indexData).length > 1 && indexData[data];
      return (
        <option key={data} value={data}>
          {intl.formatMessage({ id: 'ChartIndividualMeasurement.whp' }, { applianceIndex })}
        </option>
      );
    } else if (data.indexOf(AIRCONDITIONER) !== -1) {
      applianceIdData
        .filter(id => id.indexOf(AIRCONDITIONER) !== -1)
        .forEach((key, index) => {
          indexData[key] = index + 1;
        });
      const applianceIndex = Object.keys(indexData).length > 1 && indexData[data];
      return (
        <option key={data} value={data}>
          {intl.formatMessage(
            { id: 'ChartIndividualMeasurement.airConditioner' },
            { applianceIndex }
          )}
        </option>
      );
    } else if (data.indexOf(ECOCUTE) !== -1) {
      applianceIdData
        .filter(id => id.indexOf(ECOCUTE) !== -1)
        .forEach((key, index) => {
          indexData[key] = index + 1;
        });
      const applianceIndex = Object.keys(indexData).length > 1 && indexData[data];
      return (
        <option key={data} value={data}>
          {intl.formatMessage({ id: 'ChartIndividualMeasurement.ecoCute' }, { applianceIndex })}
        </option>
      );
    } else if (data.indexOf(EV) !== -1) {
      applianceIdData
        .filter(id => id.indexOf(EV) !== -1)
        .forEach((key, index) => {
          indexData[key] = index + 1;
        });
      const applianceIndex = Object.keys(indexData).length > 1 && indexData[data];
      return (
        <option key={data} value={data}>
          {intl.formatMessage({ id: 'ChartIndividualMeasurement.ev' }, { applianceIndex })}
        </option>
      );
    }
    return <option />;
  });

/**
 * 表示する家電を取得
 */
const getApplianceValue = (
  applianceIdData: string[],
  selectedApplianceTypeId: string,
  intl: intlShape
) => {
  const applianceValue = {};
  applianceIdData.forEach(data => {
    const indexData = {};
    if (data === 'whp') {
      applianceIdData
        .filter(id => id === 'whp')
        .forEach((key, index) => {
          indexData[key] = index + 1;
        });
      const applianceIndex = Object.keys(indexData).length > 1 && indexData[data];
      applianceValue[data] = intl.formatMessage(
        { id: 'ChartIndividualMeasurement.whp' },
        { applianceIndex }
      );
    } else if (data.indexOf(AIRCONDITIONER) !== -1) {
      applianceIdData
        .filter(id => id.indexOf(AIRCONDITIONER) !== -1)
        .forEach((key, index) => {
          indexData[key] = index + 1;
        });
      const applianceIndex = Object.keys(indexData).length > 1 && indexData[data];
      applianceValue[data] = intl.formatMessage(
        { id: 'ChartIndividualMeasurement.airConditioner' },
        { applianceIndex }
      );
    } else if (data.indexOf(ECOCUTE) !== -1) {
      applianceIdData
        .filter(id => id.indexOf(ECOCUTE) !== -1)
        .forEach((key, index) => {
          indexData[key] = index + 1;
        });
      const applianceIndex = Object.keys(indexData).length > 1 && indexData[data];
      applianceValue[data] = intl.formatMessage(
        { id: 'ChartIndividualMeasurement.ecoCute' },
        { applianceIndex }
      );
    } else if (data.indexOf(EV) !== -1) {
      applianceIdData
        .filter(id => id.indexOf(EV) !== -1)
        .forEach((key, index) => {
          indexData[key] = index + 1;
        });
      const applianceIndex = Object.keys(indexData).length > 1 && indexData[data];
      applianceValue[data] = intl.formatMessage(
        { id: 'ChartIndividualMeasurement.ev' },
        { applianceIndex }
      );
    }
  });
  return applianceValue[selectedApplianceTypeId] || '';
};

const Legend = ({
  updateActiveBarData,
  activeBarData,
  updateActiveFlg,
  applianceIdData,
  selectedApplianceTypeId,
  selectedApplianceBuyEnergy,
  dispatch,
  intl,
}: {
  updateActiveBarData: UpdateActiveBarData,
  activeBarData: BarData,
  updateActiveFlg: (activeFlg: boolean) => void,
  applianceIdData: string[],
  selectedApplianceTypeId: string,
  selectedApplianceBuyEnergy: number[],
  dispatch: Dispatch,
  intl: intlShape,
}): Node => {
  const option = applianceIdData.length > 1 && getOption(applianceIdData, intl);
  return (
    <div style={style.legendContainer}>
      <div key="selectedAppliance" style={style.legendItem}>
        {option ? (
          <select
            id="selectAppliance"
            style={style.legendSelect}
            defaultValue={selectedApplianceTypeId}
            onChange={e => {
              const element = e.target;
              changeselectedApplianceTypeId(
                element,
                updateActiveBarData,
                activeBarData,
                updateActiveFlg,
                applianceIdData,
                dispatch
              );
            }}
          >
            {option.map(data => data)}
          </select>
        ) : (
          [
            <div key="icon" style={{ ...style.legendIcon, ...style.legendObserved }} />,
            <div key="legendLabel" style={style.legendLabel}>
              {getApplianceValue(applianceIdData, selectedApplianceTypeId, intl)}
            </div>,
          ]
        )}

        {activeBarData && [
          <p key="legendValue" style={style.legendValueCenter}>
            {formatEnergy({
              intl,
              energy: activeBarData.selected.y,
              unit: intl.formatMessage({ id: 'ChartIndividualMeasurement.axisUnit' }),
            })}
          </p>,
          <p key="legendCurrency" style={style.legendValue}>
            <Currency value={selectedApplianceBuyEnergy[Number(activeBarData.selected.x)] || 0} />
          </p>,
        ]}
      </div>
    </div>
  );
};

/**
 * X軸ラベル
 */
const horizonAxisUnit = (unit, intl) => {
  switch (unit) {
    case 'day':
      return intl.formatMessage({ id: 'ChartIndividualMeasurement.horizonAxisUnit' });
    case 'week':
      return intl.formatMessage({ id: 'ChartIndividualMeasurement.dayOfTheWeek' });
    default:
      return '';
  }
};

/**
 * 積算グラフ
 * 必要データを生成してチャートコンポーネントに渡す
 * 発電量 or 電力使用で生成データ、ラベルスタイルを変える
 */
const Chart = ({
  updateActiveBarData,
  activeBarData,
  updateActiveFlg,
  activeFlg,
  applianceIdData,
  selectedApplianceTypeId,
  unit,
  dispatch,
  intl,
  validApplianceData,
  selectedApplianceBuyEnergy,
}: {
  updateActiveBarData: UpdateActiveBarData,
  activeBarData: BarData,
  updateActiveFlg: (activeFlg: boolean) => void,
  activeFlg: boolean,
  applianceIdData: string[],
  selectedApplianceTypeId: string,
  unit: string,
  dispatch: Dispatch,
  intl: intlShape,
  validApplianceData: ValidApplianceData,
  selectedApplianceBuyEnergy: number[],
}): Node => {
  const chartProps = createChartPropsForGeneration(validApplianceData);
  const isNotActive = activeFlg;
  if (activeFlg) {
    updateActiveFlg(false);
  }

  return (
    <div>
      <StackedBarChart
        startHour={validApplianceData.startHour}
        endHour={validApplianceData.endHour}
        initialHour={12}
        axisUnit={intl.formatMessage({ id: 'ChartIndividualMeasurement.axisUnit' })}
        horizonAxisUnit={horizonAxisUnit(unit, intl)}
        {...chartProps}
        handleSelect={barData => {
          updateActiveBarData(barData);
        }}
        isNotActive={isNotActive}
        unit={unit}
      />
      <Legend
        updateActiveBarData={updateActiveBarData}
        activeBarData={activeBarData}
        updateActiveFlg={updateActiveFlg}
        applianceIdData={applianceIdData}
        selectedApplianceTypeId={selectedApplianceTypeId}
        selectedApplianceBuyEnergy={selectedApplianceBuyEnergy}
        dispatch={dispatch}
        intl={intl}
      />
    </div>
  );
};

const PureChartIndividualMeasurement = (props: Props): Node => {
  const [activeBarDataForGeneration, updateActiveBarDataForGeneration] = useState<
    BarData,
    UpdateActiveBarData
  >(null);

  const [activeFlg, updateActiveFlg] = useState<boolean, (activeFlg: boolean) => void>(false);

  const {
    requesting,
    unitEnergyList,
    unit,
    period,
    idsToShow,
    pricePlan,
    selectedApplianceTypeId,
    applianceEnergy,
    intl,
  } = props;

  if (requesting) {
    return (
      <div>
        <div>
          <span style={style.areaTitle}>
            {intl.formatMessage({ id: 'ChartIndividualMeasurement.title' })}
          </span>
          <span style={style.criterion}>
            {props.intl.formatMessage({ id: 'others.criterion' })}
          </span>
        </div>
        {unit === 'day' && (
          <InfoButton contentType={HELP_STACKED_CHART} dispatch={props.dispatch} />
        )}
        <Loading space={80}>
          <div>{props.intl.formatMessage({ id: 'application.dataLoading' })}</div>
        </Loading>
      </div>
    );
  }

  // 個別計測データのapplianceIdを配列を取得
  const ApplianceIds = [AIRCONDITIONER, ECOCUTE, EV];
  const applianceIdObserved = getApplianceIdObserved(
    applianceEnergy,
    unit,
    period,
    idsToShow,
    ApplianceIds
  );

  // 現在選択されている個別計測データを取得
  const selectedId =
    (applianceIdObserved.includes(selectedApplianceTypeId) && selectedApplianceTypeId) ||
    applianceIdObserved[0];
  const applianceList = getObservedData(applianceEnergy, unit, period, applianceIdObserved);
  const { selectedAppliance, selectedApplianceBuyEnergy } = getApplianceEnergy(
    unitEnergyList,
    applianceList,
    unit,
    period,
    pricePlan,
    selectedId
  );
  const selectedApplianceData = getValidEnergy(selectedAppliance, unit);

  return (
    <div>
      <div>
        <span style={style.areaTitle}>
          {intl.formatMessage({ id: 'ChartIndividualMeasurement.title' })}
        </span>
        <span style={style.criterion}>{props.intl.formatMessage({ id: 'others.criterion' })}</span>
      </div>
      {unit === 'day' && <InfoButton contentType={HELP_STACKED_CHART} dispatch={props.dispatch} />}
      {selectedApplianceData ? (
        <Chart
          updateActiveBarData={updateActiveBarDataForGeneration}
          activeBarData={activeBarDataForGeneration}
          updateActiveFlg={updateActiveFlg}
          activeFlg={activeFlg}
          applianceIdData={applianceIdObserved}
          selectedApplianceTypeId={selectedId}
          unit={unit}
          dispatch={props.dispatch}
          intl={intl}
          validApplianceData={selectedApplianceData}
          selectedApplianceBuyEnergy={selectedApplianceBuyEnergy}
        />
      ) : (
        <ChartNodata type={CHART_NO_DATA} />
      )}
    </div>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  idsToShow: state.applianceState.idsToShow,
  pricePlan: state.pricePlan,
  selectedApplianceTypeId: state.chartTime.selectedApplianceTypeId,
});

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  injectIntl(PureChartIndividualMeasurement)
);

export const PureChartIndividualMeasurementForTest = PureChartIndividualMeasurement;
