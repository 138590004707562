// @flow

import moment$MomentObject from 'moment-timezone';
import type { Dispatch } from 'redux';
import { createSelector } from 'reselect';
import { applianceId, timeUnitId } from 'Client/lib/id';
import { calcPriceRanking } from 'Client/lib/pricePlanUtils';
import { energyPriceRange } from 'Client/lib/localizedValues';

const aircon = require('Client/images/icn_aircon.png');
const highfever = require('Client/images/icn_highfever.png');
const microwave = require('Client/images/icn_microwave.png');
const ricecooker = require('Client/images/icn_ricecooker.png');
const ih = require('Client/images/icn_IH.png');
const ecocute = require('Client/images/icn_ecocute.png');
const wash = require('Client/images/icn_wash.png');
const vacuum = require('Client/images/icn_vacuum.png');
const tv = require('Client/images/icn_tv.png');
const dishwasher = require('Client/images/icn_dishwasher.png');
const oven = require('Client/images/icn_oven.png');
const fridge = require('Client/images/icn_refrigerator.png');
const storageBattery = require('Client/images/icn_battery.png');
const chargingStorageBattery = require('Client/images/icn_battery_charging.png');
const hybridStorageBattery = require('Client/images/icn_hybrid.png');
const eneFarm = require('Client/images/icn_enefarm.png');
const background = require('Client/images/icn_plug.png');
const heater = require('Client/images/icn_heat.png');
const light = require('Client/images/icn_light.png');

export const switchImage = (id: string): string => {
  switch (id) {
    case applianceId.airConditioner: {
      return aircon;
    }
    case applianceId.clothesWasher: {
      return wash;
    }
    case applianceId.microwave: {
      return microwave;
    }
    case applianceId.refrigerator: {
      return fridge;
    }
    case applianceId.riceCooker: {
      return ricecooker;
    }
    case applianceId.tv: {
      return tv;
    }
    case applianceId.vacuumCleaner: {
      return vacuum;
    }
    case applianceId.dishwasher: {
      return dishwasher;
    }
    case applianceId.oven: {
      return oven;
    }
    case applianceId.ecoCute: {
      return ecocute;
    }
    case applianceId.ih: {
      return ih;
    }
    case applianceId.highFever: {
      return highfever;
    }
    case applianceId.heater: {
      return heater;
    }
    case applianceId.light: {
      return light;
    }
    case applianceId.dischargedStorageBattery: {
      return storageBattery;
    }
    case applianceId.chargedStorageBattery: {
      return chargingStorageBattery;
    }
    case applianceId.eneFarm: {
      return eneFarm;
    }
    case applianceId.dischargedHybridStorageBattery: {
      return hybridStorageBattery;
    }
    case applianceId.chargedHybridStorageBattery: {
      return hybridStorageBattery;
    }
    case applianceId.background: {
      return background;
    }
    default: {
      return '';
    }
  }
};

export const extractServiceProviderIdFromState = createSelector(
  state => state.userInfo.contractorId,
  contractorId => contractorId.split('_')[0]
);

export const makeValidPeriod = (unit: string, momentObj: moment$MomentObject) => {
  switch (unit) {
    case 'year': {
      return momentObj.format('YYYY');
    }
    case 'month': {
      return momentObj.format('YYYY-MM');
    }
    case 'week': {
      return momentObj.startOf('week').isBefore(momentObj)
        ? momentObj
            .add(1, 'week')
            .startOf('week')
            .format('YYYY-MM-DD')
        : momentObj.startOf('week').format('YYYY-MM-DD');
    }
    case 'day': {
      return momentObj.format('YYYY-MM-DD');
    }
    default: {
      throw new Error(`unit ${unit} is not defined`);
    }
  }
};

export const getHourlyRequestMeta = (unit: string) => {
  const setUnit = unit || 'day';
  return {
    reqUnit: timeUnitId[setUnit],
    subUnitFlg: false,
  };
};

export const getHourlyRequestMetaSub = (unit: string) => {
  const setUnit = unit || 'week';
  return {
    reqUnit: timeUnitId[setUnit],
    subUnitFlg: true,
  };
};

const compareNumbers = (a, b) => b.energyValue - a.energyValue;
export const sortRanking = (
  appliance: {
    id: string,
    energy: number[],
  }[],
  pricePlan: {
    pricePlan: string,
    normalPlanPrice: number,
    multistagePlanSetting: Object[],
  }
): {
  id: string,
  energy: number[],
  energyValue: number,
}[] =>
  appliance
    .map(target => ({
      ...target,
      energyValue: calcPriceRanking(pricePlan, target.energy),
    }))
    .sort(compareNumbers);
export const toSetUpPriceValue = (
  inputElement: Object,
  type: string,
  actionType: string,
  dispatch: Dispatch,
  index?: number
) => {
  const minValue = energyPriceRange.ja.min;
  const maxValue = energyPriceRange.ja.max;
  const isFloat = value => Math.round(value) !== value;
  const isErr = value => value < minValue || value > maxValue || (type === 'int' && isFloat(value));
  const value = (type === 'int' && Math.round(inputElement.value)) || inputElement.value;
  const newPrice = isNaN(value) ? String(value).substr(0, String(value).length - 1) : value;
  dispatch({
    type: actionType,
    price: newPrice,
    index,
    isErr: isErr(value),
  });
};

/**
 * フォームバリデータ
 * formik のエラーオブジェクト用に各バリデータは Object 型を返す
 */
export const validateFormValues = {
  mailAddress: (values: { mailAddress: string }, type: string): { mailAddress?: string } => {
    const { mailAddress } = values;
    if (!mailAddress) {
      return { mailAddress: `${type}.requiredMailAddress` };
    }

    const isMailAddress = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(mailAddress);
    if (!isMailAddress) {
      return { mailAddress: `${type}.requiredFormat` };
    }

    return {};
  },
  oldPassword(values: { oldPassword: string }, type: string): { oldPassword?: string } {
    const { oldPassword } = values;
    if (!oldPassword) {
      return { oldPassword: `${type}.requiredOldPassword` };
    }

    return {};
  },
  newPassword: (
    values: { oldPassword?: string, newPassword: string } | { newPassword: string },
    type: string
  ): { newPassword?: string } => {
    const { newPassword } = values;
    if (!newPassword) {
      return { newPassword: `${type}.requiredNewPassword` };
    }

    const isComplex = /(?=.*\d)(?=.*[a-zA-Z])[!-~]{8,100}$/i.test(newPassword);
    if (!isComplex) {
      return { newPassword: `${type}.requiredComplex` };
    }

    if (values.oldPassword) {
      const isDifferent = values.oldPassword !== newPassword;
      if (!isDifferent) {
        return { newPassword: `${type}.requiredDifferent` };
      }
    }

    return {};
  },
  confirmationPassword(
    values: { newPassword: string, confirmationPassword: string },
    type: string
  ): { confirmationPassword?: string } {
    const { newPassword, confirmationPassword } = values;
    if (!confirmationPassword) {
      return { confirmationPassword: `${type}.requiredConfirmationPassword` };
    }

    const isMatched = newPassword === confirmationPassword;
    if (!isMatched) {
      return { confirmationPassword: `${type}.requiredMatched` };
    }

    return {};
  },
};

export const toggleApplianceFilter = (
  prevFilter: { id: string, display: boolean }[],
  id: string,
  syncIds: ?(string[])
): { id: string, display: boolean }[] => {
  const existSetting = prevFilter.find(appliance => appliance.id === id);
  if (!existSetting) {
    return [...prevFilter];
  }

  const idsToUpdate = [id, ...(syncIds || [])];
  return [...prevFilter].map(appliance => {
    if (!idsToUpdate.includes(appliance.id)) {
      return appliance;
    }
    return {
      ...appliance,
      display: !existSetting.display,
    };
  });
};

/**
 * Energyの配列をnumber[]に変換する
 */
export const convertEnergy = (energies: (number | null)[]): number[] =>
  energies.map(energy => energy || 0);

/**
 * Energyの配列の合計を取得する
 */
export const getTotalEnergy = (energies: number[] | null): number =>
  (energies || []).reduce((total, current) => total + current, 0);
