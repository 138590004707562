// @flow
/* eslint-env browser */

import moment from 'moment-timezone';
import qs from 'qs';
import { CALL_API, getJSON } from 'redux-api-middleware';
import * as types from 'Client/actions/types';
import { timeUnitId } from 'Client/lib/id';
import { timeFormat } from 'Client/lib/chartUtils';

declare var config: { FIREBASE_FUNCTIONS_URL: string };
const endpoint = {
  loginInfo: '/api/v1/login-info',
  login: '/api/v1/login',
  logout: '/api/v1/logout',
  multiFetch: '/api/v1/multifetch',
  userInfo: '/api/v1/user/info',
  userStatus: '/api/v1/user/status',
  timeSeries: '/api/v1/power/timeseries',
  useStats: '/api/v1/use/stats',
  energyHourly: '/api/v1/energy/hourly',
  settingsUpdate: '/api/v1/settings/update',
  requestMailChange: `${config.FIREBASE_FUNCTIONS_URL}/requestMailChange`,
  certificateMailChange: `${config.FIREBASE_FUNCTIONS_URL}/certificateMailChange`,
  sendPasswordMail: `${config.FIREBASE_FUNCTIONS_URL}/sendPasswordMail`,
  checkActionCode: `${config.FIREBASE_FUNCTIONS_URL}/checkActionCode`,
  updatePassword: `${config.FIREBASE_FUNCTIONS_URL}/updatePassword`,
};

let xsrfToken;

const getHeaders = (idToken?: string) => {
  // Androidに限りrefreshTokenが取得できないようなので暫定対応
  const oauthToken = idToken || qs.parse((location.search || '').slice(1)).token;
  return {
    'Content-Type': 'application/json',
    'X-XSRF-TOKEN': xsrfToken,
    ...(oauthToken
      ? {
          Authorization: `Bearer ${oauthToken}`,
        }
      : {}),
  };
};

const payload = (action, state, res) => {
  xsrfToken = res.headers.get('X-XSRF-TOKEN') || xsrfToken;
  return getJSON(res);
};

const multiFetchEndPoint = req => {
  const prefix = `${endpoint.multiFetch}/?`;
  // random for caching countermeasures.
  return (
    prefix + req.map(({ name, type }) => `${name}=${endpoint[type]}?_=${Math.random()}`).join('&')
  );
};

const multiFetchBody = requests => {
  const body = {};
  requests.forEach(request => {
    body[request.name] = request.body;
  });
  return body;
};

const multiFetchMeta = requests => {
  const meta = {};
  requests.forEach(request => {
    meta[request.name] = request.meta;
  });
  return meta;
};

export const apiMultiFetch = (
  requests: {
    type: string,
    name: string,
    meta: {
      dateTime?: string,
      hour?: string,
      unit?: string,
    },
    body: {
      unit?: string,
      sts: number,
      ets: number,
    },
  }[]
): Object => {
  const meta = multiFetchMeta(requests);
  return {
    [CALL_API]: {
      endpoint: multiFetchEndPoint(requests),
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(multiFetchBody(requests)),
      credentials: 'same-origin',
      types: [
        {
          type: types.REQUEST_API_MULTIFETCH,
          meta,
        },
        {
          type: types.SUCCESS_API_MULTIFETCH,
          meta,
          payload,
        },
        {
          type: types.FAILURE_API_MULTIFETCH,
          meta,
        },
      ],
    },
  };
};

const parseUrlHash = () => ({
  idTokenQuery: qs.parse((location.search || '').slice(1)).token,
  refreshTokenQuery: qs.parse((location.search || '').slice(1)).refreshToken,
});

export const apiCheckLogin = (token: string = ''): Object => {
  const { idTokenQuery = '', refreshTokenQuery = '' } = parseUrlHash();
  const idToken = idTokenQuery || token;
  return {
    [CALL_API]: {
      endpoint: endpoint.loginInfo,
      method: 'POST',
      headers: getHeaders(idToken),
      credentials: 'same-origin',
      types: [
        {
          type: types.REQUEST_API_LOGIN_INFO,
          payload,
          meta: { idToken, refreshToken: refreshTokenQuery },
        },
        {
          type: types.SUCCESS_API_LOGIN_INFO,
          payload,
        },
        types.FAILURE_API_LOGIN_INFO,
      ],
    },
  };
};

export const apiLoginExecute = (
  mailaddress: string,
  password: string,
  username: string,
  idToken?: string
): Object => ({
  [CALL_API]: {
    endpoint: endpoint.login,
    method: 'POST',
    headers: getHeaders(idToken),
    body: JSON.stringify({ mailaddress, password, username }),
    credentials: 'same-origin',
    types: [
      { type: types.REQUEST_API_LOGIN, meta: { idToken } },
      {
        type: types.SUCCESS_API_LOGIN,
        payload,
      },
      types.FAILURE_API_LOGIN,
    ],
  },
});

export const apiLogoutExecute = (): Object => ({
  [CALL_API]: {
    endpoint: endpoint.logout,
    method: 'POST',
    headers: getHeaders(),
    credentials: 'same-origin',
    types: [types.REQUEST_API_LOGOUT, types.SUCCESS_API_LOGOUT, types.FAILURE_API_LOGOUT],
  },
});

export const apiGetUserInfo = (): Object => ({
  [CALL_API]: {
    endpoint: endpoint.userInfo,
    method: 'POST',
    headers: getHeaders(),
    credentials: 'same-origin',
    types: [
      types.REQUEST_API_USER_INFO,
      {
        type: types.SUCCESS_API_USER_INFO,
        payload,
      },
      types.FAILURE_API_USER_INFO,
    ],
  },
});

export const apiGetUserStatus = (token: string = ''): Object => ({
  [CALL_API]: {
    endpoint: endpoint.userStatus,
    method: 'POST',
    headers: getHeaders(token),
    credentials: 'same-origin',
    types: [
      types.REQUEST_API_USER_STATUS,
      {
        type: types.SUCCESS_API_USER_STATUS,
        payload,
      },
      types.FAILURE_API_USER_STATUS,
    ],
  },
});

export const apiGetTimeSeries = (
  dateTime: string,
  unit: string,
  sts: number,
  ets: number
): Object => {
  const bodyUnit = unit === timeUnitId.year ? timeUnitId.month : unit;
  return {
    [CALL_API]: {
      endpoint: endpoint.timeSeries,
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({ unit: bodyUnit, sts, ets }),
      credentials: 'same-origin',
      types: [
        {
          type: types.REQUEST_API_TIME_SERIES,
          meta: { unit },
        },
        {
          type: types.SUCCESS_API_TIME_SERIES,
          meta: { dateTime, unit },
          payload,
        },
        {
          type: types.FAILURE_API_TIME_SERIES,
          meta: { unit },
        },
      ],
    },
  };
};

export const apiGetPowersByDay = (date: string): Object => {
  const sts = moment(date)
    .startOf('day')
    .unix();
  const ets = moment(date)
    .endOf('day')
    .unix();
  return {
    [CALL_API]: {
      endpoint: '/api/v1/power/daystats',
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({ sts, ets }),
      credentials: 'same-origin',
      types: [
        types.REQUEST_API_DAY_STATS,
        {
          type: types.SUCCESS_API_DAY_STATS,
          payload,
        },
        types.FAILURE_API_DAY_STATS,
      ],
    },
  };
};

export const apiGetTimeSeriesByHour = (i: number): Object => {
  const hour = moment().subtract(i, 'hours');
  return apiGetTimeSeries(
    hour.format('HH'),
    timeUnitId.hour,
    hour.startOf('hour').unix(),
    hour.endOf('hour').unix()
  );
};

export const apiGetTimeSeriesOfToday = (): Object => {
  const now = moment();
  return apiGetTimeSeries(
    now.format('YYYY-MM-DD'),
    timeUnitId.day,
    now.startOf('day').unix(),
    now.endOf('day').unix()
  );
};

export const apiGetTimeSeriesByUnit = (unit: string, period: string) => {
  const format = timeFormat[unit];
  const time = moment(period, format);
  return apiGetTimeSeries(
    time.startOf(unit).format(format),
    timeUnitId[unit],
    time.startOf(unit).unix(),
    time.endOf(unit).unix()
  );
};

export const apiGetUseStats = (hour: number, sts: number, ets: number): Object => ({
  [CALL_API]: {
    endpoint: endpoint.useStats,
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ sts, ets }),
    credentials: 'same-origin',
    types: [
      types.REQUEST_API_USE_STATS,
      {
        type: types.SUCCESS_API_USE_STATS,
        meta: { hour },
        payload,
      },
      types.FAILURE_API_USE_STATS,
    ],
  },
});

export const apiGetUseStatsByHour = (i: number): Object => {
  const hour = moment().subtract(i, 'hours');
  return apiGetUseStats(
    Number(hour.format('HH')),
    hour.startOf('hour').unix(),
    hour.endOf('hour').unix()
  );
};

export const apiGetUseStatsForLastYear = (): Object => {
  const now = moment();
  const ets = now.unix();
  const sts = now.subtract(1, 'years').unix();
  return {
    [CALL_API]: {
      endpoint: endpoint.useStats,
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({ sts, ets, timeUnit: '50' }),
      credentials: 'same-origin',
      types: [
        types.REQUEST_API_USE_STATS,
        {
          type: types.SUCCESS_API_USE_STATS,
          meta: { timeUnit: 'year' },
          payload,
        },
        types.FAILURE_API_USE_STATS,
      ],
    },
  };
};

export const apiGetEnergyHourly = (
  unitForMeta: { reqUnit: string, subUnitFlg: boolean },
  dateTime: string,
  unit: string,
  sts: number,
  ets: number,
  dateTimeList: string[] = []
): Object => {
  const { reqUnit, subUnitFlg } = unitForMeta;
  return {
    [CALL_API]: {
      endpoint: endpoint.energyHourly,
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({ unit, sts, ets }),
      credentials: 'same-origin',
      types: [
        {
          type: types.REQUEST_API_ENERGY_HOURLY,
          meta: { reqUnit },
        },
        {
          type: types.SUCCESS_API_ENERGY_HOURLY,
          meta: { dateTime, reqUnit, subUnitFlg, dateTimeList },
          payload,
        },
        {
          type: types.FAILURE_API_ENERGY_HOURLY,
          meta: { reqUnit },
        },
      ],
    },
  };
};
export const apiGetEnergyHourlyByUnit = (
  unitForMeta: { reqUnit: string, subUnitFlg: boolean },
  unit: string,
  requestUnit: string,
  period: string
): Object => {
  const format = timeFormat[unit];
  const dateTime = moment(period, format);
  return apiGetEnergyHourly(
    unitForMeta,
    moment(dateTime).format(format),
    requestUnit,
    dateTime.startOf(unit).unix(),
    dateTime.endOf(unit).unix() > moment().unix() ? moment().unix() : dateTime.endOf(unit).unix()
  );
};
export const apiGetEnergyHourlyBySubUnit = (
  meta: Object,
  unit: string,
  requestUnit: string,
  dateTimeList: string[]
): Object => {
  const format = timeFormat[unit];
  const formatDateTime = moment(dateTimeList[0], format);
  const lastIndex = dateTimeList.length;
  const createEts = () => {
    if (formatDateTime.endOf(unit).unix() > moment().unix()) {
      return moment().unix();
    }
    return unit === 'week'
      ? moment(dateTimeList[lastIndex - 1])
          .add(6, 'days')
          .unix()
      : moment(dateTimeList[lastIndex - 1])
          .endOf(unit)
          .unix();
  };
  return apiGetEnergyHourly(
    meta,
    moment(dateTimeList[0]).format(format),
    requestUnit,
    moment(dateTimeList[0]).unix(),
    createEts(),
    dateTimeList
  );
};

export const apiSettingsUpdate = (settings: Object): Object => ({
  [CALL_API]: {
    endpoint: endpoint.settingsUpdate,
    method: 'POST',
    headers: { ...getHeaders() },
    body: JSON.stringify({ settings }),
    credentials: 'same-origin',
    types: [
      types.REQUEST_API_SETTINGS_UPDATE,
      {
        type: types.SUCCESS_API_SETTINGS_UPDATE,
        payload,
      },
      types.FAILURE_API_SETTINGS_UPDATE,
    ],
  },
});

export const apiChangeMail = (idToken: string, mailAddress: string): Object => ({
  [CALL_API]: {
    endpoint: endpoint.requestMailChange,
    method: 'POST',
    headers: getHeaders(idToken),
    body: JSON.stringify({ email: mailAddress }),
    types: [types.REQUEST_CHANGE_MAIL, types.SUCCESS_CHANGE_MAIL, types.FAILURE_CHANGE_MAIL],
  },
});

export const apiCertificateMail = (token: string): Object => ({
  [CALL_API]: {
    endpoint: endpoint.certificateMailChange,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token }),
    types: [types.RESET_CERTIFICATION, types.SUCCESS_CERTIFICATION, types.FAILURE_CERTIFICATION],
  },
});

export const apiResetPassword = (mailAddress: string): Object => ({
  [CALL_API]: {
    endpoint: `${endpoint.sendPasswordMail}?email=${encodeURIComponent(mailAddress)}`,
    method: 'GET',
    types: [
      types.REQUEST_RESET_PASSWORD,
      types.SUCCESS_RESET_PASSWORD,
      types.FAILURE_RESET_PASSWORD,
    ],
  },
});

export const apiCheckActionCode = (actionCode: string): Object => ({
  [CALL_API]: {
    endpoint: endpoint.checkActionCode,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ actionCode }),
    types: [
      types.RESET_CERTIFICATION,
      types.SUCCESS_PASSWORD_RESET_CERTIFICATION,
      types.FAILURE_CERTIFICATION,
    ],
  },
});

export const updatePassword = (actionCode: string, newPassword: string): Object => ({
  [CALL_API]: {
    endpoint: endpoint.updatePassword,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ actionCode, newPassword }),
    types: [
      types.REQUEST_RESET_PASSWORD,
      types.SUCCESS_RESET_PASSWORD,
      types.FAILURE_RESET_PASSWORD,
    ],
  },
});
