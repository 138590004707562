// @flow

import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import TopApplianceRankingItem from 'Client/components/TopApplianceRankingItem';
import Prefixer from 'Client/lib/Prefixer';
import { sortRanking } from 'Client/lib/globalUtils';
import { rankingFormat } from 'Client/lib/localizedValues';
import type { State, ApplianceEnergyState, PricePlanState } from 'Client/types/state';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  container: {
    width: '100%',
    fontSize: '0.8em',
  },
  overlap: {
    color: 'white',
    opacity: 0.2,
  },
});

type StateProps = {|
  currentDate: string,
  applianceEnergy: ApplianceEnergyState,
  pricePlan: PricePlanState,
  idsToShowForRanking: (string | null)[],
|};
type OwnProps = {|
  requesting: boolean,
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

export const PureTopApplianceRanking = ({
  applianceEnergy,
  requesting,
  pricePlan,
  idsToShowForRanking,
  currentDate,
}: Props): Node => {
  const currentAplEnergy = applianceEnergy.monthly.find(
    ele => moment(currentDate, 'YYYY-MM-DD').format('YYYY-MM') === ele.dateTime
  );
  if (!currentAplEnergy || currentAplEnergy.applianceEnergy.length === 0 || requesting) {
    return (
      <div style={style.container}>
        <TopApplianceRankingItem rank={1} rankItem={{ id: '', energy: 0 }} />
        <TopApplianceRankingItem rank={2} rankItem={{ id: '', energy: 0 }} />
        <TopApplianceRankingItem rank={3} rankItem={{ id: '', energy: 0 }} />
      </div>
    );
  }
  const filteredAplEnergy = rankingFormat.ja
    .map(rankingAppliance => {
      const matchedAppliance = currentAplEnergy.applianceEnergy.find(
        appliance => appliance.id === rankingAppliance.id
      );
      return {
        ...rankingAppliance,
        energy: matchedAppliance ? matchedAppliance.energy : [],
      };
    })
    .filter(item => idsToShowForRanking.includes(item.id));
  const rank = sortRanking(filteredAplEnergy, pricePlan)
    .slice(0, 3)
    .map((value, index) => (
      <TopApplianceRankingItem key={`ranking-${value.id}`} rankItem={value} rank={index + 1} />
    ));
  return <div style={style.container}>{rank}</div>;
};

const mapStateToProps = (state: State): StateProps => ({
  currentDate: state.timeRange.currentDate,
  applianceEnergy: state.applianceEnergy,
  pricePlan: state.pricePlan,
  idsToShowForRanking: state.applianceState.idsToShowForRanking,
});

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  PureTopApplianceRanking
);
