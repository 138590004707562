// @flow

import React, { useState } from 'react';
import Prefixer from 'Client/lib/Prefixer';
import { injectIntl, intlShape } from 'react-intl';
import { isEmbeded } from '../lib/serviceCheck';

const prefixer = new Prefixer();

const styles = prefixer.prefix({
  helpArea: {
    position: 'fixed',
    right: 0,
    bottom: 0,
    margin: '10px 20px',
    width: '48px',
    height: '50px',
    backgroundColor: '#a1a1a1',
    color: '#373737',
    fill: '#373737',
    borderRadius: '999rem',
    textDecoration: 'none',
    fontWeight: 700,
  },
  help: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

declare var config: { URLS: Object[] };

type Props = { intl: intlShape };

const Help = ({ intl }: Props) => {
  const [viewHelp, setViewHelp] = useState(false);
  const { FAQ } = config.URLS[intl.locale.toUpperCase()] || {};

  setTimeout(() => setViewHelp(true), 3000);

  if (!viewHelp || isEmbeded) {
    return null;
  }
  return (
    <a href={FAQ} style={styles.helpArea} target="_blank" rel="noopener noreferrer">
      <div style={styles.help}>
        <svg width="20" height="20" viewBox="0 0 20 20" aria-hidden="true">
          <g id="Layer_4">
            <path d="M11,12.3V13c0,0-1.8,0-2,0v-0.6c0-0.6,0.1-1.4,0.8-2.1c0.7-0.7,1.6-1.2,1.6-2.1c0-0.9-0.7-1.4-1.4-1.4 c-1.3,0-1.4,1.4-1.5,1.7H6.6C6.6,7.1,7.2,5,10,5c2.4,0,3.4,1.6,3.4,3C13.4,10.4,11,10.8,11,12.3z" />
            <circle cx="10" cy="15" r="1" />
            <path d="M10,2c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S5.6,2,10,2 M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10s10-4.5,10-10S15.5,0,10,0 L10,0z" />
          </g>
        </svg>
      </div>
    </a>
  );
};

export default injectIntl(Help);
