// @flow
/* eslint-env browser */
export default (
  location: ?string,
  contractorId: ?string,
  serviceProviderId: ?string,
  appUserId: ?string,
  status: string = ''
) => {
  if (contractorId && serviceProviderId && appUserId) {
    window.ga('set', 'dimension1', contractorId);
    window.ga('set', 'dimension2', serviceProviderId);
    window.ga('set', 'dimension4', status);
    window.ga('set', 'dimension9', appUserId);
    window.ga('set', 'userId', appUserId);
    window.ga('send', 'pageview', location);
  }
};
