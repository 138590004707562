// @flow
import type { State, Action } from 'redux';
import { timeUnitId } from 'Client/lib/id';
import { total, getWhpValue, getSellPValue, getBuyPValue } from './energyReducer';

const successApiMultifetchHourly = (energyState, targets, meta) => {
  let unitEnergy = targets.map(target => ({
    hour: meta[target.name].dateTime,
    whp: getWhpValue(target.body),
    sellEnergy: getSellPValue(target.body),
    buyEnergy: getBuyPValue(target.body),
  }));
  if (targets.length === 2) {
    const hoursToUpdate = unitEnergy.map(value => value.hour);
    const rest10Hours = energyState.hourly.filter(value => hoursToUpdate.indexOf(value.hour) < 0);
    unitEnergy = [...unitEnergy, ...rest10Hours];
  }
  return {
    ...energyState,
    hourly: unitEnergy,
  };
};

const successApiMultifetchDaily = (energyState, targets, meta) => {
  const existDays = energyState.daily.map(daily => daily.day);
  const unitEnergy = targets
    .filter(target => !existDays.includes(meta[target.name].dateTime))
    .map(target => ({
      day: meta[target.name].dateTime,
      whp: getWhpValue(target.body),
      sellEnergy: getSellPValue(target.body),
      buyEnergy: getBuyPValue(target.body),
      consumeEnergy: total(target.body.consume_p),
      pgEnergy: total(target.body.pg_p),
      chargedEnergy: total(target.body.charged_p),
      dischargedEnergy: total(target.body.discharged_p),
    }));
  return {
    ...energyState,
    daily: [...energyState.daily, ...unitEnergy],
  };
};

const successApiMultifetchWeekly = (energyState, targets, meta) => {
  const existWeeks = energyState.weekly.map(weekly => weekly.week);
  const unitEnergy = targets
    .filter(target => !existWeeks.includes(meta[target.name].dateTime))
    .map(target => ({
      week: meta[target.name].dateTime,
      whp: total(target.body.whp),
      sellEnergy: total(target.body.sell_p),
      buyEnergy: total(target.body.buy_p),
      consumeEnergy: total(target.body.consume_p),
      pgEnergy: total(target.body.pg_p),
      chargedEnergy: total(target.body.charged_p),
      dischargedEnergy: total(target.body.discharged_p),
    }));
  return {
    ...energyState,
    weekly: [...energyState.weekly, ...unitEnergy],
  };
};

const successApiMultifetchMonthly = (energyState, targets, meta) => {
  const existMonths = energyState.monthly.map(monthly => monthly.month);
  const unitEnergy = targets
    .filter(target => !existMonths.includes(meta[target.name].dateTime))
    .map(target => ({
      month: meta[target.name].dateTime,
      whp: total(target.body.whp),
      sellEnergy: total(target.body.sell_p),
      buyEnergy: total(target.body.buy_p),
      consumeEnergy: total(target.body.consume_p),
      pgEnergy: total(target.body.pg_p),
      chargedEnergy: total(target.body.charged_p),
      dischargedEnergy: total(target.body.discharged_p),
    }));
  return {
    ...energyState,
    monthly: [...energyState.monthly, ...unitEnergy],
  };
};

export default (energyState: State, action: Action = {}): State => {
  // don't acquire multiple units at the same time. (OK: hourly / NG: hourly and daily)
  const { payload, meta } = action || {};
  const targetKeys = Object.keys(payload).filter(key => key.match(/^timeSeries/));
  if (!targetKeys.length) {
    return energyState;
  }
  const targets = targetKeys.map(key => ({
    ...payload[key],
    name: key,
  }));
  switch (meta[targetKeys[0]].unit) {
    case timeUnitId.hour:
      return successApiMultifetchHourly(energyState, targets, meta);
    case timeUnitId.day:
      return successApiMultifetchDaily(energyState, targets, meta);
    case timeUnitId.week:
      return successApiMultifetchWeekly(energyState, targets, meta);
    case timeUnitId.month:
      return successApiMultifetchMonthly(energyState, targets, meta);
    default: {
      return energyState;
    }
  }
};
