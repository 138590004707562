// @flow

import React, { Component } from 'react';
import type { Dispatch } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import type { ContextRouter } from 'react-router-dom';
import HeaderBar from 'Client/components/HeaderBar';
import TopBody from 'Client/components/TopBody';
import FadeTransition from 'Client/components/utils/FadeTransition';
import { loadTopPage } from 'Client/lib/topPageUtils';
import { RESET_TUTORIAL } from 'Client/actions/types';
import type { State } from 'Client/types/state';
import { isEmbeded } from 'Client/lib/serviceCheck';

import Prefixer from 'Client/lib/Prefixer';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  content: {
    textAlign: 'center',
    marginTop: '25px',
    maxWidth: '980px',
    margin: '0px auto',
    color: '#847965',
  },
  boxUp: {
    width: '100%',
    padding: '55px 0px 0px',
  },
});

const availableStatus = ['normal', 'not_estimable', 'learning', 'imperfect_learning'];

const toppageOnEnter = props => {
  const { isPg, contractorId, status, dispatch } = props;
  if (contractorId && availableStatus.includes(status)) {
    loadTopPage(dispatch, isPg);
  }
};

type StateProps = {|
  loginState: string,
  contractorId: string,
  tutorialFinished: boolean,
  status: string,
  isUserSettingRequest: boolean,
  isPg: boolean,
|};
type OwnProps = {|
  ...ContextRouter,
|};
type Props = {
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
};

class ExtendTopPage extends Component<Props> {
  componentDidMount() {
    const { loginState, tutorialFinished, status, isUserSettingRequest } = this.props;
    if (
      loginState === 'SUCCESS' &&
      !tutorialFinished &&
      !isUserSettingRequest &&
      availableStatus.includes(status) &&
      !isEmbeded
    ) {
      this.props.dispatch({ type: RESET_TUTORIAL });
      this.context.router.history.replace('/tutorial');
      return;
    }
    toppageOnEnter(this.props);
  }

  componentDidUpdate() {
    toppageOnEnter(this.props);
  }

  render() {
    const { dispatch } = this.props;
    return (
      <FadeTransition pathname={this.props.location.pathname}>
        <div>
          <HeaderBar pageType="top" />
          <div style={style.content}>
            <div style={style.boxUp}>
              <TopBody dispatch={dispatch} />
            </div>
          </div>
        </div>
      </FadeTransition>
    );
  }
}

ExtendTopPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state: State): StateProps => ({
  loginState: state.loginInfo.loginState,
  contractorId: state.userInfo.contractorId,
  tutorialFinished: state.tutorial.tutorialFinished,
  status: state.userInfo.status,
  isUserSettingRequest: state.userSetting.requesting,
  isPg: state.userSetting.isPg,
});

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  ExtendTopPage
);
