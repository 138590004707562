// @flow

import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import Popup from 'Client/components/utils/Popup';
import { CLOSING_POP_UP, CLOSED_POP_UP, OPEN_PRICE_PLAN_POP_UP } from 'Client/actions/types';
import Prefixer from 'Client/lib/Prefixer';
import {
  NO_DATA_FOR_24_HOURS,
  SERVICE_UNAVAILABLE,
  APPLIANCE_FOUND,
  CONTRACT_EXPIRED,
  NETWORK_DISCONNECTED,
  INFORMATION,
  USER_STATUS_ERROR,
  SESSION_EXPIRED,
} from 'Client/actions/alertPopupTypes';
import type { State, PopupState } from 'Client/types/state';

const prefixer = new Prefixer();

// エラーダイアログポップアップが表示されるタイミング
// * getUseTab 24時間のデータがnull
// * getUserInfo startDialog
// * config.informationLinkからの戻り値
// * ~~login ログインできなかった パスワード違い~~
// * login ログインできなかった 契約切れ
// * ネットワークに繋がらない
//

const styles = prefixer.prefix({
  popUpBox: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    color: '#ffb835',
    fontWeight: 400,
    fontSize: 17,
    textAlign: 'center',
    margin: '16px 10px 0',
  },
  content: {
    color: '#1f1f21',
    margin: '10px auto',
    backgroundClip: 'padding-box',
    padding: '4px 12px 8px 12px',
    fontSize: '14px',
    textAlign: 'center',
  },
  button: {
    background: '#ffb835',
    fontWeight: 'bold',
    fontSize: 16,
    borderRadius: 3,
    textAlign: 'center',
    margin: '5px auto 10px',
    padding: 0,
    width: '80%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    border: 0,
    height: 44,
  },
  ok: {
    width: '100%',
    textAlign: 'center',
    padding: '20px 0 20px 0',
    fontSize: 12,
  },
  buttonText: {
    color: '#fff',
    textDecoration: 'none',
    fontWeight: 'normal',
    width: '100%',
  },
  link: {
    color: '#333',
    textDecoration: 'underline',
  },
});

const getLinkButton = (linkUrl, linkMessage) => {
  if (!linkUrl && !linkMessage) {
    return null;
  }
  return (
    <button style={styles.button}>
      <a style={styles.buttonText} href={linkUrl} target="_blank" rel="noopener noreferrer">
        {linkMessage}
      </a>
    </button>
  );
};

const getCloseButton = (label, isButton, ClosePopup) => (
  <div style={styles.ok}>
    {isButton ? (
      <div className="close" style={styles.button} onClick={ClosePopup}>
        {label}
      </div>
    ) : (
      <div className="close" style={styles.link} onClick={ClosePopup}>
        {label}
      </div>
    )}
  </div>
);

type StateProps = {|
  popup: PopupState,
|};
type OwnProps = {|
  intl: intlShape,
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

declare var config: { URLS: Object[] };

export const PureAlertPopup = ({ popup, intl, dispatch }: Props): ?Node => {
  const { Q_NO_DATA_FOR_24_HOURS, Q_SERVICE_UNAVAILABLE, Q_CONTRACT_EXPIRED } =
    config.URLS[intl.locale.toUpperCase()] || {};
  const { open, contentType, status, information, haveShownSettingPricePlanDialog } = popup;
  if (!open && !contentType) {
    return null;
  }

  let title = '';
  let message = '';
  let linkButton = '';
  let closeButton = '';
  let isOpen = false;
  const animationDuration = 200;
  const pricePlanPopupDuration = 300;
  const ClosePopup = () => {
    dispatch({
      type: CLOSING_POP_UP,
    });
    setTimeout(() => {
      dispatch({ type: CLOSED_POP_UP });
    }, animationDuration);
    if (contentType === 'APPLIANCE_FOUND' && !haveShownSettingPricePlanDialog) {
      setTimeout(() => {
        dispatch({ type: OPEN_PRICE_PLAN_POP_UP, contentType: 'pricePlan' });
      }, pricePlanPopupDuration);
    }
  };

  switch (contentType) {
    case NO_DATA_FOR_24_HOURS:
      title = (
        <span>
          {intl.formatMessage({ id: 'alertPopup.noDataT1' })}
          <br />
          {intl.formatMessage({ id: 'alertPopup.noDataT2' })}
        </span>
      );
      message = intl.formatMessage({ id: 'alertPopup.noDataD' });
      linkButton = getLinkButton(
        Q_NO_DATA_FOR_24_HOURS,
        intl.formatMessage({ id: 'alertPopup.noDataLink' })
      );
      closeButton = getCloseButton(
        intl.formatMessage({ id: 'alertPopup.noAction' }),
        false,
        ClosePopup
      );
      isOpen = true;
      break;
    case SERVICE_UNAVAILABLE:
      title = intl.formatMessage({ id: 'alertPopup.disableAquireT' });
      message = (
        <span>
          {intl.formatMessage({ id: 'alertPopup.disableAquireD1' })}
          <br />
          {intl.formatMessage({ id: 'alertPopup.disableAquireD2' })}
          <br />
          {intl.formatMessage({ id: 'alertPopup.disableAquireD3' })}
        </span>
      );
      linkButton = getLinkButton(
        Q_SERVICE_UNAVAILABLE,
        intl.formatMessage({ id: 'alertPopup.disableAquireLink' })
      );
      closeButton = getCloseButton(
        intl.formatMessage({ id: 'alertPopup.noAction' }),
        false,
        ClosePopup
      );
      break;
    case APPLIANCE_FOUND:
      title = (
        <div>
          <div>{intl.formatMessage({ id: 'alertPopup.StartT1' })}</div>
          <div>{intl.formatMessage({ id: 'alertPopup.StartT2' })}</div>
        </div>
      );
      message = (
        <span>
          {intl.formatMessage({ id: 'alertPopup.StartD1' })}
          {intl.formatMessage({ id: 'alertPopup.StartD2' })}
          <br />
          {intl.formatMessage({ id: 'alertPopup.StartD3' })}
          {intl.formatMessage({ id: 'alertPopup.StartD4' })}
        </span>
      );
      linkButton = null;
      closeButton = getCloseButton(
        intl.formatMessage({ id: 'alertPopup.StartButton' }),
        true,
        ClosePopup
      );
      break;
    case CONTRACT_EXPIRED:
      title = intl.formatMessage({ id: 'others.notice' });
      message = (
        <span>
          {intl.formatMessage({ id: 'alertPopup.loginFalied' })}
          <br />
          {intl.formatMessage({ id: 'alertPopup.checkContract' })}
        </span>
      );
      linkButton = getLinkButton(
        Q_CONTRACT_EXPIRED,
        intl.formatMessage({ id: 'alertPopup.inquiry' })
      );
      closeButton = getCloseButton(intl.formatMessage({ id: 'others.close' }), false, ClosePopup);
      break;
    case NETWORK_DISCONNECTED:
      title = intl.formatMessage({ id: 'others.notice' });
      message = intl.formatMessage({ id: 'others.networkError' });
      if (status) {
        message = `${message}(${status})`;
      }
      linkButton = null;
      closeButton = getCloseButton(intl.formatMessage({ id: 'others.close' }), false, ClosePopup);
      break;
    case INFORMATION:
      title = intl.formatMessage({ id: 'others.notice' });
      message = <span>{information}</span>;
      linkButton = null;
      closeButton = getCloseButton(intl.formatMessage({ id: 'others.close' }), false, ClosePopup);
      break;
    case USER_STATUS_ERROR:
      title = intl.formatMessage({ id: 'alertPopup.userStatusErrorTitle' });
      message = (
        <span>
          {intl.formatMessage({ id: 'alertPopup.userStatusErrorMessage1' })}
          <br />
          {intl.formatMessage({ id: 'alertPopup.userStatusErrorMessage2' })}
          <br />
          {intl.formatMessage({ id: 'alertPopup.userStatusErrorMessage3' })}
        </span>
      );
      linkButton = getLinkButton(
        Q_SERVICE_UNAVAILABLE,
        intl.formatMessage({ id: 'alertPopup.disableAquireLink' })
      );
      closeButton = null;
      break;
    case SESSION_EXPIRED:
      title = null;
      message = (
        <span>
          {intl.formatMessage({ id: 'alertPopup.sessionExpiredMessage1' })}
          <br />
          {intl.formatMessage({ id: 'alertPopup.sessionExpiredMessage2' })}
          <br />
          <br />
        </span>
      );
      linkButton = null;
      closeButton = null;
      break;
    default:
      return null;
  }

  return (
    <Popup
      open={open}
      animationDuration={animationDuration}
      closeHandler={isOpen ? ClosePopup : () => {}}
      style={{
        popupContainer: {
          display: 'table',
        },
        tableCell: {
          display: 'table-cell',
          verticalAlign: 'middle',
        },
        popup: {
          paddingTop: '5px',
          top: '-10vh',
          margin: '0 auto',
        },
      }}
    >
      <div style={styles.popUpBox}>
        <div style={styles.title}> {title} </div>
        <div style={styles.content}> {message} </div>
        {linkButton}
        {closeButton}
      </div>
    </Popup>
  );
};

PureAlertPopup.propTypes = {
  popup: PropTypes.shape({
    open: PropTypes.bool,
    contentType: PropTypes.string,
    status: PropTypes.number,
    information: PropTypes.string,
  }),
  intl: intlShape.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state: State): StateProps => ({
  popup: state.popup,
});

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  injectIntl(PureAlertPopup)
);
