// @flow

import moment from 'moment-timezone';
import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import sizeMe from 'react-sizeme';
import Currency from 'Client/components/utils/Currency';
import Prefixer from 'Client/lib/Prefixer';
import { makeValidPeriod } from 'Client/lib/globalUtils';
import type { State, PricePlanState } from 'Client/types/state';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  areaWrapper: {
    fontSize: '1.5em',
    fontWeight: 'bold',
    padding: '12px 0px 20px 0px',
  },
  titleWrapper: {
    display: 'inline-block',
  },
  criterion: {
    fontSize: '0.5em',
    textAlign: 'right',
  },
  title: {
    color: '#847965',
  },
  contents: {
    marginBottom: '0.3em',
    fontSize: '1.5em',
    color: '#7c7c7c',
  },
  small: {
    fontSize: '0.8em',
  },
  result: {
    textAlign: 'center',
    fontSize: '0.8em',
    paddingLeft: '8px',
  },
  sellColor: {
    color: '#46b7aa',
  },
  buyColor: {
    color: '#ff897e',
  },
  sameColor: {
    color: '#8b4513',
  },
  detailButton: {
    width: '60%',
    border: 'none',
    margin: '5px 0',
  },
  detailButtonDisable: {
    opacity: '0.3',
  },
  loading: {
    fontSize: '0.7em',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    color: '#FFBD00',
    padding: '7.5px 0px',
  },
});

const renderCurrency = todaysBalance => {
  const color = () => {
    if (todaysBalance > 0) {
      return style.sellColor;
    } else if (todaysBalance < 0) {
      return style.buyColor;
    }
    return style.sameColor;
  };
  return (
    <div style={style.contents}>
      <Currency value={todaysBalance} style={{ ...style.small, ...color() }} />
    </div>
  );
};

const renderLoading = intl => (
  <div>
    <div style={style.contents}>
      <span style={style.small}>
        <div style={style.loading}>{intl.formatMessage({ id: 'topDailyBalance.loading' })}</div>
      </span>
    </div>
  </div>
);

const renderDetailButton = (image, requesting) => {
  const period = makeValidPeriod('day', moment());
  const buttonStyle = requesting
    ? { ...style.detailButton, ...style.detailButtonDisable }
    : style.detailButton;
  const detailButtonImage = () => (
    <img style={{ ...buttonStyle, width: '60%' }} src={image} alt="detail" />
  );
  return requesting ? (
    <div>{detailButtonImage()}</div>
  ) : (
    <Link to={`/chart/day/${period}`}>{detailButtonImage()}</Link>
  );
};

type StateProps = {|
  hourlyRequesting: boolean,
  pricePlan: PricePlanState,
|};
type OwnProps = {|
  todaysBalance: number,
  intl: intlShape,
  dailyRequesting: boolean,
  size: {
    width: number,
    height: number,
  },
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

const PureTopDailyBalance = ({
  todaysBalance,
  intl,
  hourlyRequesting,
  dailyRequesting,
}: Props): Node => {
  const detailPg = require(`Client/images/btn_detail_pv_default_ja.png`);
  const someRequesting = hourlyRequesting || dailyRequesting;
  return (
    <div style={style.areaWrapper}>
      <div style={style.titleWrapper}>
        <div style={style.criterion}>{intl.formatMessage({ id: 'others.criterion' })}</div>
        <div style={style.title}>{intl.formatMessage({ id: 'topDailyBalance.title' })}</div>
      </div>
      {dailyRequesting ? renderLoading(intl) : renderCurrency(todaysBalance)}
      {renderDetailButton(detailPg, someRequesting)}
    </div>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  hourlyRequesting: state.energy.hourlyRequesting,
  pricePlan: state.pricePlan,
});

const sizeMeHOC = sizeMe({ monitorHeight: true });

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  sizeMeHOC(injectIntl(PureTopDailyBalance))
);

export const PureTopDailyBalanceForTest = PureTopDailyBalance;
