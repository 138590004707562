// flow
import moment from 'moment-timezone';
import type { State, Action } from 'redux';
import { timeUnitId } from 'Client/lib/id';
import { total } from './energyReducer';

const HOURS_IN_ONE_DAY = 24;

const subUnitWeeklyData = (payload, dateTimeList) => {
  let whp = Array(HOURS_IN_ONE_DAY).fill(0);
  let sellEnergy = 0;
  let consumeEnergy = 0;
  let pgEnergy = 0;
  let chargedEnergy = 0;
  let dischargedEnergy = 0;
  let buyEnergy = Array(HOURS_IN_ONE_DAY).fill(0);
  const weekEnergy = [];
  dateTimeList.forEach(startWeekDay => {
    [0, 1, 2, 3, 4, 5, 6].forEach(ele => {
      const found = payload.find(
        target =>
          target.unix ===
          moment(startWeekDay, 'YYYY-MM-DD')
            .add(ele, 'day')
            .unix()
      );
      if (found) {
        if (found.data && found.data.whp)
          found.data.whp.forEach((energy, indx) => {
            whp[indx] += energy;
          });
        sellEnergy += total(found.data.sold_energy);
        consumeEnergy += total(found.data.consumed_energy);
        pgEnergy += total(found.data.generated_energy);
        chargedEnergy += (found.data.charged_energy && total(found.data.charged_energy)) || 0;
        dischargedEnergy +=
          (found.data.discharged_energy && total(found.data.discharged_energy)) || 0;
        found.data.bought_energy.forEach((energy, indx) => {
          buyEnergy[indx] += energy;
        });
      }
    });
    const oneWeeklyEnergy = {
      week: startWeekDay,
      whp,
      sellEnergy,
      buyEnergy,
      consumeEnergy,
      pgEnergy,
      chargedEnergy,
      dischargedEnergy,
    };
    weekEnergy.push(oneWeeklyEnergy);
    whp = Array(HOURS_IN_ONE_DAY).fill(0);
    sellEnergy = 0;
    consumeEnergy = 0;
    pgEnergy = 0;
    chargedEnergy = 0;
    dischargedEnergy = 0;
    buyEnergy = Array(HOURS_IN_ONE_DAY).fill(0);
  });
  return weekEnergy;
};

const subUnitMonthlyData = payload => {
  let month = '';
  let whp = Array(HOURS_IN_ONE_DAY).fill(0);
  let sellEnergy = 0;
  let consumeEnergy = 0;
  let pgEnergy = 0;
  let chargedEnergy = 0;
  let dischargedEnergy = 0;
  let buyEnergy = Array(HOURS_IN_ONE_DAY).fill(0);
  const monthEnergy = [];
  payload.forEach(target => {
    month = moment.unix(target.unix).format('YYYY-MM');
    if (target.data && target.data.whp)
      target.data.whp.forEach((energy, indx) => {
        whp[indx] += energy;
      });
    sellEnergy += total(target.data.sold_energy);
    consumeEnergy += total(target.data.consumed_energy);
    pgEnergy += total(target.data.generated_energy);
    chargedEnergy += (target.data.charged_energy && total(target.data.charged_energy)) || 0;
    dischargedEnergy +=
      (target.data.discharged_energy && total(target.data.discharged_energy)) || 0;
    target.data.bought_energy.forEach((energy, indx) => {
      buyEnergy[indx] += energy;
    });
    const oneMonthlyEnergy = {
      month,
      whp,
      sellEnergy,
      buyEnergy,
      consumeEnergy,
      pgEnergy,
      chargedEnergy,
      dischargedEnergy,
    };
    monthEnergy.push(oneMonthlyEnergy);
    whp = Array(HOURS_IN_ONE_DAY).fill(0);
    sellEnergy = 0;
    consumeEnergy = 0;
    pgEnergy = 0;
    chargedEnergy = 0;
    dischargedEnergy = 0;
    buyEnergy = Array(HOURS_IN_ONE_DAY).fill(0);
  });
  return monthEnergy;
};

const getTotalsOfEachParam = target => ({
  whp: target.data.whp || 0,
  sellEnergy: total(target.data.sold_energy),
  buyEnergy: target.data.bought_energy || 0,
  consumeEnergy: total(target.data.consumed_energy),
  pgEnergy: total(target.data.generated_energy),
  chargedEnergy: (target.data.charged_energy && total(target.data.charged_energy)) || 0,
  dischargedEnergy: (target.data.discharged_energy && total(target.data.discharged_energy)) || 0,
});

const calculateEachEnergyParam = unitEnergy => {
  const whp = Array(HOURS_IN_ONE_DAY).fill(0);
  let sellEnergy = 0;
  let consumeEnergy = 0;
  let pgEnergy = 0;
  let chargedEnergy = 0;
  let dischargedEnergy = 0;
  const buyEnergy = Array(HOURS_IN_ONE_DAY).fill(0);
  unitEnergy.forEach(target => {
    if (target.whp)
      target.whp.forEach((energy, index) => {
        whp[index] += energy;
      });
    sellEnergy += target.sellEnergy;
    consumeEnergy += target.consumeEnergy;
    pgEnergy += target.pgEnergy;
    chargedEnergy += target.chargedEnergy || 0;
    dischargedEnergy += target.dischargedEnergy || 0;
    target.buyEnergy.forEach((energy, index) => {
      buyEnergy[index] += energy;
    });
  });
  return {
    whp,
    sellEnergy,
    buyEnergy,
    consumeEnergy,
    pgEnergy,
    chargedEnergy,
    dischargedEnergy,
  };
};

const successApiEnergyHourlyDaily = (energyState, payload, meta) => {
  if (energyState.daily.find(item => meta.dateTime === item.day)) {
    return energyState;
  }
  const unitEnergy = payload.map(target => ({
    day: moment.unix(target.unix).format('YYYY-MM-DD'),
    ...getTotalsOfEachParam(target),
    splitWhp: (target.data.whp && target.data.whp) || Array(24).fill(null),
    splitSellEnergy: target.data.sold_energy,
    splitBuyEnergy: target.data.bought_energy,
    splitConsumeEnergy: target.data.consumed_energy,
    splitPgEnergy: target.data.generated_energy,
    splitChargedEnergy:
      (target.data.charged_energy && target.data.charged_energy) || Array(24).fill(null),
    splitDischargedEnergy:
      (target.data.discharged_energy && target.data.discharged_energy) || Array(24).fill(null),
  }));
  return {
    ...energyState,
    daily: [...energyState.daily, ...unitEnergy],
  };
};

const successApiEnergyHourlyWeekly = (energyState, payload, meta) => {
  if (energyState.weekly.find(item => meta.dateTime === item.week)) {
    return energyState;
  }
  if (meta.subUnitFlg) {
    const weekEnergy = subUnitWeeklyData(payload, meta.dateTimeList);
    return {
      ...energyState,
      weekly: [...energyState.weekly, ...weekEnergy],
    };
  }
  const unitEnergy = payload.map(target => ({
    week: moment.unix(target.unix).format('YYYY-MM-DD'),
    ...getTotalsOfEachParam(target),
    splitWhp: target.data.whp,
  }));
  const weeklyEnergy = [
    ...energyState.weekly,
    {
      week: meta.dateTime,
      ...calculateEachEnergyParam(unitEnergy),
    },
  ];
  return {
    ...energyState,
    weekly: weeklyEnergy,
  };
};

const successApiEnergyHourlyMonthly = (energyState, payload, meta) => {
  if (energyState.monthly.find(item => meta.dateTime === item.month)) {
    return energyState;
  }
  if (meta.subUnitFlg) {
    const monthEnergy = subUnitMonthlyData(payload);
    return {
      ...energyState,
      monthly: [...energyState.monthly, ...monthEnergy],
    };
  }
  const unitEnergy = payload.map(target => ({
    month: moment.unix(target.unix).format('YYYY-MM'),
    ...getTotalsOfEachParam(target),
  }));
  const monthlyEnergy = [
    ...energyState.monthly,
    {
      month: unitEnergy[0].month,
      ...calculateEachEnergyParam(unitEnergy),
    },
  ];
  return {
    ...energyState,
    monthly: monthlyEnergy,
  };
};

const successApiEnergyHourlyYearly = (energyState, payload, meta) => {
  if (energyState.yearly.find(item => meta.dateTime === item.year)) {
    return energyState;
  }
  const unitEnergy = payload.map(target => ({
    year: moment.unix(target.unix).format('YYYY'),
    ...getTotalsOfEachParam(target),
  }));
  const yearlyEnergy = [
    ...energyState.yearly,
    {
      year: unitEnergy[0].year,
      ...calculateEachEnergyParam(unitEnergy),
    },
  ];
  return {
    ...energyState,
    yearly: yearlyEnergy,
  };
};

export default (energyState: State, action: Action = {}): State => {
  const { payload, meta } = action || {};
  if (!Object.keys(payload).length) {
    return energyState;
  }
  switch (meta.reqUnit) {
    case timeUnitId.day:
      return successApiEnergyHourlyDaily(energyState, payload, meta);
    case timeUnitId.week:
      return successApiEnergyHourlyWeekly(energyState, payload, meta);
    case timeUnitId.month:
      return successApiEnergyHourlyMonthly(energyState, payload, meta);
    case timeUnitId.year:
      return successApiEnergyHourlyYearly(energyState, payload, meta);
    default: {
      return energyState;
    }
  }
};
