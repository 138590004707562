// @flow
import type { Action } from 'redux';
import { timeUnitId } from 'Client/lib/id';

import {
  LOAD_TOP_PAGE,
  RESET_CHART_PAGE,
  REQUEST_API_TIME_SERIES,
  SUCCESS_API_TIME_SERIES,
  FAILURE_API_TIME_SERIES,
  REQUEST_API_MULTIFETCH,
  SUCCESS_API_MULTIFETCH,
  FAILURE_API_MULTIFETCH,
  REQUEST_API_DAY_STATS,
  SUCCESS_API_DAY_STATS,
  FAILURE_API_DAY_STATS,
  REQUEST_API_ENERGY_HOURLY,
  SUCCESS_API_ENERGY_HOURLY,
  FAILURE_API_ENERGY_HOURLY,
} from 'Client/actions/types';
import type { RequestsReducerState as State } from 'Client/types/state';

export const initialRequestsState: State = {
  hourlyRequesting: false,
  dailyRequesting: false,
  weeklyRequesting: false,
  monthlyRequesting: false,
  yearlyRequesting: false,
};

const setHourlyRequesting = (requestsState, requesting) => ({
  ...requestsState,
  hourlyRequesting: requesting,
});

const setDailyRequesting = (requestsState, requesting) => ({
  ...requestsState,
  dailyRequesting: requesting,
});

const setWeeklyRequesting = (requestsState, requesting) => ({
  ...requestsState,
  weeklyRequesting: requesting,
});

const setMonthlyRequesting = (requestsState, requesting) => ({
  ...requestsState,
  monthlyRequesting: requesting,
});

const setYearlyRequesting = (requestsState, requesting) => ({
  ...requestsState,
  yearlyRequesting: requesting,
});

const setRequestsStateByUnit = (requestsState, timeUnit, requesting) => {
  switch (timeUnit) {
    case timeUnitId.hour:
      return setHourlyRequesting(requestsState, requesting);
    case timeUnitId.day:
      return setDailyRequesting(requestsState, requesting);
    case timeUnitId.week:
      return setWeeklyRequesting(requestsState, requesting);
    case timeUnitId.month:
      return setMonthlyRequesting(requestsState, requesting);
    case timeUnitId.year:
      return setYearlyRequesting(requestsState, requesting);
    default:
      return requestsState;
  }
};

const endRequestByUnit = (requestsState, action) => {
  const { meta } = action || {};
  const unit = meta.unit || meta.reqUnit;
  return setRequestsStateByUnit(requestsState, unit, false);
};

const requestApiTimeSeries = (requestsState, action) => {
  const { meta } = action || {};
  const requesting = !action.error;
  return setRequestsStateByUnit(requestsState, meta.unit, requesting);
};

const getTimeSeriesKey = obj => Object.keys(obj).filter(key => key.match(/^timeSeries/));

const requestApiMultifetch = (requestsState, action) => {
  const { meta } = action || {};
  const targetKeys = getTimeSeriesKey(meta);
  if (!targetKeys.length) {
    return requestsState;
  }
  const requesting = !action.error;
  return setRequestsStateByUnit(requestsState, meta[targetKeys[0]].unit, requesting);
};

const successApiMultifetch = (requestsState, action) => {
  const { payload, meta } = action || {};
  const targetKeys = getTimeSeriesKey(payload);
  if (!targetKeys.length) {
    return requestsState;
  }
  return setRequestsStateByUnit(requestsState, meta[targetKeys[0]].unit, false);
};

const failureApiMultifetch = (requestsState, action) => {
  const { meta } = action || {};
  const targetKeys = getTimeSeriesKey(meta);
  if (!targetKeys.length) {
    return requestsState;
  }
  return setRequestsStateByUnit(requestsState, meta[targetKeys[0]].unit, false);
};

const requestApiDayStats = (requestsState, action) => {
  const requesting = !action.error;
  return setDailyRequesting(requestsState, requesting);
};

const requestApiEnergyHourly = (requestsState, action) => {
  const { meta } = action || {};
  const requesting = !action.error;
  return setRequestsStateByUnit(requestsState, meta.reqUnit, requesting);
};

const successApiEnergyHourly = (requestsState, action) => {
  const { payload, meta } = action || {};
  if (!Object.keys(payload).length) {
    return {
      ...requestsState,
      dailyRequesting: false,
      weeklyRequesting: false,
      monthlyRequesting: false,
      yearlyRequesting: false,
    };
  }
  switch (meta.reqUnit) {
    case timeUnitId.day:
      return setDailyRequesting(requestsState, false);
    case timeUnitId.week:
      return setWeeklyRequesting(requestsState, false);
    case timeUnitId.month:
      return setMonthlyRequesting(requestsState, false);
    case timeUnitId.year:
      return setYearlyRequesting(requestsState, false);
    default: {
      return requestsState;
    }
  }
};

export default (requestsState: State = initialRequestsState, action: Action = {}): State => {
  switch (action.type) {
    case REQUEST_API_TIME_SERIES:
      return requestApiTimeSeries(requestsState, action);
    case SUCCESS_API_TIME_SERIES:
      return endRequestByUnit(requestsState, action);
    case FAILURE_API_TIME_SERIES:
      return endRequestByUnit(requestsState, action);
    case REQUEST_API_MULTIFETCH:
      return requestApiMultifetch(requestsState, action);
    case SUCCESS_API_MULTIFETCH:
      return successApiMultifetch(requestsState, action);
    case FAILURE_API_MULTIFETCH:
      return failureApiMultifetch(requestsState, action);
    case REQUEST_API_DAY_STATS:
      return requestApiDayStats(requestsState, action);
    case FAILURE_API_DAY_STATS:
      return setDailyRequesting(requestsState, false);
    case SUCCESS_API_DAY_STATS:
      return setDailyRequesting(requestsState, false);
    case LOAD_TOP_PAGE:
      return initialRequestsState;
    case RESET_CHART_PAGE:
      return initialRequestsState;
    case REQUEST_API_ENERGY_HOURLY:
      return requestApiEnergyHourly(requestsState, action);
    case SUCCESS_API_ENERGY_HOURLY:
      return successApiEnergyHourly(requestsState, action);
    case FAILURE_API_ENERGY_HOURLY:
      return endRequestByUnit(requestsState, action);
    default:
      return requestsState;
  }
};
