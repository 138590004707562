// @flow

import type { Store, Middleware, Action } from 'redux';
import type { State } from 'Client/types/state';

import {
  SUCCESS_API_LOGIN_INFO,
  SWITCH_PG_FLAG,
  SWITCH_APL_DISPLAY_FLAG,
  CHANGE_SELL_PRICE,
  TUTORIAL_FINISHED,
  OPEN_PRICE_PLAN_POP_UP,
  FAILURE_API_TIME_SERIES,
  SETTING_NORMAL_PRICE_PLAN_POP_UP,
  SETTING_MULTISTAGE_PRICE_PLAN_POP_UP,
  CHANGE_SELECTED_APPLIANCE,
} from 'Client/actions/types';
import { apiSettingsUpdate } from 'Client/actions/apis';
import { loadSettingsFromLocalStorage, checkItem } from 'Client/lib/localStorageMiddleware';
import { getUid } from 'Client/lib/mbaasAuthentication';
import { toggleApplianceFilter } from 'Client/lib/globalUtils';
import { isEmbeded } from 'Client/lib/serviceCheck';

const apiSettings = (settings: Object, state: State, store: Store) => {
  store.dispatch(apiSettingsUpdate(settings));
};

const updateSettings = (uid, settings, state, store) => {
  const { userSetting, pricePlan, chartTime, tutorial, popup } = state;
  const { haveShownStartDialog, haveShownSettingPricePlanDialog, lastDateUnavailableShown } = popup;
  const { isPg, isPgChangedByUser, sellPrice, aplFilter } = userSetting;
  const { tutorialFinished } = tutorial;
  const { pricePlan: pricePlanName, normalPlanPrice, multistagePlanSetting } = pricePlan;
  const { selectedApplianceTypeId } = chartTime;

  apiSettings(
    {
      // userSetting reducer
      isPg,
      isPgChangedByUser,
      sellPrice,
      aplFilter,
      // popup reducer
      haveShownStartDialog,
      haveShownSettingPricePlanDialog,
      lastDateUnavailableShown,
      // tutorial reducer
      tutorialFinished,
      // pricePlan reducer
      pricePlan: pricePlanName || '',
      normalPlanPrice,
      multistagePlanSetting,
      // chartTime reducer
      selectedApplianceTypeId,
      ...settings,
    },
    state,
    store
  );
};

export default (store: Store) => (next: Middleware) => (action: Action) => {
  const state: State = store.getState();
  const { userInfo } = state;
  const { idTokenState: idToken } = userInfo;
  // idTokenがある場合のときだけはしらせる
  if (!idToken) {
    next(action);
    return;
  }

  const uid = getUid();

  // 読み出し
  if (action.type === SUCCESS_API_LOGIN_INFO && !action.payload.settings) {
    const { user = {} } = action.payload || {};
    const { contractorId = '' } = user;
    // APIから設定を取得できなかった場合はlocalStorageから読み出し
    const uidLocalStorageData = checkItem(uid) || {};
    if (Object.keys(uidLocalStorageData).length !== 0) {
      store.dispatch(apiSettingsUpdate(loadSettingsFromLocalStorage(uid, state)));
    } else {
      store.dispatch(apiSettingsUpdate(loadSettingsFromLocalStorage(contractorId, state)));
    }
  }

  // 各設定変更時にfirestoreに設定保存
  // 対象reducerはuserSetting / pricePlan / popup / tutorial
  if (action.type === SWITCH_PG_FLAG) {
    updateSettings(
      uid,
      {
        isPg: action.isPg,
        isPgChangedByUser: true,
      },
      state,
      store
    );
  }

  if (action.type === CHANGE_SELL_PRICE) {
    updateSettings(
      uid,
      {
        dispSellPrice: action.price,
        sellPrice: action.isErr ? state.userSetting.sellPrice : action.price,
      },
      state,
      store
    );
  }

  if (action.type === OPEN_PRICE_PLAN_POP_UP) {
    updateSettings(
      uid,
      {
        haveShownSettingPricePlanDialog: true,
      },
      state,
      store
    );
  }

  if (action.type === SETTING_NORMAL_PRICE_PLAN_POP_UP) {
    updateSettings(
      uid,
      {
        pricePlan: 'NORMAL',
        normalPlanPrice: action.normalPlanPrice,
      },
      state,
      store
    );
  }

  if (action.type === SETTING_MULTISTAGE_PRICE_PLAN_POP_UP) {
    updateSettings(
      uid,
      {
        pricePlan: 'MULTISTAGE',
        multistagePlanSetting: action.multistagePlanSetting,
      },
      state,
      store
    );
  }

  // pgFlag、料金プランの設定、電力買取単価以外は走らせない場合
  if (isEmbeded) {
    next(action);
    return;
  }

  if (action.type === CHANGE_SELECTED_APPLIANCE) {
    updateSettings(
      uid,
      {
        selectedApplianceTypeId: action.selectedApplianceTypeId,
      },
      state,
      store
    );
  }

  if (action.type === SWITCH_APL_DISPLAY_FLAG) {
    const { id, syncIds } = action;
    const aplFilter = toggleApplianceFilter(state.userSetting.aplFilter, id, syncIds);
    updateSettings(
      uid,
      {
        aplFilter,
      },
      state,
      store
    );
  }

  if (action.type === TUTORIAL_FINISHED) {
    updateSettings(
      uid,
      {
        haveShownStartDialog: state.popup.haveShownStartDialog,
        tutorialFinished: true,
      },
      state,
      store
    );
  }

  if (action.type === FAILURE_API_TIME_SERIES) {
    updateSettings(
      uid,
      {
        lastDateUnavailableShown: new Date().toISOString(),
      },
      state,
      store
    );
  }

  next(action);
};
