// @flow

import moment from 'moment-timezone';
import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { intlShape } from 'react-intl';
import { connect } from 'react-redux';
import Loading from 'Client/components/utils/Loading';
import TopHourlyApplianceStateItem from 'Client/components/TopHourlyApplianceStateItem';
import Prefixer from 'Client/lib/Prefixer';
import { extractServiceProviderIdFromState } from 'Client/lib/globalUtils';
import type { State } from 'Client/types/state';

const prefixer = new Prefixer();

const processingHouse = require('Client/images/img_processing_house.png');

const style = prefixer.prefix({
  list: {
    display: 'table',
    tableLayout: 'fixed',
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '100%',
  },
  applianceCell: {
    display: 'table-cell',
    width: '33%',
  },
  altMessage: {
    padding: '100px 0px',
  },
  altMessageTitle: {
    width: '97%',
    margin: '0px auto',
  },
  altMesssgeText: {
    fontSize: '0.8em',
  },
  notfoundLearning: {
    textAlign: 'center',
    margin: '20px',
  },
  notfoundLearningHeader: {
    color: '#888',
    fontSize: '1.2em',
  },
  notfoundLearningFooter: {
    color: '#bbb',
    fontSize: '0.8em',
  },
  processingHouse: {
    width: '100%',
  },
  notFoundButton: {
    background: '#a1a1a1',
    color: '#fff',
    fontWeight: 'bold',
    borderRadius: '5px',
    width: '140px',
    height: '42px',
    margin: '0px auto 20px',
    lineHeight: '42px',
    padding: '0px 5px',
    textAlign: 'center',
  },
  noDisplayApl: {
    color: 'gray',
  },
});

const ApplianceList = ({ status, aplFilter, appliances, serviceProviderId, intl }) => {
  const visibleApplianceIds = aplFilter.filter(filter => filter.display).map(filter => filter.id);
  const appliancesToShow = appliances.filter(appliance =>
    visibleApplianceIds.includes(appliance.id)
  );

  // 蓄電池表示を充電 or 放電 or OFF に特定する
  const chargedStorageBatteryIndex = appliancesToShow.findIndex(
    appliance => appliance.id === '994_charged' || appliance.id === '9972_charged'
  );
  const dischargedStorageBatteryIndex = appliancesToShow.findIndex(
    appliance => appliance.id === '994_discharged' || appliance.id === '9972_discharged'
  );
  if (chargedStorageBatteryIndex !== -1 && dischargedStorageBatteryIndex !== -1) {
    const chargedStorageBattery = appliancesToShow[chargedStorageBatteryIndex];
    const dischargedStorageBattery = appliancesToShow[dischargedStorageBatteryIndex];

    if (chargedStorageBattery.duration === 0 && dischargedStorageBattery.duration === 0) {
      // off アイコンは discharged のものを使うため、charged を切り落とす
      appliancesToShow.splice(chargedStorageBatteryIndex, 1);
    } else {
      // 稼働時間の小さい方を切り落とす（等しい場合は discharged を優先する）
      appliancesToShow.splice(
        dischargedStorageBattery.duration >= chargedStorageBattery.duration
          ? chargedStorageBatteryIndex
          : dischargedStorageBatteryIndex,
        1
      );
    }
  }

  const applianceList = appliancesToShow.map((appliance, key) => {
    const cellKey = `appliance-cell-${key}`;
    const applianceKeyPrefix = `appliance-${key}`;
    return (
      <div key={cellKey} style={style.applianceCell}>
        <TopHourlyApplianceStateItem
          key={`${applianceKeyPrefix}-${appliance.id}`}
          learning={status === 'learning'}
          display
          appliance={appliance}
          serviceProviderId={serviceProviderId}
          intl={intl}
        />
      </div>
    );
  });
  const columnCount = 3;
  const rowCount = Math.ceil(applianceList.length / 3);
  const range = upTo => Array.from(Array(upTo).keys());
  const listPadding = range(columnCount - (applianceList.length % columnCount)).map(number => (
    <div key={`padded-cell-${number}`} style={style.applianceCell} />
  ));
  return (
    <div style={style.list}>
      {range(rowCount).map(rowIndex => (
        <div key={`appliance-list-row-${rowIndex}`} style={{ display: 'table-row' }}>
          {applianceList
            .concat(listPadding)
            .slice(rowIndex * columnCount, (rowIndex + 1) * columnCount)}
        </div>
      ))}
    </div>
  );
};

const ImperfectLearningMessage = ({ intl }) => (
  <div style={style.notfoundLearning}>
    <div style={style.notfoundLearningHeader}>
      {intl.formatMessage({ id: 'topHourlyApplianceStateList.da1' })}
      <br />
      {intl.formatMessage({ id: 'topHourlyApplianceStateList.da2' })}
    </div>
    <img src={processingHouse} alt={'house'} style={style.processingHouse} />
    <div style={style.notfoundLearningFooter}>
      {intl.formatMessage({ id: 'topHourlyApplianceStateList.daSub1' })}
      <br />
      {intl.formatMessage({ id: 'topHourlyApplianceStateList.daSub2' })}
      <br />
      {intl.formatMessage({ id: 'topHourlyApplianceStateList.daSub3' })}
    </div>
  </div>
);

const NoDisplayAplMessage = ({ intl }) => (
  <div style={style.altMessage}>
    <div style={style.noDisplayApl}>
      {intl.formatMessage({ id: 'topHourlyApplianceStateList.na1' })}
      <br />
      {intl.formatMessage({ id: 'topHourlyApplianceStateList.na2' })}
    </div>
  </div>
);

const MeasuringMessage = ({ intl }) => (
  <div style={style.altMessage}>
    <div style={style.altMessageTitle}>
      {intl.formatMessage({ id: 'topHourlyApplianceStateList.ms' })}
    </div>
    <div style={style.altMesssgeText}>
      {intl.formatMessage({ id: 'topHourlyApplianceStateList.msSub' })}
    </div>
  </div>
);

const NoDataMessage = ({ intl }) => (
  <div style={style.altMessage}>
    <div style={style.altMessageTitle}>
      {intl.formatMessage({ id: 'topHourlyApplianceStateList.nd' })}
    </div>
    <div style={style.altMesssgeText}>
      {intl.formatMessage({ id: 'topHourlyApplianceStateList.ndSub' })}
    </div>
  </div>
);

const hasDisplayApls = (aplFilter, idsToShow) =>
  !!aplFilter.find(apl => apl.display && idsToShow.includes(apl.id));

type StateProps = {|
  aplFilter: {
    id: string,
    display: boolean,
  }[],
  requesting: boolean,
  serviceProviderId: string,
|};
type OwnProps = {|
  status: string,
  disaggregated: boolean,
  appliances: {
    id: string,
    duration: number,
    roundedDuration: number,
  }[],
  idsToShow: (string | null)[],
  intl: intlShape,
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

export const PureTopHourlyApplianceStateList = (props: Props): Node => {
  const {
    aplFilter,
    status,
    disaggregated,
    appliances,
    serviceProviderId,
    requesting,
    idsToShow,
    intl,
  } = props;

  if (requesting) {
    return <Loading space={80} />;
  }
  if (status === 'imperfect_learning') {
    return <ImperfectLearningMessage intl={intl} />;
  }
  if (!disaggregated) {
    if (status === 'learning' || status === 'not_estimable') {
      return null;
    }
    // TODO momentをcomponentで使うのはNG
    if (moment().minute() > 10) {
      return <NoDataMessage intl={intl} />;
    }
    return <MeasuringMessage intl={intl} />;
  }
  if (!hasDisplayApls(aplFilter, idsToShow) && !requesting) {
    if (status === 'learning') {
      return null;
    }
    return <NoDisplayAplMessage intl={intl} />;
  }
  return (
    <ApplianceList
      status={status}
      aplFilter={aplFilter}
      appliances={appliances}
      serviceProviderId={serviceProviderId}
      intl={intl}
    />
  );
};

PureTopHourlyApplianceStateList.defaultProps = {
  idsToShow: [],
};

const mapStateToProps = (state: State): StateProps => ({
  aplFilter: state.userSetting.aplFilter,
  requesting: state.applianceState.requesting,
  serviceProviderId: extractServiceProviderIdFromState(state),
});

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  PureTopHourlyApplianceStateList
);
