// @flow

import React from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import type { Location } from 'react-router-dom';
import { INIQUITY_CERTIFICATION, RESET_CERTIFICATION } from 'Client/actions/types';
import { apiCertificateMail, apiCheckActionCode } from 'Client/actions/apis';
import type { State } from 'Client/types/state';

type StateProps = {|
  certificationState: string,
|};
type OwnProps = {|
  location: Location,
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

const parseSearch = (search: string): { [key: string]: string } => {
  const searchStr = search.substr(1);
  const params = {};
  searchStr.split('&').forEach(x => {
    const i = x.indexOf('=');
    const key = x.slice(0, i);
    const val = x.slice(i + 1);
    params[key] = decodeURIComponent(val);
  });
  return params;
};

class Certification extends React.Component<Props> {
  componentDidMount() {
    const { dispatch } = this.props;
    const params = parseSearch(this.props.location.search);

    const isMailChanging = !!params.token;
    const isPasswordReset = params.mode === 'resetPassword';
    const hasAccessAuthority = isMailChanging || isPasswordReset;
    if (!hasAccessAuthority) {
      dispatch({ type: INIQUITY_CERTIFICATION });
    }

    if (isMailChanging) {
      dispatch(apiCertificateMail(params.token));
    }

    if (isPasswordReset) {
      dispatch(apiCheckActionCode(params.oobCode));
    }
  }

  componentWillUnmount() {
    this.props.dispatch({ type: RESET_CERTIFICATION });
  }

  render() {
    switch (this.props.certificationState) {
      case 'SUCCESS':
        return <Redirect to="/completed/mailchanged" />;
      case 'ERROR':
        return <Redirect to="/error/expired" />;
      case 'INIQUITY':
        return <Redirect to="/login" />;
      case 'PASSWORD_CHANGE': {
        const { oobCode } = parseSearch(this.props.location.search);
        return <Redirect to={`/reset/password/register?oobCode=${oobCode}`} />;
      }
      default:
        return <div />;
    }
  }
}

const mapStateToProps = (state: State): StateProps => ({
  certificationState: state.certification.certificationState,
});

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  Certification
);
