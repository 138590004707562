// @flow

import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { intlShape } from 'react-intl';
import TopHourlyApplianceStateList from 'Client/components/TopHourlyApplianceStateList';
import QuestionIcon from 'Client/components/utils/QuestionIcon';
import Prefixer from 'Client/lib/Prefixer';
import { applianceId } from 'Client/lib/id';
import { OPEN_POP_UP } from 'Client/actions/types';
import { HELP_HOME_BOTTOM } from 'Client/actions/helpPopupTypes';
import type { State, ApplianceState } from 'Client/types/state';
import NotEstimable from 'Client/components/NotEstimable';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  title: {
    color: '#908572',
    textAlign: 'center',
    padding: '0px',
    margin: '10px 0px',
    fontSize: '0.8em',
    width: '100%',
    height: '25px',
    lineHeight: '25px',
    background: '#f2efe0',
    borderRadius: '30px',
    position: 'relative',
  },
  updateButton: {
    width: '20px',
    height: 'auto',
    border: 'none',
    float: 'right',
    margin: '2px 5px 2px 0px',
  },
  updateButtonDisable: {
    opacity: '0.3',
  },
  learningTitle: {
    color: '#aaa',
    textAlign: 'center',
    fontSize: '1.0em',
    marginBottom: '5px',
  },
  learningText: {
    color: '#bbb',
    textAlign: 'center',
    fontSize: '0.7em',
    marginBottom: '25px',
  },
  info: {
    color: 'rgb(177, 169, 154)',
    padding: '0px 10px 0px 0px',
    top: '10%',
    right: '10%',
    position: 'absolute',
    transform: 'translate(85%, 0%)',
  },
});

// TODO selectorにできる
const selectedHourApls = (selectedHour, hourlyDurations, idsToShow = []) => {
  const hourlyDuration = hourlyDurations.find(hd => hd.hour === selectedHour);
  if (!hourlyDuration) {
    return [];
  }

  return hourlyDuration.appliances
    .map(({ id, duration }) => {
      if (!idsToShow.includes(id)) {
        return null;
      }
      if (duration > 0) {
        const { airConditioner, riceCooker, clothesWasher, tv } = applianceId;
        const threeMinsRoundedApls = [airConditioner, riceCooker, clothesWasher, tv];
        if (duration <= 3 && threeMinsRoundedApls.includes(id)) {
          return { id, duration, roundedDuration: 0 };
        }
        const roundedDuration = duration % 10 > 0 ? Math.floor(duration / 10 + 1) * 10 : duration;
        return { id, duration, roundedDuration };
      }

      return { id, duration, roundedDuration: 0 };
    })
    .filter(v => v);
};

const learningMain = (appliances, intl) => {
  const message = appliances.find(x => x && x.duration)
    ? intl.formatMessage({ id: 'topHourlyApplianceState.lm1' })
    : intl.formatMessage({ id: 'topHourlyApplianceState.lm2' });
  return message;
};

const renderLearningMessage = (selectedHour, hourlyDurations, idsToShow, intl) => (
  <div>
    <div style={style.learningTitle}>
      {learningMain(selectedHourApls(selectedHour, hourlyDurations, idsToShow), intl)}
    </div>
    <div style={style.learningText}>
      {intl.formatMessage({ id: 'topHourlyApplianceState.lsm1' })}
      <br />
      {intl.formatMessage({ id: 'topHourlyApplianceState.lsm2' })}
    </div>
  </div>
);

const renderCurrentTimeAlt = intl => (
  <span>{intl.formatMessage({ id: 'topHourlyApplianceState.alt' })}</span>
);

type StateProps = {|
  status: string,
  applianceState: ApplianceState,
  idsToShow: (string | null)[],
|};
type OwnProps = {|
  selectedHour: number,
  intl: intlShape,
  dispatch: Dispatch,
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
|};

export const PureTopHourlyApplianceState = ({
  status,
  selectedHour,
  applianceState,
  idsToShow,
  intl,
  dispatch,
}: Props): Node => (
  <div>
    <div style={style.title}>
      {intl.formatMessage({ id: 'topHourlyApplianceState.title' })}
      &nbsp;
      {status === 'learning' && renderCurrentTimeAlt(intl)}
      <div style={style.info}>
        <QuestionIcon
          onClick={e => {
            dispatch({
              type: OPEN_POP_UP,
              contentType: HELP_HOME_BOTTOM,
              target: e.target,
            });
          }}
        />
      </div>
    </div>
    <TopHourlyApplianceStateList
      status={status}
      disaggregated={applianceState.disaggregated}
      appliances={selectedHourApls(selectedHour, applianceState.hourlyDurations, idsToShow)}
      idsToShow={idsToShow}
      intl={intl}
    />
    {applianceState.disaggregated &&
      status === 'learning' &&
      renderLearningMessage(selectedHour, applianceState.hourlyDurations, idsToShow, intl)}
    <NotEstimable type="top" />
  </div>
);

PureTopHourlyApplianceState.defaultProps = {
  idsToShow: [],
};

const mapStateToProps = (state: State): StateProps => ({
  status: state.userInfo.status,
  applianceState: state.applianceState,
  idsToShow: state.applianceState.idsToShow,
});

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  PureTopHourlyApplianceState
);
