// @flow

import React from 'react';
import type { Node } from 'react';
import Prefixer from 'Client/lib/Prefixer';

const prefixer = new Prefixer();

const styles = prefixer.prefix({
  questionIconContainer: {
    display: 'inline-block',
    width: 20,
    height: 20,
    cursor: 'pointer',
  },
  questionIcon: {
    width: '100%',
    height: 'auto',
  },
});

type Props = {
  style?: Object,
  onClick?: (e: SyntheticEvent<HTMLElement>) => void,
};

const QuestionIcon = (props: Props): Node => {
  const QuestionImage = require('Client/images/question.svg');
  return (
    <span style={{ ...styles.questionIconContainer, ...props.style }} onClick={props.onClick}>
      <img src={QuestionImage} width="20" height="20" alt="question" style={styles.questionIcon} />
    </span>
  );
};

QuestionIcon.defaultProps = {
  style: {},
  onClick: () => {},
};

export default QuestionIcon;

export const QuestionIconForTest = QuestionIcon;
