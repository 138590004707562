// @flow

import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import sizeMe from 'react-sizeme';
import TopDailyChargeArea from 'Client/components/TopDailyChargeArea';
import TopTotalAmountArea from 'Client/components/TopTotalAmountArea';
import TopTimeBar from 'Client/components/TopTimeBar';
import TopHourlyAnalysisArea from 'Client/components/TopHourlyAnalysisArea';
import TopHouseAnimation from 'Client/components/TopHouseAnimation';
import ContentContainer from 'Client/components/utils/ContentContainer';
import Prefixer from 'Client/lib/Prefixer';
import type { State } from 'Client/types/state';

const prefixer = new Prefixer();
const preInstallation = require('Client/images/pre_installation.png');
const loadingAnime = require('Client/images/loading_top.gif');

const style = prefixer.prefix({
  upperArea: {
    background: '#ffbd00',
    width: '100%',
    padding: '20px 0px 0px',
  },
  balloon: {
    background: '#fff',
    borderRadius: '5px',
    border: '1px solid white',
    width: '90%',
    margin: '0px auto',
    padding: '0px 0px 0px 0px',
  },
  lowerArea: {
    display: 'table',
    width: '92%',
    margin: '15px auto',
    padding: '0px',
  },
  timeBar: {
    display: 'table-cell',
    width: '25%',
    margin: '0px',
    padding: '0px',
    fontWeight: 'bold',
    color: '#847965',
  },
  hourlyAnalysisArea: {
    width: '68%',
    display: 'table-cell',
    textAlign: 'center',
    margin: '0px',
    padding: '0px',
    fontWeight: 'bold',
  },
  infoBackground: {
    height: '100vh',
    background: '#ffbd00',
  },
  infoTitle: {
    fontSize: '1.3em',
    color: '#fff',
    fontWeight: 'bold',
    padding: '30px 0px 10px',
    textAlign: 'center',
    width: '90%',
    margin: '0px auto',
  },
  infoImage: {
    width: '100%',
  },
  infoBox: {
    borderRadius: '3px',
    background: '#fff',
    width: '80%',
    maxWidth: '340px',
    margin: '10px auto 10px',
    textAlign: 'center',
    padding: '20px',
    color: '#555',
    fontWeight: 'bold',
  },
  infoText: {
    color: '#847965',
    fontWeight: 'bold',
    fontSize: '0.9em',
    textAlign: 'center',
    width: '90%',
    margin: '0px auto',
  },
  infoAnotation: {
    color: '#888',
  },
  infoLink: {
    color: '#555',
  },
  infoButton: {
    background: '#ffb835',
    fontWeight: 'bold',
    textAlign: 'center',
    margin: '15px auto 0',
    padding: '10px',
    width: '80%',
    display: 'block',
    color: '#fff',
    borderRadius: '5px',
  },
  loadingAnimeWp: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
    margin: '30% 0px',
    height: '300px',
  },
  loadingAnime: {
    width: '80%',
    maxWidth: '300px',
  },
  howToConnectBtn: {
    color: 'white',
    textDecoration: 'none',
  },
});

const renderNormal = (isPg, range, aplFilter, hourlyDurations, dispatch, locale, alertInfo) => (
  <div>
    <div style={style.upperArea}>
      <div style={style.balloon}>
        {isPg ? <TopDailyChargeArea /> : <TopTotalAmountArea dispatch={dispatch} />}
      </div>
      <TopHouseAnimation
        range={range}
        aplFilter={aplFilter}
        hourlyDurations={hourlyDurations}
        alertInfo={alertInfo}
        dispatch={dispatch}
      />
    </div>
    <div style={style.lowerArea}>
      <div style={style.timeBar}>
        <TopTimeBar dispatch={dispatch} />
      </div>
      <div style={style.hourlyAnalysisArea}>
        <TopHourlyAnalysisArea dispatch={dispatch} />
      </div>
    </div>
  </div>
);

const showPreInstallation = intl =>
  intl.formatMessage({ id: 'topBody.showPreInstallation' }) === 'true';

declare var config: { URLS: Object[] };
const renderPreInstallation = intl => {
  const { Q_WAITING_ONE_WEEK } = config.URLS[intl.locale.toUpperCase()] || {};

  return (
    <div style={style.infoBackground}>
      <div style={style.infoTitle}>
        {intl.formatMessage({ id: 'topBody.waitingT1' })}
        <br />
        {intl.formatMessage({ id: 'topBody.waitingT2' })}
      </div>
      <div style={style.infoBox}>
        <img src={preInstallation} alt="preInstallation" style={style.infoImage} />
        <div style={style.infoText}>
          {intl.formatMessage({ id: 'topBody.waitingDF1' })}
          <br />
          {intl.formatMessage({ id: 'topBody.waitingDF2' })}
          <br />
          {intl.formatMessage({ id: 'topBody.waitingDF3' })}
          <br />
          <br />
          {intl.formatMessage({ id: 'topBody.waitingDS1' })}
          <br />
          {intl.formatMessage({ id: 'topBody.waitingDS2' })}
          <br />
          <br />
          {intl.formatMessage({ id: 'topBody.waitingDT1' })}
          <br />
          {intl.formatMessage({ id: 'topBody.waitingDT2' })}
          <br />
          <br />
          {showPreInstallation(intl) && (
            <div style={style.infoAnotation}>
              {intl.formatMessage({ id: 'topBody.waitingA1' })}
              <a
                href={Q_WAITING_ONE_WEEK}
                target="_blank"
                rel="noopener noreferrer"
                style={style.infoLink}
              >
                {intl.formatMessage({ id: 'topBody.waitingA2' })}
              </a>
              {intl.formatMessage({ id: 'topBody.waitingA3' })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const showNullRootPowerButton = intl =>
  intl.formatMessage({ id: 'topBody.showNullRootPowerButton' }) === 'true';

const renderButton = intl => {
  const { Q_HOW_TO_CONNECT } = config.URLS[intl.locale.toUpperCase()] || {};

  return (
    <div style={style.infoButton}>
      <a
        href={Q_HOW_TO_CONNECT}
        target="_blank"
        rel="noopener noreferrer"
        style={style.howToConnectBtn}
      >
        {intl.formatMessage({ id: 'topBody.disconnectedButton' })}
      </a>
    </div>
  );
};

const renderNullRootPower = intl => {
  const nullRootPower = require(`Client/images/intro_ja.png`);
  return (
    <div style={style.infoBackground}>
      <div style={style.infoTitle}>
        {intl.formatMessage({ id: 'topBody.disconnectedT1' })}
        <br />
        {intl.formatMessage({ id: 'topBody.disconnectedT2' })}
      </div>
      <div style={style.infoBox}>
        <img src={nullRootPower} alt="nullRootPower" style={style.infoImage} />
        <div style={style.infoText}>
          {intl.formatMessage({ id: 'topBody.disconnectedD1' })}
          <br />
          {intl.formatMessage({ id: 'topBody.disconnectedD2' })}
        </div>
        {showNullRootPowerButton(intl) && renderButton(intl)}
      </div>
    </div>
  );
};

const renderLoadingAnime = () => (
  <div style={style.loadingAnimeWp}>
    <img src={loadingAnime} alt="loading" style={style.loadingAnime} />
  </div>
);

const renderBody = (
  isPg,
  range,
  aplFilter,
  hourlyDurations,
  userStatus,
  dispatch,
  intl,
  alertInfo
) => {
  switch (userStatus) {
    case 'pre_installation':
      return renderPreInstallation(intl);
    case 'null_root_power':
      return renderNullRootPower(intl);
    default:
      return renderNormal(
        isPg,
        range,
        aplFilter,
        hourlyDurations,
        dispatch,
        intl.locale,
        alertInfo
      );
  }
};

const bodyFontSize = width => (width >= 400 ? '16px' : '12px');

type StateProps = {|
  isPg: boolean,
  range: number[],
  aplFilter: {
    id: string,
    display: boolean,
  }[],
  hourlyDurations: {
    hour: number,
    appliances: {
      id: string,
      duration: number,
    }[],
  }[],
  userStatus: string,
  statusRequesting: boolean,
  infoRequesting: boolean,
  alertInfo: {
    noUpload: boolean,
    noSolar: boolean,
  },
|};
type OwnProps = {|
  intl: intlShape,
  size: {
    width: number,
    height: number,
  },
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

export const PureTopBody = ({
  isPg,
  range,
  aplFilter,
  hourlyDurations,
  userStatus,
  statusRequesting,
  infoRequesting,
  dispatch,
  intl,
  size,
  alertInfo,
}: Props): Node => (
  <ContentContainer style={{ fontSize: bodyFontSize(size.width) }}>
    {statusRequesting || infoRequesting
      ? renderLoadingAnime()
      : renderBody(isPg, range, aplFilter, hourlyDurations, userStatus, dispatch, intl, alertInfo)}
  </ContentContainer>
);

const mapStateToProps = (state: State): StateProps => ({
  isPg: state.userSetting.isPg,
  range: state.timeRange.range,
  aplFilter: state.userSetting.aplFilter,
  hourlyDurations: state.applianceState.hourlyDurations,
  userStatus: state.userInfo.status,
  statusRequesting: state.userInfo.statusRequesting,
  infoRequesting: state.userInfo.infoRequesting,
  alertInfo: state.applianceState.alertInfo,
});

const sizeMeHOC = sizeMe({ monitorHeight: true });

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  sizeMeHOC(injectIntl(PureTopBody))
);
