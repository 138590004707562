// @flow

import React from 'react';
import PropTypes from 'prop-types';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { CHANGE_CHART_UNIT, RESET_CHART_PAGE } from 'Client/actions/types';
import { latestPeriod, isOneHourPassed } from 'Client/lib/chartUtils';
import Prefixer from 'Client/lib/Prefixer';
import type { State, EnergyReducerChartState } from 'Client/types/state';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  unitAreaFixed: {
    position: 'fixed',
    zIndex: '10000',
    left: '0px',
    right: '0px',
    top: '0px',
    height: '52px',
    width: '100%',
    marginTop: '57px',
    backgroundColor: '#ffffff',
  },
  unitCell: {
    width: '25%',
    height: '50px',
    display: 'inline-block',
    fontWeight: 'bold',
    color: '#c5c5c5',
    backgroundColor: '#ffffff',
    borderBottom: '2px solid #c5c5c5',
  },
  unitCellSelected: {
    borderBottom: '2px solid #ffbd00',
  },
  unitTitle: {
    marginTop: '12px',
  },
  unitTitleSelected: {
    color: '#ffbd00',
  },
  unitSelectedDisable: {
    color: '#ffe7b2',
  },
  unitDisable: {
    color: '#d5d5d5',
  },
});

const clickUnit = (
  requesting,
  unitEnergyList,
  subUnitEnergyList,
  unit,
  currentTime,
  dispatch,
  router
) => {
  if (requesting) {
    return;
  }
  const period = latestPeriod(unit);
  if (isOneHourPassed(currentTime)) {
    dispatch({ type: RESET_CHART_PAGE });
  }
  dispatch({ type: CHANGE_CHART_UNIT, unit });

  router.history.push(`/chart/${unit}/${period}`);
};

const unitTitle = (unit, intl) => {
  switch (unit) {
    case 'day':
      return intl.formatMessage({ id: 'chartTimeUnit.day' });
    case 'week':
      return intl.formatMessage({ id: 'chartTimeUnit.week' });
    case 'month':
      return intl.formatMessage({ id: 'chartTimeUnit.month' });
    case 'year':
      return intl.formatMessage({ id: 'chartTimeUnit.year' });
    default:
      return '';
  }
};

const unitStyle = (requesting, displayUnit, selectedUnit) => {
  if (requesting) {
    return displayUnit === selectedUnit
      ? { ...style.unitTitle, ...style.unitSelectedDisable }
      : { ...style.unitTitle, ...style.unitDisable };
  }
  return displayUnit === selectedUnit
    ? { ...style.unitTitle, ...style.unitTitleSelected }
    : style.unitTitle;
};

const cellStyle = (serviceProviderId, displayUnit, selectedUnit) =>
  displayUnit === selectedUnit ? { ...style.unitCell, ...style.unitCellSelected } : style.unitCell;

const renderUnitCell = (
  requesting,
  unitEnergyList,
  subUnitEnergyList,
  displayUnit,
  selectedUnit,
  currentTime,
  dispatch,
  intl,
  serviceProviderId,
  router
) => (
  <div
    style={cellStyle(serviceProviderId, displayUnit, selectedUnit)}
    onClick={() =>
      clickUnit(
        requesting,
        unitEnergyList,
        subUnitEnergyList,
        displayUnit,
        currentTime,
        dispatch,
        router
      )
    }
  >
    <div style={unitStyle(requesting, displayUnit, selectedUnit)}>
      {unitTitle(displayUnit, intl)}
    </div>
  </div>
);

type StateProps = {|
  energyList: EnergyReducerChartState,
  selectedUnit: string,
  currentTime: string,
  serviceProviderId: string,
|};
type OwnProps = {|
  requesting: boolean,
  intl: intlShape,
|};
type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

const PureChartTimeUnit = (
  { requesting, energyList, selectedUnit, currentTime, dispatch, intl, serviceProviderId }: Props,
  { router }: Object
): Node => (
  <div style={style.unitAreaFixed}>
    {renderUnitCell(
      requesting,
      energyList.weekly,
      energyList.daily,
      'day',
      selectedUnit,
      currentTime,
      dispatch,
      intl,
      serviceProviderId,
      router
    )}
    {renderUnitCell(
      requesting,
      energyList.weekly,
      energyList.daily,
      'week',
      selectedUnit,
      currentTime,
      dispatch,
      intl,
      serviceProviderId,
      router
    )}
    {renderUnitCell(
      requesting,
      energyList.monthly,
      energyList.weekly,
      'month',
      selectedUnit,
      currentTime,
      dispatch,
      intl,
      serviceProviderId,
      router
    )}
    {renderUnitCell(
      requesting,
      energyList.yearly,
      energyList.monthly,
      'year',
      selectedUnit,
      currentTime,
      dispatch,
      intl,
      serviceProviderId,
      router
    )}
  </div>
);

PureChartTimeUnit.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state: State): StateProps => ({
  energyList: {
    daily: state.energy.daily,
    weekly: state.energy.weekly,
    monthly: state.energy.monthly,
    yearly: state.energy.yearly,
  },
  selectedUnit: state.chartTime.unit,
  currentTime: state.chartTime.currentTime,
  serviceProviderId: state.userInfo.serviceProviderId,
});

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  injectIntl(PureChartTimeUnit)
);

export const PureChartTimeUnitForTest = PureChartTimeUnit;
