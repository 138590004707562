// @flow
import React from 'react';
import type { State } from 'Client/types/state';
import type { Dispatch } from 'redux';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import Prefixer from 'Client/lib/Prefixer';

const prefixer = new Prefixer();
const topEconowaImage = require('Client/images/top_econowa.png');
const rankingEconowaImage = require('Client/images/ranking_econowa.png');

const styles = prefixer.prefix({
  img: {
    width: '100%',
  },
});

type StateProps = {|
  userStatus: string,
|};

type OwnProps = {|
  type: 'top' | 'ranking',
  style: Object,
  intl: intlShape,
|};

type Props = {|
  ...StateProps,
  ...OwnProps,
  dispatch: Dispatch,
|};

export const PureNotEstimable = ({ userStatus, type, style }: Props) => {
  if (userStatus !== 'not_estimable') {
    return null;
  }
  const img = type === 'top' ? topEconowaImage : rankingEconowaImage;
  return (
    <div style={{ ...style }}>
      <a href="https://uchiwake-to-econowa.web.app/" target="_blank" rel="noopener noreferrer">
        <img src={img} alt="econowa" style={styles.img} />
      </a>
    </div>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  userStatus: state.userInfo.status,
});

export default connect<Props, OwnProps, StateProps, {}, State, Dispatch>(mapStateToProps)(
  injectIntl(PureNotEstimable)
);
