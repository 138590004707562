/* eslint-env browser */
/* eslint class-methods-use-this: 0 */

const requiresWebkitPrefix =
  typeof navigator === 'undefined' ||
  /OS 8_[0-9_]+ like Mac OS X/.test(navigator.userAgent) ||
  /SamsungBrowser/.test(navigator.userAgent);

const prefixer = styles => {
  if (typeof styles !== 'object') return styles;
  if (Array.isArray(styles)) return styles.map(value => prefixer(value));

  const newStyles = {};
  Object.keys(styles).forEach(key => {
    const value = prefixer(styles[key]);
    newStyles[key] = value;

    // convert values
    if (requiresWebkitPrefix && value.startsWith && value.startsWith('flex')) {
      newStyles[key] = value.replace(/^flex/, '-webkit-flex');
    }

    // convert keys
    if (key.startsWith('flex')) {
      newStyles[key.replace(/^flex/, 'WebkitFlex')] = value;
    } else if (key.startsWith('justifyContent')) {
      newStyles[key.replace(/^justifyContent/, 'WebkitJustifyContent')] = value;
    } else if (key.startsWith('alignItems')) {
      newStyles[key.replace(/^alignItems/, 'WebkitAlignItems')] = value;
    } else if (key.startsWith('alignContent')) {
      newStyles[key.replace(/^alignContent/, 'WebkitAlignContent')] = value;
    } else if (key.startsWith('transform')) {
      newStyles[key.replace(/^transform/, 'WebkitTransform')] = value;
    } else if (key.startsWith('appearance')) {
      newStyles[key.replace(/^appearance/, 'WebkitAppearance')] = value;
    } else if (key.startsWith('Appearance')) {
      newStyles[key.replace(/^Appearance/, 'WebkitAppearance')] = value;
    }
  });
  return newStyles;
};

export default class {
  prefix(obj) {
    return prefixer(obj);
  }
}
